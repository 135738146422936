import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  //padding: 0 36px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    /* margin-top: 30px; */
    //padding: 4vh 2vh 0vh 2vh;
    padding: 36px;
    
    div.divider {
      width: 100%;
      height: 0.6px;
      margin-top: 17.5px;
      margin-bottom: 17.5px;
    }
  }
`;

export const Logo = styled.img`
  display: block;
  object-fit: contain;
  width: 106px;
  margin-right: 30px;
`;
export const TegraBlog = styled.div`
  display: flex;
  align-items: center;

  p {
    width: 60px;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.23;
    margin-right: 19.1px;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
`;

export const Description = styled.p`
  font-size: 12px;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.12px;
  text-align: left;
  //width: 350px;
  margin-left: 20.4px;

  @media (max-width: 600px) {
    margin-left: 0;
  }
  max-width: 100%;
  color: #ffffff;
`;
