import styled from 'styled-components';
import React from 'react';
import TegraNav from '../TegraNav';
import TegraFooter from '../TegraFooter';
import footerLinks from '../../model/footerLinks';
import { useAuth } from '../../hooks/auth';

import { getLogoByTipo, getTituloByTipo } from '../../utils/logo';

import logoSioux from '../../assets/logo-sioux-b.svg';

const HomeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
`;

const SignatureSioux = styled.div`
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;

    span {
        line-height: 10px;
        color: #adadab;
    }
    
    a {
        text-indent: -9999px;
        display: inline-flex;
        margin: 0 5px;
        width: 48px;
        height: 12px;
        background-color: #adadab;
        -webkit-mask-image: url(${logoSioux});
        mask-image: url(${logoSioux});
        align-items: center;
    }
`;

const title = '';

const anoAtual = new Date().getFullYear();
const footerTrademark = '© ' + anoAtual + ' TEGRA Incorporadora. All rights reserved.';

const BannerWrapper: React.FC = ({ children }) => {
  const { user } = useAuth();

  return (
    <HomeWrapper>
      <TegraNav
        title={title}
        description={getTituloByTipo(user.tipo)}
        tegraLogo={getLogoByTipo(user.idCargoSigavi == 14 || user.idCargoSigavi == 27 ? 0 : user.tipo, user.isLab)}
      />      

      {children}
      <TegraFooter
        title={title}
        description={getTituloByTipo(user.tipo)}
        links={footerLinks}
        tegraLogo={getLogoByTipo(user.tipo, user.isLab)}
        trademark={footerTrademark}
        isParceiro={user.tipo === 0}
        isTegraUser={user}
      />
      <SignatureSioux>
            <span>Desenvolvido por <a href="https://sioux.ag" target="_blank" title="Desenvolvido por Sioux digital 1:1">Agência de marketing digital</a></span>
      </SignatureSioux>
    </HomeWrapper>
  );
};

export default BannerWrapper;
