import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  DropdownParent,
  DropdownContent,
  DropdownChild,
  DropdownItem,
  DropdownUserImage,
  DropdownLabel,
} from './styles';
import { UndecoratedLink } from '../../../UndecoratedLink/styles';
import { MenuData } from '../../NavMain/NavMenu';
import { useAuth } from '../../../../hooks/auth';
import defaultImage from '../../../../assets/avatar-white.svg';

interface DropdownProps {
  menuItem: MenuData;
  profile?: boolean;
  icon?: string;
  name?: string;
  setModalRegional?: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  menuItem,
  profile,
  icon,
  setModalRegional,
}) => {
  const [active, setActive] = useState(false);
  const node = useRef() as React.MutableRefObject<HTMLLIElement>;
  const { path } = useRouteMatch();
  const { signOut } = useAuth();
  const history = useHistory();

  const signUserOut = async (): Promise<void> => {
    await signOut();
    history.push('/');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (e: MouseEvent): void => {
    if (node.current.contains(e.target as any)) {
      return;
    }
    setActive(false);
  };

  const onClick = useCallback(
    (e, item) => {
      if (item.changeRegional) {
        e.preventDefault();
        setModalRegional(true);
      }
    },
    [setModalRegional],
  );

  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [active]);
  return (
    <DropdownItem ref={node} onClick={() => setActive(!active)}>
      <DropdownParent active={active}>
        <DropdownLabel onFocus={() => {}} onBlur={() => {}} active={active}>
          {profile ? `Meu perfil` : menuItem.label}
        </DropdownLabel>
        {profile ? (
          <DropdownUserImage
            src={icon || defaultImage}
            onError={(e: any) => {
              e.target.src = defaultImage;
            }}
          />
        ) : (
          ''
        )}
      </DropdownParent>
      <DropdownContent active={active}>
        {menuItem.children &&
          menuItem.children.map(menuChildItem => (
            <UndecoratedLink
              to={`${path}${menuChildItem.link}`}
              key={menuChildItem.link}
              onClick={e => onClick(e, menuChildItem)}
            >
              <DropdownChild>{menuChildItem.label}</DropdownChild>
            </UndecoratedLink>
          ))}
        {profile && (
          <UndecoratedLink to="#">
            <DropdownChild onClick={async () => await signUserOut()}>Sair</DropdownChild>
          </UndecoratedLink>
        )}
      </DropdownContent>
    </DropdownItem>
  );
};

export default Dropdown;
