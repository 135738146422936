import styled from "styled-components";
import colors from "../../../styles/colors";
import chevron from "../../../assets/tegra-arrow-down-icon.svg";

export const ContactsSalesFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.875rem;
    @media(min-width: 992px) {
        display: none;
    }
`;

export const ContactsSalesFilterLabel = styled.label`
    margin-bottom: 0.75rem;
`;

export const ContactsSalesFilterSelect = styled.select`
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    background-color: white;
    border: 1px solid ${colors.inputBorder};
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;  
    font-family: Montserrat;
    font-size: 13px;
`;

export const ContactsSalesFilterSelectWrapper = styled.div`
    position: relative;
    &::after{
        pointer-events: none;
        content: "";
        background-image: url(${chevron});
        position: absolute;
        display: block;
        height: 80%;
        width: 40px;
        right: 0;
        border-left: 1px solid ${colors.inputBorder};
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 8px;
        top: 10%;
    }
`;
export const ContactsSalesFilterOption = styled.option`
`;