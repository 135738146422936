import styled from "styled-components";

export const NavMenuItem = styled.li`
    font-family: Montserrat;
    font-size: 12.3px;
    //font-size: 14px;
    color: white;
    width: 100%;
    font-weight: 500;    
    text-align: right;
    list-style: none;
    border-bottom: 1px #5a5a57 solid;
    padding: 1.25rem 0 0.625rem;
    @media(min-width: 992px) {
        width: unset;  
        padding: 0;
        padding-right: 1rem;
        margin-right: 0.4rem;
        margin-left: 0.4rem;
        border-bottom: none;
        display: flex;
        height: 100%;
        align-items: center;
    }
`

export const NavItemContainer = styled.div`
    position: relative;
`