import styled from 'styled-components';
import { UndecoratedLink } from '../UndecoratedLink/styles';

export const ContactBarContainer = styled.div`
  position: fixed;
  background-color: #d74128;
  display: flex;
  bottom: 0;
  z-index: 100;
  width: 100%;
  @media (min-width: 767px) {
    top: 12rem;
    width: 100px;
    bottom: unset;
    right: 0;
    flex-direction: column;
  }
`;

export const TitleBreak = styled.br`
  @media (min-width: 767px) {
    display: none;
  }
`;

export const ContactBarTitle = styled.div`
  text-transform: uppercase;
  flex-basis: 25%;
  display: flex;
  text-align: left;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 0 5px;
  height: 60px;
  max-width: 110px;
  @media (min-width: 767px) {
    text-align: center;
    padding: 15px;
    padding-bottom: 12px;
    text-transform: none;
    font-size: 16px;
    text-decoration: none;
  }
`;

export const ContactBarItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #821b14;
  margin-left: 3px;
  padding: 0.5rem;
  flex-grow: 1;
  @media (min-width: 767px) {
    justify-content: space-between;
    margin-left: 0;
    margin-top: 3px;
    height: 70px;
    padding: 0.7rem 0.2rem;
    a,
    button {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;
      background: transparent;
      border: none;
      text-decoration: none;
    }
  }
`;

export const ItemIcon = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
`;

export const ItemBody = styled.span`
  display: none;
  font-size: 14px;
  color: white;
  @media (min-width: 767px) {
    display: block;
    font-size: 12px;
  }
`;

export const FormEmail = styled.div`
  position: fixed;
  width: 325px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: auto;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=3,Direction=135,Color=#000000);

  & .header-email {
    height: 30px;
    background-color: #d74127;
    color: #fff;
    padding-left: 15px;
    position: relative;
    line-height: 30px;
    font-weight: 600;
    margin: -1px;
    & .close-email {
      position: absolute;
      right: 0;
      top: 0;
      line-height: 30px;
      height: 30px;
      width: 30px;
      text-align: center;
      background-color: #821b14;
      font-family: monospace;
      font-size: 25px;
      border: none;
      color: #fff;
    }
  }
  & .body-email {
    overflow: auto;
    font-size: 13px;
    padding: 20px;

    & .formContato {
      & .box-input {
        position: relative;
        margin-bottom: 20px;

        & .input {
          margin: 0;
          outline: none;
          transition: 0.1s all ease-in;
          height: 35px;
          line-height: 35px;
          width: 100%;
          font-size: 15px;
          border: none;
          border-bottom: 1px solid #60605d;
        }

        & .label {
          transition: 0.2s cubic-bezier(0, 1.3, 1, 1) all;
          position: absolute;
          left: 0;
          bottom: 8px;
          pointer-events: none;
          font-weight: 300;
          font-size: 15px;
          color: #adadab;
        }
      }

      & .box-textarea {
        & .textarea {
          width: 100%;
          padding-top: 10px;
          padding-left: 10px;
          height: 105px;
          resize: none;
          font-size: 100%;
          font-family: inherit;
          border: 1px solid #60605d;
        }
      }

      & .checkbox {
        margin: 20px 0;
        & .texto-check {
          color: #9b9fa3;
          position: relative;

          input {
            margin: 0;
            outline: none;
            &:checked ~ .checkmark {
              background-color: #fcb813;
              &::after {
                display: block;
              }
            }
          }

          .checkmark {
            position: absolute;
            height: 20px;
            width: 20px;
            top: -2px;
            left: 0;
            background-color: #ccc;
            &:after {
              content: '';
              left: 7px;
              top: 1px;
              width: 6px;
              height: 14px;
              display: none;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
            }
          }

          span {
            padding-left: 10px;
            a {
              color: #ebb92d;
              text-decoration: none;
            }
          }
        }
      }

      .btn {
        height: 30px;
        line-height: 30px;
        padding: 0 30px;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        background-color: #ebb92d;
        display: inline-block;
        margin: 0 auto;
        border: none;
        display: block;
      }

      .feedback{
        margin-top: 30px;
        font-size: 12px;
        text-align: center;
        width: 100%;
        display: block;
      }
    }
  }
`;
