import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    position: relative;
    padding-left: 26px;
    cursor: pointer;

    input {
      display: none;
      &:checked ~ span:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
    span {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: solid 1px #adadab;
      display: block;
      position: absolute;
      left: 0;
      top: 0;

      &:after {
        content: '';
        width: 8px;
        height: 8px;
        background: #ebb92d;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        border-radius: 50%;
        transition: 300ms ease-in-out 0s;
      }
    }
  }
`;
