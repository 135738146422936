import styled, { css } from 'styled-components';

interface IContainerProps {
  isAction: boolean;
  isExtra: boolean;
  isMaterials: boolean;
}

export const Container = styled.button<IContainerProps>`
  height: ${props => (props.isMaterials ? 'unset' : '49px')};;
  padding: ${props => (props.isMaterials ? '8px' : '15px')};
  color: #60605d;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  background-color: #ebb92d;

  border: solid 1px #ebb92d;

  &.small{
    margin: 0;
    height: 40px;
    padding: 19px 8px;
  }

  &.cancelar{
    color: #ebb92d;
    background-color: #FFFFFF;
  }

  &:hover {
    opacity: .7;
  }

  ${props =>
    props.isAction &&
    props.isExtra &&
    css`
      border-color: #ebb92d;
    `}

  ${props =>
    !props.isAction &&
    css`
      border-color: #ebb92d;
      background-color: transparent;
      color: #ebb92d;
    `}

    ${props =>
      props.disabled &&
      css`
        border: solid 1px #ffffff;
        background-color: #e6e6e6;
        color: #adadab;
      `}

  img {
    margin-left: 8px;
    &.margin0{
      margin-left: 0px;
    }
  }
`;
