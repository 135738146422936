import styled from 'styled-components';
import optionBgImage from '../../assets/tegra-barra_amarela.png';

export const Container = styled.div`
  width: 740px;

  @media (max-width: 600px) {
    width: 100%;
  }
  max-width: 100%;
  margin: 61.5px auto;
`;

export const SignUpOptionsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 58.5px;
  padding: 31px 40px;
  background: #fff;
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
  margin-bottom: 5px;
`;
