import React, { useState, useEffect } from 'react';

import { useLocation, useRouteMatch } from 'react-router-dom';
import NavFeatured from './NavFeatured';
import NavMain from './NavMain';
import { MenuData } from './NavMain/NavMenu';
import { useAuth } from '../../hooks/auth';
import { FaTruckMonster } from 'react-icons/fa';
import userEvent from '@testing-library/user-event';
import { useHistory } from 'react-router-dom';

interface TegraNavProps {
  tegraLogo: string;
  title: string;
  description: string;
}

const menuDataExample: MenuData[] = [
  {
    active: false,
    label: 'Home',
    link: '/',
  },
  {
    active: false,
    label: 'Produtos',
    link: '/produtos',
  },
  {
    active: false,
    label: 'Eventos',
    link: '/evento',
  },
  {
    active: false,
    label: 'Eventos Parcerias',
    link: '/eventosParcerias',
  },
  {
    active: false,
    label: 'Presença Plantão',
    link: '/presenca-plantao',
  },
   {
     active: false,
     label: 'Registro Visita',
     link: '/registro-visita',
   },
  {
    active: false,
    label: 'Espaço Gerente',
    link: '/espaco-gerente',
  },
  {
    active: false,
    label: 'Liderança',
    link: '/lideranca',
  },
  {
    active: false,
    label: 'Materiais de Produtos',
    link: '/materiais-de-produtos',
    children: [
      /*
      {
        active: false,
        label: 'Materiais Gerais',
        link: '/materiais-de-produtos/materiais-gerais',
      },
      */
      {
        active: false,
        label: 'Books',//'Apresentações',
        link: '/materiais-de-produtos/books',//'/materiais-de-produtos/apresentacoes',
      },
      {
        active: false,
        label: 'Espelhos e tabelas',
        link: '/materiais-de-produtos/espelhos-e-tabelas',
      },
      {
        active: false,
        label: 'Imagens',
        link: '/materiais-de-produtos/imagens',
      },    
      {
        active: false,
        label: 'Plantas',
        link: '/materiais-de-produtos/plantas',
      },        
      {
        active: false,
        label: 'Vídeos',
        link: '/materiais-de-produtos/videos',
      },

      {
        active: false,
        label: 'Tour Virtual',
        link: '/materiais-de-produtos/tour-virtual',
      },      
      {
        active: false,
        label: 'Materiais Gerais',
        link: '/materiais-de-produtos/email-marketing',
      },
      {
        active: false,
        label: 'Treinamentos',
        link: '/materiais-de-produtos/treinamento-de-produtos',
      },
      {
        active: false,
        label: 'Informações de IM',
        link: '/materiais-de-produtos/informacoes-de-im',
      },
      {

        active: false,
        label: 'Apresentações',//'Treinamentos',
        link: '/materiais-de-produtos/apresentacoes', //'/materiais-de-produtos/treinamentos',
      },
      {
        active: false,
        label: 'Memorial Descritivo',
        link: '/materiais-de-produtos/memorial-descritivo',
      },      
      {
        active: false,
        label: 'Simule Financiamento',
        link: '/materiais-de-produtos/simule-financiamento',
      },

      /*
      {
        active: false,
        label: 'IPTU e Condomínio',
        link: '/materiais-de-produtos/iptu-e-condominio',
      },
      {
        active: false,
        label: 'E-mail Marketing',
        link: '/materiais-de-produtos/email-marketing',
      },
*/


    ],
  },
  {
    active: false,
    label: 'Minha Página',
    link: '/minha-pagina',
  },
  {
    active: false,
    label: 'Premiações e Metas',
    link: '/premiacoes-e-metas',
    featured: true,
  },
  {
    active: false,
    label: 'Novidades',
    link: '/novidades',
    featured: true,
  },
  {
    active: false,
    label: 'Calendário de Eventos',
    link: '/calendario-de-eventos',
    featured: true,
  },
  {
    active: false,
    label: 'Relatórios',
    link: '#',
    featured: true,
    children: [
      {
        active: false,
        label: 'Acesso dos corretores',
        link: '/relatorio/acessos',
      },
      {
        active: false,
        label: 'Produtos mais acessados',
        link: '/relatorio/materiais',
      },
      {
        active: false,
        label: 'Atividades',
        link: '/relatorio/atividades',
      },
    ],
  },
  {
    active: false,
    label: 'Ranking',
    link: '#',
    featured: true,    
    children:  [      
      {
        active: false,
        label: 'Ranking Corretores',
        link: '/ranking/corretores',
      },      
      {
        active: false,
        label: 'Ranking Gerente',
        link: '/ranking/gerente',
      },      
    ],
  }, 
  {
    active: false,
    label: 'Contatos',
    link: '/contatos',
    featured: true,
    children: [
      {
        active: false,
        label: 'Vendas',
        link: '/contatos/vendas',
      },
      {
        active: false,
        label: 'Corretores/gerentes',
        link: '/contatos/gerentes',
      },
      {
        active: false,
        label: 'Incorporadora',
        link: '/contatos/incorporadora',
      },
    ],
  },
  {
    active: false,
    external: true,
    label: 'Sugestões',
    link: 'https://www.contatoseguro.com.br/jeitocerto',
    featured: true,
  },
  {
    active: false,
    label: 'Perguntas Frequentes',
    link: '/perguntas-frequentes',
    featured: true,
  },
  {
    active: false,
    external: true,
    label: 'Pagadoria',
    link: 'https://www.recebasuacomissao.com.br/',
    featured: true,
  },
  {
    active: false,
    external: true,
    label: 'Academia',
    link: 'https://www.academiategra.com.br/',
    featured: true,
  },
  /*
  {
    active: false,
    external: true,
    label: 'Telegram',
    link: 'https://t.me/joinchat/H0rERina5tbz1ASy/',
    featured: true,
  },*/
];

const TegraNav: React.FC<TegraNavProps> = ({
  tegraLogo,
  title,
  description,
}) => {
  const [menuData, setMenuData] = useState<MenuData[]>([]);
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    //Se for Sigavi, força a assinatura dos termos
    /*
    if (user.idCargoSigavi > 0 && (window.location.pathname != "/dashboard/") &&
       (!user.termoAceiteConduta || !user.termoAceiteEtica || 
                                  !user.termoAceiteImagem || !user.termoAceiteLgpd || !user.termoAceiteAbrainc)){ 
        history.push('/dashboard/');
    }
    */
    
    document.getElementById('robots')?.setAttribute("content", "noindex");
    document.getElementById('seoHome')?.removeAttribute("rel");
    document.getElementById('seoHome')?.removeAttribute("href");


    const mappedLinks = menuDataExample
      .filter(item => {
        if (!user.acessos.sugestoes && item.link === '/sugestoes') {
          return false;
        }
        if (
          !user.acessos.perguntasFrequentes &&
          item.link === '/perguntas-frequentes'
        ) {
          return false;
        }
        if (
          !user.acessos.calendarioTrabalho &&
          item.link === '/calendario-de-eventos'
        ) {
          return false;
        }
        if (
          !user.acessos.premiacaoMetas &&
          item.link === '/premiacoes-e-metas'
        ) {
          return false;
        }
        if (!user.acessos.espacoGerente && item.link === '/espaco-gerente') {
          return false;
        }
        if (
          !user.acessos.presencaPlantao &&
          item.link === '/presenca-plantao'
        ) {
          return false;
        }
        if (
          !user.acessos.registroVisita &&
          item.link === '/registro-visita'
        ) {
          return false;
        }
        if (
          !user.acessos.evento &&
          item.link === '/evento'
        ) {
          return false;
        }
        if (
          !user.acessos.eventosParcerias &&
          item.link === '/eventosParcerias'
        ) {
          return false;
        }
        if (!user.acessos.lideranca && item.link === '/lideranca') {
          return false;
        }
        if (!user.acessos.pagadoria && item.label === 'Pagadoria') {
          return false;
        }
        if (!user.acessos.academia && item.label === 'Academia') {
          return false;
        }
        if (!user.acessos.minhaPagina && item.link === '/minha-pagina') {
          return false;
        }
        if (!user.acessos.relatorios && item.label === 'Relatórios') {
          return false;
        }
        if (
          ((user.idCargoSigavi != 14 && user.idCargoSigavi != 27) && (user.tipo === 0 || !user.acessos.ranking)) &&
          item.label === 'Ranking'
        ) {
          return false;
        }      
        else if (user.idCargoSigavi == 14 || user.idCargoSigavi == 27) {
          var position = menuDataExample.findIndex(obj => obj.label === 'Ranking');
          menuDataExample[position].children =
            [      
              {
                active: false,
                label: 'Ranking Gerente',
                link: '/ranking/gerente',
              },      
            ];

        }
        
        if (
          (user.tipo === 0 || !user.acessos.contatos) &&
          item.label === 'Contatos'
        ) {
          return false;
        }
        {/*
        if (
          (user.tipo !== 0 || user.idRegional === 2) &&
          item.label === 'Telegram'
        ) {
          return false;
        }
      */}
        return true;
      })
      .map(item => {
        const link = item.external ? item.link : `${path}${item.link}`;
        const active = pathname === link;
        const activatedItem = { ...item, active, link };
        return activatedItem;
      });

    setMenuData([...mappedLinks]);
  }, [pathname, path, user]);

  return (
    <>
      <NavFeatured menuData={menuData} />
      <NavMain
        menuData={menuData}
        tegraLogo={tegraLogo}
        title={title}
        description={description}
      />
    </>
  );
};

export default TegraNav;
