import styled from 'styled-components';

export const Container = styled.div`
  border: solid 1px #e6e6e6;
  padding: 31px 31px;
  margin-bottom: 40px;
  margin-top: 20px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: -15px;
    left: 79px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #e6e6e6;

    @media (max-width: 425px) {
      left: 45%;
    }
  }
`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 29px;

  & > div:not(:last-child) {
    margin-right: 40px;
  }

  & > div:first-child {
    margin-top: calc(11px + 1rem);
  }

  & > div:not(:first-child) {
    max-width: 248px;
    width: 100%;
    /* max-height: 67px; */

    label,
    p {
      margin-top: 0;
      margin-bottom: 11px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      -webkit-letter-spacing: normal;
      -moz-letter-spacing: normal;
      -ms-letter-spacing: normal;
      letter-spacing: normal;
      text-align: left;
      color: #60605d;
    }

    input {
      height: 40px;
    }
  }

  .tarefaResponsavel input {
    height: 26px !important;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    & > div:not(:last-child) {
      margin-right: 0px;
    }

    & > div:not(:first-child) {
      max-width: 100%;
      margin-top: 20px;
    }
  }

  @media (width: 768px) {
    & > div:first-child {
      span {
        display: none;
      }
    }
  }

  @media (max-width: 320px) {
    & > div:first-child {
      span {
        display: none;
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  & > div.buttons {
    display: flex;
    button {
      margin-left: 15px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    button {
      width: 100%;
    }

    & > * {
      margin-top: 10px;
    }
  }
`;
