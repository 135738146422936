import { 
    Container
} from "./styles";
import pinLocation from '../../../assets/location-pin.png';
import educacao from '../../../assets/guia-bairro-educacao.png'
import gastronomia from '../../../assets/guia-bairro-gastronomia.png'
import lazer from '../../../assets/guia-bairro-lazer.png'
import saibaMais from '../../../assets/guia-bairro-saiba-mais.png'
import visualizarIcon from '../../../assets/tegra-icon-open.svg';
import React from "react";

interface GuiaBairroProps {
    mapaUrl?: any;
    endereco?: string;
    bairro?:string;
    blocoHtml1?: string;
    blocoHtml2?: string;
    sobreLocalizacao?: string;
    blocoGastronomia?: string;
    blocoLazer?: string;
    blocoEducacao?: string;
    blocoVoceSabia?: string;
    uf?: string;
}

const GuiaBairro: React.FC<GuiaBairroProps> = ({
    mapaUrl= '', 
    endereco= '',
    bairro='', 
    blocoHtml1= '', 
    blocoHtml2= '', 
    sobreLocalizacao= '', 
    blocoGastronomia= '',
    blocoLazer= '',
    blocoEducacao= '',
    blocoVoceSabia= '',
    uf=''
}) => {
    let exibirCuriosidades = true;
    if (blocoGastronomia == null || blocoGastronomia == ""){
        if (blocoLazer == null || blocoLazer == ""){
            if (blocoEducacao == null || blocoEducacao == ""){
                if (blocoVoceSabia == null || blocoVoceSabia == ""){
                    exibirCuriosidades = false;
                }
            }
        }
    }
    let slugBairro = '';
    let linkbairro = 'https://www.tegraincorporadora.com.br/';
    if (bairro !== null && bairro !== "" ){
        slugBairro = bairro.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(' ').join('-'); //Remove os acentos
        linkbairro += uf.toLowerCase() + '/guia-de-bairro/'+ slugBairro;
    }
    console.log(slugBairro, slugBairro !== "");
    return (
        <Container>
            <div className="guia-de-bairro">
                <div className="title">
                    <h1>Conheça o bairro</h1>
                </div>
                <div className="mapa">
                    <iframe 
                        src={mapaUrl} 
                        width="100%" 
                        height="600px" 
                        data-gtm-yt-inspected-7200357_164="true"
                    ></iframe>
                    {blocoHtml1 && (
                        <div className="descricao">
                        <p className="endereco"><img src={pinLocation}/>{endereco}</p>
                        <p className="blocoHtml1" dangerouslySetInnerHTML={{__html: blocoHtml1}}></p>
                    </div>
                    )}
                    <div className="bairro">
                        {sobreLocalizacao && (
                            <div>
                                <h2>{bairro}</h2>
                                <p dangerouslySetInnerHTML={{__html: sobreLocalizacao}}></p>
                            </div> 
                        )}
                        {exibirCuriosidades && (
                            <div>
                                <h2>Curiosidades do bairro</h2>
                                <div className="curiosidades">
                                    { blocoGastronomia && (
                                        <div className="topicos">
                                            <p className="titulo-topicos"><img src={gastronomia}/>Gastronomia</p>
                                            <p className="descricao-topicos" dangerouslySetInnerHTML={{__html: `${blocoGastronomia.substring(0, 120)}[..]`}}></p>
                                        </div>
                                    )}
                                    { blocoLazer && (
                                        <div className="topicos">
                                            <p className="titulo-topicos"><img src={lazer}/>Lazer</p>
                                            <p className="descricao-topicos" dangerouslySetInnerHTML={{__html: `${blocoLazer.substring(0, 120)}[..]`}}></p>
                                        </div>
                                    )}
                                    { blocoEducacao && (
                                        <div className="topicos">
                                            <p className="titulo-topicos"><img src={educacao}/>Educação</p>
                                            <p className="descricao-topicos" dangerouslySetInnerHTML={{__html: `${blocoEducacao.substring(0, 120)}[..]`}}></p>
                                        </div>
                                    )}
                                    {blocoVoceSabia && (
                                        <div className="topicos">
                                            <p className="titulo-topicos"><img src={saibaMais}/>Você sabia?</p>
                                            <p className="descricao-topicos" dangerouslySetInnerHTML={{__html: `${blocoVoceSabia.substring(0, 120)}[..]`}}></p>
                                        </div>                                    
                                    )}
                                </div>
                            </div>


                        )}                        
                    </div>
                    {exibirCuriosidades && slugBairro !== "" &&(
                        <div className="tudo-sobre-bairro">
                            <a
                                className="link-guiabairro"
                                target="_blank"
                                href={linkbairro}
                            >
                                TUDO SOBRE {bairro.toUpperCase()}
                                <img src={visualizarIcon} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </Container>
        
    );
};

export default GuiaBairro;