import React, {
  useState,
  useCallback,
  useRef,
  MouseEvent,
} from 'react';

import {
  Container as GridContainer,
  Row,
  Col,
  Visible,
} from 'react-grid-system';
import { FormHandles } from '@unform/core';
import ImageGallery from 'react-image-gallery';
// import ReactImageGallery from 'react-image-gallery';
import { toast } from 'react-toastify';
import api, { baseURL } from '../../../services/api';
import {
  Container,
  PhotosLinksContainer,
  PhotosLinksContainerActions,
  PhotosLinksContainerHeader,
  ImageGalleryButtonLeft,
  ImageGalleryButtonRight,
  ImageGalleryContainer,
  ImageGalleryActionsOver,
  Title,
} from './styles';
import TegraButton from '../../../components/TegraButton';
import iconArrowLeft from '../../../assets/tegra-icon-white-arrow-left.svg';

import 'react-image-gallery/styles/css/image-gallery.css';
import { useAuth } from '../../../hooks/auth';
import { log, ETipoAtividade, ETipoSessao } from '../../../utils/log';
import { number } from 'yup';

interface IPhoto {
  name: string;
  thumbnail: any;
  original: any;
  description?: string;
  id?: number;
  galeria?: string;
}

interface IProdutoPhotoGalleryProps {
  imagens: IPhoto[];
  className?: string;
  title: string;
  empreendimento: string;
  id_empreendimento: number;
}

interface IMulticheckBoxItem {
  value: string;
  id: string;
  label: string;
  url: string;
  defaultChecked?: boolean;
  checked?: boolean;
}

interface IPhotosFormData {
  todas: boolean;
  photos: string;
}
const ProdutoPhotoGallery: React.FC<IProdutoPhotoGalleryProps> = ({
  imagens,
  title,
  className = '',
  id_empreendimento,
  empreendimento,
  ...rest
}) => {
  const [photosCheckBoxData, setPhotosCheckBoxData] = useState<
    IMulticheckBoxItem[]
  >(() => {
    let items: IMulticheckBoxItem[] = [];
    if (imagens) {
      items = imagens.map(imagem => ({
        label:
          imagem.name ||
          (imagem.galeria
            ? `${imagem.galeria}${imagem.original.split('.').pop()}`
            : empreendimento) ||
          '-',
        url: imagem.original,
        value: imagem.id ? imagem.id.toString() : '-1',
        id: imagem.id ? imagem.id.toString() : '-1',
        checked: false,
      }));
    }
    return items;
  });

  console.log("idempreendimento", id_empreendimento);
  const { user } = useAuth();

  const photosGalleryRef = useRef<ImageGallery>(null);

  const downloadSingle = (filename: string, file: string): void => {
    window.open(
      `${baseURL}Download?link=${decodeURIComponent(
        file,
      )}&empreendimento=${encodeURIComponent(
        empreendimento,
      )}&legendas=${filename}`,
    );
  };

  const handleImageGalleryBaixar = useCallback(() => {
    if (photosGalleryRef.current) {
      const currentIndex = photosGalleryRef.current.getCurrentIndex();

      const { original = '', name, id } = imagens[currentIndex] || {};

      if (original) {
        downloadSingle(name, original);
        log(
          empreendimento,
          title,
          ETipoAtividade.Download,
          ETipoSessao.Produto,
          id_empreendimento,
          name,
          id || 0,
          user,
        );
      }
    }
  }, [downloadSingle, empreendimento, id_empreendimento, imagens, title, user]);

  const getTextButton = useCallback(() => {
    if (photosGalleryRef.current) {
      const currentIndex = photosGalleryRef.current.getCurrentIndex();

      const { id } = imagens[currentIndex] || {};

      const index = photosCheckBoxData.findIndex(
        a => a.id == ((id as unknown) as string),
      );
      return photosCheckBoxData[index].checked ? 'Remover' : 'Selecionar';
    }
  }, [imagens, photosCheckBoxData]);

  const selecionarImagem = useCallback(
    (e: MouseEvent): void => {
      e.preventDefault();
      if (photosGalleryRef.current) {
        const currentIndex = photosGalleryRef.current.getCurrentIndex();

        const { id } = imagens[currentIndex] || {};

        const index = photosCheckBoxData.findIndex(
          a => a.id == ((id as unknown) as string),
        );

        const checkbox = document.getElementById(
          photosCheckBoxData[index].label,
        ) as HTMLInputElement;

        if (photosCheckBoxData[index].checked) {
          photosCheckBoxData[index].checked = false;
          if (checkbox) {
            checkbox.checked = false;
          }
        } else {
          photosCheckBoxData[index].checked = true;
          if (checkbox) {
            checkbox.checked = true;
          }
        }

        setPhotosCheckBoxData([...photosCheckBoxData]);
        photosGalleryRef?.current?.forceUpdate();
      }
    },
    [photosCheckBoxData],
  );

  const handleClick = useCallback(
    (a: any): void => {
      const index = photosCheckBoxData.findIndex(l => l.id == a.id);

      if (photosCheckBoxData[index].checked) {
        photosCheckBoxData[index].checked = false;
      } else {
        photosCheckBoxData[index].checked = true;
      }

      setPhotosCheckBoxData([...photosCheckBoxData]);
      photosGalleryRef?.current?.forceUpdate();
    },
    [photosCheckBoxData],
  );


  const downloadTodas = (e: MouseEvent): void => {
    e.preventDefault();
    log(
      empreendimento,
      title,
      ETipoAtividade.Download,
      ETipoSessao.Produto,
      id_empreendimento,
      'todas as imagens',
      photosCheckBoxData.map(p => p.id),
      user,
    );

    const apiURL = `Download/imagens/${id_empreendimento},${title}`;
          api
            .get(apiURL, { responseType: 'blob' })
            .then(response => {
              const nav = (window.navigator as any);        
                if (nav.msSaveOrOpenBlob) {
                // IE11
                nav.msSaveOrOpenBlob(
                  new Blob([response.data]),
                  `${empreendimento}.zip`,
                );
              } else {
                const url = window.URL.createObjectURL(
                  new Blob([response.data]),
                );
                const lnk = document.createElement('a');
                lnk.href = url;
                lnk.setAttribute('download', `${empreendimento}.zip`);
                document.body.appendChild(lnk);
                lnk.click();
                document.body.removeChild(lnk);
                setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(url);
                }, 100);
              }
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
  }


  const downloadSelecionadas = (e: MouseEvent): void => {
    e.preventDefault();
    log(
      empreendimento,
      title,
      ETipoAtividade.Download,
      ETipoSessao.Produto,
      id_empreendimento,
      'múltiplas imagens',
      photosCheckBoxData.filter(c => c.checked).map(p => p.id),
      user,
    );
    if (photosCheckBoxData.filter(c => c.checked).length > 0){
      const links: any[] = [];
      const legendas: any[] = [];
      photosCheckBoxData
        .filter(i => i.checked === true)
        .forEach(item => {
          links.push(item.url);
          legendas.push(item.label);
        });
  
      api
        .post('Download', {
          empreendimento,
          link: links,
          legenda: legendas,
        })
        .then(res => {
          window.open(`${baseURL}Download?key=${res.data}`);
        })
        .catch(err => {
          toast(
            'Ops, ocorreu um erro ao tentar baixar os arquivos, atualize a página e tente novamente',
            {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              type: 'error',
            },
          );
        });  
    }
    else{
      toast(
        'Selecione as imagens para download.',
        {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        },
      );
    }
  };

  return (
    <Container {...rest} className={className}>
      <Title>{title}</Title>
      <GridContainer
        className="grid-container"
        style={{
          padding: 0,
          marginLeft: '0px',
          maxWidth: '100%',
          marginRight: '0px',
        }}
      >
        <Row style={{ marginRight: '0px' }}>
          <Visible sm={false} md={false} xl xxl lg>
            <Col sm={12} md={12} lg={2} xl={2}>
              <PhotosLinksContainer>
                <PhotosLinksContainerHeader>
                  <h2>{title}</h2>
                </PhotosLinksContainerHeader>
              </PhotosLinksContainer>
            </Col>
          </Visible>
          <Col
            className="coluna"
            style={{ paddingRight: '0px' }}
            sm={12}
            md={12}
            xl={10}
            lg={10}
          >
            <ImageGalleryContainer>
              <ImageGallery
                ref={photosGalleryRef}
                showThumbnails
                showFullscreenButton={false}
                showBullets={false}
                infinite
                showPlayButton={false}
                items={imagens}
                lazyLoad
                renderLeftNav={(onClick, disabled) => (
                  <ImageGalleryButtonLeft
                    className="image-gallery-custom-left-nav"
                    disabled={disabled}
                    onClick={onClick}
                  >
                    <img src={iconArrowLeft} alt="Anterior" />
                  </ImageGalleryButtonLeft>
                )}
                renderRightNav={(onClick, disabled) => (
                  <ImageGalleryButtonRight
                    className="image-gallery-custom-right-nav"
                    disabled={disabled}
                    onClick={onClick}
                  >
                    <img src={iconArrowLeft} alt="Próximo" />
                  </ImageGalleryButtonRight>
                )}
                renderCustomControls={() => (
                  <ImageGalleryActionsOver className="image-gallery-description">
                    {/*Claris Plantas*/}
                    {id_empreendimento == 335 && (
                      <TegraButton
                        isAction
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open('https://empreendimentos.tegraincorporadora.com.br/clarisplantas/','_blank');
                          }}
                      >
                        Navegue pelas Plantas
                      </TegraButton>

                      )}
                      {id_empreendimento != 335 && (                       
                        <>
                          <TegraButton
                            onClick={handleImageGalleryBaixar}
                            isAction
                            isExtra
                          >
                            Baixar
                          </TegraButton>
                          <TegraButton onClick={selecionarImagem} isAction isExtra>
                            {getTextButton()}
                          </TegraButton>
                        </>
                      )}

                  </ImageGalleryActionsOver>
                )}
                renderThumbInner={a => (
                  id_empreendimento != 335 && (
                    <div className="thumb">
                      <input
                        type="checkbox"
                        onClick={() => handleClick(a)}
                        value={a.description}
                        id={a.description}
                      />
                      <span className="geekmark" />
                      <img src={a.thumbnail} />
                    </div>
                )
                )}
              />
              {id_empreendimento != 335 && (
                <PhotosLinksContainerActions>
                  <TegraButton
                    isAction
                    type="submit"
                    onClick={downloadTodas}
                  >
                    Baixar Todas  (
                    {photosCheckBoxData.length})
                  </TegraButton>
                  <TegraButton
                    isAction
                    type="submit"
                    onClick={downloadSelecionadas}
                  >
                    Baixar selecionadas (
                    {photosCheckBoxData.filter(c => c.checked).length})
                  </TegraButton>
                </PhotosLinksContainerActions>
              )}
            </ImageGalleryContainer>
          </Col>
        </Row>
      </GridContainer>
    </Container>
  );
};

export default ProdutoPhotoGallery;
