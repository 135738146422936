import React from 'react';

import {
  Container,
  HeroContent,
  Title,
  Description,
  Category,
  Subtitle,
  SubDescription,
} from './styles';
import TegraDivider from '../TegraDivider';

interface TegraOpaqueHeroProps {
  title: string;
  description: any;
  category?: string;
  subtitle?: string;
  subDescription?: string;
  bairro?: string;
  cidade?: string;
}

const TegraOpaqueHero: React.FC<TegraOpaqueHeroProps> = ({
  title,
  description,
  category = '',
  bairro = '',
  cidade = '',
  subtitle = '',
  subDescription = '',
}) => {
  return (
    <Container hasCategory={!!category}>
      <HeroContent hasCategory={!!category}>
        <TegraDivider height={120} axis="y" width={4} color="#ebb92d" />
        <div className="info">
          {category && <Category>{category}</Category>}
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          <Description>
            {bairro && (
              <>
                {bairro} {cidade}
                <br />
              </>
            )}
            {description}
          </Description>
          {subDescription && <SubDescription>{subDescription}</SubDescription>}
        </div>
      </HeroContent>
    </Container>
  );
};

export default TegraOpaqueHero;
