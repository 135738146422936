import styled, { css } from 'styled-components';

export const Container = styled.div`
  .ReactModal__Overlay {
    opacity: 0.9;
    background-color: #272723;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 40px;
  top: 35px;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 20px;
  }
`;
