import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;

  &.formContainer {
    background: #e6e6e6;
    padding: 20px 30px;

    button {
      margin-left: auto;
      margin-top: 20px;
    }
  }
`;

export const Content = styled.div`
  margin: 52px auto 0;
  width: 894px;
  max-width: 100%;
  .salvar {
    float: right;
    margin-right: 0;
  }
  table.default{
    margin-bottom: 8px;
    border-bottom: 1px solid #eee;
  }
`;
export const MensagemSucesso = styled.div`
  margin-top: 20px;
  background: #1f9e13;
  padding: 15px;
  color: #fff;
  text-align: center;
  margin: 0 30px;
`;
export const MensagemErro = styled.div`
  margin-top: 20px;
  background: #d84128;
  padding: 15px;
  color: #fff;
  text-align: center;
  margin: 0 30px;
`;
