import React, { useRef, useState, useCallback, useEffect } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { AiFillWindows } from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import arrowDownIcon from '../../assets/tegra-icon-arrow-down.svg';

import {
  Container,
  Content,
  SignUpAction,
  LinkErrado,
  SignUpActionText,
  LoginContainer,
  LinkSenha2,
  BoxEntrarSenha,
  LoginBox,
  LoginBoxTitle,
  SingleLoginBox,
  LoginBoxSubTitle,
  ErrorsContainer,
  MessageCorretor,
  BoxOpcoes,
  BoxSenha,
} from './styles';
import TegraIntroHeader from '../../components/TegraIntroHeader';
import TegraDivider from '../../components/TegraDivider';
import TegraInput from '../../components/TegraInput';
import TegraButton from '../../components/TegraButton';
import TegraLinkButton from '../../components/TegraLinkButton';
import TegraInputWithMask from '../../components/TegraInputWithMask';
import { useAuth } from '../../hooks/auth';
import { getLink, isParcerias } from '../../utils/logo';
import { useLoader } from '../../hooks/loader';
import windows from '../../assets/windows.png';
import whatsapp from '../../assets/whatsapp.svg';
import voltar from '../../assets/voltar_login.svg';
import api from '../../services/api';

interface CorretorFormData {
  cpf: string;
  senha: string;
}

interface ParceiroFormData {
  cpf_or_cnpj: string;
  senha: string;
}

const Login: React.FC = () => {
  const { signIn, signInAzureAD } = useAuth();
  const history = useHistory();
  const { setLoaderActive } = useLoader();

  const formRefCorretor = useRef<FormHandles>(null);
  const formRefParceiro = useRef<FormHandles>(null);

  const [corretorOpen, setCorretorOpen] = useState(true);
  const [parceiroOpen, setParceiroOpen] = useState(true);
  const [portalIncorreto, setPortalIncorreto] = useState(false);

  const [documentoCorretor, setDocumentoCorretor] = useState('');
  const [documentoParceiro, setDocumentoParceiro] = useState('');
  const [senhaParceiro, setSenhaParceiro] = useState('');

  const [parceiroErrors, setParceiroErrors] = useState<string[]>([]);
  const [corretorErrors, setCorretorErrors] = useState<string[]>([]);

  const [isOpenPass, setIsOpenPass] = useState<any>(false); // Senha Regular Parceiro
  const [isOpenWhats, setIsOpenWhats] = useState<any>(false); // Senha Regular Whats
  const [telefone, setTelefone] = useState('');
  const [reenvioSenha, setReenvioSenha] = useState(false);

  const [contErros, setContErros] = useState(0);

  /*
  const handleIconClick = useCallback(
    (name: string) => {
      switch (name) {
        case 'corretor':
          setCorretorOpen(!corretorOpen);
          break;
        case 'parceiro':
          setParceiroOpen(!parceiroOpen);
          break;
        default:
          break;
      }
    },
    [corretorOpen, parceiroOpen],
  );
*/
  useEffect(() => {
      const url = window.location.href;
      if (url === "https://portaldocorretor.tegravendas.com.br/" || 
          url === "https://portaldocorretor.tegravendas.com.br" || 
          url === "https://portaldocorretor.tegravendas.com.br/login/" || 
          url === "https://portaldocorretor.tegravendas.com.br/login" ||           
          url === "https://portaldeparcerias.tegravendas.com.br/" || 
          url === "https://portaldeparcerias.tegravendas.com.br" || 
          url === "https://portaldeparcerias.tegravendas.com.br/login/" ||
          url === "https://portaldeparcerias.tegravendas.com.br/login" ||
          url === "http://localhost:3000/")      
      {
        document.getElementById('robots')?.setAttribute("content", "index");
        document.getElementById('seoHome')?.setAttribute("rel", "canonical");
        document.getElementById('seoHome')?.setAttribute("href", url);
      }
  }, []);

  const handleSubmitCorretor: SubmitHandler<CorretorFormData> = async (
    data,
  ): Promise<void> => {
    // Todo fazer login corretor
    setLoaderActive(true);

    localStorage.removeItem('@Tegra:user');
    sessionStorage.removeItem('@Tegra:user');
    sessionStorage.removeItem('modalExpertegra');

    const resposta = await signInAzureAD();

    setLoaderActive(false);
    if (`${resposta}`.includes('Error')) {
      const validationErrors: string[] = [];
      if (`${resposta}`.includes('User cancelled the flow')) {
        validationErrors.push(`O usuário fechou a janela de autenticação.`);
      }
      else if (`${resposta}`.includes('401')) {
        validationErrors.push(`Usuário sem permissão de acesso. Contate o administrador do Sistema.`);
      }
      else if (`${resposta}`.includes('idCargoSigavi')) {
        validationErrors.push(`Usuário sem permissão de acesso. Faça o login e tente novamente. `);
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      else if (`${resposta}`.includes('pkce_not_created')) {
        validationErrors.push(`Ops! Infelizmente o Portal do Corretor não é compatível com esta versão do navegador. Atualize para uma versão mais recente ou então tente acessar o portal em outro dispositivo.`);
      }
      else {
        validationErrors.push(`${resposta}`);
      }
      setCorretorErrors(validationErrors);
      window.stop();
    }
    else{
      var historico = localStorage.getItem('history');
      history.push(historico?historico:'/');
      localStorage.removeItem('history');
    }
  };

  const handleSubmitParceiro: SubmitHandler<ParceiroFormData> = async (
    data,
  ): Promise<void> => {
    try {
      setLoaderActive(true);
      const schema = Yup.object().shape({
        //cpf_or_cnpj: Yup.string().required('CPF/CNPJ'),
        senha: Yup.string().required('Senha'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      /*
      await signInMessage({
        documento: documentoParceiro, //data.cpf_or_cnpj,
        senha: isOpenPass ? data.senha : senhaParceiro,
        from_parcerias: isParcerias(),
        //acesso_whats: isOpenWhats,
      });
*/
      await signIn({
        documento: documentoParceiro,
        senha: isOpenPass ? data.senha : senhaParceiro,
        from_parcerias: isParcerias(),
        acesso_whats: isOpenWhats? true: false,
      });

      var historico = localStorage.getItem('history');
      history.push(historico?historico:'/');
      localStorage.removeItem('history');
    } catch (err : any) {
      setLoaderActive(false);
      const validationErrors: string[] = [];

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors.push(error.message);
        });
      }

      if (err.response) {
        const { erro } = err.response.data;
        if (erro === 'PORTALINCORRETO') {
          setPortalIncorreto(true);
        } else if (erro === 'ACESSOCRECI') {
          validationErrors.push(
            'Devido a atualizações de segurança, foi encaminhado ao seu e-mail um link para cadastro de uma nova senha de acesso!',
          );
        } else {
          if (contErros == 2 && isOpenWhats){
            setContErros(0);
            setIsOpenWhats(false);
          }
          setContErros(contErros + 1);
          validationErrors.push(erro);
        }
      }
      setParceiroErrors(validationErrors);
    }
  };

  const  ParceiroExist = useCallback(async (tipo) => {
    let resParceiro;
    if (documentoParceiro == ''){
      const validationErrors: string[] = [];
      validationErrors.push(
        'CPF/CNPJ',
      );
      setParceiroErrors(validationErrors);
    }
    else{
      try {
        setLoaderActive(true);
        setSenhaParceiro('');

        if (!reenvioSenha){
          const params = new URLSearchParams();
          params.append('tx_documento', documentoParceiro);
          if (tipo > 1){
            params.append('acesso_whats', 'true');
          }
          resParceiro = await api.post('Login/ParceiroExist', params);
          setTelefone(resParceiro.data.data.tx_telefone);
        }else{
          const delay = ms => new Promise(res => setTimeout(res, ms));
         await delay(1500);
        }
        setParceiroErrors( [] );
        if (tipo == 1){
          setIsOpenPass(true);
        }
        else if (tipo == 2) {
          setIsOpenWhats(true);
        }
        else {
          setIsOpenWhats(true);
          const validationErrors: string[] = [];
          validationErrors.push(
            'Uma nova senha foi enviada. Aguarde.',
          );
          setParceiroErrors(validationErrors);
          setReenvioSenha(true);
        }
        setLoaderActive(false);
      } catch (err : any) {
        setLoaderActive(false);
        const validationErrors: string[] = [];

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors.push(error.message);
          });
        }

        if (err.response) {
          const { erro } = err.response.data;
          validationErrors.push(erro);
        }
        setParceiroErrors(validationErrors);
        setIsOpenPass(false);
        setIsOpenWhats(false);
        setTelefone('');
      }
    }
  },[documentoParceiro, parceiroErrors, isOpenPass, isOpenWhats, telefone, senhaParceiro, reenvioSenha]);

  return (
    <Container>
      <TegraIntroHeader dividerAxis="y" dividerColor="#ebb92d" />
      <LoginContainer>
        <Content>
          <LoginBox>
            {!isParcerias() && (
              <SingleLoginBox>
                <Form ref={formRefCorretor} onSubmit={handleSubmitCorretor}>
                  <div className="title" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="withSubtitle" style={{ width: '100%' }}>
                      <LoginBoxTitle>Sou corretor Tegra Vendas</LoginBoxTitle>
                      <LoginBoxSubTitle className="isAlt" >
                        <div className="title-left">
                          Sou corretor Tegra Vendas
                        </div>
                      </LoginBoxSubTitle>
                    </div>
                    <MessageCorretor>
                      Se você é um corretor(a) Tegra Vendas, clique no botão abaixo e faça o login com o
                      <span> mesmo usuário e senha que você acessa a rede da Tegra Vendas.</span>
                    </MessageCorretor>
                    {/*
                    <AccordionIcon
                      isOpen={corretorOpen}
                      onClick={() => handleIconClick('corretor')}
                    >
                      <img src={arrowDownIcon} alt="Accordion arrow" />
                    </AccordionIcon>
                    */}
                  </div>
                  <BoxEntrarSenha style={{ justifyContent: 'center', height: '80px', alignContent: 'flex-end' }}>
                    <TegraButton type="submit" isAction style={{
                      margin: 0,
                      alignSelf: 'center',
                      fontSize: 14,
                      fontWeight: 500
                    }}>
                      {/*<AiFillWindows size={30} style={{ marginRight: 8 }} />*/}
                      <img src={windows} alt="" style={{ marginRight: 8, marginLeft: 0, paddingRight: 10 }} />
                      Login com Microsoft
                    </TegraButton>
                  </BoxEntrarSenha>
                  {corretorErrors.length >= 1 && (
                    <ErrorsContainer>
                      {corretorErrors.map(error => (
                        <p>{error}</p>
                      ))}
                    </ErrorsContainer>
                  )}


                  {/*
                  {corretorErrors.length >= 1 && (
                    <ErrorsContainer>
                      {corretorErrors.map(error => (
                        <p>{error}</p>
                      ))}
                    </ErrorsContainer>
                  )}
                  {portalIncorreto && (
                    <ErrorsContainer>
                      <p>
                        Olá, você é um{' '}
                        {isParcerias()
                          ? 'Corretor Tegra Vendas,'
                          : 'Corretor Parceiro,'}{' '}
                        mas está acessando o Portal{' '}
                        {isParcerias() ? 'de Parcerias.' : 'do Corretor.'}
                        <br />
                        <a href={getLink(!isParcerias())}>
                          Clique aqui para acessar corretamente.
                        </a>
                      </p>
                    </ErrorsContainer>
                  )}
                  {corretorOpen && (
                    <>
                      <TegraInputWithMask
                        label="CPF ou CNPJ"
                        name="cpf"
                        value={documentoCorretor}
                        maskChar={null}
                        mask=""
                        onChange={e =>
                          setDocumentoCorretor(
                            e.target.value.replace(/\D/g, ''),
                          )}
                      />
                      <TegraInput label="SENHA" type="password" name="senha" />
                      <BoxEntrarSenha>
                        <TegraButton type="submit" isAction>
                            Entrar
                        </TegraButton>
                      </BoxEntrarSenha>
                    </>
                  )} */}
                </Form>
              </SingleLoginBox>
            )}
            {!!isParcerias() && (
              <SingleLoginBox>
                <Form ref={formRefParceiro} onSubmit={isOpenPass || isOpenWhats?handleSubmitParceiro:ParceiroExist}>
                  {isOpenPass && (
                    <BoxSenha>
                        <TegraButton
                          className='button'
                          onClick={() => {setIsOpenPass(false); setDocumentoParceiro('');setParceiroErrors( [] ); }}
                          isAction icon={voltar} >
                        </TegraButton>
                        <p className='inputTitle'>
                          INSIRA SUA SENHA ABAIXO
                        </p>
                        <div className='inputSenha'>
                          <TegraInput label="" type="password" name="senha" />
                        </div>
                          <LinkSenha2>
                            <Link
                              to="/esqueci-a-senha"
                              onClick={() => {setIsOpenPass(false); setDocumentoParceiro('');setParceiroErrors( [] ); }}
                            >
                              Esqueci a senha
                            </Link>
                          </LinkSenha2>
                          <TegraButton type="submit" isAction style={{ alignSelf: 'auto' }} >
                            CONFIRMAR
                          </TegraButton>
                    </BoxSenha>
                  )}
                  {isOpenWhats && (
                    <BoxSenha>
                        <TegraButton
                          className='button'
                          onClick={() => {setIsOpenWhats(false); setDocumentoParceiro('');setParceiroErrors( [] ); }}
                          isAction icon={voltar} >
                        </TegraButton>
                        <div className='boxTexto'>
                          <p className='inputTitle'>
                            INSIRA O CÓDIGO ENVIADO PARA <br/>
                            O WHATSAPP <span className='negrito'>{telefone}</span>
                          </p>
                        </div>
                        <div className='inputSenha'>
                          <TegraInputWithMask
                            label=""
                            name="senha"
                            maskChar={null}
                            mask=""
                            value={senhaParceiro}
                            onChange={e =>
                              setSenhaParceiro(
                                e.target.value.replace(/\D/g, ''),
                            )}
                          />
                        </div>
                        <LinkSenha2>
                          <TegraLinkButton isAction
                              onClick={() => {ParceiroExist(3) }}>
                              Não recebi o código
                          </TegraLinkButton>
                        </LinkSenha2>

                        <TegraButton type="submit" isAction style={{ alignSelf: 'auto' }} >
                            CONFIRMAR
                        </TegraButton>
                    </BoxSenha>
                  )}
                  {!isOpenPass && !isOpenWhats && (
                    <div className="title">
                      <div className="withSubtitle">
                        <LoginBoxTitle>Sou um parceiro</LoginBoxTitle>
                        <TegraDivider
                          marginLeft={13}
                          height={18}
                          marginRight={13}
                          axis="y"
                          color="#adadab"
                        />
                        <LoginBoxSubTitle>
                          Corretor autônomo ou Imobiliária
                        </LoginBoxSubTitle>
                      </div>
                    </div>
                  )}
                  {parceiroErrors.length >= 1 && (
                    <ErrorsContainer>
                      {parceiroErrors.map(error => (
                        <p>{error}</p>
                      ))}
                    </ErrorsContainer>
                  )}
                  {portalIncorreto && (
                    <ErrorsContainer>
                      <p>
                        Olá, você é um{' '}
                        {!isParcerias()
                          ? 'Corretor Parceiro,'
                          : 'Corretor Tegra Vendas,'}{' '}
                        mas está acessando o Portal{' '}
                        {!isParcerias() ? 'do Corretor.' : 'de Parcerias.'}
                        <br />
                        <a href={getLink(!isParcerias())}>
                          Clique aqui para acessar corretamente.
                        </a>
                      </p>
                    </ErrorsContainer>
                  )}
                  {parceiroOpen && !isOpenPass && !isOpenWhats &&(
                    <>
                      <TegraInputWithMask
                        label="CPF ou CNPJ"
                        name="cpf_or_cnpj"
                        maskChar={null}
                        mask=""
                        value={documentoParceiro}
                        onChange={e =>
                          setDocumentoParceiro(
                            e.target.value.replace(/\D/g, ''),
                          )}
                      />
                      {
                        /*<TegraInput label="SENHA" type="password" name="senha" />
                      <BoxEntrarSenha>
                        <LinkSenha>
                          <Link to="/esqueci-a-senha">
                            ESQUECI A SENHA
                          </Link>
                        </LinkSenha>
                        <TegraButton type="submit" isAction style={{ marginTop: '0px', margin: '0px 0px' }} >
                          ENTRAR
                        </TegraButton>
                      </BoxEntrarSenha>
                      */}
                    </>
                  )}
                  {!isOpenPass && !isOpenWhats && (
                    <BoxOpcoes>
                      <TegraButton isAction
                        onClick={() => {setReenvioSenha(false); ParceiroExist(1) }}
                        style={{backgroundColor: '#60605d', color: '#fff', border: 'solid 1px #60605d' }} >
                        ACESSAR COM A SENHA REGULAR
                      </TegraButton>
                      <TegraButton isAction
                        onClick={() => {setReenvioSenha(false); ParceiroExist(2) }}
                        icon={whatsapp} >
                        RECEBER ACESSO POR WHATSAPP
                      </TegraButton>
                    </BoxOpcoes>
                  )}
                </Form>
              </SingleLoginBox>
            )}

            {!isOpenPass && !isOpenWhats && (
              <TegraDivider
                marginLeft={40}
                height={300}
                marginRight={40}
                axis="y"
                color="#adadab"
              />

            )}
          </LoginBox>
        </Content>
        {!isOpenPass && !isOpenWhats && (
          <SignUpAction>
            {isParcerias() && (
              <>
                <SignUpActionText>Cadastre-se como um corretor parceiro.</SignUpActionText>
                <Link to="/signup">
                  <TegraButton isAction isExtra style={{ border: 'solid', borderWidth: 'thin', borderColor: '#60605d', margin: '0px 0px' }}>
                    CADASTRE-SE
                  </TegraButton>
                </Link>
              </>
            )}
            {!isParcerias() &&(
              <>
                <SignUpActionText>Não é um corretor Tegra Vendas?<br/> Cadastre-se como um corretor parceiro.</SignUpActionText>
                <Link to="/parceiros">
                  <TegraButton isAction isExtra style={{ border: 'solid', borderWidth: 'thin', borderColor: '#60605d', margin: '0px 0px' }}>
                    CADASTRE-SE
                  </TegraButton>
                </Link>
              </>
            )}
          </SignUpAction>
        )}
      </LoginContainer>

      {(isOpenPass || isOpenWhats) &&(
        <LinkErrado>
          <Link
            to="/login"
            onClick={() => {setIsOpenWhats(false); setIsOpenPass(false);setDocumentoParceiro('');setParceiroErrors( [] ); }}
          >Voltar ao Login</Link>
        </LinkErrado>
      )}
    </Container>
  );
};

export default Login;
