import React from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import {
  Container,
  BlogItem,
  BlogItemContent,
  BlogItemThumbnail,
  BlogItemInfo,
  BlogItemActions,
} from './styles';

interface BlogItem {
  imagem?: any;
  titulo: string;
  subtitulo: string;
  conteudo: string;
  chamada?: string;
  link: string;
  data: string;
  id: string;
}

interface ITegraBlogCardProps {
  items: BlogItem[];
  pageSlug: string;
}

const TegraBlogCard: React.FC<ITegraBlogCardProps> = ({ items }) => {
  const { path } = useRouteMatch();

  // const getShortDescription = useCallback((str: string) => {
  //   const [firstPElement] = str.split('</p>');
  //   const div = document.createElement('div');
  //   div.innerHTML = `${firstPElement}</p>`;
  //   const text = div.textContent || div.innerText || '';
  //   return text;
  // }, []);

  return (
    <Container>
      {items.map(blogItem => (
        <BlogItem key={blogItem.id}>
          <BlogItemContent>
            {blogItem.imagem ? (
              <Link
                to={`${path}/${blogItem.link}`}
                className="thumbnailContainer TitleWithImageContainer"
              >
                <BlogItemThumbnail
                  src={blogItem.imagem}
                  alt={`Image do ${blogItem.titulo}`}
                />
                <h2 className="TitleWithImage">{blogItem.titulo}</h2>
              </Link>
            ) : (
              <Link
                to={`${path}/${blogItem.link}`}
                className="thumbnailContainer"
              >
                <h2 className="SingleTitle">{blogItem.titulo}</h2>
                <hr className="SingleTitle-border" />
              </Link>
            )}
            <Link to={`${path}/${blogItem.link}`}>
              <BlogItemInfo>
                <p>{blogItem.chamada}</p>
              </BlogItemInfo>
            </Link>
          </BlogItemContent>
          <BlogItemActions>
            <Link to={`${path}/${blogItem.link}`}>Ver mais</Link>
          </BlogItemActions>
        </BlogItem>
      ))}
      <BlogItemContent />
      <BlogItemContent />
    </Container>
  );
};

export default TegraBlogCard;
