import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface ContainerProps {
  hasCategory: boolean;
}

export const Container = styled.header<ContainerProps>`
  background-size: cover;
  width: 100%;
  height: 200px;
  padding: 60px 0px ;
  padding-left: calc(calc(calc(100vw - calc(1140px + 3.5rem)) / 2) + 2.25rem);

  @media (max-width: 600px) {
    padding-left: 33px;

    ${props =>
      props.hasCategory &&
      css`
        padding-top: 30px;
      `}
  }
`;

interface HeroContentProps {
  hasCategory: boolean;
}

export const HeroContent = styled.div<HeroContentProps>`
  display: flex;
  position: relative;

  ${props =>
    props.hasCategory &&
    css`
      .divider {
        position: absolute;
        bottom: -11px;
      }
    `}

  .info {
    margin-left: 26px;
    width: 100%;
    max-width: 550px;

    @media (max-width: 600px) {
      max-width: 100%;
      margin-left: 0;

      .divider {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    .divider {
      display: none;
    }
  }
`;

export const Title = styled.h1`
  max-width: 684px;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
  text-align: left;
  color: #60605d;
  word-wrap: break-word;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  color: ${colors.featuredBlack};
  font-weight: 500;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
  margin-top: 11px;
  width: 500px;
  max-width: 100%;

  strong {
    font-weight: bold;
    font-size: 14px;
  }

  @media (max-width: 600px) {
    width: 305px;
  }
`;

export const SubDescription = styled.p`
  font-weight: normal;
  color: ${colors.inputBorder};
  font-size: 12px;
  margin-top: 0.3rem;
`

export const Category = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
  margin-bottom: 5px;
`;
