import React from 'react';
import TegraNavIcon from '../TegraNavIcon';
import {
  FooterWrapper,
  FooterTrademark,
  LinkGroupWrapper,
  FooterContainer,
  FooterWrapperSmall,
  FooterTrademarkSmall,
} from './styles';
import LinkGroup from './LinkGroup';
import { useAuth } from '../../hooks/auth';

export interface FooterLink {
  label: string;
  link: string;
  onlyParceiros?: boolean;
}

export interface FooterLinkGroup {
  title?: string;
  columns: boolean;
  items: FooterLink[];
}

interface TegraFooterProps {
  title: string;
  description: string;
  links: FooterLinkGroup[];
  trademark: string;
  tegraLogo: string;
  isParceiro?: boolean;
  isTegraUser?: any;
  isSmall?: boolean;
}

const TegraFooter: React.FC<TegraFooterProps> = ({
  title,
  description,
  links,
  tegraLogo,
  trademark,
  isParceiro,
  isTegraUser,
  isSmall = false,
}) => {
  const { user } = useAuth();
  return (
    <FooterContainer>
      {!isSmall &&(
          <FooterWrapper>
          {isTegraUser && (
            <>
              <TegraNavIcon
                description={description}
                tegraLogo={tegraLogo}
                title={title}
                link={'/'}
              />
              <LinkGroupWrapper>
                {links.map(footerLinkGroup => {
                  if(!user.acessos.contatos && footerLinkGroup.title === 'Contatos'){
                    return false
                  }else{
                  return (
                    <LinkGroup
                      isParceiro={isParceiro || false}
                      key={`footer-link-group-${footerLinkGroup.title}`}
                      group={footerLinkGroup}
                    />
                  );}
                })}
              </LinkGroupWrapper>
            </>
          )}
            <FooterTrademark> {trademark} </FooterTrademark>
          </FooterWrapper>        
      )}
      {isSmall && (
          <FooterWrapperSmall>
              <FooterTrademarkSmall> {trademark} </FooterTrademarkSmall>
          </FooterWrapperSmall>
      )}


    </FooterContainer>
  );
};

export default TegraFooter;
