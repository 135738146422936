import styled from "styled-components";
import colors from "../../styles/colors";
import bg from "../../assets/canal_parceiros/bg.png";
import logoSioux from '../../assets/logo-sioux-b.svg';

export const Container = styled.div`
  width: auto;
  position: relative;
  background-image: url(${bg});
  background-position: -169px -36px;
  background-repeat: no-repeat;
  overflow: hidden;

  h2 {
    margin-left: 20px;
    font-size: 24px;
  }

  .btn-parceiro {
    top: 54px;
    right: 80px;
    width: 270px;
    height: 46px;
    position: absolute;

    font-family: Montserrat;
    text-align: center;
    text-transform: uppercase;

    border: 1px solid ${colors.primaryColor};
    color: ${colors.primaryColor};
    background: rgb(0, 0, 0, 0.6);
    cursor: pointer !important;
  }

  .btn-parceiro:hover {
    opacity: 0.3;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
    background: #60605D;

    h2 {
      margin-left: 0;
      font-size: 22px;
    }

    .btn-parceiro {
      top: 20px;
      right: auto;
      position: relative;
      cursor: pointer !important;
    }
  }
`
export const Banner = styled.div`
  width: 464px;
  height: 150px;
  padding: 45px 100px 0 80px;

  display: flex;
  justify-content: space-between;

  color: #FFFFFF;
  background: linear-gradient(70deg, #52524F 0%, #52524F 89%, #FFFFFF00 89%, #FFFFFF00 100%);
/*
  .logo {
    width: 180px;
    height: 42px;
    margin-top: 10px;
  }
*/
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 45px 0 0 40px;
    background: #60605D;

    h2 {
      margin-right: 40px;
    }
  }
`;

export const Logo = styled.div`
    width: 180px;
    height: 42px;
    margin-top: 10px;
`

export const Block = styled.div`
  width: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  color: #FFFFFF;

  h1 {
    margin: 40px 0 24px 80px;
    font-size: 48px;
    color: ${colors.primaryColor}
  }

  h3 {
    font-size: 27px;
    color: ${colors.primaryColor};
  }

  .center {
    margin: auto;
  }

  .btn {
    background: ${colors.primaryColor} padding-box;
    border: 1px solid #FFFFFF;

    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #60605D;
    cursor: pointer !important;
  }

  .btn:hover {
    opacity: 0.3;
  }

  .flex {
    display: flex;
  }

  .quem-somos {
    width: 662px;
    margin: 0 0 25px 80px;

    font-size: 16px;
    font-family: Montserrat;
    text-align: left;
  }

  .parceiro-tegra {
    width: 358px;
    height: 54px;
    margin: 30px 0 100px 80px;
    margin-top: 30px;
  }

  .lake {
    width: 1000px;
    height: 50px;
    left: 983px;
    top: 752px;
    position: absolute;
    display: flex;
    color: #60605D;
    background: linear-gradient(70deg,  #FFFFFF00 0%,  #FFFFFF00 2%, ${colors.primaryColor} 2%, ${colors.primaryColor} 100%);
    z-index: 1;

    p {
      margin-top: 12px;
      margin-left: 8px;
      font-size: 15px;
      color: #60605D;
    }

    h4 {
      margin-top: 10px;
      margin-left: 18px;
      font-size: 27px;
      text-transform: uppercase;
    }
  }


  .containerConquista{
    .margin-espaco{
      margin: 0 0 0 80px;
    }

    .margin-rodape{
      margin: 0 0 100px 80px;
    }

    .conquistas2 {
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      //margin: 0 0 100px 80px;
      justify-content: flex-start;    

      .barra{
        width: 370px;
        margin-top: 30px;

        p{
          font-family: Montserrat;
          font-size: 27px;
          font-weight: 700;
          line-height: 32.91px;
          text-align: left;
          color: #fff;
          margin-top: 15px;
        }

      }
    }
  }

  .conquistas {
    width: 900px;
    margin: 0 0 100px 80px;
    justify-content: space-between;

    .barra {
      width: 50px;
      height: 125px;
      background: linear-gradient(70deg, #60605D 0%, #60605D 49%, #FFFFFF80 49%, #FFFFFF80 50%, #60605D 50%, #60605D 100%);
    }
  }

  .vantagens {
    margin: 0 0 120px 80px;
    position: relative;

    h3 {
      font-size: 24px;
      font-family: Montserrat;
    }

    .box {
      margin-top: 24px;
      display: flex;
    }

    .icone {
      width: 40px;
      height: 40px;
    }

    .linha {
      margin-left: 25px;
    }

    .linha h4 {
      margin-bottom: 8px;
      font-size: 27px;
    }

    .linha .conteudo {
      max-width: 590px;
      font-size: 16px;
    }

    .box-image {
      width: 600px;
      height: 344px;
      left: 668px;
      bottom: -60px;
      position: absolute;
      background: linear-gradient(70deg, #60605D 0%, #60605D 21%, #52524F 21%, #52524F 100%);
      z-index: 1;
    }

    .local {
      width: 400px;
      height: 50px;
      left: 784px;
      bottom: -60px;
      position: absolute;
      display: flex;
      color: #60605D;
      background: linear-gradient(70deg, #FFFFFF00 0%, #FFFFFF00 4.5%, #EBB92D 4.5%, #EBB92D 95%, #FFFFFF00 95%, #FFFFFF00 100%);
      z-index: 1;

      h4 {
        margin-top: 8px;
        margin-left: 35px;
        font-size: 27px;
        font-weight: bold;
        text-transform: uppercase;
        color: #60605D;
      }

      p {
        margin-top: 13px;
        margin-left: 8px;
        font-size: 15px;
        color: #60605D;
      }
    }

    .barra {
      width: 200px;
      height: 495px;
      top: 0;
      left: 732px;
      position: absolute;
      background: linear-gradient(70deg, #FFFFFF00 0%, #FFFFFF00 49%, ${colors.primaryColor} 49%, ${colors.primaryColor} 50%, #FFFFFF00 50%, #FFFFFF00 100%);
    }
  }

  .parceiro {
    width: 1600px;
    height: 700px;
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(70deg,#52524F 0%,#52524F 77%,#60605D 77%,#60605D 83%,#272723 83%,#272723 100%);

    h3 {
      width: 100%;
      margin-left: 80px;
      padding-top: 66px;
    }

    .big-box {
      width: 1101px;
      height: 354px;
      margin-top: 53px;
      margin-left: 120px;
      display: flex;
      flex-wrap: wrap;
      font-size: 15px;

      h4 {
        margin-right: 10px;
        font-size: 76px;
        color: ${colors.primaryColor};
      }

      .text {
        margin: auto;
      }

      .box {
        height: 177px;
        padding: 36px 65px 36px 20px;
        display: flex;
        background: #595955;
      }

      .box-1 {
        width: 295px;
      }

      .box-2 {
        width: 464px;
        margin-left: -65px;
        background: linear-gradient(70deg,#595955 0%,#595955 15%,${colors.primaryColor} 15%,${colors.primaryColor} 85%, #595955 85%, #595955 100%);
        color: #595955;
        z-index: 1;

        h4 {
          margin-left: 65px;
          color: #595955;
        }
      }

      .box-3 {
        width: 490px;
        margin-left: -85px;
        background: linear-gradient(70deg, #595955 0%, #595955 85%, #52524F 85%, #52524F 100%);

        h4 {
          margin-left: 65px;
        }
      }

      .box-4 {
        width: 471px;
        background: linear-gradient(70deg,${colors.primaryColor} 0%, ${colors.primaryColor} 85%, #595955 85%, #595955 100%);
        color: #595955;
        z-index: 1;

        h4 {
          color: #595955;
        }
      }

      .box-5 {
        width: 548px;
        margin-left: -90px;
        background: linear-gradient(70deg, #595955 0%, #595955 85%, #52524F 85%, #52524F 100%);

        h4 {
          margin-left: 70px;
        }
      }
    }

    .full {
      width: 100%;
      text-align: center;
    }

    button {
      width: 208px;
      height: 54px;
      margin: 70px auto 50px auto;
    }
  }

  .portal {
    padding-top: 47px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: #60605D;

    h3 {
      width: 100%;
      margin-bottom: 40px;
      font-size: 27px;
      text-align: center;
    }

    h4 {
      width: 100%;
      margin-left: 80px;
      font-size: 32px;
    }

    h4 span {
      color: ${colors.primaryColor};
    }

    .video {
      width: 713px;
      height: 401px;
      margin: 0 auto 145px auto;
    }

    .relative {
      position: relative;
    }

    .imagem {
      width: 535px;
      height: 380px;
      top: -40px;
      left: 800px;
      position: absolute;
    }

    .box {
      width: 780px;
      heigth: auto;
      padding-top: 52px;
      margin: 0 0 10px 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .item {
      width: 360px;
      display: flex;
    }

    .vetor {
      margin-top: 6px;
      width: 10px;
      height: 17px;
    }

    .text {
      width: 340px;
      margin-bottom: 30px;
      margin-left: 9px;
      font-size: 22px;
      z-index: 1;
    }

    .container-btn{
      display: flex;
      justify-content: center;
      margin: 120px auto 80px auto;
    }


    .btn {
      width: 358px;
      height: 54px;      
      cursor: pointer !important;
    }

    .footer {
      font-size: 10px;
      padding: 0 80px 40px 80px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-left: 0;
    padding-right: 0;

    h1 {
      margin: 60px 40px 0 40px;
      font-size: 24px;
      text-align: left;
    }

    h2 {
      font-size: 15px;
    }

    h3 {
      text-align: left;
    }

    .btn {
      //max-width: 90%;
      cursor: pointer !important;
    }

    .quem-somos {
      width: 80%;
      margin: 10px 40px 0 40px;
      font-size: 16px;
      text-align: left;      
    }

    .parceiro-tegra {
      margin: 40px 0;
      font-size: 16px;
    }

    .lake {
      visibility: hidden;
    }

    .containerConquista{   
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .margin-espaco{
        margin: 20px 0 0;
      }

      .margin-rodape{
        margin: 0 0 40px;
      }

      .conquistas2 {
        width: 88%;
        flex-wrap: wrap;
        justify-content: center;

        .barra{
          width: 100%;
          p{
            text-align: center !important;
            font-size: 24px !important;
          }
        }
      }
    }

    .conquistas {
      width: 100%;
      margin: 40px 0;
      flex-wrap: wrap;
      justify-content: center;

      .barra {
        visibility: hidden;
        position: absolute;
      }

      .local {
        width: 100%;
        background: ${colors.primaryColor};
      }
    }

    .vantagens {
      width: 90%;
      margin: 20px 0 120px 30px;

      .linha {
        text-align: left;
      }

      .linha h4 {
        font-size: 20px;
      }

      .box-image {
        left: -146px;
        position: relative;
      }

      .local {
        width: 111%;
        left: -30px;
        background: ${colors.primaryColor};

        p {
          font-size: 14px;
        }

        h4 {
          margin-left: 12px;
          font-size: 24px;
        }
      }

      .barra {
        visibility: hidden;
      }
    }

    .parceiro {
      width: 100%;
      height: 1280px;
      background: #52524F;

      h3 {
        max-width: 90%;
        margin-left: 20px;
      }

      .big-box {
        width: 100%;
        height: 885px;
        margin: 0;
        position: relative;

        .box {
          padding: 36px 20px;
          width: 100%;
          margin-left: 0;

          h4 {
            margin-left: 0;
          }
        }

        .box-1, .box-3, .box-5 {
          background: #595955;
        }

        .box-2, .box-4 {
          background: #EBB92D;
        }
      }

      button {
        margin: 30px 0 50px 0;
      }
    }

    .portal {
      padding-top: 40px;

      h3 {
        margin: 0 22px 26px 22px;
        text-align: left;
      }

      h4 {
        width: 65%;
        margin-left: 20px;
        text-align: left;
      }

      .video {
        width: 100%;
        margin: 0 0 60px 0;

        iframe {
          width: 100% !important;
        }
      }

      .imagem {
        top: 0;
        left: -182px;
        height: 466px;
        position: relative;
      }

      .box {
        width: 85%;
        margin-left: 30px;

        .item {
          margin-left: -6px;

          .text {
            text-align: left;
          }
        }
      }

      .center {
        margin-left: 20px;
      }

      .container-btn{
        display: flex;
        justify-content: center;
        margin: 80px 0 80px 0;
        width: 100%;
      }

      .btn {       
        cursor: pointer !important;
      }

      .footer {
        width: 100%;
        padding: 0 20px 40px 20px;
        //text-align: left;
      }
    }
  }
`
export const Item = styled.div`
  width: auto;
  max-width: 160px;
  min-width: 120px;
  height: 125px;

  h3 {
    text-align: center;
    font-size: 48px;
    letter-spacing: 0px;
    color: ${colors.primaryColor};
  }

  h3 span {
    vertical-align: middle;
    font-size: 16px;
  }

  p {
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: auto;
    max-width: 240px;
    margin-bottom: 20px;

    p {
      font-size: 20px;
    }
  }
`;

export const SignatureSioux = styled.div`
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;

    span {
        line-height: 10px;
        color: #adadab;
    }
    
    a {
        text-indent: -9999px;
        display: inline-flex;
        margin: 0 5px;
        width: 48px;
        height: 12px;
        background-color: #adadab;
        -webkit-mask-image: url(${logoSioux});
        mask-image: url(${logoSioux});
        align-items: center;
    }
`;


