import React, { useRef, useCallback, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { Form, FormHandles, SubmitHandler } from '@unform/core';
import { Wizard, Steps, Step, StepObject } from 'react-albus';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { isParcerias } from '../../../utils/logo';

export interface CorretorFormData {
  id_parceiro?: number;
  step: string;
  regional: {
    label: string;
    value: string;
    uf: string;
  };
  cidade: {
    label: string;
    value: string;
  };
  creci: string;
  cpf: string;
  dt_nascimento: string;
  nome: string;
  email: string;
  telefone: string;
  celular: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  possui_gerente: boolean;
  gerente: {
    label: string;
    value: string;
  };
  identificacao_doc: File | null;
  creci_doc: File | null;
  comp_residencia_doc: File | null;
  closed: boolean;
  zona: {
    label: string;
    value: string;
    cidade: number | string;
  };
  senha: string;
  confirmacaoSenha: string;
  token: string;
  contato: {
    label: string;
    value: string;
    outraOrigem: string;
  };
}

interface TegraCorretorStepsProps { }

const TegraCorretorSteps: React.FC<TegraCorretorStepsProps> = () => {
  const { signInRegister } = useAuth();
  const history = useHistory();
  const formRefCorretor = useRef<FormHandles>(null);
  const dataLocal = JSON.parse(sessionStorage.getItem('_cadastro') || '{}');
  const [formData, setFormData] = useState<CorretorFormData>(
    dataLocal as CorretorFormData,
  );
  const [currentStep, setCurrentStep] = useState<StepObject>({
    id: dataLocal.step || 'stepOne',
  });

  const url = new URL(window.location.href)
  const acessoAdm = url.searchParams.get('tk') !== null? true : false;

  const handleSubmitCorretor: SubmitHandler<CorretorFormData> = async (
    data,
  ): Promise<void> => {
    if (!formRefCorretor.current) {
      throw new Error('Sem form');
    }

    // termina o cadastro e já loga
    api
      .post('Cadastro/part3', {
        // só utilizado caso esteja editando
        id_parceiro: formData?.id_parceiro,
        id_tipoParceiro: 2,
        tx_cpf: formData.cpf,
        tx_nome: formData.nome,
        tx_email: formData.email,
        tx_telefone: formData.telefone,
        id_zona: formData.zona?.value,
        tx_zona: formData.zona?.label,
        tx_celular: formData.celular,
        tx_logradouro: formData.endereco,
        tx_numero: formData.numero,
        tx_complemento: formData.complemento,
        tx_bairro: formData.bairro,
        tx_cep: formData.cep,
        id_regional: formData.regional?.value,
        tx_regional: formData.regional?.label,
        id_cidade: formData.cidade?.value,
        tx_cidade: formData.cidade?.label,
        tx_creci: formData.creci,
        dt_cadastro: new Date().toISOString(),
        tx_uf: formData.regional.uf,        
        isAcessoAdm: acessoAdm,
        id_gerente: formData.gerente.value || 0,
        tx_gerente: formData.gerente.label,
        hasGerente: formData.possui_gerente,
        tx_origemContato: formData.contato?.label,
        tx_outraOrigem: formData.contato?.label === "Outros" ? formData.contato?.outraOrigem : null,
        dt_nascimento: formData.dt_nascimento,        
      })
      .then(async res => {
        const { sucesso } = res.data;
        if (sucesso) {
          await signInRegister(dataLocal.token);
          sessionStorage.removeItem('_cadastro');
          sessionStorage.removeItem('_corretorSelecionado');
          history.push('/');
        }
      })
      .catch(err => {
        if (err.response) {
          const { erro } = err.response.data;

          const validationErrors: string[] = [];

          validationErrors.push(erro);
          alert(validationErrors[0]);
        }
      });
    // Enviar dados p api e concluir cadastro
    // deveria ter o part3 aqui
  };

  const changeStep = useCallback(
    (id: string) => {
      setCurrentStep({ id });
    },
    [currentStep],
  );
  const updateForm = useCallback(
    (data: CorretorFormData) => {
      setFormData({
        ...formData,
        ...data,
      });

      if (!formRefCorretor.current) {
        throw new Error('error');
      }

      formRefCorretor.current.setData({
        ...formData,
        ...data,
      });
    },
    [formData],
  );

  return (
    <>
      <Form ref={formRefCorretor} onSubmit={handleSubmitCorretor}>
        <Wizard>
          <Steps step={currentStep}>
            <Step
              id="stepOne"
              render={() => (
                <StepOne
                  acessoAdm={acessoAdm}
                  id="stepOne"
                  updateForm={updateForm}
                  formData={formData}
                  formRef={formRefCorretor}
                  changeStep={changeStep}
                />
              )}
            />
            <Step
              id="stepTwo"
              render={() => (
                <StepTwo
                  id="stepTwo"
                  updateForm={updateForm}
                  formData={formData}
                  formRef={formRefCorretor}
                  changeStep={changeStep}
                />
              )}
            />
            <Step
              id="stepThree"
              render={() => (
                <StepThree
                  id="stepThree"
                  formData={formData}
                  formRef={formRefCorretor}
                  changeStep={changeStep}
                />
              )}
            />
          </Steps>
        </Wizard>
      </Form>
    </>
  );
};

export default TegraCorretorSteps;

