import React from 'react';
import ProductMaterials from '../..';

const CicloNegocio: React.FC = () => {
  return (
    <ProductMaterials
      title="Treinamentos"
      subtitle="Ciclo do Negócio"
      description="Treinamentos da Incorporação."
      showColumnTitle={false}
      actions={['open','download']}
      apiUrl="MateriaisGerais"
      columns={[
        { label: 'Arquivo', field: 'nome' },
        { label: 'Tipo', field: 'extensao' },
      ]}
      anchorName="CicloNegocio"
      onClickItem={() => localStorage.setItem('SCROLL', '#CicloNegocio')}
    />
  );
};

export default CicloNegocio;
