import React, { useRef, useState, useCallback, useEffect } from 'react';

import { FiChevronLeft } from 'react-icons/fi';
import {
  Link,
  Switch,
  useRouteMatch,
  Route,
  useParams,
  useHistory,
} from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

const SignInAdm: React.FC = () => {
  const { regional, token, uf } : any = useParams();
  const { signInAdm } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function doLogin() {
      if (regional && token && uf) {
        await signInAdm({
          regional,
          token,
          uf,
        });
      }
      history.push(`/`);
    }
    doLogin();
  }, [history, regional, signInAdm, token]);

  return <div>Loading..</div>;
};

export default SignInAdm;
