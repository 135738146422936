import styled from 'styled-components';
import SimpleBar from 'simplebar-react';

export const Container = styled.div``;

export const TrackHorizontal = styled.div`
  height: 19px;
  width: auto;
  border: solid 1px #adadab;
  background: transparent;
  padding: 4px;
`;

export const TrackVertical = styled.div`
  width: 19px;
  height: auto;
  border: solid 1px #adadab;
  background: transparent;
  padding: 4px;
`;

export const ThumbVertical = styled.div`
  width: 11px;
  min-height: 40px;
  background-color: #ebb92d;
`;

export const ThumbHorizontal = styled.div`
  background-color: #ebb92d;
  height: 11px;
  min-width: 40px;
`;

export const CustomScrollbar = styled(SimpleBar)`
  /* max-width: 1000px; */
  width: inherit;
  max-height: 509px;

  .simplebar-track.simplebar-vertical {
    width: 19px;
    background-color: #fff;
    border: solid 1px #adadab;
    border-radius: 0;
    padding: 7px 0px;

    .simplebar-scrollbar {
      /* height: 40px; */
      left: 2px;
      &::before {
        opacity: 1;
        left: 1px;
        transition: opacity 0s linear;
        width: 11px;
        background-color: #ebb92d;
        border-radius: 0;
      }
    }
  }

  .simplebar-track.simplebar-horizontal {
    width: 100%;
    height: 19px;
    background-color: #fff;
    border: solid 1px #adadab;
    border-radius: 0;
    padding: 7px 0px;

    .simplebar-scrollbar {
      /* height: 40px; */
      left: 2px;
      &::before {
        opacity: 1;
        left: 1px;
        transition: opacity 0s linear;
        height: 11px;
        background-color: #ebb92d;
        border-radius: 0;
      }
    }
  }
`;
