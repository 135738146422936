import React from 'react';
import ProductMaterials from '../..';

const OutrasImagens: React.FC = () => {
  return (
    <ProductMaterials
      title="Materiais Gerais"
      subtitle="Outras Imagens"
      description="Baixe outras imagens do produto."
      showColumnTitle={false}
      actions={['open','download']}
      apiUrl="MateriaisGerais"
      columns={[
        { label: 'Produto', field: 'tx_produto' },
        { label: 'Arquivo', field: 'nome' },
      ]}
      anchorName="OutrasImagens"
      onClickItem={() => localStorage.setItem('SCROLL', '#Email')}
    />
  );
};

export default OutrasImagens;
