import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
  position: relative;
`;

export const Content = styled.div`
  margin-top: 40px;
  /* padding: 0 33px; */
  position: relative;
  }

  @media (min-width: 600px) {
    form {
      padding-top: 40px;
      background-color: #e6e6e6;
      max-width: 483px;
    }
  }
`;

export const SearchBox = styled.div`
  padding: 0px 36px 40.5px;

  @media (min-width: 600px) {
    padding-bottom: 0;
  }
`;
export const LoadMore = styled.div`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

export const FiltrarContainer = styled.div`
  padding-bottom: 15px;

  button {
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    background: transparent;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #60605d;

    img {
      margin-left: 10.5px;
    }
  }
`;

export const ProdutosContainer = styled.div``;


export const Icon = styled.img``;

type StyledCheckboxProps = {
  checked?: boolean;
}
export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  margin-right: 10px;
  padding: 3.4px 5.6px;
  width: 16px;
  height: 16px;
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: solid 1px #adadab;
  background-color: #ffffff;

  img {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;


export const DivDropDown = styled.div`
  //background: linear-gradient(315deg,#FDBE39 0%,#FDBE39 50%,#E6E5E5 10%,#E6E5E5 100%);
  width: 10px;
  height: 10px;
  position: relative;
  bottom:-10px;
`;