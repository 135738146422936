import styled, { keyframes } from 'styled-components';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled(Accordion)``;

export const CustomAccordionItem = styled(AccordionItem)`
  margin-bottom: 21px;
  padding-top: 21px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6e6e6;
`;

export const CustomAccordionItemHeading = styled(AccordionItemHeading)`
  cursor: pointer;
`;

export const CustomAccordionItemButton = styled(AccordionItemButton)`
  display: flex;
  justify-content: space-between;

  button {
    background: transparent;
    outline: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &[aria-expanded='true'] button {
    transform: rotate(180deg);
  }

  h2 {
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723;
  }
`;

export const CustomAccordionItemPanel = styled(AccordionItemPanel)`
  padding: 30px 10px 0px;
  animation: ${fadeIn} 0.35s ease-in;

  p {
    white-space: pre-wrap;
    font-size: 16px;
  }

  a {
    text-decoration: none;
  }
`;
