import React, { useState } from 'react';

import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { Container, Content } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TarefasAtividadesMain from './TarefasAtividadesMain';
import MeusCorretores from './MeusCorretores';
import BuscaCorretor from './BuscaCorretor';
import TegraTabsContainer from '../../components/TegraTabsContainer';
import { useAuth } from '../../hooks/auth';

const EspacoGerente: React.FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const { user } = useAuth();

  const [defaultTabIndex] = useState<number>(() => {
    if (pathname.includes('meus-corretores')) {
      return 1;
    }
    if (pathname.includes('busca-corretor')) {
      return 2;
    }

    return 0;
  });
  return (
    <Container>
      <TegraOpaqueHero
        title="Espaço Gerente"
        description="Faça a gestão das suas tarefas, mensagens e delegue funções para sua equipe de corretores."
      />
      <Content>
        <TegraTabsContainer>
          <Tabs defaultIndex={defaultTabIndex}>
            <TabList>
              <Tab>
                <Link to={`${path}`}>Minhas tarefas</Link>
              </Tab>
              <Tab>
                <Link to={`${path}/meus-corretores`}>Meus corretores</Link>
              </Tab>
              <Tab>
                <Link to={`${path}/busca-corretor`}>Verificar existência de parceiro</Link>
              </Tab>              
              {/* <Tab>
                <Link to={`${path}/relatorios`}>Relatórios</Link>
              </Tab> */}
            </TabList>
            <TabPanel>
              <TarefasAtividadesMain user_id={user.id} tipo_pagina="minha" />
            </TabPanel>
            <TabPanel>
              <MeusCorretores />
            </TabPanel>
            <TabPanel>
              <BuscaCorretor />
            </TabPanel>
            {/* <TabPanel>
              <Relatorios />
            </TabPanel> */}
          </Tabs>
        </TegraTabsContainer>
      </Content>
    </Container>
  );
};

export default EspacoGerente;
