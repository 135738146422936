import React from 'react';
import ProductMaterials from '../..';

/*Antigo Presentations*/
const Books: React.FC = () => {
  return (
    <ProductMaterials
      //title="Apresentações"
      title="Books"
      subtitle=""
      description="Materiais prontos para demonstrar nossos empreendimentos a seus clientes."
      showColumnTitle={false}
      actions={['open', 'download']}
      apiUrl="Books"
      columns={[
        { label: 'Produto', field: 'tx_produto' },
        { label: 'Book', field: 'nome' },
      ]}
      anchorName="Books"
      onClickItem={() => localStorage.setItem('SCROLL', '#Books')}
    />
  );
};

export default Books;
