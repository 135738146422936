import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import GlobalStyle from './styles/global';
import AppProvider from './hooks';
import Routes from './routes';
import ScrollToTop from './routes/ScrollToTop';
import './App.sass';

var pathurl = window.location.pathname;
if (pathurl != '/' && pathurl != '/login/'){
  localStorage.removeItem('history');
  localStorage.setItem('history',pathurl )  
}

const App: React.FC = () => (
  <Router>
    <ScrollToTop>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyle />
    </ScrollToTop>
  </Router>
);

export default App;
