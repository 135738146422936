import React from 'react';

import {
  Container,
  HeroContent,
  Title,
  Description,
  StatusObra,
} from './styles';
import TegraDivider from '../../../components/TegraDivider';
import iconDinheiro from '../../../assets/icon-dinheiro.png';
import iconDorm from '../../../assets/icon-dorm.png';
import iconMetragem from '../../../assets/icon-metragem.png';
import iconVaga from '../../../assets/icon-vaga.png';

interface TegraOpaqueHeroProductProps {
  title: string;
  description: any;
  category?: string;
  subtitle?: string;
  valorAproximado?: string;
  vagas?: string;
  subDescription?: string;
  bairro?: string;
  cidade?: string;
  descricaoValor?: string;
}

const TegraOpaqueHeroProduct: React.FC<TegraOpaqueHeroProductProps> = ({
  title,
  description,
  category = '',
  bairro = '',
  cidade = '',
  subtitle = '',
  valorAproximado = '',
  vagas = '',
  subDescription = '',
  descricaoValor = '',
}) => {
  return (
    <Container hasCategory={!!category}>
      <HeroContent hasCategory={!!category}>
        <TegraDivider height={100} axis="y" width={4} color="#ebb92d" />
        <div className="info">
          <StatusObra>{description}</StatusObra>
          <Title>{title}</Title>
          <Description>
            {bairro && (
              <>
                {bairro} • {cidade}
                <br />
              </>
            )}
          </Description>
        </div>
        <div className="infos-adicionais">
          {subDescription && (
            <div className="item-info metragem">
              <img src={iconMetragem} alt="Metragem" />
              <p>{subDescription}</p>
            </div>
          )}
          {subtitle && (
            <div className="item-info dorm">
              <img src={iconDorm} alt="Dormitórios" />
              <p>{subtitle}</p>
            </div>
          )}
          {vagas && (
            <div className="item-info vaga">
              <img src={iconVaga} alt="Vagas" />
              <p>{vagas}</p>
            </div>
          )}
          {valorAproximado && (
            <div className="item-info dinheiro">
              <img src={iconDinheiro} alt="Valor" />
              <div>
                <p>
                  A partir de <br />{valorAproximado}
                </p>
                {descricaoValor}
              </div>
            </div>
          )}
        </div>
      </HeroContent>
    </Container>
  );
};

export default TegraOpaqueHeroProduct;
