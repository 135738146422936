import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FooterLink } from '../..';
import { LinkGroupItemWrapper } from './styles';
import UndecoratedLink from '../../../UndecoratedLink';

interface LinkGroupItemProps extends FooterLink {
  columns: boolean;
  externalLink?: string;
}
const LinkGroupItem: React.FC<LinkGroupItemProps> = ({
  link,
  label,
  columns,
  externalLink,
}) => {
  const { path } = useRouteMatch();

  if (label === "Sugestões" && externalLink) {
    return (
      <LinkGroupItemWrapper columns={columns}>
        <a style={{textDecoration: 'none', color: 'inherit'}} target="_blank" href={externalLink}>{label}</a>
      </LinkGroupItemWrapper>
    );
  } else {
    return (
      <LinkGroupItemWrapper columns={columns}>
        <UndecoratedLink to={`${path}${link}`}>{label}</UndecoratedLink>
      </LinkGroupItemWrapper>
    );
  };

  
};

export default LinkGroupItem;
