import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { isParcerias, getLink } from '../../utils/logo';
import TegraButton from '../../components/TegraButton';
import TegraIntroHeader from '../../components/TegraIntroHeader';
import {
  Container,
  Content,
  BoxContainer,
  Box,
  BoxTitle,
  SingleBox,
  ErrorsContainer,
  LinkErrado,
  InputWrapper,
} from './styles';
import ajaxLoading from '../../assets/ajax-loader.gif';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import api from '../../services/api';
import TegraInput from '../../components/TegraInput';

interface CadastroSenhaFormData {
  senha: string;
  confirmacaoSenha: string;
}

interface CadastroSenhaResponse {
  tx_tipo: string;
  tx_cpfCnpj: string;
}

const CadastroSenha: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const [passwordToken, setPasswordToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [passwordState, setPasswordState] = useState(false);
  const [passwordValid, setPasswordValid] = useState({
    hasSix: false,
    hasEighteen: false,
    hasUpper: false,
    hasLower: false,
    hasNumber: false,
    hasSpecial: false,
  });

  const checkPassword = (passwordValue: string) => {
    
    let passwordValidState = { ...passwordValid },
      hasSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
      hasNumbers = /[0-9]/,
      hasLower = /[a-z]/,
      hasUpper = /[A-Z]/,
      testSpecial = hasSpecialChar.test(passwordValue),
      testNumbers = hasNumbers.test(passwordValue),
      testLower = hasLower.test(passwordValue),
      testUpper = hasUpper.test(passwordValue),
      testSixChar = passwordValue.length >= 6 ? true : false,
      testEighteenChar = passwordValue.length <= 18 ? true : false;

    passwordValidState.hasSix = testSixChar;
    passwordValidState.hasEighteen = testEighteenChar;
    passwordValidState.hasUpper = testUpper;
    passwordValidState.hasLower = testLower;
    passwordValidState.hasNumber = testNumbers;
    passwordValidState.hasSpecial = testSpecial;
    setPasswordValid(passwordValidState);
  };

  const { token } : any = useParams();
  useEffect(() => {
    setPasswordToken(token);
  }, [token]);
  
  const handleSubmitPasswordChange: SubmitHandler<CadastroSenhaFormData> = useCallback(
    async data => {
      try {
        setErrors([]);

        const schema = Yup.object().shape({
          senha: Yup.string().required('Senha é obrigatória'),
          confirmacaoSenha: Yup.string()
            .oneOf(
              [Yup.ref('senha'), undefined],
              'A senha e a confirmação de senha não são iguais',
            )
            .required('Confirmação é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        const response = await api.post<CadastroSenhaResponse>(
          'Cadastro/senha/',
          {
            token: passwordToken,
            senha: data.senha,
            confirmacaoSenha: data.confirmacaoSenha,
          },
        );
        const { data: parceiroCorretor } = response;
        if (parceiroCorretor) {
          await signIn({
            documento: parceiroCorretor.tx_cpfCnpj,
            senha: data.senha,
            from_parcerias: isParcerias(),
            acesso_whats: false,
          });
          history.push('/');
        }
      } catch (err: any) {
        const validationErrors: string[] = [];

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors.push(error.message);
          });
        }

        if (err.response) {
          const { erro } = err.response.data;
          validationErrors.push(erro);
        }

        setErrors(validationErrors);
      } finally {
        setLoading(false);
      }
    },
    [passwordToken],
  );

  return (
    <Container>      
      <TegraIntroHeader dividerAxis="y" dividerColor="#ebb92d"/>
      <BoxContainer>      
        <Content>
          <Box>
            <SingleBox>
              <Form ref={formRef} onSubmit={handleSubmitPasswordChange}>
                <div className="title">
                  <div className="withSubtitle">
                    <BoxTitle>Cadastro de senha</BoxTitle>
                  </div>
                </div>
                {errors.length >= 1 && (
                  <ErrorsContainer>
                    {errors.map(error => (
                      <p>{error}</p>
                    ))}
                  </ErrorsContainer>
                )}

                <div className="password-orientations">
                  <p
                    className={passwordValid.hasSix ? 'orientation-valid' : ''}
                  >
                    Mínimo de 6 caracteres;
                  </p>
                  <p
                    className={
                      passwordValid.hasEighteen ? 'orientation-valid' : ''
                    }
                  >
                    Máximo de 18 caracteres;
                  </p>
                  <p
                    className={
                      passwordValid.hasUpper ? 'orientation-valid' : ''
                    }
                  >
                    Possuir letras maiusculas;
                  </p>
                  <p
                    className={
                      passwordValid.hasLower ? 'orientation-valid' : ''
                    }
                  >
                    Possuir letras minusculas;
                  </p>
                  <p
                    className={
                      passwordValid.hasNumber ? 'orientation-valid' : ''
                    }
                  >
                    Possuir ao menos um número;
                  </p>
                  <p
                    className={
                      passwordValid.hasSpecial ? 'orientation-valid' : ''
                    }
                  >
                    Possuir ao menos um caracter especial;
                  </p>
                </div>
                <InputWrapper>
                  <TegraInput
                    label="SENHA"
                    name="senha"
                    type={passwordState ? 'text' : 'password'}
                    minLength={6}
                    maxLength={18}
                    onChange={e => checkPassword(e.target.value)}
                  />
                  {passwordState ? (
                    <FaEyeSlash
                      onClick={() => setPasswordState(false)}
                      className="icon"
                      style={{fontSize:'1.50em',opacity:'50%', right: '14px'}}
                    />
                  ) : (
                    <FaEye
                      onClick={() => setPasswordState(true)}
                      className="icon"
                      style={{fontSize:'1.50em',opacity:'50%', right: '14px'}}
                    />
                  )}
                </InputWrapper>
                <InputWrapper>
                  <TegraInput
                    label="CONFIRMAÇÃO DE SENHA"
                    name="confirmacaoSenha"
                    type={passwordState ? 'text' : 'password'}
                    minLength={6}
                    maxLength={18}
                    onChange={e => checkPassword(e.target.value)}
                  />
                  {passwordState ? (
                    <FaEyeSlash
                      onClick={() => setPasswordState(false)}
                      className="icon"
                      style={{fontSize:'1.50em',opacity:'50%', right: '14px'}}
                    />
                  ) : (
                    <FaEye
                      onClick={() => setPasswordState(true)}
                      className="icon"
                      style={{fontSize:'1.50em',opacity:'50%', right: '14px'}}
                    />
                  )}
                </InputWrapper>
                <TegraButton type="submit" isDisabled={loading} isAction style={{marginRight: '0px', fontSize: '14px'}}>
                  Cadastrar
                  {loading && <img src={ajaxLoading} alt="Ícone do botão" />}
                </TegraButton>
              </Form>
            </SingleBox>
          </Box>
        </Content>
      </BoxContainer>

      <LinkErrado>
        <Link to="/login">Voltar ao Login</Link>
      </LinkErrado>
    </Container>
  );
};

export default CadastroSenha;
