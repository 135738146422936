import styled from 'styled-components';
import { Dots } from '@brainhubeu/react-carousel';
import heroBackground from '../../assets/tegra-hero-bg.png';

export const Container = styled.div`
  /* width: 100vw;
  height: 100vh; */

  background: #fff;
`;

export const Hero = styled.div`
  background: url(${heroBackground});
  background-size: cover;
  width: 100%;
  height: 479px;

  
  @media (max-width: 992px) {
    height: 665px;
  }

  @media (max-width: 600px) {
    //height: 690px;
    height: 760px;
  }



  padding-top: 17px;
  padding-bottom: 124px;
`;

export const HeroContent = styled.div`
  padding-top: 64px;
  @media (max-width: 600px) {
    padding-top: 94px;
  }

  display: flex;
  flex-wrap: nowrap;
  //width: 95%;
  width: calc(95% + 13rem);

  @media (max-width: 992px) {
    flex-direction: column;
    width: 100%;
  }

  /*
  @media (max-width: 1268px) {
    flex-direction: column;
  }
  */
`;

export const HeroNews = styled.div`
  background-color: rgba(96, 96, 93, 0.8);
  height: 273px;
  //flex: 50%;
  padding: 41px 43px 40px 2.25rem;
  position: relative;
  width: 100%;
  //max-width: 1003px;

  @media (max-width: 600px) {
    padding: 37px 17px 40px 40px;
  }

  @media (min-width: 992px) {
    margin-right: 20px;
    width: 50%;
  }

  @media (min-width: 1200px) {
    padding-left: calc(calc(calc(100vw - calc(1140px + 3.5rem)) / 2) + 2.25rem);
    margin-right: 20px;
    width: calc(55% + 2rem);
  }

  @media (min-width: 1600px) {
    /*max-width: 1400px;*/
    //max-width: 1200px;
    //width: 56%;
    width: calc(55% + 1.5rem);
    margin-right: 20px;
  }

  @media (min-width: 2200px) {
    width: calc(55% + 1.3rem);
    margin-right: 20px;
  }
`;

export const HeroNewsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 571px;
  max-width: 100%;

  .BrainhubCarouselItem {
    justify-content: flex-start !important;
  }
  .news h1 {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
  }

  & > p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #adadab;
  }
`;

export const HeroNewsNavigation = styled.div`
  margin-top: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeroCalendar = styled.div`
  @media (max-width: 1268px) {
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    margin-top: 33px;
  }
  p {
    color: red;
  }
  width: 100%;
  flex: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-end;

  a {
    text-decoration: none;
  }
`;

export const HeroCalendarContent = styled.div`
  display: flex;
  padding-left: 37px;
  div.info {
    text-align: right;
    flex: 1;
    margin-right: 21px;
  }

  padding-bottom: 15px;

  border-bottom: solid 1px #ffffff;

  p.title {
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: right;
    color: #ebb92d;
  }

  p.subtitle {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.14px;
    text-align: right;
    color: #ffffff;
    text-decoration: underline;
  }
`;

export const HeroWelcome = styled.div`
  position: absolute;
  top: -55px;

  @media (max-width: 600px) {
    top: -85px;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: left;
    color: #ffffff;
  }
`;

export const CustomDots = styled(Dots)`
  flex: 1;

  li {
    button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #ffffff;
      padding: 0 !important;
      opacity: 1 !important;

      &.BrainhubCarousel__thumbnail--selected {
        background: #272723;
      }
    }

    & + li {
      margin-left: 10px;
    }
  }
`;

export const VerMais = styled.p`
  /* background: red; */
  width: 100%;
  align-self: flex-end;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: #ebb92d;
  text-decoration: underline;
  cursor: pointer;

  a {
    color: inherit;
  }
`;

export const HeroNewsHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;

  img {
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  max-width: calc(1140px + 4.5rem);
  margin: 0 auto;
`;


export const CalendarContainer = styled.div`  
  height: 280px;

  @media (max-width: 992px) {
    background-color: rgba(96,96,93,0.8);
    padding-top: 10px;
  }

  .react-calendar{      
    @media (max-width: 992px) {
      background-color: transparent;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    .react-calendar__viewContainer{
      .react-calendar__month-view{        
        div{
          div{
            .react-calendar__month-view__weekdays__weekday abbr[title="domingo"]{
                display: block !important;
                color: #ADADAB;
                font-size: 14px;
                font-weight: normal;
                text-decoration: none;                
            }            
            .react-calendar__month-view__weekdays__weekday abbr[title="sábado"]{
              display: block !important;
              color: #ADADAB;
              font-size: 14px;
              font-weight: normal;
              text-decoration: none;
            }
            .react-calendar__month-view__weekdays__weekday {
              overflow: unset !important;
              abbr{
                display: block !important;
                color: #fff;
                font-size: 14px;
                font-weight: normal;
                text-decoration: none;
              }
            }
          }                      
        }
      }
    }    
  }
`;

export const TitleCalendar = styled.div`
  display: flex;
  position: relative;
  top: -305px;

  img{
    margin-right: 10px;
  }

  p{
    color: #fff;
  }

  @media (max-width: 992px) {
    padding-left: 32px;  
    top: -286px;
    p{
      color: #ADADAB;
    }
  }
`;