import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import TegraPresentationHeader, {
  TegraUser,
} from '../../components/TegraPresentationHeader';
import { IProduto } from '../../components/TegraProdutosList';
import { IFiltroFormData, IEmpreendimento } from '../Products';
import api from '../../services/api';
import { ToSlug } from '../../services/helpers';

import { Container, Content } from '../Products/styles';
import { TegraCorretor } from './style';
import { useLoader } from '../../hooks/loader';
import { getLogoByTipo, getTituloByTipo } from '../../utils/logo';
import { isLab } from '../../utils/login';
import { useWindowDimensions } from '../../utils/windowDimensions/index';
import TegraContactBar from '../../components/TegraContactBar';
import axios from 'axios';

const Corretor: React.FC = () => { 
  const { regional, apelido, empreendimento } : any = useParams();

  const { setLoaderActive } = useLoader();
  const [, setFiltroShow] = useState(false);
  const [user, setUser] = useState<TegraUser>({
    name: '',
    creci: '',
    email: '',
    phone: '',
    image: '',
    description: '',
    tipo: 0,
    isLab: false,
  });
  const [produtos, setProdutos] = useState<IProduto[]>([]);
  const [, setMaxPages] = useState<number>(Number.MAX_VALUE);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [, setTotalPages] = useState(0);
  const [rawContent, setRawContent] = useState<any>();
  const [, setIdEstado] = useState<number | undefined>(undefined);
  const PAGE_SIZE = 12;
  const [lastSearch, setLastSearch] = useState<IFiltroFormData>(
    {} as IFiltroFormData,
  );
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setLoaderActive(userLoading || productsLoading);
  }, [userLoading, productsLoading, setLoaderActive]);
  
  let urlEmpreendimento = `https://www.tegraincorporadora.com.br/${regional}/${empreendimento}`;

  const getProfileData = async () => {
    setUserLoading(true);
    await api
      .get(`Perfil/pagina`, {
        params: {
          regional,
          apelido,
        },
      })
      .then(res => {
        const userResponse = res.data;
        setIdEstado(userResponse.id_empresaSigavi === 2 ? 19 : 26);
        setUser({
          name: userResponse.tx_apelido,
          creci: userResponse.tx_creci,
          email: userResponse.tx_email,
          phone: userResponse.tx_telefone,
          image:  userResponse.user_image,
          description: userResponse.tx_saudacao,
          tipo: userResponse.tipo,
          isLab: isLab(userResponse.id_corretorSigavi),
        });
        setUserLoading(false);
      })
      .catch(err => {
        const { status } = err.response;
        if (status === 401)
          window.location.replace(urlEmpreendimento);
      });
  };

  const doSearch = async (
    filtro?: IFiltroFormData,
    force = false,
    page: number | undefined = undefined,
  ): Promise<void> => {
    const params = new URLSearchParams();

    if (!force && JSON.stringify(filtro) === JSON.stringify(lastSearch)) return;
    const data: IFiltroFormData = filtro || ({} as IFiltroFormData);

    setLastSearch(data);
    const pagina = page || currentPage;

    params.append('nu_pagina', pagina.toString());
    params.append('nu_quantidade', PAGE_SIZE.toString());
    params.append('apelido', apelido);
    params.append('regional', regional);

    if (
      data &&
      (data.aerea ||
        data.preco ||
        data.tipo_produto ||
        data.estagio_obra ||
        data.regioes ||
        data.bairros ||
        data.dormitorios ||
        data.search)
    ) {
      if (data.aerea !== '') {
        const area = JSON.parse(data.aerea as string);
        params.append('nu_areaDe', area.min || 0);
        params.append('nu_areaAte', area.max || 999999);
      }

      if (data.preco !== '') {
        const preco = JSON.parse(data.preco as string);
        params.append('nu_precoDe', preco.min || 0);
        params.append('nu_precoAte', preco.max || 999999);
      }
      if (
        data.tipo_produto !== '' &&
        JSON.parse(data.tipo_produto).length !== 0
      ) {
        JSON.parse(data.tipo_produto).forEach((item: string) =>
          params.append('id_tipo', item),
        );
      }
      if (
        data.estagio_obra !== '' &&
        JSON.parse(data.estagio_obra).length !== 0
      ) {
        JSON.parse(data.estagio_obra).forEach((item: string) =>
          params.append('id_andamento', item),
        );
      }
      if (data.regioes !== '' && JSON.parse(data.regioes).length !== 0) {
        JSON.parse(data.regioes).forEach((item: string) =>
          params.append('regioes', item),
        );
      }
      if (data.bairros !== '' && JSON.parse(data.bairros).length !== 0) {
        JSON.parse(data.bairros).forEach((item: string) =>
          params.append('bairros', item),
        );
      }
      if (
        data.dormitorios !== '' &&
        JSON.parse(data.dormitorios).length !== 0
      ) {
        JSON.parse(data.dormitorios).forEach((item: string) =>
          params.append('dormitorios', item),
        );
      }

      if (data.search) {
        params.append('pesquisa', data.search);
      }
    }
    setProductsLoading(true);
    const response = await api.get('Empreendimento/minhaPagina', {
      params,
    });

    const { items, paginas } = response.data;
    let newProdutos = pagina === 1 ? [] : produtos;

    newProdutos = newProdutos.concat(
      items.map(
        (item: IEmpreendimento) =>
          ({
            nome: item.nome,
            localidade: {
              bairro: item.bairro,
              cidade: item.cidade,
              uf: item.uf,
            },
            id: item.id,
            metragem: item.metragemDescricao,
            aerea: item.distancia,
            destaque: true,
            estagio_obra: item.empreendimentoAndamento,
            imagem: item.imagemBuscaPequena,
            preco: item.valor,
            titulo: item.nome,
            regiao: '',
            informacoes: '',
            tipo: item.empreendimentoAndamentoId,
            slug: item.slug,
          } as IProduto),
      ),
    );

    setProdutos(newProdutos);
    setTotalPages(paginas);
    setMaxPages(response.data.paginas);

  };

  const getPageData = AwesomeDebouncePromise(async (data: IFiltroFormData) => {
    await doSearch(data);
    setCurrentPage(1);
  }, 600);

  const handleFiltroShow = useCallback((shouldShow: boolean) => {
    setFiltroShow(shouldShow);
  }, []);

  const handleLoadMore = useCallback(() => {
    doSearch(lastSearch, true, currentPage + 1);
  }, [produtos, lastSearch, currentPage, doSearch]);

  useEffect(() => {
    doSearch(undefined);
    getProfileData();
  }, []);

  let urlEmpreendimentoCorretor = width <= 900 
  ? `${process.env.REACT_APP_VITRINEMOB}${ToSlug(apelido)}/${regional}/${regional === 'sp'? 'sao-paulo': regional === 'rj'? 'rio-de-janeiro': 'campinas'}/${empreendimento}?o=1`
  : `${process.env.REACT_APP_VITRINE}${ToSlug(apelido)}/${regional}/${regional === 'sp'? 'sao-paulo': regional === 'rj'? 'rio-de-janeiro': 'campinas'}/${empreendimento}?o=1`;
  
  useEffect(() => {
    const DivScriptsVitrine = document.getElementById('ScriptsVitrine');
    DivScriptsVitrine!.innerHTML = '';
    setProductsLoading(true);
    let urlVitrine = width <= 900 ? process.env.REACT_APP_VITRINEMOB : process.env.REACT_APP_VITRINE;
    axios
    .get(urlEmpreendimentoCorretor)
    .then(function (res) {
      let jqueryScript = document.createElement('script');
      jqueryScript.src= `${urlVitrine}Content/js/libs/jquery.min.js`;
      DivScriptsVitrine!.appendChild(jqueryScript);

      let htmlPagina = res.data;
      let doc = new DOMParser().parseFromString(htmlPagina, 'text/html');
      let linksVitrine = doc.querySelectorAll('link');
      for (let l = 0; l < linksVitrine.length; l++) {
        if (linksVitrine[l].href.split('/')[2] === window.location.host) {
          linksVitrine[l].href = `${urlVitrine}${linksVitrine[l].href.replace(/^(?:\/\/|[^/]+)*\//, '')}`;
        }
      }

      let scriptsJs = doc.querySelectorAll("script");
      const addDependentScripts = async function( scriptsToAdd: any ) {
        for (let s = 0; s < scriptsToAdd.length; s++) {
          if(scriptsToAdd[s].hasAttribute("src") && scriptsToAdd[s].getAttribute('src')![1] === "C"){

            let scriptVitrine = scriptsToAdd[s].getAttribute('src');
            scriptVitrine = `${urlVitrine}${scriptVitrine!.substring(1)}`;
            const scriptOnPage=document.createElement('script')
            let r = await fetch( scriptVitrine )
            scriptOnPage.text += await r.text()
            DivScriptsVitrine!.appendChild(scriptOnPage)
            
          }else if(!scriptsToAdd[s].hasAttribute("src")){       
            try{
              eval(scriptsToAdd[s].innerHTML)
            }catch(e){
              console.error(e);
            }         
          }else if(scriptsToAdd[s].hasAttribute("src")){
            let script = document.createElement('script');
            script.src= scriptsToAdd[s].getAttribute('src') || '';
            DivScriptsVitrine!.appendChild(script);
          } 
        }
      }
      addDependentScripts(scriptsJs)
      
      let imgsVitrine = doc.querySelectorAll("img[src^='/Content/']");
      for (let i = 0; i < imgsVitrine.length; i++) {
        let imgVitrineLink = imgsVitrine[i].getAttribute('src');
        imgVitrineLink = `${urlVitrine}${imgVitrineLink!.substring(1)}`;
        imgsVitrine[i].setAttribute('src', imgVitrineLink);
      }
      
      setRawContent(doc.documentElement.innerHTML);
      setProductsLoading(false);
    })
    .catch(function (err) {
      // window.location.replace(urlEmpreendimento);
      console.log(err);
    });
  }, [urlEmpreendimentoCorretor]);
  
  return (
    <TegraCorretor>
      <TegraContactBar tel={user.phone} mail={user.email} name={user.name}/>
      <div className="header-container">
        <TegraPresentationHeader user={user} details />
      </div>
      <div dangerouslySetInnerHTML={{ __html: rawContent }}></div>
      <div id="ScriptsVitrine" />
    </TegraCorretor>
  );
};

export default Corretor;
