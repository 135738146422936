/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { Container, Content, PageAnchor } from './styles';
import TegraOpaqueHeroProduct from './TegraOpaqueHeroProduct';
import ProdutoVideoGallery from './ProdutoVideoGallery';
import ProdutoSimuladorFinanciamento from './ProdutoSimuladorFinanciamento';
import TegraArquivosList from './TegraArquivosList';
import NavAncora from './NavAncora';
import GuiaBairro from './GuiaBairro';
import TegraDivider from '../../components/TegraDivider';
import ProdutoPhotoGallery from './ProdutoPhotoGallery';
import api from '../../services/api';
import getFormattedDateAndTime from '../../utils/date/getFormattedDateAndTime';
import { useLoader } from '../../hooks/loader';
import ContactFloatCardProduct from '../../components/ContactFloatCardProduct';
import {
  getTelegramURL,
  getFacebookURL,
  getWhatsappURL,
} from '../../utils/share';
import { NumericLiteral } from 'typescript';

interface IPhoto {
  name: string;
  original: any;
  thumbnail: any;
  description?: string;
  galeria?: string;
}

interface IImagem {
  id: number;
  caminho: string;
  url: string;
  urlVirtual: string;
  urlFinalSioux: string;
  legenda: string;
  ordem: number;
  destaque: boolean;
  origemId: number;
  origemTabela: string;
  tipo: number;
  tamanho: number;
  galeria: string;
  fotos: boolean;
  urlFinal: string;
  obtemCaminhoCompleto: string;
}

interface IPlanta {
  id: number;
  legenda: string;
  obtemCaminhoCompleto: string;
  origemTabela: string;
  urlFinal: string;
  galeria?: string;
}

interface IDocumento {
  dt_cadastro: string;
  id: number;
  id_produto: number;
  id_tipoDocumento: number;
  is_deleted: boolean;
  nome: string;
  tx_produto: string;
  urL_documento: string;
  urL_facebook?: string;
  urL_telegram?: string;
  urL_whats?: string;
}

interface IVideo {
  id: number;
  name: string;
  description: string;
  content: any;
  tx_thumbnail?: any;
}

interface TegraArquivoItem {
  visualizar: boolean;
  compartilhar: boolean;
  baixar: boolean;
  nome: string;
  data_inclusao?: string;
  disable_buttons?: boolean;
  id: number;
  produto: number;
  link?: string;
  urlWhats?: string;
  urlFacebook?: string;
  urlTelegram?: string;
}

interface IProduto {
  id: number;
  nome: string;
  chamada: string;
  bairro: string;
  cidade: string;
  uf: string;
  metragemDe: number;
  metragemAte: number;
  metragemDescricao: string;
  dormitoriosDescricao: string;
  flag100Vendido: true;
  empreendimentoTipo: string;
  empreendimentoAndamento: string;
  showFinanciamento: boolean;
  espelho: boolean;
  tabela: boolean;
  valor: number;
  prazo: number;
  porcentagemEntrada: number;
  taxaEfetiva: number;
  fichaTecnica: string;
  books: {
    id: number;
    id_produto: number;
    id_tipoDocumento: number;
    tx_produto: string;
    nome: string;
    urL_documento: string;
    is_deleted: true;
    dt_cadastro: string;
  };

  imagens: IImagem[];
  plantas: IPlanta[];
  mapaUrl: string;
  possuiTabelaEspelho: boolean;
  textoLegal: string;
  bancoFinanciador: string;
  sobreLocalizacao: string;
  blocoGastronomia: string;
  blocoLazer: string;
  blocoEducacao: string;
  blocoVoceSabia: string;
  blocoHtml1: string;
  blocoHtml2: string;
  logradouro: string;
  numero: number;
  vagasDescricao: string;
  descricaoValor: string;
  videos: {
    data_publicacao: string;
    dt_publicacao: string;
    dt_publicacaoAntiga: string;
    ic_parceiros: boolean;
    ic_tegraVendas: boolean;
    id_empreendimento: number;
    id_video: number;
    is_ativo: boolean;
    is_deleted: false;
    nu_size: number;
    tx_arquivo: string;
    tx_extensao: string;
    tx_link: string;
    tx_thumbnail: null;
    tx_tipoVideo: string;
    tx_titulo: string;
  }[];
  apresentacao: IDocumento[];
  treinamento: IDocumento[];
  valorAproximado: string;
  iptuCondominio: IDocumento[];
  tourVirtual: IDocumento[];
  emailMarketing: IDocumento[];
  memorial: IDocumento[];
  informacoesIM: IDocumento[];
  materiaisGerais: IDocumento[];
}

interface IProductDetailsProps { }
const ProductDetails: React.FC<IProductDetailsProps> = () => {
  const [produto, setProduto] = useState<IProduto>({
    ...({} as IProduto),
    imagens: [],
    plantas: [],
    videos: [],
    apresentacao: [],
    iptuCondominio: [],
    tourVirtual: [],
    emailMarketing: [],
    memorial: [],
    informacoesIM: [],
    materiaisGerais: [],
    treinamento: [],
  } as IProduto);

  const { id, uf, slug, cidade } = useParams<any>();

  const { setLoaderActive } = useLoader();

  useEffect(() => {
    // Fazer chamada na api
    setLoaderActive(true);
    api
      .get('Empreendimento/slug', { params: { slug } })
      .then(res => {
        const { result } = res.data;
        setProduto(result);
        console.log("dados",result);
      })
      .finally(() => {
        setLoaderActive(false);

        const scrollElement = localStorage.getItem('SCROLL') || '';
        if (scrollElement) {
          const element: any = document.querySelector(scrollElement);
          if (element) {
            let { top } = element.getBoundingClientRect();
            top -= 50;
            setTimeout(() => {
              window.scrollTo({
                top,
                behavior: 'smooth',
              });
            }, 500);
          }
          localStorage.removeItem('SCROLL');
        }
      });
  }, [id, setLoaderActive]);

  const materiais = useMemo<TegraArquivoItem[]>(() => {
    const arquivos: TegraArquivoItem[] = [];
    if (produto.tabela || produto.espelho) {
      const arquivoEspelho: TegraArquivoItem = {
        id: 2,
        nome: 'Espelho e tabela',
        produto: produto.id,
        link: window.location.origin + '/dashboard/espelhos-e-tabelas/' + produto.id,
        baixar: false,
        compartilhar: false,
        visualizar: true,
      };
      arquivos.push(arquivoEspelho);
    }
    if (produto.books) {
      const arquivoBooks: TegraArquivoItem = {
        id: produto.books.id,
        produto: produto.id,
        nome: produto.books.nome,
        link: produto.books.urL_documento,
        baixar: true,
        compartilhar: false,
        visualizar: true,
      };
      arquivos.push(arquivoBooks);
    }
    const arquivoFicha: TegraArquivoItem = {
      id: 1,
      produto: produto.id,
      nome: 'Ficha Técnica',
      link: produto.fichaTecnica,
      baixar: false,
      compartilhar: false,
      visualizar: true,
    };
    arquivos.push(arquivoFicha);
    return arquivos;
  }, [produto]);

  const imagens = useMemo<IPhoto[]>(() => {
    return produto.imagens.map(imagem => ({
      id: imagem.id,
      produto: produto.id,
      name: imagem.legenda,
      description: imagem.legenda,
      original: imagem.obtemCaminhoCompleto,
      thumbnail: imagem.obtemCaminhoCompleto
    }));
  }, [produto]);

  const videos = useMemo<IVideo[]>(() => {
    return produto.videos.map(video => ({
      name: video.tx_titulo,
      id: video.id_video,
      produto: produto.id,
      tx_thumbnail: video.tx_thumbnail,
      tx_arquivo: video.tx_arquivo,
      content: video.data_publicacao,
      description: video.tx_tipoVideo,
      link: video.tx_link,
    }));
  }, [produto]);

  const plantas = useMemo<IPhoto[]>(() => {
    return produto.plantas.map(planta => ({
      name: planta.legenda,
      description: planta.legenda,
      id: planta.id,
      produto: produto.id,
      original: planta.obtemCaminhoCompleto,
      thumbnail: planta.obtemCaminhoCompleto,
      galeria: planta.galeria,
    }));
  }, [produto]);

  const apresentacao = useMemo<TegraArquivoItem[]>(() => {
    return produto.apresentacao.map(documento => ({
      id: documento.id,
      data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      produto: produto.id,
      link: documento.urL_documento,
      baixar: true,
      compartilhar: false,
      visualizar: true,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);

  const treinamento = useMemo<TegraArquivoItem[]>(() => {
    return produto.treinamento.map(documento => ({
      id: documento.id,
      data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      produto: produto.id,
      link: documento.urL_documento,
      baixar: true,
      compartilhar: false,
      visualizar: true,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);

  const tourVirtual = useMemo<TegraArquivoItem[]>(() => {
    return produto.tourVirtual?.map(documento => ({
      id: documento.id,
      // data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      produto: produto.id,
      link: documento.urL_documento,
      baixar: false,
      compartilhar: true,
      visualizar: true,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);

  const iptu = useMemo<TegraArquivoItem[]>(() => {
    return produto.iptuCondominio?.map(documento => ({
      id: documento.id,
      data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      link: documento.urL_documento,
      baixar: true,
      compartilhar: false,
      visualizar: true,
      produto: produto.id,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);

  const materiaisGerais = useMemo<TegraArquivoItem[]>(() => {
    return produto.materiaisGerais?.map(documento => ({
      id: documento.id,
      data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      link: documento.urL_documento,
      baixar: true,
      compartilhar: false,
      visualizar: true,
      produto: produto.id,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);


  const memorial = useMemo<TegraArquivoItem[]>(() => {
    return produto.memorial.map(documento => ({
      id: documento.id,
      data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      link: documento.urL_documento,
      baixar: true,
      compartilhar: false,
      visualizar: true,
      produto: produto.id,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);

  const emailMarketing = useMemo<TegraArquivoItem[]>(() => {
    return produto.emailMarketing.map(documento => ({
      id: documento.id,
      data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      link: documento.urL_documento,
      baixar: true,
      produto: produto.id,
      compartilhar: false,
      visualizar: true,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);

  const infoIM = useMemo<TegraArquivoItem[]>(() => {
    return produto.informacoesIM.map(documento => ({
      id: documento.id,
      data_inclusao: getFormattedDateAndTime(documento.dt_cadastro),
      nome: documento.nome,
      link: documento.urL_documento,
      baixar: true,
      produto: produto.id,
      compartilhar: false,
      visualizar: true,
      urlWhats: getWhatsappURL(documento.urL_documento),
      urlFacebook: getFacebookURL(documento.urL_documento),
      urlTelegram: getTelegramURL(documento.urL_documento),
    }));
  }, [produto]);

  return (
    <Container>
      <TegraOpaqueHeroProduct
        title={produto.nome}
        subtitle={produto.dormitoriosDescricao}
        subDescription={produto.metragemDescricao}
        valorAproximado={produto.valorAproximado}
        description={produto.empreendimentoAndamento}
        bairro={produto.bairro}
        cidade={produto.cidade}
        vagas={produto.vagasDescricao}
        descricaoValor={produto.descricaoValor}
      />
      <div>
        <TegraDivider height={1} color="#e6e6e6" axis="x" />
      </div>
      <TegraArquivosList
        titulo="Materiais do Produto"
        arquivos={materiais}
        produto={produto.nome}
        id_empreendimento={produto.id}
      />
      <PageAnchor href="#" id="Imagens" />
      {produto.imagens && produto.imagens.length > 0 && (
        <ProdutoPhotoGallery
          className="photoGallery"
          imagens={imagens}
          title="Imagens"
          empreendimento={produto.nome}
          id_empreendimento={produto.id}
        />
      )}
      <PageAnchor href="#" id="Plantas" />
      {produto.plantas && produto.plantas.length > 0 && (
        <ProdutoPhotoGallery
          className="photoGallery planta"
          imagens={plantas}
          title="Plantas"
          empreendimento={produto.nome}
          id_empreendimento={produto.id}
        />
      )}
      {produto.mapaUrl && (       
        <GuiaBairro
          mapaUrl={produto.mapaUrl}
          endereco={`${produto.logradouro}, ${produto.numero}`}
          bairro={produto.bairro}
          blocoHtml1={produto.blocoHtml1}
          blocoHtml2={produto.blocoHtml2}
          sobreLocalizacao={produto.sobreLocalizacao}
          blocoGastronomia={produto.blocoGastronomia}
          blocoLazer={produto.blocoLazer}
          blocoEducacao={produto.blocoEducacao}
          blocoVoceSabia={produto.blocoVoceSabia}
          uf={produto.uf}
        />
      )}
      {produto.videos && produto.videos.length > 0 && (
        <>
          <PageAnchor href="#" id="Videos" />
          <ProdutoVideoGallery
            className="videosGallery"
            videos={videos}
            empreendimento={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}

      {produto.apresentacao && produto.apresentacao.length > 0 && (
        <>
          <PageAnchor href="#" id="Apresentacao" />
          <TegraArquivosList
            titulo="Apresentação"
            arquivos={apresentacao}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}
      {produto.treinamento && produto.treinamento.length > 0 && (
        <>
          <PageAnchor href="#" id="Treinamento" />
          <TegraArquivosList
            titulo="Treinamento"
            arquivos={treinamento}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}      
      {produto.iptuCondominio && produto.iptuCondominio.length > 0 && (
        <>
          <PageAnchor href="#" id="Iptu" />
          <TegraArquivosList
            titulo="IPTU e Condomínio"
            arquivos={iptu}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}
      {produto.tourVirtual && produto.tourVirtual.length > 0 && (
        <>
          <PageAnchor href="#" id="Tour" />
          <TegraArquivosList
            titulo="Tour Virtual"
            arquivos={tourVirtual}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}
      {/* {produto.materiaisGerais && produto.materiaisGerais.length > 0 && (
        <>
          <PageAnchor href="#" id="MateriaisGerais" />
          <TegraArquivosList
            titulo="Materiais Gerais"
            arquivos={materiaisGerais}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )} */}
      {produto.emailMarketing && produto.emailMarketing.length > 0 && (
        <>
          <PageAnchor href="#" id="Email" />
          <TegraArquivosList
            titulo="E-mail marketing"
            arquivos={emailMarketing}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}
      {produto.memorial && produto.memorial.length > 0 && (
        <>
          <PageAnchor href="#" id="Memorial" />
          <TegraArquivosList
            titulo="Memorial Descritivo"
            arquivos={memorial}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}
      {produto.informacoesIM && produto.informacoesIM.length > 0 && (
        <>
          <PageAnchor href="#" id="Informacoes" />
          <TegraArquivosList
            titulo="Informações de IM"
            arquivos={infoIM}
            produto={produto.nome}
            id_empreendimento={produto.id}
          />
        </>
      )}
      {/* <TegraDivider axis="x" height={1} color="#e6e6e6" /> */}
      <PageAnchor href="#" id="Simule" />
      {produto.showFinanciamento && (
        <ProdutoSimuladorFinanciamento
          valor={produto.valor}
          prazo={produto.prazo}
          porcentagemEntrada={produto.porcentagemEntrada}
          taxaEfetiva={produto.taxaEfetiva}
        />
      )}
      {produto.bancoFinanciador && (
        <div className="banco-financiador">
          Banco Financiador :
          <br />
          <img src={produto.bancoFinanciador}></img>
        </div>
      )}
      {produto.textoLegal && (
        <div className="texto-legal">
          <p dangerouslySetInnerHTML={{ __html: produto.textoLegal }}></p>
        </div>
      )}
      <ContactFloatCardProduct id_produto={produto.id}/>
    </Container>
  );
};

export default ProductDetails;
