import React, { useCallback,useState   } from 'react';

import ReactSelect from "react-select";
import { components } from "react-select";
import checkItem from '../../assets/tegra-icon-check.svg';

import {
    DivDropDown,
    StyledCheckbox,
    Icon,
    ValueWrapper,
  } from './styles';

interface TegraSelectDropDownProps {
    label: string;
    name: string;
    options: {
        label: string;
        value: string;
      }[];
    setItems: Function;    
  }

  const TegraSelectDropDown: React.FC<TegraSelectDropDownProps> = ({
      label,
      name,
      options,
      setItems,
  }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);


      const ValueContainer = props => {
        return (
          <components.ValueContainer {...props} >
            <ValueWrapper>
              {props.selectProps.placeholder}
              <span className="dummy-input-wrapper">{props.children}</span>
            </ValueWrapper>
          </components.ValueContainer>
        );
      };


      const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
    
              <div style={{display: 'flex'}}>
                 <StyledCheckbox checked={props.isSelected || false} defaultChecked={false}>
                    <Icon src={checkItem} />
                 </StyledCheckbox>{" "}
                <label>{props.label}</label>
              </div>
            </components.Option>
          </div>
        );
      };
    
      const customStyles = {
        option: (provided, state) => ({
          ...provided,
          cursor: 'pointer',
          color: '#60605D',
          padding: 20,
          paddingBottom: 10,
          paddingTop: 10,
          backgroundColor: '#fff',
          "&:hover": {
            backgroundColor: "#fff",
          }
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: 'absolute',
        }),
        multiValueRemove:(provided, state) => ({
          ...provided,
          "&:hover": {
            backgroundColor: "#e6e6e6",
            color: '#60605d',
          }
        }),
        control: (provided, state) => ({
          ...provided,
          background: '#E6E5E5',
          boxShadow: 'none',
          border: 0,
          cursor: 'pointer',
        }),
        indicatorsContainer:(provided, state) => ({
          ...provided,     
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 0,
         
        }),
        indicatorSeparator:(provided, state) => ({
          ...provided,     
          width: 0,
        }),    
        dropdownIndicator: (provided, state) => ({
          ...provided,
          background: state.selectProps.menuIsOpen ? 
              'linear-gradient(315deg,#60605d 0%,#60605d 20%,#E6E5E5 10%,#E6E5E5 100%)' :
              'linear-gradient(315deg,#FDBE39 0%,#FDBE39 20%,#E6E5E5 10%,#E6E5E5 100%)' ,
    
      }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
          return { ...provided, opacity, transition };
        }
      }
      
    
    
      const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <DivDropDown/>
          </components.DropdownIndicator>
        );
      };    

      const onOptionChange = useCallback(
        (e, nome) => {
            setItems(e);
            setMenuIsOpen(true);
            //console.log('selecionado',e);
            //console.log('selecionado',nome);
        },
        [setItems, setMenuIsOpen],
      );

    return (
        <div style={{borderBottomColor: '#5F605D', borderBottomWidth: 1, borderBottomStyle: 'solid'}}>

        <ReactSelect
          styles={customStyles}
          options={options}
          isMulti
          components={{
            ValueContainer: ValueContainer,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            Option, 
          }}
          onChange={e => onOptionChange(e,name)}
          openMenuOnClick={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isSearchable={false}
          placeholder={label}
        />  
        </div>
  
    );
  };
  
  export default TegraSelectDropDown;