import styled from "styled-components";
import colors from "../../../styles/colors";
import chevron from '../../../assets/tegra-arrow-down-icon.svg'

export const RankingSelfHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2.25rem;
    @media(min-width: 992px) {
        margin-bottom: 0;    
    }
`
export const RankingSelfContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.lightGrey};
    margin: 0 -2.25rem;
    width: calc(100% + 4.5rem);
    padding: 2.25rem;
    margin-bottom: 2.5rem;
    @media(min-width: 992px) {
        flex-direction: row;
        padding: 1rem 2.5rem;
        align-items: center;
    }
`;

export const RankingSelfBackIcon = styled.div`
    background-image: url(${chevron});
    transform: rotate(90deg);
    height: 10px;
    width: 20px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right:1.25rem;
`;

export const RankingSelfImage = styled.img`
    border-radius: 50%;
    height: 65px;
    width: 65px;
    @media(min-width: 992px) {
        margin-right: 1rem;
    }
`;

export const RankingSelfDivider = styled.div`
    display: none;
    margin: 0 1rem;
    background-color: ${colors.primaryColor};
    width: 1px;
    height: 2rem;
    @media(min-width: 992px) {
        display: block;
    }
`;

export const RankingSelfData = styled.p`
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin-bottom: 1rem;
    @media(min-width: 992px) {
        margin-bottom: 0;
    }
`;