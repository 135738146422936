/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import TegraTabsContainer from '../../components/TegraTabsContainer';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraButton from '../../components/TegraButton';
import { useAuth } from '../../hooks/auth';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

import {
  TableContainer,
  RelatorioPageContainer,
  RelatorioContentWrapper,
  FilterGrid,
  FilterGridItem,
  RelatorioTabsWrapper,
} from './styles';
import { getDescricao, getSessao } from '../../utils/log';
import { isConstructorDeclaration } from 'typescript';

const tabNumber = Object.freeze({
  ACESSOS: 0,
  MATERIAIS: 1,
  ACESSOSDETALHES: 2,
  MATERIAISDETALHES: 3,
  MATERIAISDETALHESUSUARIOS: 4,
});

const columnsTypes = Object.freeze([
  { key: 'dt_cadastro', header: 'Data', groups: [0] },
  { key: 'id_tipoSessao', header: 'Seção', groups: [0, 2, 4] },
  { key: 'tx_usuario', header: 'Usuário', groups: [0, 2] },
  { key: 'tx_cargo', header: 'Cargo', groups: [0] },
  { key: 'tx_empreendimento', header: 'Empreendimento', groups: [0, 1, 2] },
  { key: 'tx_atividade', header: 'Tipo de atividade', groups: [0, 1, 2, 3] },
  { key: 'nu_quantidade', header: 'Qtd.', groups: [1, 2, 3, 4] },
  { key: 'txt_descricao', header: 'Descrição', groups: [0, 2] },
]);

export interface IRelatorio {
  [key: string]: string;
}

const RelatorioAtividades: React.FC = () => {
  const history = useHistory();
  const { setLoaderActive } = useLoader();
  const navigate = (url: string): void => {
    history.push(`/dashboard/relatorio/${url}`);
  };
  const navigateIndex = (index: number): void => {
    switch (index) {
      case tabNumber.ACESSOS:
        return history.push(`/dashboard/relatorio/acessos`);
      case tabNumber.MATERIAIS:
        return history.push(`/dashboard/relatorio/materiais`);
      default:
        break;
    }
  };
  const [groupBy, setGroupBy] = useState(0);
  const [q, setQ] = useState('');
  const [corretor, setCorretor] = useState(0);
  const [acao, setAcao] = useState(-1);
  const [sessao, setSessao] = useState(-1);
  const [gerente, setGerente] = useState(0);
  const [superintendente, setSuperintendente] = useState(0);
  const [empreendimento, setEmpreendimento] = useState(0);
  const [empreendimentos, setEmpreendimentos] = useState([] as any[]);
  const { user } = useAuth();

  const [superintendentes, setSuperintendentes] = useState([] as any[]);
  const [gerentes, setGerentes] = useState([] as any[]);
  const [corretores, setCorretores] = useState([] as any[]);
  const [columns, setColumns] = useState([] as any[]);
  const [dados, setDados] = useState([] as any[]);
  const [dadosRaw, setDadosRaw] = useState([] as any[]);
  const [dadosVisible, setDadosVisible] = useState(10);
  const [order, setOrder] = useState(0);

  const dataAnterior = new Date();
  dataAnterior.setDate(dataAnterior.getDate() - 30);

  const [dateFrom, setDateFrom] = useState(
    dataAnterior.toISOString().split('T')[0],
  );
  const [dateTo, setDateTo] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    async function load() {
      if (user.isAdmin || (superintendentes && superintendentes.length > 0)) {
        const rs = await api.get(`Gerente/dropSuperintendentes`);
        setSuperintendentes(rs.data);
      } else {
        setSuperintendentes([]);
      }
    }
    load();
  }, [user]);

  useEffect(() => {
    async function load() {
      if (superintendente !== 0 && (user.isSuperintendente || user.isAdmin)) {
        const rs = await api.get(`Gerente/dropGerentes?id=${superintendente}`);
        setGerentes(rs.data);
      } else if (user.isRH) {
        const rs = await api.get(`Gerente/dropGerentes`);
        setGerentes(rs.data);
      } else {
        if(superintendente === 0) {
          setCorretor(0);
          setGerente(0);
        }
        setGerentes([]);
        setCorretores([]);
      }
    }
    load();
  }, [user, superintendente]);

  useEffect(() => {
    async function load() {
      if (
        gerente !== 0 &&
        (user.isGerente || user.isSuperintendente || user.isAdmin || user.isRH)
      ) {
        const rs = await api.get(`Gerente/dropCorretores?id=${gerente}`);
        setCorretores(rs.data);
      } else {
        if(gerente === 0) {
          setCorretor(0);
        }
        setCorretores([]);
      }
    }
    load();
  }, [user, gerente]);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await api.get(`Empreendimento/list`);
        setEmpreendimentos(response.data);
        if (user.isSuperintendente) {
          setSuperintendente(user.id);
        } else if (user.isGerente) {
          setGerente(user.id);
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetch();
  }, [user]);

  const getQuery = (
    from: any,
    to: any,
    gr: any,
    c: any,
    a: any,
    s: any,
    sup: any,
    ge: any,
    e: any,
    query: any,
  ) => {
    return `?q=${encodeURIComponent(
      query,
    )}&inicio=${from}&fim=${to}&groupBy=${gr}&superintendente=${sup}&corretor=${c}&acao=${a}&sessao=${s}&gerente=${ge}&empreendimento=${e}`;
  };

  const onSearch = useCallback(() => {
    async function search(qs: any, gr: any) {
      setLoaderActive(true);
      const url = `RelatorioAtividades${qs}`;
      const response = await api.get(url);
      setDadosVisible(10);
      setOrder(0);
      const apiResponse = response.data;
      try {
        setColumns(columnsTypes.filter(ct => ct.groups.includes(gr)));
        setDados(
          apiResponse.map(
            (dado: any, index: number) =>
              ({
                ...dado,
                dt_cadastro: new Date(dado.dt_cadastro).toLocaleDateString(),
                id_tipoAtividade: getDescricao(dado.id_tipoAtividade),
                id_tipoSessao: getSessao(dado.id_tipoSessao),
                visible: index <= 9,
              } as any),
          ),
        );

        setDadosRaw(
          apiResponse.map(
            (dado: any, index: number) =>
              ({
                ...dado,
                dt_cadastro: new Date(dado.dt_cadastro).toLocaleDateString(),
                id_tipoAtividade: getDescricao(dado.id_tipoAtividade),
                id_tipoSessao: getSessao(dado.id_tipoSessao),
                visible: index <= 9,
              } as any),
          ),
        );
      } catch (e) {
        console.error(e);
      }
      setLoaderActive(false);
    }
    const query = getQuery(
      dateFrom,
      dateTo,
      groupBy,
      corretor,
      acao,
      sessao,
      superintendente,
      gerente,
      empreendimento,
      q,
    );
    search(query, groupBy);
  }, [
    setLoaderActive,
    dateFrom,
    dateTo,
    groupBy,
    superintendente,
    corretor,
    acao,
    sessao,
    gerente,
    empreendimento,
    q,
  ]);

  const loadMore = useCallback(() => {
    const index = dados.filter(d => d.visible).length + 10;
    setDadosVisible(index);
    setDados([
      ...dados.map((d, i) => ({
        ...d,
        visible: i < index,
      })),
    ]);
  }, [dados]);

  const onExport = useCallback(
    e => {
      e.preventDefault();
      async function download(qs: any) {
        const filename = `export_${new Date().toISOString()}.xlsx`;
        api
          .get(`RelatorioAtividades/export${qs}`, {
            responseType: 'blob',
            timeout: 2 * 60 * 1000
          })
          .then(response => {
            const nav = (window.navigator as any);        
            if (nav.msSaveOrOpenBlob) {
              // IE11
              nav.msSaveOrOpenBlob(
                new Blob([response.data]),
                filename,
              );
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(url);
              }, 100);
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
      const query = getQuery(
        dateFrom,
        dateTo,
        groupBy,
        corretor,
        acao,
        sessao,
        superintendente,
        gerente,
        empreendimento,
        q,
      );
      download(query);
    },
    [
      dateFrom,
      dateTo,
      groupBy,
      superintendente,
      corretor,
      acao,
      sessao,
      gerente,
      empreendimento,
      q,
    ],
  );

  useEffect(() => {
    onSearch();
  }, []);

  const orderByName = useCallback(() => {
    if (order === 0) {
      setDados([
        ...dados
          .sort((a, b) =>
            a.tx_usuario > b.tx_usuario
              ? 1
              : b.tx_usuario > a.tx_usuario
              ? -1
              : 0,
          )
          .map((d, i) => ({
            ...d,
            visible: i < dadosVisible,
          })),
      ]);
      setOrder(1);
    } else if (order === 1) {
      setDados([
        ...dados
          .sort((a, b) =>
            a.tx_usuario < b.tx_usuario
              ? 1
              : b.tx_usuario < a.tx_usuario
              ? -1
              : 0,
          )
          .map((d, i) => ({
            ...d,
            visible: i < dadosVisible,
          })),
      ]);
      setOrder(2);
    } else {
      setDados([
        ...dadosRaw.map((d, i) => ({
          ...d,
          visible: i < dadosVisible,
        })),
      ]);
      setOrder(0);
    }
  }, [dados]);

  return (
    <RelatorioPageContainer>
      <TegraOpaqueHero title="Relatórios" subtitle="" description="" />

      <RelatorioContentWrapper>
        <TegraTabsContainer>
          <Tabs selectedIndex={2} onSelect={index => navigateIndex(index)}>
            <RelatorioTabsWrapper>
              <TabList>
                <Tab onClick={() => navigate('acessos')}>
                  Acesso dos corretores
                </Tab>
                <Tab onClick={() => navigate('materiais')}>
                  Produtos mais acessados
                </Tab>
                <Tab onClick={() => navigate('atividades')}>Atividades</Tab>
              </TabList>
            </RelatorioTabsWrapper>
            <TabPanel />
            <TabPanel />
            <TabPanel>
              <FilterGrid>
                <FilterGridItem className="w-auto">
                  <input
                    type="text"
                    style={{ minWidth: 372 }}
                    value={q}
                    onChange={({ target }) => setQ(target.value)}
                    placeholder="Buscar por nome, e-mail, CPF, CNPJ ou CRECI"
                  />
                  <select
                    id="empreendimentos"
                    value={empreendimento}
                    className="margin"
                    style={{ minWidth: 300 }}
                    onChange={({ target }) =>
                      setEmpreendimento(Number(target.value))
                    }
                  >
                    <option value={0}>-- empreendimentos --</option>
                    {empreendimentos.map(e => (
                      <option value={e.id}>{e.nome}</option>
                    ))}
                  </select>
                  <select
                    id="acoes"
                    value={acao}
                    className="margin"
                    style={{ maxWidth: 150 }}
                    onChange={({ target }) => setAcao(Number(target.value))}
                  >
                    <option value={-1}>-- ações --</option>
                    <option value="0">Acessou</option>
                    <option value="1">Logou</option>
                    <option value="2">Atualizou documentos</option>
                    <option value="3">Visualizou</option>
                    <option value="4">Downloads</option>
                    <option value="5">Compartilhou</option>
                    <option value="6">Atualizou minha página</option>
                  </select>
                  <select
                    id="sessoes"
                    value={sessao}
                    className="margin"
                    style={{ maxWidth: 150 }}
                    onChange={({ target }) => setSessao(Number(target.value))}
                  >
                    <option value={-1}>-- seção --</option>
                    <option value="1">Books</option>
                    <option value="2">Atividades</option>
                    <option value="3">Caldendário de Trabalho</option>
                    <option value="4">Contatos - Gerente</option>
                    <option value="5">Contatos - Tegra Vendas</option>
                    <option value="6">Contatos - Tegra Incorporadora</option>
                    <option value="7">Documentos Parceiro</option>
                    <option value="8">Lista de Email Marketing</option>
                    <option value="9">Email Marketing</option>
                    <option value="10">Lista Produtos</option>
                    <option value="11">Produtos</option>
                    <option value="12">Lista de Espelho e Tabelas</option>
                    <option value="13">Espelho e Tabelas</option>
                    <option value="14">FAQ</option>
                    <option value="15">Lista de Informações de IM</option>
                    <option value="16">Informações de IM</option>
                    <option value="17">Lista de IPTU e Condomínio</option>
                    <option value="18">IPTU e Condomínio</option>
                    <option value="19">Lista de Liderança</option>
                    <option value="20">Liderança</option>
                    <option value="21">Lista de Memorial Descritivo</option>
                    <option value="22">Memorial Descritivo</option>
                    <option value="23">Minha Página</option>
                    <option value="24">Lista de Novidades</option>
                    <option value="25">Novidades</option>
                    <option value="26">Lista de Premiação</option>
                    <option value="27">Premiação</option>
                    <option value="28">Lista de Ranking</option>
                    <option value="29">Ranking de Equipe</option>
                    <option value="30">Ranking de Corretores</option>
                    <option value="31">Ranking de Gerentes</option>
                    <option value="32">Relatório de Acessos</option>
                    <option value="33">Relatório de Atividades</option>
                    <option value="34">Simule Financiamento</option>
                    <option value="35">Tour Vitrual</option>
                    <option value="36">Lista de Apresentações</option>
                    <option value="37">Apresentação</option>
                    <option value="38">Lista de Videos</option>
                    <option value="39">Video</option>
                    <option value="40">Materiais Gerais</option>
                    <option value="41">Lista de Treinamento</option>
                    <option value="42">Treinamento</option>
                    <option value="43">Redes Sociais</option>
                    <option value="44">Peças e Anúncios</option>                    
                    <option value="45">Outras Imagens</option>                    
                    <option value="46">Materiais do Corretor</option>                    
                    <option value="47">Manuais</option>                    
                    <option value="0">Undefined</option>
                  </select>
                </FilterGridItem>

                <FilterGridItem className="w-auto">
                  <TegraButton onClick={onSearch} isAction>
                    BUSCAR
                  </TegraButton>

                  <TegraButton onClick={e => onExport(e)} isAction>
                    EXPORTAR
                  </TegraButton>
                </FilterGridItem>
              </FilterGrid>
              <FilterGrid>
                <span>
                  <select
                    id="groupBy"
                    value={groupBy}
                    className="margin"
                    onChange={({ target }) => setGroupBy(Number(target.value))}
                  >
                    <option value={0}>-- agrupar por --</option>
                    <option value={1}>Empreendimento</option>
                    <option value={2}>Usuário</option>
                    <option value={3}>Tipo de atividade</option>
                    <option value={4}>Seção</option>
                  </select>

                  {(user.isAdmin ||
                    (superintendentes && superintendentes.length > 0)) && (
                    <select
                      id="superintendentes"
                      value={superintendente}
                      style={{ marginLeft: 10 }}
                      className="margin"
                      onChange={({ target }) =>
                        setSuperintendente(Number(target.value))
                      }
                    >
                      <option value={0}>-- superintendentes --</option>
                      {superintendentes.map(e => (
                        <option value={e.id_corretorSigavi}>
                          {e.tx_apelido}
                        </option>
                      ))}
                    </select>
                  )}
                  {(user.isRH || (gerentes && gerentes.length > 0)) && (
                    <select
                      id="gerentes"
                      value={gerente}
                      style={{ marginLeft: 10 }}
                      className="margin"
                      onChange={({ target }) =>
                        setGerente(Number(target.value))
                      }
                    >
                      <option value={0}>-- gerentes --</option>
                      {gerentes.map(e => (
                        <option value={e.id_corretorSigavi}>
                          {e.tx_apelido}
                        </option>
                      ))}
                    </select>
                  )}
                  {(user.isRH || (corretores && corretores.length > 0)) && (
                    <select
                      id="corretores"
                      value={corretor}
                      style={{ marginLeft: 10 }}
                      className="margin"
                      onChange={({ target }) =>
                        setCorretor(Number(target.value))
                      }
                    >
                      <option value={0}>-- corretores --</option>
                      {corretores.map(e => (
                        <option value={e.id_corretorSigavi}>
                          {e.tx_apelido}
                        </option>
                      ))}
                    </select>
                  )}

                  <label>de</label>
                  <input
                    type="date"
                    value={dateFrom}
                    className="margin"
                    onChange={({ target }) => {
                      setDateFrom(target.value);
                      setDateTo('');
                    }}
                  />
                  <label>a</label>
                  <input
                    type="date"
                    min={dateFrom}
                    value={dateTo}
                    className="margin"
                    onChange={({ target }) => setDateTo(target.value)}
                  />
                </span>
              </FilterGrid>

              <TableContainer>
                <table cellSpacing="0">
                  <thead>
                    <tr>
                      {columns &&
                        columns.map(c =>
                          c.header === 'Usuário' ? (
                            <th className="" onClick={orderByName}>
                              {c.header}
                              {order === 0 && <FaSort />}
                              {order === 1 && <FaSortDown />}
                              {order === 2 && <FaSortUp />}
                            </th>
                          ) : (
                            <th>{c.header}</th>
                          ),
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {dados &&
                      dados
                        .filter(d => d.visible)
                        .map(d => (
                          <tr>
                            {columns && columns.map(c => <td>{d[c.key]}</td>)}
                          </tr>
                        ))}
                  </tbody>
                </table>
                {dados && dados.filter(d => !d.visible).length > 0 && (
                  <div className="text-center">
                    <TegraButton isAction onClick={loadMore}>
                      Carregar mais
                    </TegraButton>
                  </div>
                )}
              </TableContainer>
            </TabPanel>
          </Tabs>
        </TegraTabsContainer>
      </RelatorioContentWrapper>
    </RelatorioPageContainer>
  );
};

export default RelatorioAtividades;
