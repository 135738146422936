import React from 'react';
import successIcon from '../../assets/feedback-success.svg';
import TegraButton from '../../components/TegraButton';
import UndecoratedLink from '../../components/UndecoratedLink';
import {
  FeedbackContainer,
  FeedbackIcon,
  FeedbackTitle,
  FeedbackDescription,
  FeedbackWrapper,
} from './styles';

const MyDocumentsFeedback: React.FC = () => {
  return (
    <FeedbackWrapper>
      <FeedbackContainer>
        <FeedbackIcon src={successIcon} />
        <FeedbackTitle>Solicitação enviada</FeedbackTitle>
        <FeedbackDescription>
          {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio error a
          ex libero exercitationem recusandae itaque mollitia, labore dolore
          veniam id nam eos amet quis quia quae cumque asperiores! Laudantium! */}
        </FeedbackDescription>
        <UndecoratedLink to="/home">
          <TegraButton isAction>IR PARA A HOME</TegraButton>
        </UndecoratedLink>
      </FeedbackContainer>
    </FeedbackWrapper>
  );
};

export default MyDocumentsFeedback;
