import styled from 'styled-components';
import colors from '../../../styles/colors';
import NavItem from '../NavItem';
import dropdownDarkChevron from '../../../assets/arrow-dark-icon.svg';
import externalIconDark from '../../../assets/open-external-dark-icon.svg';
import { DropdownContent, DropdownLabel } from '../NavItem/Dropdown/styles';

export const FeaturedNavItem = styled(NavItem)`
  li {
    border-bottom: none;
    text-align: center;
    padding: 0.75rem;
    color: #60605d;
    font-weight: 500;
    font-size: 12px;
    ${DropdownContent} {
      color: white;
      @media (min-width: 992px) {
        top: 78%;
      }
    }
    ${DropdownLabel} {
      &::before {
        background-image: url(${dropdownDarkChevron});
        right: -0.75rem;
        width: 7px;
        height: 7px;
        background-size: contain;
      }
    }
  }
  &.linkExternal {
    position: relative;
    li{
        font-weight: 600;
    }
  }

  &.linkExternal::after {
    content: '';
    top: 40%;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${externalIconDark});
  }
`;

export const NavFeaturedWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  max-width: 1140px;
  margin-left: -2rem;
`;
export const NavFeaturedContainer = styled.div`
  width: 100%;
  background-color: ${colors.primaryColor};
  display: none;
  justify-content: center;
  @media (min-width: 992px) {
    display: flex;
    z-index: 4;
  }
`;
