import React from 'react';
import { NavHypnoboxContainer } from './styles';
import { UndecoratedAnchor } from '../../UndecoratedLink/styles';

interface NavHypnoboxProps {
  featured?: boolean;
}

const NavHypnobox: React.FC<NavHypnoboxProps> = ({ featured }) => {
  return (
    <NavHypnoboxContainer featured={featured}>
      <UndecoratedAnchor href="//tegravendas.hypnobox.com.br/?controle=Login&acao=index">Hypnobox</UndecoratedAnchor>
    </NavHypnoboxContainer>
  );
};

export default NavHypnobox;
