import React from 'react';
import { UndecoratedLink as Link } from './styles';

interface UndecoratedLinkProps {
  to: string;
}
const UndecoratedLink: React.FC<UndecoratedLinkProps> = ({
  children,
  to,
  ...rest
}) => (
  <Link to={to} {...rest}>
    {children}
  </Link>
);
export default UndecoratedLink;
