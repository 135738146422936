import { parse, differenceInCalendarDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function getDifferenceInDays(data: any): number {
  if (data === '00/00/0000') {
    return 0;
  }
  const days = differenceInCalendarDays(new Date(data), new Date());
  if (isNaN(days)) {
    return 0;
  }
  return days;
}
