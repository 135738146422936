import React, { useState, useEffect, useMemo } from 'react';

import { Container } from './styles';
import TegraScrollBar from '../../../components/TegraScrollBar';
import api from '../../../services/api';
import getFormattedMoney from '../../../utils/money/getFormattedMoney';
import { useLoader } from '../../../hooks/loader';
import { position } from 'polished';

interface TabelaRowData {
  andar: string;
  unidade: string;
  area: string;
  sinal: string;
  complemento: string;
  mensali: string;
  intermediaria: string;
  parcela: string;
  financiamento: string;
  final: string;
  total: string;
}

const mockData: TabelaRowData[] = [
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
  {
    andar: '00',
    unidade: '0000',
    area: '0000,00m2',
    sinal: 'R$000.000.000,00',
    complemento: 'R$000.000.000,00',
    mensali: 'R$000.000.000,00',
    intermediaria: 'R$000.000.000,00',
    parcela: 'R$000.000.000,00',
    financiamento: 'R$000.000.000,00',
    final: 'R$000.000.000,00',
    total: 'R$000.000.000,00',
  },
];

interface IUnidade {
  id: number;
  codigo_empreendimento_sap: string;
  nome_empreendimento: string;
  codigo_bloco_sap: string;
  nome_bloco: string;
  nome_unidade: string;
  pep_unidade: string;
  arquivoImplantacao: string;
  arquivoLogo: string;
  final_nome_unidade: string;
  numero_andar: number;
  numero_prumada: number;
  metros_area_total: number;
  valortotal: number;
  parcelas: {
    pep_unidade: string;
    valor_por_parcela: number;
    qtd: string;
    data: string;
    nome_parcela: string;
    periodicidade: string;
  }[];
}

interface ITabela {
  codigo_bloco_sap: 'string';
  andares: {
    numero_andar: number;
    unidades: IUnidade[];
  }[];
}

interface TegraProdutoTabelaProps {
  idEmpreendimento: string;
  idBloco: string;
  codigo: string;
}

interface IResponse {
  tabela: ITabela;
}

interface IRow {
  andar: {
    numero_andar: number;
  };
  unidade: IUnidade;
}

const TegraProdutoTabela: React.FC<TegraProdutoTabelaProps> = ({
  idEmpreendimento,
  idBloco,
  codigo,
}) => {
  const [data, setData] = useState<TabelaRowData[]>(mockData);
  const [tabelaRows, setTabelaRows] = useState<IRow[]>([]);
  const [parcelas, setParcelas] = useState<any[]>([]);

  const { setLoaderActive } = useLoader();

  const [tabela, setTabela] = useState<ITabela>({
    ...({} as ITabela),
    andares: [],
  } as ITabela);

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        setLoaderActive(true);
        const response = await api.get(
          `EspelhosTabelas/tabela/${idEmpreendimento}/${codigo}/${idBloco}`,
        );

        const {
          tabela: tabelaData,
          parcelas_tabela: parcelasArr,
        } = response.data;
        if (tabelaData && tabelaData.length > 0) setTabela(tabelaData[0]);
        else setTabela({} as ITabela);
        setParcelas(parcelasArr);
      } finally {
        setLoaderActive(false);
      }
    }
    loadData();
  }, [idEmpreendimento, idBloco, codigo]);

  useEffect(() => {
    const rows: IRow[] = [];
    if (tabela.andares) {
      tabela.andares.forEach(andar => {
        if (andar.unidades) {
          andar.unidades.forEach(unidade => {
            rows.push({
              andar,
              unidade,
            });
          });
        }
      });
    }
    setTabelaRows(rows);
  }, [tabela]);

  return (
    <Container>
      {parcelas && parcelas.length == 0 && (
        <div>Tabela de vendas indisponível</div>
      )}
      {parcelas && parcelas.length > 0 && (
        <TegraScrollBar>
          <table>
            <thead style={{position:'sticky', top: 0}}> 
              <tr>
                <th>Andar</th>
                <th>Unidade</th>
                <th>Área</th>
                {parcelas &&
                  parcelas.map(p => <th>{p.parcelaApresentacao}</th>)}
                <th>Valor Total</th>
              </tr>
            </thead>
            <tbody>
              {tabelaRows.map(row => (
                <tr>
                  <td>{row.andar.numero_andar}</td>
                  <td>{row.unidade.nome_unidade}</td>
                  <td>{row.unidade.metros_area_total}m2</td>
                  {parcelas &&
                    parcelas.map(p => (
                      <td>
                        {getFormattedMoney(
                          row.unidade.parcelas.find(
                            pp => pp.nome_parcela == p.parcela,
                          )?.valor_por_parcela || 0,
                        )}
                      </td>
                    ))}
                  <td>{getFormattedMoney(row.unidade.valortotal)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TegraScrollBar>
      )}
    </Container>
  );
};

export default TegraProdutoTabela;
