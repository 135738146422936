import React from 'react';
import ProductMaterials from '../..';

const Simulations: React.FC = () => {
  return (
    <ProductMaterials
      title="Simule Financiamento"
      subtitle=""
      description="Crie simulações para nossos empreendimentos e informe seus clientes sobre como será o pagamento financiado."
      showColumnTitle={false}
      actions={['simulate']}
      apiUrl="SimularFinanceiro/All"
      columns={[{ label: 'Label', field: 'empreendimento' }]}
      anchorName="Simule"
      onClickItem={() => localStorage.setItem('SCROLL', '#Simule')}
    />
  );
};

export default Simulations;
