import React from 'react';
import ProductMaterials from '../..';

const TreinamentoDiversos: React.FC = () => {
  return (
    <ProductMaterials
      title="Treinamentos"
      subtitle="Diversos"
      description="Treinamentos Diversos."
      showColumnTitle={false}
      actions={['open','download']}
      apiUrl="MateriaisGerais"
      columns={[
        { label: 'Arquivo', field: 'nome' },
        { label: 'Tipo', field: 'extensao' },
      ]}
      anchorName="TreinamentoDiversos"
      onClickItem={() => localStorage.setItem('SCROLL', '#Diversos')}
    />
  );
};

export default TreinamentoDiversos;
