import styled from 'styled-components';
import { Container as GridContainer } from 'react-grid-system';
import TegraSelect from '../../../../components/TegraSelect';

export const ErrorsContainer = styled.div`
  margin-top: 20px;
  background: #d84128;
  padding: 15px;
  .info-error {color: #fff;}
  p {
    color: #fff;

    & + p {
      margin-top: 5px;
    }
  }
`;

export const LineDivTitleErrors = styled.div`
  height: 20px;
  left: 0;
  border-top: 1px solid #fff;
  width: 100%;
  transform: translateY(40%);
`;

export const Wrapper = styled.div`
  div.divider {
    margin: 40.5px 0 24.5px;
  }

  & > div.divider {
    margin: 23.5px 0 20px;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
  }
`;
export const DontKnowCEPContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 10px;
  a {
    font-size: 12px;
  }
`;

export const CustomSelect = styled(TegraSelect)`
  width: 400px;
  font-size: 13px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const WizardNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      margin-top: 12px;
    }
    /* justify-content: flex-start; */
  }

  a {
    display: block;
    font-size: 12px;
    text-decoration: none;
  }
`;

export const WizardNavigationGoBack = styled.div`
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
  }
`;

export const Subtitle = styled.h2`
  height: 24px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
  margin-bottom: 5px;
`;

export const ExtraActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 33px;

  a {
    display: block;
    text-decoration: none;

    &.jaPossuoCadastro {
      text-decoration: underline;
    }
  }

  p,
  a,
  span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
    cursor: pointer;
  }
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #adadab;

  span {
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
  }
`;
