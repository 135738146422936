import styled from "styled-components";
import colors from "../../../styles/colors";
import chevron from '../../../assets/tegra-arrow-down-icon.svg'

export const Container = styled.div`
    display: flex;
    background-color: #ebb92d;
    align-items: center;
    justify-content: center;
    margin: 30px auto;

    div {
        font-size: 16px;
        text-align: center;
        padding: 20px 0;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-weight: 500;

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #737371;
            margin: 0px 10px 0px 20px;
        }
    }
`

export const BackIcon = styled.div`
    background-image: url(${chevron});
    transform: rotate(90deg);
    height: 10px;
    width: 20px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right:2rem;
    margin-left: 1.25rem;
`;