import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  background-color: #adadab;
  height: 1286px;

  @media (max-width: 600px) {
    height: 214px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: auto;

  .document {
    position: relative;
    max-width: 100%;

    @media (max-width: 425px) {
      overflow-x: hidden;
      height: 100%;

      .react-pdf__Page {
        height: 100%;
      }

      .react-pdf__Page__textContent {
        width: auto;
        height: auto;
      }
    }

    .pagesNav {
      width: 100%;
      display: flex;
      justify-content: space-between;

      position: absolute;
      left: -20px;
      top: 50%;
      bottom: 50%;

      @media (max-width: 600px) {
        left: 0;
      }
    }

    @media (max-width: 747px) {
      .react-pdf__Page__svg {
        transform: scale(0.8);
      }

      .react-pdf__Page__svg {
        width: auto !important;
        height: auto !important;
        overflow: scroll;

        svg {
          max-width: 400px;
          width: 90vw;
          height: auto;
          display: block;
        }
      }
    }

    @media (max-width: 747px) {
      .react-pdf__Page__svg {
        width: auto !important;
        height: 90vh !important;
        overflow: scroll;
      }
    }
  }
`;

export const FileNavLeft = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e8b829;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.disabled &&
    css`
      background-color: #e6e6e6;
    `}

  img {
    transform: scale(0.5);
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

export const FileNavRight = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e8b829;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.disabled &&
    css`
      background-color: #e6e6e6;
    `}

  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);

  margin-right: -40px;

  img {
    transform: scale(0.5);
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;
