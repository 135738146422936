import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Banner,
  Block,
  Container,
  Item,
  Logo,
  SignatureSioux,
} from './style';

import vetor from '../../assets/canal_parceiros/vector.png';
import iconeVantagen1 from '../../assets/canal_parceiros/rocket.png'
import iconeVantagen2 from '../../assets/canal_parceiros/piggy-bank-coins.png'
import iconeVantagen3 from '../../assets/canal_parceiros/trophy.png'
import iconeVantagen4 from '../../assets/canal_parceiros/handshake.png'
import iconeVantagen5 from '../../assets/canal_parceiros/house-heart.png'
import imgVantagens from '../../assets/canal_parceiros/mask-group-12.png';
import iconeAtuacao from '../../assets/canal_parceiros/atuacao.png';
import iconeCertificado from '../../assets/canal_parceiros/certificado.png';
import iconeColaboradores from '../../assets/canal_parceiros/colaboradores.png';
import iconePremio from '../../assets/canal_parceiros/premio.png';
import iconeSelo from '../../assets/canal_parceiros/selo.svg';

import imgPortal from '../../assets/canal_parceiros/mask-group-8.png';

//import logoTegra from '../../assets/tegra-incorporadora-logo.png';
import parceriasLogo from '../../assets/tegra-parcerias.png';
import { margin } from 'polished';

const CanalParceiros: React.FC = () => {
  const [linkParceiro, setLinkParceiro] = useState('');
  useEffect(() => {
    var link =   window.location.hostname.toLowerCase().indexOf('portaldeparcerias') === 0 ||
    window.location.hostname.toLowerCase().indexOf('qaportaldeparcerias') === 0;

    var qa =  window.location.hostname.toLowerCase().indexOf('qa') === 0 ? 'qa' : '';
    if (link)
      setLinkParceiro(`https://${qa}portaldeparcerias.tegravendas.com.br/login/`)
    else
      setLinkParceiro(`https://${qa}portaldocorretor.tegravendas.com.br/`);


    const url = window.location.href;
    if (qa === '')      
    {
      document.getElementById('robots')?.setAttribute("content", "index");
      document.getElementById('seoHome')?.setAttribute("rel", "canonical");
      document.getElementById('seoHome')?.setAttribute("href", url);
    }
  }, []);

  return(
    <Container>
    <Banner>
      <Logo style={{width:200}}>
        <img src={parceriasLogo} alt='Logo-Parceiros' style={{width:'100%'}}/>
      </Logo>
      <h2>
        Cadastro de Parceiros
      </h2>
    </Banner>

    <a href={linkParceiro}>
      <button className='btn-parceiro'>Já sou parceiro ou corretor</button>
    </a>

    <Block>
      <h1>Tegra Incorporadora</h1>
      <div className='quem-somos'>
        Somos uma das maiores empresas de desenvolvimento urbano, imobiliário residencial e comercial de médio e alto padrão do Brasil, com forte atuação nas cidades de São Paulo, Campinas, Rio de Janeiro e Niterói.
      </div>
      <div className='quem-somos'>
        {/*Integramos a Brookfield Asset Management, listada nas bolsas de Nova Iorque e Toronto.*/}
        Nossa controladora, a gestora de ativos canadense Brookfield, possui mais de U$ 925 bilhões em ativos sob gestão e atua nos setores de Real Estate, Infraestrutura, Energia Renovável & Transição, Private Equity, Crédito e Soluções em Seguros em mais de 30 países.
      </div>
      <div className='quem-somos'>
        {/*Para nós, cada empreendimento tem um motivo especial para existir. Por isso, desenvolvemos construções customizadas, entregues no prazo e com custo-benefício adequado aos nossos clientes.*/}
        Temos um propósito claro: construir empreendimentos com alma, para que as pessoas possam viver melhor. Nossos projetos têm história, inspiram e atraem as pessoas ao redor. São bem pensados e construídos com paixão, comprometimento, entrega e energia.
      </div>

      <Link to="/signup">
        <button className='btn parceiro-tegra'>venha ser parceiro da Tegra</button>
      </Link>

      <div className='lake'>
          <h4>The Lake</h4>
          <p>Alphaville - São Paulo</p>
        </div>
    </Block>

    <Block>
      <div className='containerConquista'>
        <div className='flex relative conquistas2 margin-espaco'>
          <div className='barra'>
            <img className='icone' src={iconeAtuacao} alt='Atuação' />
            <p>Forte atuação nos estados de SP e RJ</p>
          </div>
          <div className='barra'>
            <img className='icone' src={iconeSelo} alt='Signatários do Pacto Global do ONU' />
            <p>Signatários do Pacto Global da ONU</p>
          </div>
        </div>

        <div className='flex relative conquistas2 margin-rodape'>
          <div className='barra'>
            <img className='icone' src={iconeCertificado} alt='Certificados Empreendedor AQUA' />
            <p>Certificados Empreendedor AQUA</p>
          </div>
          <div className='barra'>
            <img className='icone' src={iconeColaboradores} alt='+ de 1.000 colaboradores' />
            <p>+ de 1.000 <br/>colaboradores</p>
          </div>
          <div className='barra'>
            <img className='icone' src={iconePremio} alt='84 vezes premiada' />
            <p>84 vezes <br/>premiada</p>
          </div>        
        </div>
      </div>
    </Block>
{/*    
    <Block>
      <div className='flex relative conquistas'>
        <Item>
          <h3><span></span>45</h3>
          <p>anos de atuação no mercado imobiliário brasileiro</p>
        </Item>
        <div className='barra'></div>
        <Item>
          <h3><span>+ de</span>106</h3>
          <p>mil imóveis desenvolvidos</p>
        </Item>
        <div className='barra'></div>
        <Item>
          <h3><span>+ de</span>26</h3>
          <p>milhões de m² construídos e em construção</p>
        </Item>
        <div className='barra'></div>
        <Item>
          <h3><span>+ de</span>97</h3>
          <p>mil clientes</p>
        </Item>
        <div className='barra'></div>
        <Item>
          <h3><span></span>77</h3>
          <p>vezes premiada</p>
        </Item>
      </div>
    </Block>
*/}
    <Block>
      <div className='vantagens'>
        <h3>Vantagens em ser um Parceiro Tegra</h3>

        <div className='box'>
          <img className='icone' src={iconeVantagen1} alt='Foguete' />

          <div className='linha'>
            <h4>Comissão competitiva de até 4%*</h4>
            <div className='conteudo'>Uma das melhores taxas do mercado</div>
          </div>
        </div>

        <div className='box'>
        <img className='icone' src={iconeVantagen2} alt='Pig Bank' />

          <div className='linha'>
            <h4>Pagamento rápido</h4>
            <div className='conteudo'>Nosso fluxo é facilitado e um dos mais ágeis do mercado</div>
          </div>
        </div>

        <div className='box'>
        <img className='icone' src={iconeVantagen3} alt='Trofeu' />

          <div className='linha'>
            <h4>Premiações</h4>
            <div className='conteudo'>Participação em campanhas de incentivo</div>
          </div>
        </div>

        <div className='box'>
        <img className='icone' src={iconeVantagen4} alt='Handshake' />

          <div className='linha'>
            <h4>Respeito e transparência</h4>
            <div className='conteudo'>Prezamos pela segurança e garantia do trabalho do corretor Parceiro</div>
          </div>
        </div>

        <div className='box'>
        <img className='icone' src={iconeVantagen5} alt='Casa' />

          <div className='linha'>
            <h4>Imóveis com alma e personalidade</h4>
            <div className='conteudo'>Produtos com alto padrão nos acabamentos, para atender um público exigente</div>
          </div>
        </div>

        <div className='box-image'>
          <img src={imgVantagens} alt='Vantagens' />
        </div>

        <div className='local'>
          <h4>Volp 40</h4>
          <p>Botafogo - Rio de Janeiro</p>
        </div>

        <div className='barra'></div>
      </div>
    </Block>

    <Block>
      <div className='parceiro'>
        <h3>Veja como é fácil ser um Parceiro Tegra</h3>
        <div className='big-box'>
          <div className='box box-1'>
            <h4>1</h4>
            <div className='text'>Faça o seu cadastro e aguarde contato</div>
          </div>
          <div className='box box-2'>
            <h4>2</h4>
            <div className='text'>Após aprovação, você receberá acesso ao Portal de Parcerias e contará com o apoio dos gerentes especialistas nos produtos</div>
          </div>
          <div className='box box-3'>
            <h4>3</h4>
            <div className='text'>O Portal de Parcerias vai garantir autonomia para baixar materiais e informações dos produtos, a hora que quiser</div>
          </div>
          <div className='box box-4'>
            <h4>4</h4>
            <div className='text'>Está atendendo um cliente e quer fazer uma proposta? Você terá pronta resposta em até 40 minutos**</div>
          </div>
          <div className='box box-5'>
            <h4>5</h4>
            <div className='text'>Efetuou a venda? A Tegra realiza o pagamento com prazo ágil e efetivo***</div>
          </div>
        </div>

        <div className='full'>
          <Link to="/signup" className='center'>
            <button className='btn'>Cadastre-se</button>
          </Link>
        </div>
      </div>
    </Block>

    <Block>
      <div className='portal'>
        <h3>Saiba mais sobre o Portal de Parcerias</h3>

        <div className='video'>
          <iframe
            width="713"
            height="401"
            src={`https://www.youtube.com/embed/z17KD1z4JLY`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>

        <div className='relative'>
          <h4>Diferenciais do <span>Portal de Parcerias</span></h4>

          <div className='imagem'>
            <img src={imgPortal} alt='Portal' />
          </div>

          <div className='box'>
            <div className='item'>
              <img className='vetor' src={vetor} alt='Vetor' />
              <div className='text'>Fácil acesso a informações sobre os produtos</div>
            </div>

            <div className='item'>
              <img className='vetor' src={vetor} alt='Vetor' />
              <div className='text'>Materiais exclusivos para e-mail e redes sociais</div>
            </div>

            <div className='item'>
              <img className='vetor' src={vetor} alt='Vetor' />
              <div className='text'>Tabelas e espelhos de venda atualizados em tempo real</div>
            </div>

            <div className='item'>
              <img className='vetor' src={vetor} alt='Vetor' />
              <div className='text'>Treinamentos e apresentações</div>
            </div>

            <div className='item'>
              <img className='vetor' src={vetor} alt='Vetor' />
              <div className='text'>Book, imagens em alta qualidade e vídeos para baixar a qualquer momento</div>
            </div>
          </div>
        </div>

        <h4>E muito mais novidades!</h4>

        <div className='container-btn'>
          <Link to="signup">
            <button className='btn'>Venha ser parceiro da Tegra</button>
          </Link>
        </div>
        <div className='footer'>
          <div style={{textAlign:'justify'}}>*Taxa variável, relacionada ao produto em questão e referente a Parceiros Tegra Pessoa Jurídica.</div>
          <div style={{textAlign:'justify'}}>**Retorno garantido em até 40 minutos para corretores que já são Parceiros Tegra, mediante documentação completa, composta por: Pessoa Física - Ficha cadastral Tegra com preenchimento completo, cópia dos documentos pessoais (RG, CPF, CNH), cópia do comprovante de residência atualizado de todos os compradores, cópia dos comprovantes de renda (holerites ou extratos bancários dos últimos três meses); Pessoa Jurídica - Ficha cadastral Tegra com preenchimento completo com os dados do representante, cópia do cartão do CNPJ, cópia dos documentos pessoais do representante (RG, CPF, CNH), cópia do Contrato Social, cópia do último balanço da empresa ou do Imposto de Renda, cópia do comprovante de endereço atualizado da empresa.</div>
          <div style={{textAlign:'justify'}}>***De acordo com fluxo de pagamento do cliente.</div>
        </div>
        <SignatureSioux>
              <span>Desenvolvido por <a href="https://sioux.ag" target="_blank" title="Desenvolvido por Sioux digital 1:1">Agência de marketing digital</a></span>
        </SignatureSioux>
      </div>
    </Block>
  </Container>
  );
};

export default CanalParceiros;
