import styled, { css } from 'styled-components';
import { grayscale } from 'polished';

interface IContainerProps {
  isAction: boolean;
  isExtra: boolean;
}

export const Container = styled.button<IContainerProps>`
  height: 33px;
  padding: 8px;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ebb92d;

  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #ebb92d;
  background-color: transparent;
  color: #ebb92d;

  ${props =>
    props.disabled &&
    css`
      color: #e6e6e6;
      border-color: #e6e6e6;
    `}

  img {
    margin-left: 8px;
  }
`;
