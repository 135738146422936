import styled from "styled-components";
import colors from "../../../styles/colors";
import searchIcon from "../../../assets/tegra-icon-search.svg";

export const ContactsManagementFilterContainer = styled.div`
    max-width: 600px;
    margin-top: 2rem;
`;

export const ContactsManagementInput = styled.input`
    border: 1px solid ${colors.inputBorder};
    width: 100%;
    padding: 0.75rem 1rem;
`;

export const ContactsManagementInputWrapper = styled.div`
    position: relative;
    &::after{
        pointer-events: none;
        content: "";
        background-image: url(${searchIcon});
        position: absolute;
        display: block;
        height: 80%;
        width: 40px;
        right: 0;
        background-position: center center;
        background-repeat: no-repeat;
        top: 10%;
    }
`

export const ContactsManagementFilterTitle = styled.h1`
    margin-bottom: 0.625rem;
    font-size: 1.5rem;
    color: ${colors.featuredBlack};
`;