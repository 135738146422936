import React from 'react';
import {
  NavFeaturedWrapper,
  NavFeaturedContainer,
  FeaturedNavItem,
} from './styles';
import { MenuData } from '../NavMain/NavMenu';
import NavHypnobox from '../NavHypnobox';
import { useAuth } from '../../../hooks/auth';

interface NavFeaturedProps {
  menuData: MenuData[];
}

const NavFeatured: React.FC<NavFeaturedProps> = ({ menuData }) => {
  const { user } = useAuth();
  return (
    <NavFeaturedContainer>
      <NavFeaturedWrapper>
        {menuData
          .filter(item => item.featured)
          .map(item => (
            <FeaturedNavItem key={`${item.link}-featured`} menuItem={item} className={item.external ? 'linkExternal' : ''} />
          ))}        
        {user.acessos.hypnobox && <NavHypnobox featured />}
      </NavFeaturedWrapper>
    </NavFeaturedContainer>
  );
};

export default NavFeatured;
