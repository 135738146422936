import React from 'react';

import { Container } from './styles';

interface ITegraDividerProps {
  color: string;
  axis: 'x' | 'y';
  height?: number;
  width?: number;
  marginLeft?: number;
  marginRight?: number;
}

const TegraDivider: React.FC<ITegraDividerProps> = ({
  width,
  color,
  axis,
  marginLeft,
  marginRight,
  height,
  ...rest
}) => {
  return (
    <Container
      className="divider"
      {...rest}
      width={width}
      height={height}
      marginLeft={marginLeft}
      marginRight={marginRight}
      color={color}
      axis={axis}
    />
  );
};

export default TegraDivider;
