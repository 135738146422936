import styled from "styled-components";
import colors from "../../styles/colors";
import { TegraRoundImageContainer } from '../TegraRoundImage/styles';

export const TegraPaginaCorretorContainer = styled.div`
    transform: skew(0deg, 1deg);
    display: flex;
    align-items: center;
    background-color: ${colors.greyBackground};
    flex-direction: column;    
` 
export const TegraCorretorOuterWrapper = styled.div`
    transform: skew(0deg, -1deg);
    padding-bottom: 1rem;
    background-color: ${colors.greyBackground};
`

export const TegraCorretorWrapper = styled.div`

    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 1.5rem 2.5rem 1rem;
    
    max-width: 1140px;
    width: 100%;
    justify-content: flex-start;
    @media(min-width: 992px) {
        padding: 1.5rem 2.5rem 2.5rem;
        flex-direction: row;
        & ${TegraRoundImageContainer}{
            margin-left: 30px;
        }
    }
`

export const TegraCorretorName = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 .3rem;
    width: 100%;
    text-align: center;
    @media(min-width: 992px) {
        margin-top: 0;
        text-align: left;
        white-space: nowrap;
    }
`;

export const TegraCorretorDescription = styled.div`
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.4rem;
    @media(min-width: 992px) {
        margin-left: 2.5rem;
        margin-top: 0;
    }
`;

export const CorretorDescriptionRow = styled.div`
    width: 100%;
    text-align: center;
    @media(min-width: 992px) {
        text-align: left;
    }
`

export const TegraCorretorDivider = styled.div`
    width: 70px;
    border-top: 5px solid ${colors.primaryColor};
    margin-top: 1rem;
    @media(min-width: 992px) {
        margin: 0 3.75rem 0 2.5rem;
        width: 0;
        height: 70px;
        border-top: none;
        border-left: 5px solid ${colors.primaryColor};
    }
`;
interface TegraCorretorIntroductionProps {
    details?: boolean
}
export const TegraCorretorIntroduction = styled.div<TegraCorretorIntroductionProps>`
    font-size: 14px;
    margin-top: 1rem;
    text-align: center;
    margin-bottom: ${props => props.details ? '1rem' : '0' };
    @media(min-width: 992px) {
        text-align: left;
        margin-top: 0rem;
        margin-bottom: 0;
    }
`;

export const CorretorBackContainer = styled.div`
    display: flex;
    width: 100%;    
    max-width: 1140px;
    margin-bottom: -2rem;
    @media (min-width: 991px){
        padding: 0 2.5rem;
    }
`;

export const CorretorBackIcon = styled.img`
    transform: rotate(90deg);
    margin-right: 1rem;
    height: 6.2px;
    margin-left: 0;
`;