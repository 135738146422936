import styled from 'styled-components';
import zIndex from '../../utils/zIndex';

interface ILoadingProps {
  active: boolean;
}

export const LoadingContainer = styled.div<ILoadingProps>`
  display: ${({ active }) => (active ? 'flex' : 'none  ')};
  background-color: white;
  height: 100vh;
  width: 100vw;

  max-height: 100vh;
  position: fixed;
  z-index: ${zIndex.loading};
  justify-content: center;
  align-items: center;

  &.LoadParcial{
    position: relative;
    width: 100%;
  }

  /* Temporização de animação da imagem cinza */
  #Layer_0 *,
  #Layer_1 *,
  #Layer_2 * {
    animation-timing-function: cubic-bezier(0.01, 0.35, 1, 0.63);
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }

  #Layer_0,
  #Layer_1,
  #Layer_2 {
    width: 100%;
    height: 100%;
  }

  /* Posicionamento da imagem amarela */
  #Layer_0 + #Layer_0,
  #Layer_1 + #Layer_1,
  #Layer_2 + #Layer_2 {
    position: absolute;
    right: 0;
  }

  /* Temporização de animação da imagem amarela */
  #Layer_0 + #Layer_0 > *,
  #Layer_1 + #Layer_1 > *,
  #Layer_2 + #Layer_2 > * {
    animation-timing-function: cubic-bezier(0.7, 0, 0, 1);
  }

  /* Animações imagem 1 */
  #Layer_0 polyline:first-of-type {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation-name: dash0poly0;
  }

  @keyframes dash0poly0 {
    0% {
      stroke-dashoffset: 300;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 300;
    }
  }

  #Layer_0 polyline:nth-of-type(2) {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation-name: dash0poly1;
  }
  @keyframes dash0poly1 {
    0% {
      stroke-dashoffset: 150;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 150;
    }
  }

  #Layer_0 rect {
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
    animation-name: dash0Rect;
  }
  @keyframes dash0Rect {
    0% {
      stroke-dashoffset: 40;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 40;
    }
  }

  /* Animações imagem 2 */

  #Layer_1 polyline {
    stroke-dasharray: 430;
    stroke-dashoffset: 430;
    animation-name: dash1Poly;
  }
  @keyframes dash1Poly {
    0% {
      stroke-dashoffset: 430;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 430;
    }
  }

  #Layer_1 line {
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
    animation-name: dash1Line;
  }
  @keyframes dash1Line {
    0% {
      stroke-dashoffset: 40;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 40;
    }
  }

  /* Animações imagem 3 */

  #Layer_2 polyline {
    stroke-dasharray: 430;
    stroke-dashoffset: 430;
    animation-name: dash2Poly;
  }
  @keyframes dash2Poly {
    0% {
      stroke-dashoffset: 430;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 430;
    }
  }

  #Layer_2 line {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation-name: dash2Line;
  }
  @keyframes dash2Line {
    0% {
      stroke-dashoffset: 150;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 150;
    }
  }
`;

export const LoadingWrapper = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
`;
