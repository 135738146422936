import styled from 'styled-components';

export const Container = styled.div`
margin-top: 10px;
margin-bottom: 30px;
display: flex;
flex-direction: column;
width: 100%;
align-items: center;

`;

export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: row;
  width: 1200px;
  background-color: #E6E5E5;
  min-height: 80px;
  //padding-top: 10px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;


  @media (max-width: 1200px) {
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    z-index: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    z-index: 0;
  }

`;

export const SeparadorOptionBusca = styled.div`
  width: 270px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 15px;

  @media (max-width: 1200px) {
    width: 66.7%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

`;

export const SeparadorOptionBusca2 = styled.div`
  width: 70%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 15px;

  @media (max-width: 600px) {
    width: 100%;
  }

`;

export const SeparadorOption = styled.div`
  width: 215px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 15px;

  @media (max-width: 1200px) {
    width: 33.3%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

`;


export const SeparadorOptionPreco = styled.div`
  width: 205px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 15px;

  @media (max-width: 1200px) {
    width: 33.3%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

`;

export const SeparadorOptionPreco2 = styled.div`
  width: 30%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 15px;

  @media (max-width: 600px) {
    width: 100%;
  }

`;

export const SeparadorOptionSearch = styled.div`  
  //padding-right: 10px;
  padding-left: 10px;
  //padding-bottom: 15px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ContainerButton = styled.div`
position: relative;
display: inline-block;
width: 100%;
border-bottom: 1px solid rgb(95, 96, 93);
`;

export const ButtonDrop = styled.button`
    padding: 0;
    width: 100%;
    border: 0;
    color: #333;
    cursor: pointer;
    outline: 0;    
    min-height: 38px;
    background: #E6E5E5;
    border-color: hsl(0,0%,80%);    
    display: flex;
    align-items: center;

    .textoButton{
      color: #60605D;
      font-size: 14px;
      width: 90%;
      text-align: initial;
      padding: 2px 10px;
    }

    .buttonDropContainer{
      //width: 10%;
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 0;    
      justify-content: space-between
    }

    .buttonDropOpen{      
      color: hsl(0,0%,80%);
      //display: flex;
      padding: 14px;
      box-sizing: border-box;
      background: linear-gradient(315deg,#60605d 0%,#60605d 20%,#E6E5E5 10%,#E6E5E5 100%);
      //position: relative;
      //right: 22px;

      @media (max-width: 600px) {
        right: 0px;
      }

    }

    .buttonDrop{      
      color: hsl(0,0%,80%);
      //display: flex;
      padding: 14px;
      box-sizing: border-box;
      background: linear-gradient(315deg,#FDBE39 0%,#FDBE39 20%,#E6E5E5 10%,#E6E5E5 100%);
      //position: relative;
      //right: 22px;

      @media (max-width: 600px) {
        right: 0px;
      }
    }

    
  
`;

export const DropDownPreco = styled.div`
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    top: 46px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    //border: 1px solid rgba(0, 0, 0, 0.04);
    //box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: #fff;
    display: flex;
    align-content: center;
    height: 200px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      width: 80%;
    }

    input{
      padding: 10px;
      margin-top: 11px;
      height: 40px;
      border: solid 1px #adadab;
      background-color: #ffffff;
    }  

`;

export const InputFiltro = styled.input`
  border: none;
  padding: 10px;
  //margin-top: 11px;
  height: 40px;
  //border: solid 1px #adadab;
  background-color: #E6E5E5 !important;
  -webkit-box-shadow: 0 0 0 30px #E6E5E5 inset;
  border-bottom: 1px solid rgb(95, 96, 93);
  width: 100%;
  position: relative;
  top: -1px;

  :focus { background-color: #E6E5E5 !important; }
  :hover { background-color: #E6E5E5 !important; }


  ::placeholder {
    color: #60605D;
  }

  

`;


export const ButtonSerch = styled.button`
    padding: 0;
    width: 100%;
    border: 0;
    color: #333;
    cursor: pointer;
    outline: 0;    
    min-height: 38px;
    background: #ECB92C;
    border-color: hsl(0,0%,80%);    
    display: flex;
    align-items: center;
    width: 48px;
    height: 48px;
    justify-content: center;

`;


export const FilterActionsContainer = styled.div`
  width: 100%;
  display: none;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
  }

  .FilterContent{
      padding: 39.7px 36px;      
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      display: flex;
    
      @media (max-width: 1200px) {
        width: 600px;
      }
    
      @media (max-width: 600px) {
        width: 100%;
      }
    
    
      button {
        width: 100%;
        margin: 0px 36px;
      }
  
  }
`;
