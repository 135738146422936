import styled from 'styled-components';
import { Container as GridContainer } from 'react-grid-system';

export const Wrapper = styled.div`
  div.divider {
    margin: 40.5px 0 24.5px;
  }

  & > div.divider {
    margin: 23.5px 0 40px;
  }

  .inputPassword{
    position: relative;
    &__icon{
      position: absolute;
      font-size: 25px;
      right: 20px;
      bottom: 9px;
    }
  }
`;
export const DontKnowCEPContainer = styled.div`
    z-index: 9999;
    position: absolute;    
    top: 15px;
    a {
      font-size: 12px;
    }

  @media (max-width: 767px) {
    right: 20px;
    left: none;
  }
  @media (min-width: 768px) {
    left: 210px;
  }

`;

export const WizardNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      margin-top: 40px;
    }
    /* justify-content: flex-start; */
  }

  a {
    display: block;
    font-size: 12px;
    text-decoration: none;
  }
`;

export const WizardNavigationGoBack = styled.div`
  display: flex;
  align-items: center;
`;

export const LineDivTitleErrors = styled.div`
  height: 20px;
  left: 0;
  border-top: 1px solid #fff;
  width: 100%;
  transform: translateY(40%);
`;


export const ErrorsContainer = styled.div`
  margin-top: 20px;
  background: #d84128;
  padding: 15px;

  p {
    color: #fff;

    & + p {
      margin-top: 5px;
    }
  }
`;

export const Subtitle = styled.h2`
  height: 24px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
  margin-bottom: 5px;
`;

export const ExtraActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 33px;

  a {
    display: block;
    text-decoration: none;

    &.jaPossuoCadastro {
      text-decoration: underline;
    }
  }

  p,
  a {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
  }
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;

  p {
    cursor: pointer;
  }
`;

export const DivTegraRio = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const TegraRedirect = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
  margin-bottom: 15px;
  span{
    color: #60605d;
  }
  a{
    color: #60605d;
  }
`;

export const OrigemContato = styled.p`
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
  margin-bottom: 11px;
  margin-top: 15.5px;
`;

export const DivTextArea = styled.div`
  padding: 10px;
  border: solid 1px #adadab;
  background-color: #ffffff;
  width: 100%;
  height: 100px;

  .textarea{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    height: 100%;
    width: 100%;
    resize: none;
    border: 0 !important;
    outline: 0 !important;
  }

`;

