import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    display: block;
    width: 100%;
    margin-bottom: 11px;
    margin-top: 15.5px;
  }

  & > div {
    padding: 10px;
    height: 40px;
    border: solid 1px #adadab;
    background-color: #ffffff;
    width: 100%;

    &.inputMoney {
      &:before {
        width: 30px;
        content: 'R$ ';
      }
    }
    &.inputPerc {
      &:before {
        width: 30px;
        content: '% ';
      }
    }
  }

  input {
    border: 0 !important;
    outline: 0 !important;
    width: 100%;
    &::placeholder {
      color: #adadab;
      text-transform: capitalize;
    }
  }
`;

export const Icon = styled.img`
  position: absolute;
  bottom: 9px;
  right: 9px;
`;
