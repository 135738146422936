import styled, { css } from 'styled-components';

export const Container = styled.div`
  .banner{
    width: 100%;
  }

  .bannerText{
    background-color: #ebb92d;
    width: 70%;
    margin: 30px auto;
    font-size: 16px;
    text-align: center;
    padding: 20px 0;
    color: #ffffff;
    font-weight: 500;
  }
`;
