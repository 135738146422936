import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { FormHandles, Form, SubmitHandler } from '@unform/core';

import {
  Container,
  Title,
  SearchContainer,
  FormActions,
  DadosList,
  DadosItem,
  DadosItemContent,
  ResultBox,
  InputContainer,
  InfoLabel,
} from './styles';

import TegraButton from '../../../components/TegraButton';
import { useLoader } from '../../../hooks/loader';
import api from '../../../services/api';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import TegraInputWithMask from '../../../components/TegraInputWithMask';
import TegraInput from '../../../components/TegraInput';
import moment from 'moment';

interface IParceiro {
  tx_nome: string;
  tx_cpfcnpj: string;
  gerente: string;
  superintendente: string;
  id_situacao: number;
  tx_situacao: string;
  dt_cadastro: string;
  tx_creci: string;
  tx_cidade:string;
}

const searchFormInitialData = {
  creci: '',
};

interface IEncontrarCorretorFormData {
  creci: string;
}

const BuscaCorretor: React.FC = () => {
  const [parceiro, setParceiro] = useState<IParceiro>();
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [mascara, setMascara] = useState('');
  const [mask, setMask] = useState('');
  const [naoEncontrado, setNaoEncontrado] = useState<boolean>(false);
  const { setLoaderActive } = useLoader();

  useEffect(() => {
    var num = cpfCnpj.replace(/[^a-z0-9]/g, '');
    if (num.length <= 7) {
      //setMascara(' 999999-*9'); // espaço inicial para posicionar o cursor corretamente!
      setMascara('  *******9'); // espaço inicial para posicionar o cursor corretamente!
    } else if (num.length > 11) {
      setMascara('99.999.999/9999-99');
    } else if (num.length > 7) {
      setMascara('999.999.999-999');
    }
  }, [cpfCnpj]);

  const encontrarCorretorFormRef = useRef<FormHandles>(null);

  const handleSearch = useCallback(() => {
    if (!cpfCnpj) {
      return;
    }
    if (cpfCnpj.replace(/_/g, '').replace(/-/g, '').replace(/ /g, '').length <= 3){
      return;
    }
    setLoaderActive(true);
    api.get(`Gerente/GetParceiroCpfCnpj?cpfcnpj=${cpfCnpj.replace(/_/g, '').replace(/-/g, '')}`).then(res => {
      setParceiro(res.data);
      if (!res.data) {
        setNaoEncontrado(true);
      } else {
        setNaoEncontrado(false);
      }

      setLoaderActive(false);
    });
  }, [cpfCnpj]);

  const handleEncontrarCorretorFormSubmit: SubmitHandler<
    IEncontrarCorretorFormData
  > = (data): void => {
    handleSearch();
  };

  return (
    <Container>
      <SearchContainer>
        <Form
          ref={encontrarCorretorFormRef}
          initialData={searchFormInitialData}
          onSubmit={handleSearch}
        >
          <Title>Verificar existênica de parceiro</Title>
          <InfoLabel>
            Preencha o campo abaixo com CPF, CNPJ ou CRECI do parceiro para
            consultar se já existe um cadastro ativo
          </InfoLabel>
          <GridContainer
            style={{
              padding: 0,
              marginLeft: '0px',
              marginRight: '0px',
              maxWidth: '100%',
              marginTop: '15px',
            }}
          >
            <Row>
              <Col sm={12} md={4} lg={4} xl={3}>
                <TegraInputWithMask
                  placeholder={'CPF, CNPJ ou CRECI'}
                  label={''}
                  mask={mascara}
                  name="creci"
                  onChange={e => setCpfCnpj(e.target.value)}
                />
              </Col>
              <Col sm={12} md={2} lg={2} xl={1}>
                <FormActions>
                  <TegraButton isAction onClick={handleSearch}>
                    BUSCAR
                  </TegraButton>
                </FormActions>
              </Col>
            </Row>
          </GridContainer>
        </Form>
      </SearchContainer>
      <ResultBox>
        {parceiro && (
          <DadosList>
            <DadosItem>
              <DadosItemContent>
                <div>
                  <strong>Parceiro existente no banco de dados</strong>
                </div>
              </DadosItemContent>
            </DadosItem>
            <DadosItem>
              <DadosItemContent>
                <p>Data de Cadastro</p>
                <div>
                  <strong>
                    {moment(new Date(parceiro.dt_cadastro)).format(`DD/MM/YYYY`)}
                  </strong>
                </div>
              </DadosItemContent>
            </DadosItem>       
            <DadosItem>
              <DadosItemContent>
                <p>Creci</p>
                <div>
                  <strong>
                    {parceiro.tx_creci}
                  </strong>
                </div>
              </DadosItemContent>
            </DadosItem>      
            <DadosItem>
              <DadosItemContent>
                <p>Cidade do Corretor</p>
                <div>
                  <strong>
                    {parceiro.tx_cidade}
                  </strong>
                </div>
              </DadosItemContent>
            </DadosItem> 
            <DadosItem>
              <DadosItemContent>
                <p>Gerente</p>
                <div>
                  <strong>
                    {parceiro.gerente ? parceiro.gerente : 'Não possui gerente'}
                  </strong>
                </div>
              </DadosItemContent>
            </DadosItem>
            <DadosItem>
              <DadosItemContent>
                <p>Superintendente</p>
                <div>
                  <strong>
                    {parceiro.superintendente
                      ? parceiro.superintendente
                      : 'Não possui superintendente'}
                  </strong>
                </div>
              </DadosItemContent>
            </DadosItem>
          </DadosList>
        )}
        {!parceiro && naoEncontrado && (
          <p>
            Ops! Esse CPF/CNPJ/CRECI não está associado a nenhum parceiro no
            Portal de Parcerias!
          </p>
        )}
      </ResultBox>
    </Container>
  );
};

export default BuscaCorretor;
