import React, { useRef, useCallback, useState, useEffect } from 'react';

import {
  Container as GridContainer,
  Row,
  Col,
  Hidden,
  Visible,
} from 'react-grid-system';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import NumberFormat from 'react-number-format';
import {
  InputContainer,
  Container,
  Title,
  Description,
  FinanciamentoContainer,
  FinanciamentoResultadoContainer,
  FinanciamentoFormContainer,
  FinanciamentoHeader,
  FinanciamentoFormActions,
} from './styles';
import TegraInput from '../../../components/TegraInput';
import TegraDivider from '../../../components/TegraDivider';
import TegraButtonExtra from '../../../components/TegraButtonExtra';
import api from '../../../services/api';
import { useLoader } from '../../../hooks/loader';

interface ISimuladorFormData {
  taxa: number;
  preco: number;
  prazo: number;
  entrada: number;
}
interface ISimuladorInput {
  valor: number;
  prazo: number;
  porcentagemEntrada: number;
  taxaEfetiva: number;
}

interface IFinanciamento {
  valorFinanciamento: string;
  valorParcela: string;
}

const ProdutoSimuladorFinanciamento: React.FC<ISimuladorInput> = ({
  valor,
  prazo,
  porcentagemEntrada,
  taxaEfetiva,
}) => {
  const { setLoaderActive } = useLoader();
  const simuladorFormRef = useRef<FormHandles>(null);
  const [financiamento, setFinanciamento] = useState({} as IFinanciamento);

  const [preco, setPreco] = useState(valor);
  const [entrada, setEntrada] = useState(valor * porcentagemEntrada);
  const [taxa, setTaxa] = useState(taxaEfetiva);
  const [meses, setMeses] = useState(prazo);

  const simular = async (obj: {
    valorImovel: number;
    entrada: number;
    prazo: number;
    taxa: number;
  }): Promise<void> => {
    setLoaderActive(true);
    const { data: financiamentoData } = await api.get<IFinanciamento>(
      'SimularFinanceiro/Simular',
      {
        params: obj,
      },
    );
    setFinanciamento(financiamentoData);
    setLoaderActive(false);
  };

  const handleSimuladorFormSubmit: SubmitHandler<ISimuladorFormData> = async (
    data,
  ): Promise<void> => {
    return simular({
      valorImovel: preco,
      entrada,
      prazo: meses,
      taxa,
    });
  };

  useEffect(() => {
    simular({
      valorImovel: preco,
      entrada,
      prazo,
      taxa: taxaEfetiva,
    });
  }, []);

  return (
    <Container>
      <FinanciamentoHeader>
        <Title>Simule Financiamento</Title>
        <Description>
          * Valores são apenas uma simulação e não têm validade comercial. Para
          realizar uma simulação real, entre em contato com um de nossos
          corretores online.
        </Description>
      </FinanciamentoHeader>
      <FinanciamentoContainer>
        <FinanciamentoFormContainer>
          <Form ref={simuladorFormRef} onSubmit={handleSimuladorFormSubmit}>
            <GridContainer
              className="grid-container"
              style={{
                padding: 0,
                marginLeft: '0px',
                maxWidth: '100%',
                marginRight: '0px',
              }}
            >
              <Row style={{ marginRight: '0px', marginTop: '0px' }}>
                <Col sm={12} md={6} xl={6}>
                  <InputContainer>
                    <label>Preço</label>
                    <NumberFormat
                      name="preco"
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale
                      decimalScale={2}
                      prefix="R$ "
                      defaultValue={preco}
                      onValueChange={values => setPreco(values.floatValue || 0)}
                    />
                  </InputContainer>
                </Col>
                <Col sm={12} md={6} xl={6}>
                  <InputContainer>
                    <label>Entrada</label>
                    <NumberFormat
                      name="entrada"
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale
                      decimalScale={2}
                      prefix="R$ "
                      defaultValue={entrada}
                      onValueChange={values =>
                        setEntrada(values.floatValue || 0)}
                    />
                  </InputContainer>
                </Col>
              </Row>
              <Row style={{ marginRight: '0px', marginTop: '0px' }}>
                <Col sm={12} md={6} xl={6}>
                  <InputContainer>
                    <label>Taxa</label>
                    <NumberFormat
                      name="taxa"
                      fixedDecimalScale
                      decimalSeparator=","
                      decimalScale={2}
                      suffix="%"
                      defaultValue={taxa}
                      onValueChange={values => setTaxa(values.floatValue || 0)}
                    />
                  </InputContainer>
                </Col>
                <Col sm={12} md={6} xl={6}>
                  <InputContainer>
                    <label>Prazo</label>
                    <NumberFormat
                      name="prazo"
                      fixedDecimalScale
                      decimalScale={0}
                      defaultValue={prazo}
                      onValueChange={values => setMeses(values.floatValue || 0)}
                    />
                  </InputContainer>
                </Col>
              </Row>
              <Row style={{ marginRight: '0px', marginTop: '0px' }}>
                <Col sm={12} md={12} xl={12}>
                  <FinanciamentoFormActions>
                    <TegraButtonExtra type="submit" isAction={false}>
                      Simular
                    </TegraButtonExtra>
                  </FinanciamentoFormActions>
                </Col>
              </Row>
            </GridContainer>
          </Form>
        </FinanciamentoFormContainer>
        <FinanciamentoResultadoContainer>
          <span className="title">Resultado</span>
          <TegraDivider axis="x" height={1} color="#fff" />
          <div>
            <span>Financiamento</span>
            <strong>
              {financiamento.valorFinanciamento || 'R$ 000.000.000,00'}
            </strong>
            <span>Valor da parcela</span>
            <strong>{financiamento.valorParcela || 'R$ 000.000.000,00'}</strong>
          </div>
        </FinanciamentoResultadoContainer>
      </FinanciamentoContainer>
    </Container>
  );
};

export default ProdutoSimuladorFinanciamento;
