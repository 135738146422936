import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  min-height: 115vh;

  background: #fff;
`;

export const Body = styled.div`
  width: 100%;
  margin-top: 80px;
  padding-left: calc(calc(calc(100vw - calc(1140px + 3.5rem)) / 2) + 2.25rem);

  h3 {
    margin-bottom: 10px;
    margin-top: 10px;
    color: #ebb92d;
  }

  p {
    margin-top: 10px;
    color: #60605d;
  }

`;

export const Buttons = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  margin-bottom: 30px;

  p {
    margin-top: 10px;
    color: #60605d;
    min-width: 140px;
  }

  img {
    margin-left: 5px;
  }

  .containerButton{
    display: flex;
    flex-wrap: wrap;

    button {
      margin-bottom: 10px;
      width: 130px;
      cursor: pointer !important;
      margin-left: 20px;
      display: flex;
      height: unset;
      padding: 8px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      border: solid 1px #ebb92d;
      border-color: #ebb92d;
      background-color: transparent;
      color: #ebb92d;
  
        &:hover{
          opacity: 0.7;
        }
    }  
  }

`;

export const Button = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;

  img {
    margin-left: 5px;
  }

  p {
    min-width: 140px;
  }

  .containerButton{
    display: flex;
    flex-wrap: wrap;

    button {      
      width: 130px;
      cursor: pointer !important;
      //margin-left: 112px;
      margin-left: 20px;
      display: flex;
      height: unset;
      padding: 8px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      border: solid 1px #ebb92d;
      border-color: #ebb92d;
      background-color: transparent;
      color: #ebb92d;
  
        @media (max-width: 600px) {
          //margin-left: 135px;
        }
  
        &:hover{
          opacity: 0.7;
        }
    }
  
  }
`;

export const Content = styled.div`
  //margin-top: 44px;

  .mobile-hide {
    display: block;
    @media (max-width: 760px) {
      display: none;
    }
  }

  @media (max-width: 600px) {
    padding-left: 33px;
    padding-right: 37px;

    .select {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 600px) {
    padding-left: 33px;
    padding-right: 37px;

    .select {
      margin-bottom: 10px;
    }
  }
`;

export const ContentImg = styled.div`
    widdth: 100%;

    img{
      width: 100%;
      height: 800px;
      object-fit: cover;      
    }
    @media (max-width: 2500px) { 
      img {
        height: 600px;
      }
    }
    @media (max-width: 1700px) { 
      img {
        height: 400px;
      }
    }
    @media (max-width: 1280px) { 
      img {
        height: 330px;
      }
    }
    @media (max-width: 600px) { 
      img {
        height: 170px;
      }
    }
`;

interface MenuItemProps {
  selected?: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  min-height: 60.5px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-left: 53px;
  cursor: pointer;

  & + li {
    border-top: 1px solid #fff;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  ${props =>
    props.selected &&
    css`
      p {
        font-weight: bold;
        color: #ebb92d;
      }

      &:after {
        content: '';
        position: absolute;
        top: 7.2px;
        bottom: 5.2px;
        right: 0;
        width: 0;
        height: 0;
        border-top: 21.6px solid transparent;
        border-bottom: 21.6px solid transparent;
        text-align: center;
        vertical-align: middle;
        border-right: 29.6px solid #ebb92d;
      }
    `}
`;

export const AccordionContent = styled.div`
  @media (min-width: 600px) {
    padding-right: 37px;
  }
`;

export const Menu = styled.ul`
  /* min-height: 548px; */
  width: 100%;
  max-width: 483px;
  background-color: #e6e6e6;
`;
