/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import TegraTabsContainer from '../../components/TegraTabsContainer';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraButton from '../../components/TegraButton';
import RelatorioGoBack from './RelatorioGoBack';

import RelatorioTable from './RelatorioTable';
import {
  RelatorioPageContainer,
  RelatorioContentWrapper,
  FilterSearchRow,
  FilterGrid,
  FilterGridItem,
  FilterGridInput,
  RelatorioTabsWrapper,
} from './styles';
import { LoadMore } from '../Products/styles';

export interface IRelatorio {
  [key: string]: string;
}

export interface ICorretor {
  [key: string]: string;
}


const Relatorio: React.FC = () => {
  const PAGE_SIZE = 9999999999;

  const tabNumber = Object.freeze({
    ACESSOS: 0,
    MATERIAIS: 1,
    ACESSOSDETALHES: 2,
    MATERIAISDETALHES: 3,
    MATERIAISDETALHESUSUARIOS: 4,
  });

  const { tab } : any = useParams();
  const history = useHistory();

  const dataAnterior = new Date();
  dataAnterior.setDate(dataAnterior.getDate() - 30);

  const [dateFrom, setDateFrom] = useState(
    dataAnterior.toISOString().split('T')[0],
  );
  const [dateTo, setDateTo] = useState(new Date().toISOString().split('T')[0]);

  const [q, setQ] = useState('');
  const [qAcessosDetalhes, setQAcessosDetalhes] = useState('');
  const [qMateriais, setQMateriais] = useState('');
  const [type, setType] = useState(0);

  const { setLoaderActive } = useLoader();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [relatorioAcessos, setRelatorioAcessos] = useState<IRelatorio[]>([]);

  const [, setRelatorioAcessosTotal] = useState<IRelatorio[]>([]);

  const [relatorioAcessosDetalhes, setRelatorioAcessosDetalhes] = useState<
    IRelatorio[]
  >([]);

  const [, setRelatorioAcessosDetalhesTotal] = useState<IRelatorio[]>([]);

  const [corretorAcessosDetalhes, setCorretorAcessosDetalhes] = useState<
    ICorretor
  >({} as ICorretor);

  const [relatorioMateriais, setRelatorioMateriais] = useState<IRelatorio[]>(
    [],
  );

  const [, setRelatorioMateriaisTotal] = useState<IRelatorio[]>([]);

  const [relatorioMateriaisDetalhes, setRelatorioMateriaisDetalhes] = useState<
    IRelatorio[]
  >([]);

  const [, setRelatorioMateriaisDetalhesTotal] = useState<IRelatorio[]>([]);

  const [
    empreendimentoMateriaisDetalhes,
    setEmpreendimentoMateriaisDetalhes,
  ] = useState<string>('' as string);

  const [
    relatorioMateriaisDetalhesUsuarios,
    setRelatorioMateriaisDetalhesUsuarios,
  ] = useState<IRelatorio[]>([]);

  const [, setRelatorioMateriaisDetalhesUsuariosTotal] = useState<IRelatorio[]>([]);

  const [paginaAcesso, setPaginaAcesso] = useState<number>(1);

  const [totalPaginaAcesso, setTotalPaginaAcesso] = useState<number>(1);

  const fetchRelatorio = async (): Promise<void> => {
    setLoaderActive(true);
    if (selectedIndex === tabNumber.ACESSOS) {
      setDateFrom(dataAnterior.toISOString().split('T')[0]);
      setDateTo(new Date().toISOString().split('T')[0]);
      const response = await api.get(
        `RelatorioAcesso?inicio=${dateFrom}&fim=${dateTo}`,
      );

      const apiResponse = response.data.items;
      setTotalPaginaAcesso(response.data.paginas);

      try {
        const relatorioAcessoTotalPlaceholder = apiResponse.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              nome:
                relatorioItem.tx_apelido != null
                  ? relatorioItem.tx_apelido
                  : relatorioItem.tx_nome,
              corretor:
                relatorioItem.id_tipoUsuario > 0 
                  ? 'Tegra Vendas' :
                    relatorioItem.tx_cnpj == null || relatorioItem.tx_cnpj === ''                      
                      ? 'Autônomo'
                      :'Imobiliária',
              gerente: relatorioItem.tx_gerente,
              coordenador: relatorioItem.tx_coordenador,
              idTipoUsuario: relatorioItem.id_tipoUsuario,
              idUsuario: relatorioItem.id_usuario,
              ultimoDownload:
                relatorioItem.dt_acesso != null
                  ? relatorioItem.dt_acessoFormatada
                  : '',
              downloads: relatorioItem.nu_downloads,
            };
          },
        );        
        setRelatorioAcessosTotal(relatorioAcessoTotalPlaceholder);
        setRelatorioAcessos(
          relatorioAcessoTotalPlaceholder.slice(0, PAGE_SIZE),
        );
        setPaginaAcesso(1);
      } catch (e) {
        console.error(e);
      }
    } else if (selectedIndex === tabNumber.MATERIAIS) {
      setDateFrom(dataAnterior.toISOString().split('T')[0]);
      setDateTo(new Date().toISOString().split('T')[0]);
      const response = await api.get('RelatorioMateriais');

      const apiResponse = response.data;
      try {
        const relatorioMateriaisTotalPlaceholder = apiResponse.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              empreendimento: relatorioItem.tx_empreendimento,
              empreendimentoId: relatorioItem.id_empreendimento,
              downloads: relatorioItem.total_downloads,
              detalhes: 'VER DETALHES',
            };
          },
        );
        setRelatorioMateriaisTotal(relatorioMateriaisTotalPlaceholder);
        setRelatorioMateriais(
          relatorioMateriaisTotalPlaceholder.slice(0, PAGE_SIZE),
        );
      } catch (e) {
        console.error(e);
      }
    } else if (selectedIndex === tabNumber.ACESSOSDETALHES) {
      const response = await api.get(
        `RelatorioAcesso/:acessosdetalhes${window.location.search}`,
      );
      //Limpa busca para quando voltar
      setType(0);
      console.log('resposta', response);
      try {
        const relatorioAcessosDetalhesTotalPlaceholder = response.data.item1.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              material: relatorioItem.tx_material,
              categoria: relatorioItem.tx_categoria,
              empreendimento: relatorioItem.tx_empreendimento,
              downloads: relatorioItem.total_downloads,
              ultimoDownloadFormatado: relatorioItem.ultimo_acessoFormatado,
            };
          },
        );
        setRelatorioAcessosDetalhesTotal(
          relatorioAcessosDetalhesTotalPlaceholder,
        );
        setRelatorioAcessosDetalhes(
          relatorioAcessosDetalhesTotalPlaceholder.slice(0, PAGE_SIZE),
        );

        const corretorAcessosDetalhesTotalPlaceholder = {
          nome: response.data.item2.tx_nome,
          foto: response.data.item2.tx_foto,
        };        

        setCorretorAcessosDetalhes(corretorAcessosDetalhesTotalPlaceholder);
      } catch (e) {
        console.error(e);
      }
    } else if (selectedIndex === tabNumber.MATERIAISDETALHES) {
      const response = await api.get(
        `RelatorioMateriais/:materiaisdetalhes${window.location.search}`,
      );
      try {
        const relatorioMateriaisDetalhesTotalPlaceholder = response.data.item1.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              material: relatorioItem.tx_material,
              empreendimentoId: relatorioItem.id_empreendimento,
              downloads: relatorioItem.total_downloads,
              detalhes: 'VER DETALHES',
            };
          },
        );
        setRelatorioMateriaisDetalhesTotal(
          relatorioMateriaisDetalhesTotalPlaceholder,
        );
        setRelatorioMateriaisDetalhes(
          relatorioMateriaisDetalhesTotalPlaceholder.slice(0, PAGE_SIZE),
        );

        const empreendimentoMateriaisDetalhesPlaceholder =
          response.data.item2.nome;
        setEmpreendimentoMateriaisDetalhes(
          empreendimentoMateriaisDetalhesPlaceholder,
        );
      } catch (e) {
        console.error(e);
      }
    } else if (selectedIndex === tabNumber.MATERIAISDETALHESUSUARIOS) {
      const response = await api.get(
        `RelatorioMateriais/:materiaisdetalhesusuarios${window.location.search}`,
      );
      const apiResponse = response.data;
      try {
        const relatorioMateriaisDetalhesUsuariosTotalPlaceholder = apiResponse.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              nome: relatorioItem.tx_nome,
              superintendente: relatorioItem.tx_gerente,
              gerente: relatorioItem.tx_coordenador,                            
              downloads: relatorioItem.total_downloads,
              // verMais: 'VER MAIS',
            };
          },
        );
        setRelatorioMateriaisDetalhesUsuariosTotal(
          relatorioMateriaisDetalhesUsuariosTotalPlaceholder,
        );
        setRelatorioMateriaisDetalhesUsuarios(
          relatorioMateriaisDetalhesUsuariosTotalPlaceholder.slice(
            0,
            PAGE_SIZE,
          ),
        );
      } catch (e) {
        console.error(e);
      }
    }
    setLoaderActive(false);
  };

  
  const exportAcessos = async (): Promise<void> => {
    setLoaderActive(true);
    if (tab === 'acessos') {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
          ...(dateFrom ? { inicio: dateFrom } : {}),
          ...(dateTo ? { fim: dateTo } : {}),
          ...(q ? { busca: q } : {}),
          ic_imobiliaria_autonomo: Number(type),
          id_superintendente: 0,
          id_gerente: 0,
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const qs: any = Object.keys(body)
          .map(key => `${key}=${body[key]}`)
          .join('&');

        const response = await api.get(
          `RelatorioAcesso/:exportarplanilhas?${qs}`,
          {
            responseType: 'blob',
            timeout: 2 * 60 * 1000
          },
        );

        const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
          // IE11
          nav.msSaveOrOpenBlob(
            new Blob([response.data]),
            'Relatorio_Acessos.xlsx',
          );
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Relatorio_Acessos.xlsx');
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
          }, 1000);
        }
      } catch (e) {
        console.error('e', e);
        //
      }
    } else if (tab === 'materiais') {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
          ...(dateFrom ? { inicio: dateFrom } : {}),
          ...(dateTo ? { fim: dateTo } : {}),
          ...(qMateriais ? { empreendimento_filter: qMateriais } : ''),
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const qs: any = Object.keys(body)
          .map(key => `${key}=${body[key]}`)
          .join('&');

        const response = await api.get(
          `RelatorioMateriais/:exportarplanilhas?${qs}`,
          {
            responseType: 'blob',
          },
        );

        const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
          // IE11
          nav.msSaveOrOpenBlob(
            new Blob([response.data]),
            'Relatorio_Materiais.xlsx',
          );
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Relatorio_Materiais.xlsx');
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
          }, 100);
        }
      } catch (e) {
        console.error('e', e);
        //
      }
    }

    setLoaderActive(false);
  };

  //Atualiza Lista de Corretores
  function incrementList(lista: IRelatorio[]) {
    setRelatorioAcessos((state) => {
      return [...state, ...lista]
    });
  };

  const loadMore = useCallback( async (e) => {
    e.preventDefault();
    //setLoaderActive(true);
    const body: any = {
      ...(dateFrom ? { inicio: dateFrom } : {}),
      ...(dateTo ? { fim: dateTo } : {}),
      ...(q ? { busca: q } : {}),
      pagina: paginaAcesso + 1,
      quantidade: PAGE_SIZE,
      ic_imobiliaria_autonomo: Number(type),
      id_superintendente: 0,
      id_gerente: 0,
    };
    setPaginaAcesso(paginaAcesso + 1);
    CarregarRelAcessos(body,true);
    },
    [relatorioAcessos,paginaAcesso],
  );


  const CarregarRelAcessos = useCallback( async (body,addList) => {
      setLoaderActive(true);    
      try {               
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const qs: any = Object.keys(body)
          .map(key => `${key}=${body[key]}`)
          .join('&');

        const response = await api.get(`RelatorioAcesso?${qs}`);
        const relatorioAcessoTotalPlaceholder = response.data.items.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              nome:
                relatorioItem.tx_apelido != null
                  ? relatorioItem.tx_apelido
                  : relatorioItem.tx_nome,
              corretor:
                relatorioItem.id_tipoUsuario > 0 
                ? 'Tegra Vendas' :
                  relatorioItem.tx_cnpj == null || relatorioItem.tx_cnpj === ''                    
                    ? 'Autônomo'
                    : 'Imobiliária',
              gerente: relatorioItem.tx_gerente,
              coordenador: relatorioItem.tx_coordenador,
              idTipoUsuario: relatorioItem.id_tipoUsuario,
              idUsuario: relatorioItem.id_usuario,
              ultimoDownload:
                relatorioItem.dt_acesso != null
                  ? relatorioItem.dt_acessoFormatada
                  : '',
              downloads: relatorioItem.nu_downloads,
            };
          },
        );
        if (addList){          
          incrementList(relatorioAcessoTotalPlaceholder);
        }
        else{
          setRelatorioAcessos(relatorioAcessoTotalPlaceholder);
          setTotalPaginaAcesso(response.data.paginas);
        }

      } catch (e) {
        console.error('e', e);
      }
      setLoaderActive(false);
    },
    [relatorioAcessos,paginaAcesso],
  );

  const onSearchAcessos = async (): Promise<void> => {
    const body: any = {
      ...(dateFrom ? { inicio: dateFrom } : {}),
      ...(dateTo ? { fim: dateTo } : {}),
      ...(q ? { busca: q } : {}),
      pagina: 1,
      quantidade: PAGE_SIZE,
      ic_imobiliaria_autonomo: Number(type),
      id_superintendente: 0,
      id_gerente: 0,
    };
    setPaginaAcesso(1); 
    CarregarRelAcessos(body,false);
  };
    
  const onSearch = async (): Promise<void> => {
    setLoaderActive(true);
    if (tab === 'acessosdetalhes') {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
          ...(dateFrom ? { inicio: dateFrom } : {}),
          ...(dateTo ? { fim: dateTo } : {}),
          ...(qAcessosDetalhes ? { busca: qAcessosDetalhes } : {}),
          idTipoUsuario: relatorioAcessos[0].idTipoUsuario,
          id: relatorioAcessos[0].idUsuario,
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const qs: any = Object.keys(body)
          .map(key => `${key}=${body[key]}`)
          .join('&');

        const response = await api.get(
          `RelatorioAcesso/:acessosdetalhes?${qs}`,
        );

        //Limpa busca para quando voltar
        setType(0);

        const relatorioAcessosDetalhesTotalPlaceholder = response.data.item1.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              material: relatorioItem.tx_material,
              categoria: relatorioItem.tx_categoria,
              empreendimento: relatorioItem.tx_empreendimento,
              downloads: relatorioItem.total_downloads,
              ultimoDownloadFormatado: relatorioItem.ultimo_acessoFormatado,
            };
          },
        );
        setRelatorioAcessosDetalhesTotal(
          relatorioAcessosDetalhesTotalPlaceholder,
        );
        setRelatorioAcessosDetalhes(
          relatorioAcessosDetalhesTotalPlaceholder.slice(0, PAGE_SIZE),
        );

        const corretorAcessosDetalhesTotalPlaceholder = {
          nome: response.data.item2.tx_nome,
          foto: response.data.item2.tx_foto,
        };

        setCorretorAcessosDetalhes(corretorAcessosDetalhesTotalPlaceholder);
      } catch (e) {
        console.error('e', e);
        //
      }
    } else if (tab === 'materiais') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const body: any = {
        ...(dateFrom ? { inicio: dateFrom } : {}),
        ...(dateTo ? { fim: dateTo } : {}),
        ...(qMateriais ? { empreendimento: qMateriais } : ''),
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const qs: any = Object.keys(body)
        .map(key => `${key}=${body[key]}`)
        .join('&');
      const response = await api.get(`RelatorioMateriais?${qs}`);

      const apiResponse = response.data;
      try {
        const relatorioMateriaisTotalPlaceholder = apiResponse.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              empreendimento: relatorioItem.tx_empreendimento,
              empreendimentoId: relatorioItem.id_empreendimento,
              downloads: relatorioItem.total_downloads,
              detalhes: 'VER DETALHES',
            };
          },
        );
        setRelatorioMateriaisTotal(relatorioMateriaisTotalPlaceholder);
        setRelatorioMateriais(
          relatorioMateriaisTotalPlaceholder.slice(0, PAGE_SIZE),
        );
      } catch (e) {
        console.error(e);
      }
    } else if (tab === 'materiaisdetalhes') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const body: any = {
        id: window.location.search.split('?')[1].substr(3),
        ...(dateFrom ? { inicio: dateFrom } : {}),
        ...(dateTo ? { fim: dateTo } : {}),
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const qs: any = Object.keys(body)
        .map(key => `${key}=${body[key]}`)
        .join('&');
      const response = await api.get(
        `RelatorioMateriais/:materiaisdetalhes?${qs}`,
      );

      const apiResponse = response.data;
      try {
        const relatorioMateriaisDetalhesTotalPlaceholder = apiResponse.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relatorioItem: any) => {
            return {
              material: relatorioItem.tx_material,
              empreendimentoId: relatorioItem.id_empreendimento,
              downloads: relatorioItem.total_downloads,
              detalhes: 'VER DETALHES',
            };
          },
        );
        setRelatorioMateriaisDetalhesTotal(
          relatorioMateriaisDetalhesTotalPlaceholder,
        );
        setRelatorioMateriaisDetalhes(
          relatorioMateriaisDetalhesTotalPlaceholder.slice(0, PAGE_SIZE),
        );
      } catch (e) {
        console.error(e);
      }
    }

    setLoaderActive(false);
  };

/*
  useEffect(() => {
    fetchRelatorio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
*/
  useEffect(() => {
    fetchRelatorio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  useEffect(() => {
    if (tab === 'acessos') {
      setSelectedIndex(0);
    } else if (tab === 'materiais') {
      setSelectedIndex(1);
    } else if (tab === 'acessosdetalhes') {
      setSelectedIndex(2);
    } else if (tab === 'materiaisdetalhes') {
      setSelectedIndex(3);
    } else if (tab === 'materiaisdetalhesusuarios') {
      setSelectedIndex(4);
    }
  }, [tab]);

  const navigate = (url: string): void => {
    history.push(`/dashboard/relatorio/${url}`);
  };

  return (
    <RelatorioPageContainer>
      <TegraOpaqueHero title="Relatórios" subtitle="" description="" />

      <RelatorioContentWrapper>
        <TegraTabsContainer>
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={index => setSelectedIndex(index)}
          >
            <RelatorioTabsWrapper>
              <TabList>
                <Tab onClick={() => navigate('acessos')}>
                  Acesso dos corretores
                </Tab>
                <Tab onClick={() => navigate('materiais')}>
                  Produtos mais acessados
                </Tab>
                <Tab
                  onClick={() =>
                    history.push('/dashboard/relatorio/atividades')}
                >
                  Atividades
                </Tab>
              </TabList>
            </RelatorioTabsWrapper>
            <TabPanel>
                <FilterGrid>
                  <FilterSearchRow>
                    <input
                      type="text"
                      value={q}
                      onChange={({ target }) => setQ(target.value)}
                      placeholder="Buscar por nome, e-mail, CPF, CNPJ ou CRECI"
                    />
                  </FilterSearchRow>
                </FilterGrid>

                <FilterGrid>
                  <FilterGridItem>
                    <label>{`De ${' '}`}</label>
                    <input
                      type="date"
                      value={dateFrom}
                      onChange={({ target }) => {
                        setDateFrom(target.value);
                        setDateTo('');
                      }}
                    />
                  </FilterGridItem>

                  <FilterGridItem>
                    <label>{`a ${' '}`}</label>
                    <input
                      type="date"
                      min={dateFrom}
                      value={dateTo}
                      onChange={({ target }) => setDateTo(target.value)}
                    />
                  </FilterGridItem>

                  <FilterGridItem>
                    <select
                      name=""
                      id="autonomoImobiliaria"
                      value={type}
                      onChange={({ target }) => setType(Number(target.value))}
                    >
                      <option value={0}>Todos</option>                      
                      <option value={1}>Autônomo e Imobiliária</option>                      
                      <option value={2}>Imobiliária</option>
                      <option value={3}>Autônomo</option>
                      <option value={4}>Tegra Vendas</option>
                    </select>
                  </FilterGridItem>

                  <FilterGridItem>
                    <TegraButton onClick={onSearchAcessos} isAction>
                      BUSCAR
                    </TegraButton>

                    <TegraButton onClick={() => exportAcessos()} isAction>
                      EXPORTAR
                    </TegraButton>
                  </FilterGridItem>
                </FilterGrid>
              {relatorioAcessos.length !== 0 ? (
                <div>
                  <RelatorioTable
                    relatorioList={relatorioAcessos}
                    tipo="Acessos"
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                  />
                  {paginaAcesso < totalPaginaAcesso && (
                      <div className="text-center">
                        <TegraButton isAction onClick={loadMore}>
                            Carregar mais
                        </TegraButton>
                      </div>
                    )
                  }
              </div>
              ) : (
                'Nenhum dado disponível com os filtros selecionados.'
              )}              
            </TabPanel>
            <TabPanel>
              <FilterGrid>
                <FilterGridItem>
                  <label>Período</label>
                  <div>
                    <FilterGridInput>
                      <label>{`De ${' '}`}</label>
                      <input
                        type="date"
                        value={dateFrom}
                        onChange={({ target }) => {
                          setDateFrom(target.value);
                          setDateTo('');
                        }}
                      />
                    </FilterGridInput>
                    <FilterGridInput>
                      <label>{`a ${' '}`}</label>
                      <input
                        type="date"
                        min={dateFrom}
                        value={dateTo}
                        onChange={({ target }) => setDateTo(target.value)}
                      />
                    </FilterGridInput>
                  </div>
                </FilterGridItem>
                <FilterGridItem>
                  <label>Empreendimento</label>
                  <FilterGridInput>
                    <input
                      type="text"
                      value={qMateriais}
                      onChange={({ target }) => setQMateriais(target.value)}
                      placeholder="Empreendimento"
                      style={{ width: '100%' }}
                    />
                  </FilterGridInput>
                </FilterGridItem>
                <FilterGridItem>
                  <br />
                  <div>
                    <TegraButton onClick={onSearch} isAction>
                      BUSCAR
                    </TegraButton>

                    <TegraButton onClick={() => exportAcessos()} isAction>
                      EXPORTAR
                    </TegraButton>
                  </div>
                </FilterGridItem>
              </FilterGrid>
              {relatorioMateriais.length !== 0 ? (
                <RelatorioTable
                  relatorioList={relatorioMateriais}
                  tipo="Materiais"
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              ) : (
                'Nenhum dado disponível com os filtros selecionados.'
              )}
            </TabPanel>
            <TabPanel>
              <FilterGrid>
                <FilterSearchRow>
                  <input
                    type="text"
                    value={qAcessosDetalhes}
                    onChange={({ target }) => setQAcessosDetalhes(target.value)}
                    placeholder="Buscar por título do material"
                  />
                </FilterSearchRow>
                <FilterGridItem>
                  <label>{`De ${' '}`}</label>
                  <input
                    type="date"
                    value={dateFrom}
                    onChange={({ target }) => {
                      setDateFrom(target.value);
                      setDateTo('');
                    }}
                  />
                </FilterGridItem>
                <FilterGridItem>
                  <label>{`a ${' '}`}</label>
                  <input
                    type="date"
                    min={dateFrom}
                    value={dateTo}
                    onChange={({ target }) => setDateTo(target.value)}
                  />
                </FilterGridItem>
                <FilterGridItem>
                  <TegraButton onClick={onSearch} isAction>
                    BUSCAR
                  </TegraButton>
                </FilterGridItem>
              </FilterGrid>
              <RelatorioGoBack
                corretorItem={corretorAcessosDetalhes}
                tipo="AcessosDetalhes"
                empreendimento=""
                materiais=""
              />
              {relatorioAcessosDetalhes.length !== 0 ? (
                <RelatorioTable
                  relatorioList={relatorioAcessosDetalhes}
                  tipo="AcessosDetalhes"
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              ) : (
                'Nenhum dado disponível com os filtros selecionados.'
              )}
            </TabPanel>
            <TabPanel>
              <FilterGrid>
                <FilterGridItem>
                  <label>{`Período ${' '}`}</label>
                  <input
                    type="date"
                    value={dateFrom}
                    onChange={({ target }) => {
                      setDateFrom(target.value);
                      setDateTo('');
                    }}
                  />
                </FilterGridItem>
                <FilterGridItem>
                  <label>{`a ${' '}`}</label>
                  <input
                    type="date"
                    min={dateFrom}
                    value={dateTo}
                    onChange={({ target }) => setDateTo(target.value)}
                  />
                </FilterGridItem>
                <FilterGridItem>
                  <TegraButton onClick={onSearch} isAction>
                    BUSCAR
                  </TegraButton>
                </FilterGridItem>
              </FilterGrid>
              <RelatorioGoBack
                corretorItem={corretorAcessosDetalhes}
                tipo="MateriaisDetalhes"
                empreendimento={empreendimentoMateriaisDetalhes}
                materiais=""
              />
              {relatorioMateriaisDetalhes.length !== 0 ? (
                <RelatorioTable
                  relatorioList={relatorioMateriaisDetalhes}
                  tipo="MateriaisDetalhes"
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              ) : (
                'Nenhum dado disponível com os filtros selecionados.'
              )}
            </TabPanel>
            <TabPanel>
              <FilterGrid>
                <FilterGridItem>
                  <label>{`Período ${' '}`}</label>
                  <input
                    type="date"
                    value={dateFrom}
                    onChange={({ target }) => {
                      setDateFrom(target.value);
                      setDateTo('');
                    }}
                  />
                </FilterGridItem>
                <FilterGridItem>
                  <label>{`a ${' '}`}</label>
                  <input
                    type="date"
                    min={dateFrom}
                    value={dateTo}
                    onChange={({ target }) => setDateTo(target.value)}
                  />
                </FilterGridItem>
                <FilterGridItem>
                  <TegraButton onClick={onSearch} isAction>
                    BUSCAR
                  </TegraButton>
                </FilterGridItem>
              </FilterGrid>
              <RelatorioGoBack
                corretorItem={corretorAcessosDetalhes}
                tipo="MateriaisDetalhesUsuarios"
                empreendimento={empreendimentoMateriaisDetalhes}
                materiais=""
              />
              {relatorioMateriaisDetalhesUsuarios.length !== 0 ? (
                <RelatorioTable
                  relatorioList={relatorioMateriaisDetalhesUsuarios}
                  tipo="MateriaisDetalhesUsuarios"
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              ) : (
                'Nenhum dado disponível com os filtros selecionados.'
              )}
            </TabPanel>
          </Tabs>
        </TegraTabsContainer>
      </RelatorioContentWrapper>
    </RelatorioPageContainer>
  );
};

export default Relatorio;
