import styled, { css } from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';

export const Container = styled.div`
  width: 50%;
  min-height: 100vh;
  display: flex;
  align-itens: center;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  //margin-top: 58.5px;
  margin-top: 25px;

  @media (max-width: 600px) {
    margin-top: 0px;
  }
`;

export const LinkErrado = styled.div`
  text-align: right;
  padding: 8px 0;
  a {
    color: #ffffff !important;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    margin-right: 36px;
    margin-bottom: 15px;
    margin-left: 36px;    
  }
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: row;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      flex: 1;
    }

    .withSubtitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      div.divider {
        display: block;
      }

      @media (max-width: 1288px) {
        flex-direction: column;
        align-items: flex-start;

        div.divider {
          display: none;
        }
      }

      @media (max-width: 920px) {
        div.divider {
          margin-left: 20px !important;
          margin-right: 20px !important;
        }
      }
    }
  }

  div.divider:last-of-type {
    display: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .title {
      svg {
        display: block;
      }

      .withSubtitle {
        flex-direction: column;
        align-items: flex-start;

        div.divider {
          display: none;
        }
      }
    }

    div.divider {
      height: 0.6px;
      width: 100%;
      margin: 20.5px 0px;
      background: #ebb92d;

      &:last-of-type {
        display: block;
      }
    }

    form button[type='submit'] {
      width: 100%;
      flex: 1;
      font-size: 16px;
    }
  }

  /* align-items: center; */
  justify-content: space-between;

  form {
    display: flex;
    flex-direction: column;
  }
  form button[type='submit'] {
    //margin-top: 45px;
    margin-top: 15px;
    align-self: flex-end;
    font-size: 16px;
  }
`;

export const SingleLoginBox = styled.div`
  flex: 48%;

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const LoginBoxTitle = styled.h1`
  font-family: Montserrat;
  //font-size: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
`;

export const LoginBoxSubTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
  display: block;

  @media (max-width: 768px) {
    margin-top: 5px;
  }

  &.isAlt {
    opacity: 0;

    @media (min-width: 1288px) {
      display: none;
    }

    @media (max-width: 947px) {
      display: none;
    }
  }
`;

export const LoginBoxLabel = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 11px;
`;

export const LoginBoxInput = styled.input`
  width: 100%;
  margin-bottom: 20px;
  border: solid 1px #adadab;
  background-color: #ffffff;
`;

export const Content = styled.div`
  width: 100%;
  padding: 40px;
  background: #fff;

  flex: 1;
  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const SignUpAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 90px;
  padding: 40px;

  background: #ebb92d;

  @media (max-width: 768px) {
    padding: 30px 36px 40px 36px;
    flex-direction: column;
    height: 192px;
    align-items: flex-start;

    a {
      width: 100%;
    }

    button {
      flex: 1;
      font-size: 16px;
      width: 100%;
      margin: 0px;
      margin-top: 13.5px;
    }
  }

  a {
    text-decoration: none;
  }
`;

export const SignUpActionText = styled.p`
  flex: 1;
  text-align: right;
  margin-right: 40px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #272723;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: left;
    margin-right: 0;
    font-weight: 500;
    width: 205px;
    flex: 1;
  }
`;

export const BoxDescription = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;

  margin-bottom: 10.5px;
`;

interface AccordionIconProps {
  isOpen: boolean;
}

export const AccordionIcon = styled.div<AccordionIconProps>`
  display: none;
  /* margin-left: 55px; */
  /* width: 22.8px; */
  /* height: 11.4px; */
  color: #272723;

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(180deg);
    `}

  @media(max-width: 600px) {
    display: block;
  }
`;

export const ErrorsContainer = styled.div`
  margin-top: 20px;

  p,
  a {
    color: #fff;

    & + p {
      margin-top: 5px;
    }
  }

  p {
    padding: 15px;
    background: #d84128;
  }

  p.warning {
    background: #ebb92d;
  }
`;
