import React, { useRef, useState, useCallback } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import arrowDownIcon from '../../assets/tegra-icon-arrow-down.svg';

import {
  Container,
  Content,
  LoginContainer,
  LinkErrado,
  LoginBox,
  LoginBoxTitle,
  SingleLoginBox,
  LoginBoxSubTitle,
  AccordionIcon,
  ErrorsContainer,
} from './styles';
import TegraIntroHeader from '../../components/TegraIntroHeader';
import TegraDivider from '../../components/TegraDivider';
import TegraInput from '../../components/TegraInput';
import TegraButton from '../../components/TegraButton';
import TegraInputWithMask from '../../components/TegraInputWithMask';
import { useAuth } from '../../hooks/auth';
import { getLink, isParcerias } from '../../utils/logo';
import { useLoader } from '../../hooks/loader';
import api from '../../services/api';

interface ForgotPasswordData {
  tx_cpfCnpj: string;
  linkPortal: string;
}

interface ParceiroFormData {
  cpf_or_cnpj: string;
  // creci: string;
}

interface Message {
  message: string;
  type?: 'error' | 'warning';
}

const ForgetPassword: React.FC = () => {
  const { setLoaderActive } = useLoader();

  const formRefCorretor = useRef<FormHandles>(null);

  const [corretorOpen, setCorretorOpen] = useState(true);
  const [parceiroOpen, setParceiroOpen] = useState(true);
  const [portalIncorreto, setPortalIncorreto] = useState(false);

  const [documentoCorretor, setDocumentoCorretor] = useState('');
  const [linkPortal, setLinkPortal] = useState(getLink(isParcerias()));

  const [errors, setErrors] = useState<Message[]>([]);

  const handleIconClick = useCallback(
    (name: string) => {
      switch (name) {
        case 'corretor':
          setCorretorOpen(!corretorOpen);
          break;
        case 'parceiro':
          setParceiroOpen(!parceiroOpen);
          break;
        default:
          break;
      }
    },
    [corretorOpen, parceiroOpen],
  );

  const handleSubmitForgotPassword: SubmitHandler<ForgotPasswordData> = async (): Promise<
    void
  > => {
    // Todo fazer login corretor
    try {
      setLoaderActive(true);
      
      const data = { tx_cpfCnpj: documentoCorretor, linkPortal: linkPortal };

      const schema = Yup.object().shape({
        tx_cpfCnpj: Yup.string().required('CPF/CNPJ'),
        linkPortal: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      setPortalIncorreto(false);

      const { data: email } = await api.post<string>(
        `Cadastro/reset-senha`,
        data,
      );

      if (email) {
        const messages: Message[] = [];
        messages.push({
          message: `Foi encaminhado ao seu e-mail (${email}) um link para cadastro de uma nova senha de acesso! Esse link expira em 5 horas.`,
          type: 'warning',
        });

        setErrors(messages);
      }
    } catch (err : any) {
      setLoaderActive(false);
      const validationErrors: Message[] = [];

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors.push({
            message: error.message,
            type: 'error',
          });
        });
      }

      if (err.response) {
        const { erro, info } = err.response.data || {};

        if (erro === 'PORTALINCORRETO') {
          setPortalIncorreto(true);
        } else if (erro === 'ACESSOCRECI') {
          validationErrors.push({
            message: `Devido a atualizações de segurança, foi encaminhado ao seu e-mail (${info}) um link para cadastro de uma nova senha de acesso!`,
            type: 'warning',
          });
        } else {
          validationErrors.push({ message: erro, type: 'error' });
        }
      }
      setErrors(validationErrors);
    } finally {
      setLoaderActive(false);
    }
  };

  return (
    <Container>
      <TegraIntroHeader dividerAxis="y" dividerColor="#ebb92d" />
      <LoginContainer>
        <Content>
          <LoginBox>
            <SingleLoginBox>
              <Form ref={formRefCorretor} onSubmit={handleSubmitForgotPassword}>
                <div className="title">
                  <div className="withSubtitle">
                    <LoginBoxTitle>Esqueci minha senha</LoginBoxTitle>

                    <LoginBoxSubTitle className="isAlt">
                      Esqueci minha senha
                    </LoginBoxSubTitle>
                  </div>
                  {/*
                  <AccordionIcon
                    isOpen={corretorOpen}
                    onClick={() => handleIconClick('corretor')}
                  >
                    <img src={arrowDownIcon} alt="Accordion arrow" />
                  </AccordionIcon>
                  */}
                </div>

                {errors.length >= 1 && (
                  <ErrorsContainer>
                    {errors.map(error => (
                      <p className={error.type}>{error.message}</p>
                    ))}
                  </ErrorsContainer>
                )}

                <TegraInputWithMask
                  label="CPF ou CNPJ"
                  name="tx_cpfCnpj"
                  maskChar={null}
                  value={documentoCorretor}
                  mask=""
                  onChange={e =>
                    setDocumentoCorretor(e.target.value.replace(/\D/g, ''))
                  }
                />

                <TegraButton type="submit" isAction style={{marginRight: '0px', fontSize: '14px'}}>
                  Recuperar
                </TegraButton>
              </Form>
            </SingleLoginBox>

            <TegraDivider
              marginLeft={40}
              height={300}
              marginRight={40}
              axis="y"
              color="#adadab"
            />
          </LoginBox>
        </Content>
      </LoginContainer>

      <LinkErrado>
        <Link to="/login">Voltar ao Login</Link>
      </LinkErrado>
    </Container>
  );
};

export default ForgetPassword;
