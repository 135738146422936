import React from 'react';
import ProductMaterials from '../..';

const Videos: React.FC = () => {
  return (
    <ProductMaterials
      title="Vídeos"
      subtitle=""
      description="Baixe vídeos prontos dos nossos empreendimentos para compartilhar com seus clientes e prospects."
      showColumnTitle
      actions={['share', 'open', 'download']}
      apiUrl="Videos"
      columns={[
        { label: 'Imagem', field: 'tx_thumbnail' },
        { label: 'Produto', field: 'tx_produto' },
        { label: 'Vídeo', field: 'nome' },
      ]}
      anchorName="Videos"
      onClickItem={() => localStorage.setItem('SCROLL', '#Videos')}
    />
  );
};

export default Videos;
