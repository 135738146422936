import styled from 'styled-components';
import optionBgImage from '../../../assets/tegra-barra_amarela.png';

export const ExtraActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: block;
    text-decoration: none;

    &.jaPossuoCadastro {
      text-decoration: underline;
    }
  }

  p,
  a {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
  }
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;

  p {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  margin-top: 33px;

  & > h1 {
    margin-bottom: 5px;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;

  &.optionTitle {
    width: 190px;
    margin-left: 39px;

    @media (max-width: 340px) {
      margin-left: 10px;
    }
  }
`;

export const Options = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  text-align: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
  }

  a {
    text-decoration: none;
    width: 100%;
  }
`;

export const Option = styled.div`
  display: flex;
  /* justify-content: space-around; */
  padding: 55px 42px;
  align-items: center;
  align-content: center;
  height: 155px;
  width: 315px;
  max-width: 100%;
  background-color: #e6e6e6;
  cursor: pointer;
  transition: transform 500ms;
  background-image: url(${optionBgImage});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% auto;

  @media (max-width: 600px) {
    margin: 0;
    width: 100%;

    &:first-child {
      @media (max-width: 600px) {
        margin: 0;
        margin-bottom: 40px;
      }
    }
  }

  &:first-child {
    margin-right: 30px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const Subtitle = styled.h2`
  height: 24px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
`;
