import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams } from 'react-router-dom';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraTabsContainer from '../../components/TegraTabsContainer';
import {
  ContatosPageContainer,
  ContatosContentWrapper,
  ContactsManagementSearchValue,
  SearchValue,
  ContactsTabListWrapper,
} from './styles';
import TegraContactGroups, {
  IContactGroup,
  IContacts,
} from './TegraContactGroups';
import ContactsSalesFilter from './ContactsSalesFilter';
import ContactsManagementFilter from './ContactsManagementFilter';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useLoader } from '../../hooks/loader';

const Contacts: React.FC = () => {
  const tabNumber = Object.freeze({ VENDAS: 0, GERENTE: 1, INCORPORADORA: 2 });

  const { tab } : any = useParams();
  const { token } = useAuth();
  const { setLoaderActive } = useLoader();
  const AuthStr = `Bearer ${token}`;
  const [ajaxTimeout, setAjaxTimeout] = useState<number>(0);
  const [salesContacts, setSalesContacts] = useState<IContactGroup[]>([]);
  const [salesContactsFiltered, setSalesContactsFiltered] = useState<
    IContactGroup[]
  >([]);
  const [salesFilterString, setSalesFilterString] = useState<string>('Todas');
  const [managementContacts, setManagementContacts] = useState<IContactGroup>({
    contacts: [],
  });
  const [filter, setFilter] = useState<string>('');
  const [developerContacts, setDeveloperContacts] = useState<IContactGroup[]>(
    [],
  );
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [managementLoading, setManagementLoading] = useState<boolean>(false);

  const getManagementData = async (filterStr: string): Promise<void> => {
    const managementGroupsPlaceholder: IContactGroup = { contacts: [] };
    setManagementLoading(true);
    setFilter('');
    setManagementContacts({
      contacts: [],
    });
    const response = await api.get(
      `Contato/corretoresGerentes?busca=${filterStr}`,
    );

    response.data.forEach((contact: any) => {
      const newContact: IContacts = {
        image: contact.tx_foto as string,
        name: contact.tx_nome,
        apelido: contact.tx_apelido,
        team: contact.tx_area,
        products: contact.tx_produtos,
        occupation: contact.tx_cargo,
        email: contact.tx_email,
        phone:
          contact.tx_telefoneCelular == undefined
            ? (contact.tx_telefone as string)
            : (contact.tx_telefoneCelular as string),
      };
      managementGroupsPlaceholder.contacts.push(newContact);
    });
    setFilter(filterStr);
    setManagementContacts(managementGroupsPlaceholder);
    setManagementLoading(false);
  };

  const updateContactData = async () => {
    setLoaderActive(true);
    if (selectedIndex == tabNumber.VENDAS) {
      const contactGroupsPlaceholder: IContactGroup[] = [];

      const response = await api.get('Contato/vendas');

      response.data.forEach((contact: any) => {
        const groupTitle: string = contact.tx_area;
        const collection = contactGroupsPlaceholder.filter(
          contactGroup => contactGroup.title == groupTitle,
        )[0];
        const newContact: IContacts = {
          image: contact.foto as string,
          name: contact.tx_nome,
          apelido: contact.tx_nome, // apelido apenas para corretor sigavi
          team: contact.tx_area,
          products: contact.tx_produtos,
          occupation: contact.tx_cargo,
          email: contact.tx_email,
          phone:
            (contact.tx_telefone == undefined || contact.tx_telefone == '')
              ? (contact.tx_celular as string)
              : (contact.tx_telefone as string),
        };
        if (typeof collection !== 'undefined') {
          collection.contacts.push(newContact);
        } else {
          contactGroupsPlaceholder.push({
            title: groupTitle,
            contacts: [newContact],
          });
        }
      });
      setSalesContacts(contactGroupsPlaceholder);
      setSalesContactsFiltered(contactGroupsPlaceholder);
    } else if (selectedIndex == tabNumber.INCORPORADORA) {
      const developerGroupsPlaceholder: IContactGroup[] = [];
      const response = await api.get('Contato/incorporadora');
      response.data.forEach((contact: any) => {
        const groupTitle: string = contact.tx_area;
        const collection = developerGroupsPlaceholder.filter(
          contactGroup => contactGroup.title === groupTitle,
        )[0];
        const newContact: IContacts = {
          image: contact.foto as string,
          name: contact.tx_nome,
          apelido: contact.tx_nome, // apelido apenas para corretor sigavi
          team: contact.tx_area,
          products: contact.tx_produtos,
          occupation: contact.tx_cargo,
          email: contact.tx_email,
          phone:
            contact.tx_telefone === undefined
              ? (contact.tx_celular as string)
              : (contact.tx_telefone as string),
        };
        if (typeof collection !== 'undefined') {
          collection.contacts.push(newContact);
        } else {
          developerGroupsPlaceholder.push({
            title: groupTitle,
            contacts: [newContact],
          });
        }
      });
      setDeveloperContacts(developerGroupsPlaceholder);
    }
    setLoaderActive(false);
  };

  useEffect(() => {
    if (tab === 'gerentes') {
      setSelectedIndex(tabNumber.GERENTE);
    } else if (tab === 'incorporadora') {
      setSelectedIndex(tabNumber.INCORPORADORA);
    }
  }, [tab]);

  useEffect(() => {
    updateContactData();
  }, [selectedIndex]);

  useEffect(() => {
    if (salesContacts.length !== 0) {
      if (salesFilterString === 'Todas') {
        setSalesContactsFiltered(salesContacts);
      } else {
        setSalesContactsFiltered(
          salesContacts?.filter(
            contactGroupItem => contactGroupItem.title === salesFilterString,
          ),
        );
      }
    }
  }, [salesFilterString]);

  const searchAPIDebounced = AwesomeDebouncePromise(async (data: string) => {
    await getManagementData(data);
  }, 600);

  return (
    <ContatosPageContainer>
      <TegraOpaqueHero
        title="Contatos"
        description="Encontre aqui os contatos mais importantes para cada campanha, empreendimento ou área."
      />
      <ContatosContentWrapper>
        <TegraTabsContainer>
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={index => setSelectedIndex(index)}
          >
            <ContactsTabListWrapper>
              <TabList>
                <Tab>Vendas</Tab>
                <Tab>Corretores/Gerentes</Tab>
                <Tab>Incorporadora</Tab>
              </TabList>
            </ContactsTabListWrapper>
            <TabPanel>
              <ContactsSalesFilter
                contactGroups={salesContacts}
                setFilter={(value: string) => setSalesFilterString(value)}
              />
              {salesContactsFiltered.map(salesContactItem => (
                <TegraContactGroups
                  key={`sales-${salesContactItem.title}`}
                  contactGroup={salesContactItem}
                />
              ))}
            </TabPanel>
            <TabPanel>
              <ContactsManagementFilter
                setFilter={(value: string) => searchAPIDebounced(value)}
              />
              {filter && (
                <ContactsManagementSearchValue>
                  Resultados da busca para <SearchValue>{filter}</SearchValue>
                </ContactsManagementSearchValue>
              )}
              {managementLoading && (
                <ContactsManagementSearchValue>
                  Carregando...
                </ContactsManagementSearchValue>
              )}
              {!managementContacts.contacts.length &&
                filter &&
                !managementLoading && (
                  <ContactsManagementSearchValue>
                    Nenhum resultado encontrado
                  </ContactsManagementSearchValue>
                )}
              <TegraContactGroups contactGroup={managementContacts} />
            </TabPanel>
            <TabPanel>
              {developerContacts.map(developerContactItem => (
                <TegraContactGroups
                  key={`developer-${developerContactItem.title}`}
                  contactGroup={developerContactItem}
                />
              ))}
            </TabPanel>
          </Tabs>
        </TegraTabsContainer>
      </ContatosContentWrapper>
    </ContatosPageContainer>
  );
};

export default Contacts;
