/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Form, FormHandles } from '@unform/core';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {
  Container,
  CorretoresContainer,
  SearchContainer,
  CorretoresTable,
  ListaAction,
  FormActions,
  ContainerModal,
  Espace,
} from './styles';
import TegraSelect from '../../../components/TegraSelect';
import openIcon from '../../../assets/tegra-icon-open.svg';
import TegraDivider from '../../../components/TegraDivider';
import TegraButtonExtra from '../../../components/TegraButtonExtra';
import TegraButton from '../../../components/TegraButton';
import api from '../../../services/api';
import { useLoader } from '../../../hooks/loader';
import { useAuth } from '../../../hooks/auth';
import { groupBy } from '../../../services/helpers';
import 'moment/locale/pt';
import { CloseButton } from '../../../components/ModalRegional/styles';
import { ReactComponent as CloseIcon } from '../../../assets/tegra-icon-close.svg';
import ajaxLoading from '../../../assets/ajax-loader.gif';
//import './PresencaLista.sass';

moment.locale('pt');
moment.updateLocale('pt', {
  weekdaysMin: 'dom._seg._ter._qua._qui._sex._sáb.'.split('_'),
});

const searchFormInitialData = {
  tipo_parceiro: {
    value: undefined,
    label: 'Todos',
  },
  status: {
    value: undefined,
    label: 'Todos',
  },
  estado: {
    value: undefined,
    label: 'Todos',
  },
  regional: {
    value: undefined,
    label: 'Todas',
  },
  superintendente: {
    value: '',
    label: '',
  },
  gerente: {
    value: '',
    label: '',
  },
  busca: '',
};

export interface ICorretor {
  id_parceiro: string;
  id_tipoCorretor: number;
  id_tipoParceiro: number;
  txt_tipoParceiro: string;
  tx_razaoSocial: string;
  tx_nome: string;
  tx_creci: string;
  tx_email: string;
  superintendente: string;
  gerente: string;
  tx_uf: string;
  tx_regional: string;
  id_situacao: string;
  tx_situacao: string;
}

interface Filter {
  id?: number;
  nome: string;
}

interface Regional extends Filter {
  uf: string;
}

interface Estado extends Filter {
  uf: string;
  regionais: {
    regional: Filter;
  }[];
}
interface ToggleProps {
  setDetail: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setItem: (value: any) => void;
}

const PresencaLista: React.FC<ToggleProps> = ({ setDetail, setItem }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [gerentes, setGerentes] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [isOpen, setIsOpen] = useState<any>(false);
  const [regional, setRegional] = useState<any>('');
  const [regionalSel, setRegionalSel] = useState<any>('');
  const [corretor, setCorretor] = useState<any>([]);
  const [gerente, setGerente] = useState<any>('');
  const [focusedInput, setFocusedInput] = useState(false);
  const [focusDate, setFocusDate] = useState<any>();
  const [date, setDate] = useState(moment());
  const [produto, setProduto] = useState<any>('');
  const [tipoevento, setTipoEvento] = useState<any>('');

  const [corretores, setCorretores] = useState<any[]>([]); 
  const [corretoresModal, setCorretoresModal] = useState<any[]>([]); 
  const [estados, setEstados] = useState<Estado[]>([]);
  const [estadosModal, setEstadosModal] = useState<Estado[]>([]);
  const [estadosSelect, setEstadosSelect] = useState<any[]>([]);
  const [estado, setEstado] = useState<any>([]);
  const [estadoModal, setEstadoModal] = useState<any>([]);
  const [regionais, setRegionais] = useState<Filter[]>([]);
  const [allRegionais, setAllRegionais] = useState<Regional[]>([]);
  const [totalPaginas, setTotalPaginas] = useState<number>(1);
  const [empreendimentos, setEmpreendimentos] = useState<any[]>([]);
  const [empreendimentosFiltro, setEmpreendimentosFiltro] = useState<any[]>([]);
  const [empreendimentosModal, setEmpreendimentosModal] = useState<any[]>([]);
  const [allEmpreendimento, setAllEmpreendimentos] = useState<any[]>([]);
  const [supervisorEmpreendimento, setSupervisorEmpreendimentos] = useState<any[]>([]);
  const [supervisorNomeEmpreendimento, setSupervisorNomeEmpreendimento] = useState<any[]>([]);
  const { setLoaderActive } = useLoader();
  const { user } = useAuth();
  const [selectCorretores, setSelectCorretores] = useState<any[]>([
    { corretor: { label: 'Todos', value: '' }, nu_order: 0 },
  ]);

  const optionsRegional = [
    { label: 'Tegra SP', value: 1 },
    { label: 'Tegra Campinas', value: 3 },
    { label: 'Tegra RJ', value: 2 },
    { label: 'Tegra Lab', value: 4 },
  ]

  // Router
  const { path, url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    async function loadGerentes() {
      const resGerentes = await api.get<any[]>(`Gerente/listGerentes`);
      setGerentes(
        resGerentes.data.map((d: any) => ({
          label: d.tx_apelido,
          value: d.id_corretorSigavi,
        })),
      );
    }

    async function loadCorretores() {
      const res = await api.get(`evento/CorretoresImoveisList`, {
        params: { nu_pagina: 1, nu_quantidade: 10 },
      });
      setCorretores(
        res.data.map((d: any) => ({
          label: d.tx_apelido,
          value: d.id_corretorSigavi,
          empresaSigavi: d.tx_empresaSigavi
        })),
      );
    }

    async function loadEstado() {
      // carregar regionais da api
      const estadoRes = await api.get<Estado[]>(`Estados`);
      const { data: estadoData } = estadoRes;
      estadoData.unshift({
        nome: 'Todos',
        uf: '',
        id: undefined,
        regionais: [],
      });
      setEstados(estadoData);
      if ([8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) > -1){
        setEstadosModal(estadoData.filter(e=>e.id == user.idEstado));        
        setEstadosSelect(estadoData.map(est => ({
                          value: est.uf,
                          label: est.nome,
                          id: est.id,
                        })).filter(e=>e.id == user.idEstado));
                        
        setEstadoModal(estadoData.map(est => ({
          value: est.uf,
          label: est.nome,
          id: est.id,
        })).filter(e=>e.id == user.idEstado)[0]);
        
      }
      else {
        setEstadosModal(estadoData);
        setEstadosSelect(estadoData.map(est => ({
          value: est.uf,
          label: est.nome,
          id: est.id,
        })));
      }
    }

    async function loadRegionais() {
      //setAllRegionais(regionalData);     
      setAllRegionais([
        {id: undefined, nome: 'Todas', uf: ''},
        {id: 1, nome: "Tegra SP", uf: "SP"},
        {id: 2, nome: "Tegra RJ", uf: "RJ"},
        {id: 3, nome: "Tegra Campinas", uf: "SP"},
        {id: 4, nome: "Tegra Lab", uf: "SP"},
      ]);
    }



    async function load() {
      try {
        // carregar corretores da api
        setLoaderActive(true);

        loadCorretores();
        loadGerentes();
        loadEstado();
        loadRegionais();

        const respEsc = await api.get(`Escritorio/GetAllEscritorios`);
        const resEmp = await api.get(`Empreendimento/listallpresencaoplantao`);
        setAllEmpreendimentos(FormatarListaEmpreendimento(resEmp.data, respEsc.data))

        if (user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10){
          const resSup = await api.get(`Empreendimento/listEmpreendimentosNaoVendidosPorSupervisor`, 
          {
            params: {
              tx_apelido: user.apelido,
            },
          },
          );
          setSupervisorEmpreendimentos(FormatarListaEmpreendimento(resSup.data, respEsc.data));
          setSupervisorNomeEmpreendimento(resSup.data.filter(item => item.id !== undefined)
              .map((c: any) => (
                c.nome
              ))
          );
        }        
        setLoaderActive(false);
      } catch (e) {
        console.log(e);
      }
    }
    load();
  }, []);

  const  ListaEmpreendimento = useCallback(async () => {
    if (user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10){
      setEmpreendimentosFiltro(supervisorEmpreendimento);
    }
    else{
      setEmpreendimentosFiltro(allEmpreendimento);
    }
  },[empreendimentos, supervisorEmpreendimento]);

  function FormatarListaEmpreendimento(resp, respEsc){
    const est = [
      { label: 'Rio de Janeiro', value: 19 },
      { label: 'São Paulo', value: 26 },
      { label: 'Campinas', value: 4793 },
    ];


    const arr = resp.map((c: any) => ({
      value: c.id,
      label: c.nome,
      tipo: c.logradouro,
      cidadeid: c.cidadeId,
      estado: est.find(e => e.value === (c.cidadeId === 4793 ? c.cidadeId : c.estadoId))?.label,
      isEscritorio: false,
    }));

    const Escritorio = respEsc.map((x: any) => ({
      value: x.id,
      label: x.nome,
      tipo: null,
      cidadeid: x.cidadeId,
      estado: x.tx_estado,
      isEscritorio: true,
    }));

    var empLista = [...Escritorio, ...arr];
    empLista.sort((a, b) => a.label.localeCompare(b.label, 'pt'));

    const g = groupBy(empLista, 'estado');
    const lst:any[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const e in g) {
      if (g[e].length > 0) {
        lst.push({
          label: e,
          options: g[e],
        });
      }
    }
    return lst;
  }

  useEffect(() => {
    if (!estado.id && !regional.id) {
      setEmpreendimentos(allEmpreendimento);      
    } 
    else if(regional.id){
      setEmpreendimentos(
        (regional.id == 1) || (regional.id == 4)? allEmpreendimento.filter(e => e.label === "São Paulo"):
        regional.id == 3 ? allEmpreendimento.filter(e => e.label === "Campinas"):
        allEmpreendimento.filter(e => e.label === "Rio de Janeiro"),
      );
    }
    else {
      setEmpreendimentos(
        estado.nome === "São Paulo" ? allEmpreendimento.filter(e => e.label === "São Paulo" || e.label === "Campinas"):
        allEmpreendimento.filter(e => e.label === estado.nome),
      );
    }
  }, [allEmpreendimento, estado, regional]);

  const onChangeRegional = useCallback(
    ({ value }) => {
      const findRegional = regionais.find(obj => obj.id === value);
      setRegional(findRegional);
      encontrarCorretorFormRef.current?.setData(
        {
          empreendimento: null
        }
      );

    },
    [regionais],
  );

  const encontrarCorretorFormRef = useRef<FormHandles>(null);

  const onChangeEstado = useCallback(
    ({ value }) => {
      const findEstado = estados.find(est => est.uf === value);
      if (findEstado) {
        const findRegionais = allRegionais.filter(
          findRegional => findRegional.uf === findEstado.uf,
        );

        findRegionais.unshift({
          nome: 'Todas',
          uf: '',
          id: undefined,
        });
        setRegionais(findRegionais);
      }
      setEstado(findEstado);
      setRegional('');
      encontrarCorretorFormRef.current?.setData(
        {
          regional: null, empreendimento: null
        }
      );
    },
    [allRegionais, estados, regional],
  );


  useEffect(() => {  
    if (!regionalSel){
      setEmpreendimentosModal([]);
      setCorretoresModal([]);
    }
    else{
      setSelectCorretores([
        { corretor: { label: 'Todos', value: '' }, nu_order: 0 }]);
      setProduto('');
      setCorretoresModal(
        corretores.filter(e => {
          if(regionalSel.value == 1){
            return e.empresaSigavi === 'Tegra SP';
          }
          else if (regionalSel.value == 3){
            return e.empresaSigavi === 'Tegra Campinas';
          }
          else if (regionalSel.value == 4){
            return e.empresaSigavi === 'Tegra Lab';
          }
          else{
            return e.empresaSigavi === 'Tegra RJ'
          }
        })
      );
      if (regionalSel.value == 1 || regionalSel.value == 4){
        setEmpreendimentosModal(
          empreendimentosFiltro.filter(e => e.label === 'São Paulo')
        );        
      }
      else if (regionalSel.value == 2){
        setEmpreendimentosModal(
          empreendimentosFiltro.filter(e => e.label === 'Rio de Janeiro')
        );
      }
      else if (regionalSel.value == 3){
        setEmpreendimentosModal(
          empreendimentosFiltro.filter(e => e.label === 'Campinas')
        );
      }
    }
  

  }, [empreendimentosFiltro, estadoModal, regionalSel, corretores]);

 

  const getParams = () => {
    if (!encontrarCorretorFormRef.current) {
      return {
        dataInicio: startDate.toDate(),
        dataFim: endDate.toDate(),
        page: currentPage,
      };
    }
    const searchData = encontrarCorretorFormRef.current.getData() as any;
    // eslint-disable-next-line consistent-return
    return {
      corretor: searchData?.corretores?.value,
      dataInicio: startDate.toDate(),
      dataFim: endDate.toDate(),
      empreendimento: searchData?.empreendimento?.isEscritorio ? null : searchData?.empreendimento?.value,
      escritorio: searchData?.empreendimento?.isEscritorio ? searchData?.empreendimento?.value : null,
      estado: searchData?.estado?.id,
      gerente: searchData?.gerente?.value,
      regional: searchData?.regional?.value,
      page: currentPage,
    };
  };

  const doSearch = () => {
    setLoaderActive(true);
    const params = getParams();
    api.get(`evento/EventosList`, { params }).then((res:any) => {
      const {
        data: { lista, nu_total },
      } = res;
      setList([...(currentPage === 1 ? [] : list), ...lista]);
      setTotalPaginas(nu_total);
      setLoaderActive(false);
    });
  };

  const handleLoadMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const handleSearch = useCallback(() => {
    setCurrentPage(1);
    doSearch();
  }, [startDate, endDate]);

  useEffect(() => {
    doSearch();
  }, [currentPage]);

  const exportList = async (): Promise<void> => {
    setLoaderActive(true);
    const params = getParams();

    const response = await api.get(`evento/getRelatorio`, {
      params,
      responseType: 'blob',
    });

    const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
      // IE11
      nav.msSaveOrOpenBlob(
        new Blob([response.data]),
        'MeusCorretores.xlsx',
      );
    } else {
      const urlDown = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDown;
      link.setAttribute('download', 'Presenca_Evento.xlsx');
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(urlDown);
      }, 100);
    }
    setLoaderActive(false);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSubmit = () => {
    if (!tipoevento.value || !produto.value) {
      toast('Preencha todos campos para adicionar', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return;
    }
    
    for(let i = 0; i < selectCorretores.length; i = i + 1) {
      if(selectCorretores[i].corretor.value === ''){
        toast('Preencha todos campos para adicionar', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
        return false;
      }
    }

    let data = {
      dt_data: new Date(Date.UTC(date.toDate().getFullYear(),
      date.toDate().getMonth(),
      date.toDate().getDate(),
      date.toDate().getHours(),
      date.toDate().getMinutes(),
      date.toDate().getSeconds())),
      corretores: selectCorretores.map((sc: any, index: number) => {
        return { id_corretor: sc.corretor.value, nu_order: index + 1 };
      }),
      tipo_evento: tipoevento.value,
      id_produto: produto.isEscritorio ? null : produto.value,
      id_escritorio: produto.isEscritorio ? produto.value : null,
      id_responsavel: user.id
    };
    
    setLoading(true);
    api
      .post(`evento/insertEvento`, data)
      .then(() => {
        setIsOpen(false);
        toast('Presença registrada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
        handleSearch();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Ocorreu um erro ao inserir, tente novamente mais tarde', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
      });
  };

  const handleCancel = () => {
    setDate(moment());
    setCorretor('');
    setProduto('');
    setTipoEvento('');
    setIsOpen(false);
  };

  const handleLimpar = () => {
    if (encontrarCorretorFormRef.current) {
      encontrarCorretorFormRef.current.reset();
      setStartDate(moment().startOf('month'));
      setEndDate(moment());
    }
    handleSearch();
  };

  const addCorretor = () => {
    setSelectCorretores(prevState => [
      ...prevState,
      { corretor: { label: 'Todos', value: '' }, nu_order: 0 },
    ]);
  };

  const removeCorretor = (idx: any, id: any) => {
    id !== ''
      ? setSelectCorretores(
          selectCorretores.filter(el => el.corretor.value !== id),
        )
      : setSelectCorretores(selectCorretores.filter((el, i) => i !== idx));
  };

  const hadleChangeCorretor = (idx: any) => (e: any) => {
    let newArr = [...selectCorretores];
    newArr[idx].corretor = e;
    setSelectCorretores(newArr);
  };

  const handleClickDetail = (item:any) =>{
    console.log('item',item);
    setItem(item);
    setDetail(true);   
  }

  return (
    <Container>
      <SearchContainer>
        <Form
          ref={encontrarCorretorFormRef}
          onSubmit={handleSearch}
          initialData={searchFormInitialData}
        >
          <GridContainer
            className="grid-container"
            style={{
              padding: 0,
              marginLeft: '0px',
              maxWidth: '100%',
              marginRight: '0px',
            }}
          >
            <Row style={{ marginRight: '0px', marginTop: '10px' }}>
              <Col sm={12} md={6} lg={6} xl={4}>
                <label className="custom-label">Datas</label>
                <DateRangePicker
                  isOutsideRange={(day: any) => false}
                  focusedInput={focusedInput}
                  startDate={startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={endDate}
                  minimumNights={0}
                  endDateId="your_unique_end_date_id"
                  onFocusChange={(f: any) => setFocusedInput(f)}
                  onDatesChange={(obj: any) => {
                    setStartDate(obj.startDate);
                    setEndDate(obj.endDate);
                  }}
                />
              </Col>

              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Gerente"
                  name="gerente"
                  onChange={setGerente}
                  options={gerentes}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Corretores"
                  name="corretores"
                  options={corretores}
                />
              </Col>
            </Row>
            <Row style={{ marginRight: '0px', marginTop: '10px' }}>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Estado"
                  name="estado"
                  onChange={onChangeEstado}
                  options={estados.map(est => ({
                    value: est.uf,
                    label: est.nome,
                    id: est.id,
                  }))}
                />
              </Col>

              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Regional"
                  name="regional"
                  onChange={onChangeRegional}
                  options={regionais.map(regional => ({
                    value: regional.id,
                    label: regional.nome,
                  }))}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  className="empreendimentoRelacionado"
                  label="Empreendimento"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  name="empreendimento"
                  options={empreendimentos}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12} xl={12}>
                <FormActions className="flex-start">
                  <div>
                    <TegraButton isAction onClick={handleLimpar}>
                      LIMPAR
                    </TegraButton>
                    <TegraButton isAction onClick={handleSearch}>
                      BUSCAR
                    </TegraButton>
                    <TegraButton isAction onClick={exportList}>
                      EXPORTAR
                    </TegraButton>
                  </div>
                  <TegraButton isAction onClick={() => {setIsOpen(true); ListaEmpreendimento();}}>
                    ADICIONAR
                  </TegraButton>
                </FormActions>
              </Col>
            </Row>
          </GridContainer>
        </Form>
      </SearchContainer>
      <TegraDivider color="#e6e6e6" axis="x" height={1} />
      <CorretoresContainer>
        <CorretoresTable>
          <thead>
            <tr>
              <th>Data</th>
              <th>Tipo Evento</th>
              <th>Produto</th>
              <th>UF</th>
              <th>Corretores</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {list.map(item => (
              <tr key={item.id_parceiro}>
                <td>
                  <span className="caption">Data</span>
                  {moment(item.dt_data).format('DD/MM/yyyy')}
                </td>
                <td>
                  <span className="caption">Tipo Evento</span>
                  {item.tipo_evento == 1 ? "Treinamento" :
                   item.tipo_evento == 2 ? "Oferta" :
                   item.tipo_evento == 3 ? "Reunião" :
                   item.tipo_evento == 4 ? "Ponto de Captação" : "Outros"
                  }
                </td>
                <td>
                  <span className="caption">Produto</span>
                  {item.produto?.nome}
                </td>
                <td>
                  <span className="caption">UF</span>
                  {item.estado?.uf}
                </td>
                <td>
                  <span className="caption">Corretores</span>
                  {item.nu_entradas}
                </td>
                <td className="action">
                {(([8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) <= -1) ||
                  (user.idCargoSigavi != 8 && user.idCargoSigavi != 10 && user.idRegional == item.id_empresaSigavi) ||
                  (user.idCargoSigavi != 8 && user.idCargoSigavi != 10 && user.idRegional == 4 && item.id_empresaSigavi == 1) ||
                  ((user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10) && supervisorNomeEmpreendimento.indexOf(item.produto?.nome) > -1)
                 )  && (                    
                      <TegraButtonExtra
                        onClick={() => handleClickDetail(item)}
                        isAction={false}
                        isExtra
                      >
                        Visualizar
                      </TegraButtonExtra>                    
                  )                                
                }
                </td>

              </tr>
            ))}
          </tbody>
        </CorretoresTable>
        {currentPage < totalPaginas && (
          <ListaAction>
            <TegraButton isAction onClick={handleLoadMore}>
              Carregar mais
            </TegraButton>
          </ListaAction>
        )}
      </CorretoresContainer>
      <Modal
        isOpen={isOpen}
        contentLabel="Presença Plantão"
        portalClassName="modal-presenca"
      >
        <ContainerModal>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </CloseButton>
          <h3>Adicionar presença em evento</h3>

          <Form
            // ref={encontrarCorretorFormRef}
            onSubmit={handleSubmit}
            // initialData={searchFormInitialData}
          >
            <label className="custom-label">Data</label>
            <SingleDatePicker
              isOutsideRange={(day: any) => false}
              focused={focusDate}
              date={date}
              id="start_date_id2"
              onFocusChange={(f: any) => setFocusDate(f.focused)}
              onDateChange={(date: any) => {
                setDate(date);
              }}
            />

              <TegraSelect
                placeholder="Selecione a Regional"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Regional"
                name="regional"
                /*onChange={setRegional}*/
                onChange={setRegionalSel}
                options={ [8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) > -1 && user.idRegional != 1?                        
                  optionsRegional.filter(item => item.value == user.idRegional) :
                  [8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) > -1 && user.idRegional == 1?
                  optionsRegional.filter(item => item.value == 1 || item.value == 4) :
                  optionsRegional}

                  
                value={regionalSel}
              />

            <TegraSelect
              placeholder= {empreendimentosModal.length > 0?'Nenhum' : 'Nenhum produto disponível'}
              noOptionsMessage={() => empreendimentosModal.length > 0? 'Nenhum selecionado' : 'Nenhum produto disponível'}
              label="Produto"
              name="produto"
              onChange={setProduto}
              options={empreendimentosModal}
              value={produto}
            />

            <TegraSelect
              placeholder="Selecione o tipo evento"
              noOptionsMessage={() => 'Nenhum selecionado'}
              label="Tipo evento"
              name="tipoevento"
              onChange={setTipoEvento}
              options={[
                { label: 'Treinamento', value: 1 },
                { label: 'Oferta', value: 2 },
                { label: 'Reunião', value: 3 },
                { label: 'Ponto de Captação', value: 4 },
              ]}
            />

            {selectCorretores.map((val, idx) => {
              return (
                <div className="corretor-select" key={idx}>
                  <TegraSelect
                    placeholder="Todos"
                    noOptionsMessage={() => 'Nenhum selecionado'}
                    label={`Corretor #${idx + 1}`}
                    name="corretor"
                    onChange={hadleChangeCorretor(idx)}
                    value={val.corretor}
                    options={corretoresModal}
                  />

                  {idx === 0 && selectCorretores.length === 1 ? (
                    <button className="plus" onClick={addCorretor} />
                  ) : idx !== selectCorretores.length - 1 ? (
                    <button
                      className="minus"
                      onClick={() => removeCorretor(idx, val.corretor.value)}
                    />
                  ) : (
                    <>
                      <button
                        className="minus"
                        onClick={() =>
                          removeCorretor(
                            idx,
                            val.corretor.value
                          )
                        }
                      />
                      <button className="plus" onClick={addCorretor} />
                    </>
                  )}
                </div>
              );
            })}
            <Espace/>
          </Form>
        </ContainerModal>
        <ListaAction>
          <TegraButton isAction isDisabled={loading} onClick={handleCancel}>
            Cancelar
          </TegraButton>
          <TegraButton isAction isDisabled={loading} onClick={handleSubmit}>
            Salvar
            {loading && <img src={ajaxLoading} alt="Ícone do botão" />}
          </TegraButton>
        </ListaAction>

      </Modal>
    </Container>
  );
};

export default PresencaLista;
