import styled from 'styled-components';

export const Container = styled.div`
  /* max-width: 100%; */

  table {
    border-spacing: collapse;
    border: 1px solid #fff;
    margin-bottom: 16px;

    td,
    th {
      padding: 10px 5px;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: 0.1px;
      text-align: center;
      color: #60605d;
    }

    th {
      background-color: #e6e6e6;
    }
  }
`;

interface StatusColorBox {
  color: string;
}

export const StatusColorBox = styled.td<StatusColorBox>`
  width: 34px;
  height: 34px;
  background-color: ${props => props.color};
  border: 1px solid #eee;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
