import { parseISO, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export default function getFormattedDate(date: string): string {
  const parsedDate = parseISO(date);
  const formattedDate = format(parsedDate, 'dd/MM/yyyy', {
    locale: ptBR,
  });
  return formattedDate;
}
