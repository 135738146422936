import React from 'react';
import ProductMaterials from '../..';

const TreinamentoProduto: React.FC = () => {
  return (
    <ProductMaterials
      title="Treinamentos"
      subtitle="Produtos"
      description="Veja como argumentar melhor sobre cada um dos nossos empreendimentos."
      showColumnTitle
      actions={['open', 'download']}
      apiUrl="MateriaisGerais"
      columns={[
        { label: 'Produto', field: 'tx_produto' },
        { label: 'Treinamento', field: 'nome' },
      ]}
      anchorName="TreinamentoProduto"
      onClickItem={() => localStorage.setItem('SCROLL', '#Treinamentos')}
    />
  );
};

export default TreinamentoProduto;
