import React, { useCallback, useState, useEffect, useRef } from 'react';

import 'react-accessible-accordion/dist/fancy-example.css';

import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Container, Content, Menu, MenuItem, AccordionContent } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraAccordion from '../../components/TegraAccordion';
import TegraDivider from '../../components/TegraDivider';
import api from '../../services/api';

interface TegraQuestion {
  name: string;
  answer: string;
}

interface TegraTopic {
  name: string;
  questions: Array<TegraQuestion>;
}

interface TopicFormData {
  topic: string;
}

interface FAQSection {
  txt_titulo: string;
  txt_itens: string[];
}

interface FAQ {
  id_FAQ: 0;
  tipo: 1;
  secoes: FAQSection[];
  is_deleted: true;
}

const FrequentlyAsked: React.FC = () => {
  const [topics, setTopics] = useState<TegraTopic[]>([]);

  const [selectedTopic, setSelectedTopic] = useState<TegraTopic>(
    {} as TegraTopic,
  );
  // const [selectedQuestions, setSelectedQuestions] = useState<TegraQuestion[]>([]);

  const handleSelectTopic = useCallback(
    name => {
      const findTopic = topics.find(topic => topic.name === name);

      if (findTopic) {
        setSelectedTopic(findTopic);
        // setSelectedQuestions(findTopic.questions);
      }
    },
    [topics],
  );

  useEffect(() => {
    if (topics.length > 0) {
      setSelectedTopic(topics[0]);
    }
  }, [topics]);

  useEffect(() => {
    async function loadPerguntas(): Promise<void> {
      const response = await api.get<FAQ[]>('FAQ');
      const { data } = response;

      setTopics(
        data.map(item => ({
          name: item.id_FAQ.toString(),
          questions: item.secoes.map(secao => ({
            name: secao.txt_titulo,
            answer: secao.txt_itens.toString(),
          })),
        })),
      );
    }

    loadPerguntas();
  }, []);

  return (
    <Container>
      <TegraOpaqueHero
        title="Perguntas frequentes"
        description="Tire suas principais dúvidas sobre o Portal."
      />
      <Content>
        <GridContainer
          className="grid-container"
          style={{ padding: 0, marginLeft: '0px', maxWidth: '100%' }}
        >
          <Row>
            <Col className="select" sm={12} md={4} xl={3}>
              <Menu>
                {topics.map(topic => (
                  <MenuItem
                    key={topic.name.toLowerCase().trim()}
                    onClick={() => handleSelectTopic(topic.name)}
                    selected={topic.name === selectedTopic.name}
                  >
                    <p>{topic.name}</p>
                  </MenuItem>
                ))}
              </Menu>
            </Col>
            <Col className="accordion-col" sm={12} md={8} xl={9}>
              <TegraDivider axis="x" color="#ADADAB" />
              <AccordionContent>
                {selectedTopic.questions && (
                  <TegraAccordion
                    items={selectedTopic.questions.map(question => ({
                      title: question.name,
                      uuid: question.name.toLowerCase().trim(),
                      content: question.answer,
                    }))}
                    style={{ border: 'none' }}
                    // onChange={handleAccordionChange}
                  />
                )}
              </AccordionContent>
            </Col>
          </Row>
        </GridContainer>
      </Content>
    </Container>
  );
};

export default FrequentlyAsked;
