import React from 'react';
import ProductMaterials from '../..';

const Blueprints: React.FC = () => {
  return (
    <ProductMaterials
      title="Plantas"
      subtitle=""
      description="Compartilhe com seus clientes as plantas dos apartamentos e áreas de nossos empreendimentos."
      showColumnTitle={false}
      //actions={['open', 'download']}
      actions={['download']}
      apiUrl="Plantas"
      columns={[{ label: 'Label', field: 'tx_produto' }]}
      /*
      columns={[
        { label: 'Produto', field: 'tx_produto' },
        { label: 'Planta', field: 'nome' },
      ]}
      */
      anchorName="Plantas"
      onClickItem={() => localStorage.setItem('SCROLL', '#Plantas')}
    />
  );
};

export default Blueprints;
