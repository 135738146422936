import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container as GridContainer,
  Row,
  Col,
  Hidden,
  Visible,
} from 'react-grid-system';
import { SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import TegraFooter, { FooterLinkGroup } from '../../components/TegraFooter';
import TegraPresentationHeader, {
  TegraUser,
} from '../../components/TegraPresentationHeader';
import TegraProdutosList, {
  IProduto,
} from '../../components/TegraProdutosList';
import produtoBg from '../../assets/tegra-produto-bg.png';
import TegraFiltro from '../../components/TegraFiltro';
import TegraDivider from '../../components/TegraDivider';
import TegraInput from '../../components/TegraInput';
import { IProductMaterial } from '../ProductMaterials';
import filtrarIcon from '../../assets/tegra-icon-filtrar.svg';
import searchIcon from '../../assets/tegra-icon-search.svg';
import { IFiltroFormData, IEmpreendimento } from '../Products';
import TegraContactBar from '../../components/TegraContactBar';
import api from '../../services/api';

import {
  Container,
  Content,
  SearchBox,
  FiltrarContainer,
  LoadMore,
} from '../Products/styles';
import { TegraCorretor, ContainerTituloBusca, SignatureSioux } from './style';
import { useLoader } from '../../hooks/loader';
import footerLinks from '../../model/footerLinks';
import TegraButton from '../../components/TegraButton';
import { getLogoByTipo, getTituloByTipo } from '../../utils/logo';
import axios from 'axios';
import TegraFiltroHorizontal from '../../components/TegraFiltroHorizontal';


const anoAtual = new Date().getFullYear();
const footerTrademark = '© ' + anoAtual + ' TEGRA Incorporadora. All rights reserved.';


const Corretor: React.FC = () => {
  const { regional, apelido } : any = useParams();
  const filtroFormRef = useRef(null);

  const { setLoaderActive } = useLoader();
  const [filtroShow, setFiltroShow] = useState(false);
  const [user, setUser] = useState<TegraUser>({
    name: '',
    creci: '',
    email: '',
    phone: '',
    image: '',
    description: '',
    tipo: 0,
    isLab: false,
  });
  const [produtos, setProdutos] = useState<IProduto[]>([]);
  const [ajaxTimeout, setAjaxTimeout] = useState<number>(0);
  const [maxPages, setMaxPages] = useState<number>(Number.MAX_VALUE);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [idEstado, setIdEstado] = useState<number | undefined>(undefined);
  const history = useHistory();
  const PAGE_SIZE = 12;
  const [lastSearch, setLastSearch] = useState<IFiltroFormData>(
    {} as IFiltroFormData,
  );

  useEffect(() => {
    setLoaderActive(userLoading || productsLoading);
  }, [userLoading, productsLoading, setLoaderActive]);

  const getProfileData = async () => {
    setUserLoading(true);

    await api
      .get(`Perfil/pagina`, {
        params: {
          regional,
          apelido,
        },
      })
      .then(res => {
        const userResponse = res.data;
        setIdEstado(userResponse.id_empresaSigavi === 2 ? 19 : 26);     
        setUser({
          name: userResponse.tx_apelido,
          creci: userResponse.tx_creci,
          email: userResponse.tx_email,
          phone: userResponse.tx_telefone,
          image: userResponse.user_image,
          description: userResponse.tx_saudacao,
          tipo: userResponse.tipo,
          isLab: userResponse.id_corretorSigavi,
        });
        setUserLoading(false);
      })
      .catch(err => {
        var status = err.response.status;
        if(status === 401)
          window.location.href = 'https://www.tegraincorporadora.com.br/';
      });    
  };

  const doSearch = async (
    filtro?: IFiltroFormData,
    force = false,
    page: number | undefined = undefined,
  ): Promise<void> => {
    const params = new URLSearchParams();

    if (!force && JSON.stringify(filtro) === JSON.stringify(lastSearch)) return;
    const data: IFiltroFormData = filtro || ({} as IFiltroFormData);

    setLastSearch(data);
    const pagina = page || currentPage;

    params.append('nu_pagina', pagina.toString());
    params.append('nu_quantidade', PAGE_SIZE.toString());
    params.append('apelido', apelido);
    params.append('regional', regional);

    if (
      data &&
      (data.aerea ||
        data.preco ||
        data.tipo_produto ||
        data.estagio_obra ||
        data.regioes ||
        data.bairros ||
        data.dormitorios ||
        data.search)
    ) {
      if (data.aerea !== '') {
        const area = JSON.parse(data.aerea as string);
        params.append('nu_areaDe', area.min || 0);
        params.append('nu_areaAte', area.max || 999999);
      }

      if (data.preco !== '') {
        const preco = JSON.parse(data.preco as string);
        params.append('nu_precoDe', preco.min || 0);
        params.append('nu_precoAte', preco.max || 999999);
      }
      if (
        data.tipo_produto !== '' &&
        JSON.parse(data.tipo_produto).length !== 0
      ) {
        JSON.parse(data.tipo_produto).forEach((item: string) =>
          params.append('id_tipo', item),
        );
      }
      if (
        data.estagio_obra !== '' &&
        JSON.parse(data.estagio_obra).length !== 0
      ) {
        JSON.parse(data.estagio_obra).forEach((item: string) =>
          params.append('id_andamento', item),
        );
      }
      if (data.regioes !== '' && JSON.parse(data.regioes).length !== 0) {
        JSON.parse(data.regioes).forEach((item: string) =>
          params.append('regioes', item),
        );
      }
      if (data.bairros !== '' && JSON.parse(data.bairros).length !== 0) {
        JSON.parse(data.bairros).forEach((item: string) =>
          params.append('bairros', item),
        );
      }
      if (
        data.dormitorios !== '' &&
        JSON.parse(data.dormitorios).length !== 0
      ) {
        JSON.parse(data.dormitorios).forEach((item: string) =>
          params.append('dormitorios', item),
        );
      }

      if (data.search) {
        params.append('pesquisa', data.search);
      }
    }
    setProductsLoading(true);
    const response = await api.get('Empreendimento/minhaPagina', {
      params,
    });

    const { items, paginas } = response.data;
    let newProdutos = pagina === 1 ? [] : produtos;
    newProdutos = newProdutos.concat(
      items.map(
        (item: IEmpreendimento) =>
          ({
            nome: item.nome,
            localidade: {
              bairro: item.bairro,
              cidade: item.cidade,
              uf: item.uf,
            },
            id: item.id,
            metragem: item.metragemDescricao,
            aerea: item.distancia,
            destaque: true,
            estagio_obra: item.empreendimentoAndamento,
            imagem: item.imagemBuscaPequena,
            preco: item.valor,
            titulo: item.nome,
            regiao: '',
            informacoes: '',
            tipo: item.empreendimentoAndamentoId,
            slug: item.slug,
            isBanner: item.isBanner,
          } as IProduto),
      ),
    );

    setProdutos(newProdutos);
    setTotalPages(paginas);
    setMaxPages(response.data.paginas);
    if (page !== currentPage) setCurrentPage(page || 1);
    setProductsLoading(false);
  };

  const getPageData = AwesomeDebouncePromise(async (data: IFiltroFormData) => {
    await doSearch(data);
    setCurrentPage(1);
  }, 600);

  const handleFiltroFormSubmit: SubmitHandler<IFiltroFormData> = async filtroData => {
    if (Object.keys(filtroData).length === 9) {
      setCurrentPage(1);
      await getPageData(JSON.parse(JSON.stringify(filtroData)));
    }
  };

  const handleFiltroShow = useCallback((shouldShow: boolean) => {
    setFiltroShow(shouldShow);
  }, []);

  const handleLoadMore = useCallback(() => {
    doSearch(lastSearch, true, currentPage + 1);
  }, [produtos, lastSearch, currentPage, doSearch]);

  useEffect(() => {
    doSearch(undefined);
    getProfileData();
  }, []);

  const goToProduto = useCallback(
    url => {
      if (url.indexOf('http') === -1) history.push(url);
      else window.location.href = url;
    },
    [history],
  );
  return (
    <TegraCorretor>
      <TegraContactBar tel={user.phone} mail={user.email} name={user.name} />
      <TegraPresentationHeader user={user} />
      <Container>
        <Content>
          <GridContainer
            className="grid-container"
            style={{
              padding: 0,
              marginLeft: '0px',
              maxWidth: '100%',
              marginRight: '0px',
            }}
          >
          <ContainerTituloBusca>
            <div className="TituloBusca">
              <p>
                Busque seu imóvel ideal
            </p>
            </div>
          </ContainerTituloBusca>
          <TegraFiltroHorizontal
            idEstado={idEstado !== undefined ? idEstado : 0}
            doSearch={getPageData}
            estadoFiltro={idEstado? true : false}
          />
            <Row style={{ marginRight: '0px' }}>
              {/*              
              <Col sm={12} md={4} xl={4} xxl={3}>
                <Form ref={filtroFormRef} onSubmit={handleFiltroFormSubmit}>
                  {filtroShow && idEstado && (
                    <TegraFiltro
                      doSearch={getPageData}
                      filtroFormRef={filtroFormRef}
                      handleFiltroShow={handleFiltroShow}
                      idEstado={idEstado}
                    />
                  )}
                  <SearchBox>
                    <Hidden sm={false} md xl xxl lg>
                      <FiltrarContainer>
                        <button
                          type="submit"
                          onClick={() => handleFiltroShow(true)}
                        >
                          Filtrar
                          <img src={filtrarIcon} alt="Filtrar" />
                        </button>
                      </FiltrarContainer>
                      <TegraDivider axis="x" height={1} color="#ADADAB" />
                    </Hidden>
                    <TegraInput
                      label="Buscar produto"
                      name="search"
                      formRef={filtroFormRef}
                      icon={searchIcon}
                      realTime
                    />
                  </SearchBox>
                  <Visible sm={false} md xl xxl lg>
                    {idEstado && (
                      <TegraFiltro
                        doSearch={getPageData}
                        filtroFormRef={filtroFormRef}
                        handleFiltroShow={handleFiltroShow}
                        idEstado={idEstado}
                      />
                    )}
                  </Visible>
                </Form>
              </Col>
                    */}
              <Col sm={12} md={12} xl={12} style={{ padding: 0 }}>
                <TegraProdutosList
                  items={produtos}
                  goToProduto={goToProduto}
                  link={process.env.REACT_APP_CORRETOR}
                  apelido={apelido}
                  materiais={false}
                  rodape={false}
                />
                {totalPages > currentPage && (
                  <LoadMore>
                    <TegraButton isAction onClick={handleLoadMore}>
                      Carregar mais
                    </TegraButton>
                  </LoadMore>
                )}
              </Col>
            </Row>
          </GridContainer>
        </Content>
      </Container>
      <TegraFooter
        tegraLogo={getLogoByTipo(user.tipo, user.isLab)}
        title=""
        description={getTituloByTipo(user.tipo)}
        links={footerLinks}
        trademark={footerTrademark}
        isSmall={true}
      />
      <SignatureSioux>
            <span>Desenvolvido por <a href="https://sioux.ag" target="_blank" title="Desenvolvido por Sioux digital 1:1">Agência de marketing digital</a></span>
      </SignatureSioux>
    </TegraCorretor>
  );
};

export default Corretor;
