import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 0 36px; */
  padding: 0 30px;
  margin-top: 80px;
`;

export const VideosContainer = styled.div`
  margin-bottom: 100px;
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-gap: 61px 46px;
  /* padding: 51px; */
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  justify-content: space-between;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }
`;

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #272723;
`;

export const VideoContainer = styled.div`
  display: flex;
  /* align-items: center; */
  padding-bottom: 40px;
  border-bottom: 1px solid #e6e6e6;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const VideoThumbnail = styled.div`
  width: 250px;
  height: 140px;

  @media (max-width: 600px) {
    width: 100%;
  }
  div {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
`;

export const VideoPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: #e6e6e6;
`;

export const VideoInfo = styled.div`
  margin-left: 30px;
  padding-top: 20px;
  max-width: 278px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-left: 0px;
  }

  strong,
  a {
    display: block;
  }
`;

export const VideoInfoHeader = styled.div`
  strong.title {
    font-size: 23px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723;
  }

  span.description {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
    text-transform: capitalize;
  }
`;

export const VideoInfoActions = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-content: center;

  @media (max-width: 768px) {
    justify-content: space-between;
  }

  button.action {
    position: relative;
    /* padding-right: 10px; */

    /* & + button {
      margin-left: 8px;
    } */
    /*
    &:after {
      position: absolute;
      content: '';
      height: 12px;
      width: 1px;
      background: #ebb92d;
      right: 0;
      top: 0.25rem;
    }

    &:last-child:after {
      display: none;
    } */

    background: transparent;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #272723;
    text-decoration: underline;
    border: none;
  }
`;
