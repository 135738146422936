import styled from 'styled-components';
import zIndex from '../../utils/zIndex';

export const FilterContainer = styled.div`
  background-color: #e6e6e6;
  padding-top: 36px;
  padding-bottom: 40.5px;
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: ${zIndex.tegraFiltro}; /* Sit on top */
  left: 0;
  top: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s;

  @media (min-width: 600px) {
    position: relative;
    max-width: 483px;
  }
`;

export const FilterContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 36px; */
  padding: 0px 24px 36px 32px;

  @media (min-width: 600px) {
    display: none;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723;
  }
`;

export const FilterContainerContent = styled.ul`
  padding: 0px 36px 30px;
  list-style: none;

  li {
    padding-top: 20px;
    padding-bottom: 18px;

    border-bottom: 1px solid #fff;

    &:last-child {
      border-bottom: none;
    }

    strong {
      display: block;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: #60605d;
      margin-bottom: 20.9px;
    }

    & > div > div > div {
      margin-top: 18px;
    }
  }
`;

export const FilterActionsContainer = styled.div`
  padding: 39.7px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media (min-width: 600px) {
    padding-top: 0;
    flex-direction: row;
    justify-content: flex-end;
    button {
      display: none;
    }
  }

  span {
    display: block;
    cursor: pointer;
    margin-bottom: 40.5px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #272723;
    text-decoration: underline;
  }

  button {
    width: 100%;
    margin: 0px 36px;
  }
`;

// export const LimparFiltro = styled.span`
//   display: block;
//   right: 0;
// `;
