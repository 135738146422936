import styled from "styled-components";
import colors from "../../../../styles/colors";
import { Link } from "react-router-dom";


export const RankingTablePosition = styled.p`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.featuredBlack};
    line-height: 1;
`;

export const RankingTableImage = styled.img`
    border-radius: 50%;
    height: 46px;
    width: 46px;
    padding: 6px;
    margin: -6px 1rem 0 0;
    @media(min-width: 992px) {
        margin: 0 1rem;
        display: none;
    }
`;

export const RankingDesktopImage = styled(RankingTableImage)`
    @media(min-width: 992px) {
        display: block;
        margin-left: 0;
    }
`;

interface IRankingTableItemProps {
    selfRow?: boolean;
}

export const RankingTableColumn = styled.td`
    padding-top: 1.5rem;
    @media(min-width: 992px) {
        display: table-cell;    
    }
`;

export const RankingTableColumnDesktop = styled.td`
    display: none;
    &:last-of-type{
        margin-left: 1rem;
    }
    @media(min-width: 992px) {
        display: table-cell;    
    }
`

export const RankingColumnHeader = styled(RankingTableColumn)`
    display: flex;
    margin: 0 1.25rem;    
    @media(min-width: 992px) {
        align-items: center;
        margin: 0;
        border-bottom: 1px solid ${colors.lightGrey};
        padding-bottom: 1.375rem;
        display: table-cell;
        width: 60px;
    }
`;

export const RankingTableItem = styled.tr<IRankingTableItemProps>`
    border-left: 10px solid;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.selfRow ? '#ebb92d' : ''};
    ${RankingTableColumnDesktop}, ${RankingColumnHeader}{
        @media(min-width: 992px) {
            border-bottom: ${props => props.selfRow ? 'none' : `1px solid ${colors.lightGrey}`};  
        }        
    }
    @media(min-width: 992px) {
        flex-direction: row;
        padding: 1.375rem 1.375rem 0;
        display: table-row;
        &:after, &:before {
            display: table-cell;
            content: '';
            height: 10px;
            width: 1.2rem;
        }
    }
`;

export const RankingTableDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 1.875rem 0 1.25rem;
    border-bottom: 1px solid ${colors.lightGrey};
    padding-bottom: 1.85rem;
    ${RankingTableColumn}{
        padding-top: 0;
        margin-left: 4rem;
    }
    @media(min-width: 992px) {
        display: none;
    }
`;

export const RankingTableTitle = styled.p`
    font-size: 13px;
    font-weight: bold;
    color: ${colors.primaryColor};
    margin-bottom: 0.4rem;
    line-height: 1;
    @media(min-width: 992px) {
        display: none;
    }
`;

export const RankingTableData = styled.p`
    margin-bottom: 0.4rem;
    line-height: 1;
    color: ${colors.greyBackground};
    @media(min-width: 992px) {
        display: flex;
        align-items: center;
        margin: 0;
    }
`;

export const EquipeLink = styled(Link)`
    text-decoration: underline !important; /* senão rola overlap com o react-tabs, deus sabe porquê... */
    color: ${colors.featuredBlack};
`