/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Form, FormHandles } from '@unform/core';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  Container,
  CorretoresContainer,
  CorretoresDivScroll,
  SearchContainer,
  CorretoresTable,
  ListaAction,
  FormActions,
} from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraSelect from '../../components/TegraSelect';
import openIcon from '../../assets/tegra-icon-open.svg';
import TegraDivider from '../../components/TegraDivider';
import TegraButtonExtra from '../../components/TegraButtonExtra';
import TegraButton from '../../components/TegraButton';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import { useAuth } from '../../hooks/auth';
import { groupBy } from '../../services/helpers';
import 'moment/locale/pt';
import './RegistroVisita.sass';
import { Content } from '../Login/styles';
import dataJson from './dataVisita.json';
import FormRegistroVisita from './FormRegistroVisita';

moment.locale('pt');
moment.updateLocale('pt', {
  weekdaysMin: 'dom._seg._ter._qua._qui._sex._sáb.'.split('_'),
});

/*
const superintendentesModalOutros = dataJson.corretores.filter(
  x => x.tipo === 'superintendente',
);*/

/*
const gerentesModalOutros = dataJson.corretores.filter(
  x => x.tipo === 'gerente',
);

const corretoresModalOutros = dataJson.corretores.filter(
  x => x.tipo === 'corretor',
);
*/
const RegistroLista: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState<any[]>([]);
  const [empreendimentosFiltro, setEmpreendimentosFiltro] = useState<any[]>([]); // Lista de empreendimentos
  const [resultadoLocal, setResultadoLocal] = useState<any[]>([]);
  const [corretores, setCorretores] = useState<any[]>([]); // Lista de Corretores
  const [gerentesVendas, setGerentesVendas] = useState<any[]>([]); // Lista de Gerente Vendas
  const [gerentesParcerias, setGerentesParcerias] = useState<any[]>([]); // Lista de Gerentes Parceria
  const [todosGerentes, setTodosGerentes] = useState<any[]>([]); // Lista Todos os Gerentes
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [isInsert, setIsInsert] = useState<any>(false);// Adicionar
  const [isEdit, setIsEdit] = useState<any>(false);// Editar
  const [focusedInput, setFocusedInput] = useState(false);
  const [superintendenteModal, setSuperintendenteModal] = useState<any>(null); //Gerente selecionado
  const [totalPaginas, setTotalPaginas] = useState<number>(1);

  //constantes para busca/Filtro
  const [superintendentesFiltro, setSuperintendentesFiltro] = useState<any[]>([]);
  const [gerentesFiltro, setGerentesFiltro] = useState<any[]>([]);
  const [itemAlterar, setItemAlterar] = useState<any[]>([]);
  
  const [corretoresFiltro, setCorretoresFiltro] = useState<any[]>([]);
  const [corretorFiltroSel, setCorretorFiltroSel] = useState<any>({label: 'Todos', value: ''});
  const [gerenteFiltroSel, setGerenteFiltroSel] = useState<any>({label: 'Todos', value: ''});
  const [superintendenteFiltroSel, setSuperintendenteFiltroSel] = useState<any>({label: 'Todos', value: ''});
  const [canalFiltroSel, setCanalFiltroSel] = useState<any>({label: 'Todos', value: ''});
  const [regionalFiltroSel, setRegionalFiltroSel] = useState<any>({label: 'Todos', value: ''}); //Regional selecionada

  const [superintendentesModalOutros, setSuperintendentesModalOutros] = useState<any[]>([]);
  const [gerentesModalOutros, setGerentesModalOutros] = useState<any[]>([]);
  const [corretoresModalOutros, setCorretoresModalOutros] = useState<any[]>([]);
  const [canalModal, setCanalModal] = useState<any[]>([]);

  const { setLoaderActive } = useLoader();

  const { user } = useAuth();
 


  useEffect(() => {    
    async function loadCanais() {
      const res = await api.get<any[]>(`CorretorTerceiro/Canais`);

      const rescanal = (user.idCargoSigavi === 5 || user.idCargoSigavi === 14 || user.idCargoSigavi === 26 || user.idCargoSigavi === 27 ) ?
        res.data.filter((x) => x.id === 1 || x.id === 2 || x.id === 3 ) : res.data;
      setCanalModal(
        rescanal.map((d: any) => ({
          label: d.nome,
          value: d.id,
          empresaSigavi: d.empresaSigaviId
        })),        
      );   
    }

    async function loadCorretoresTerceiros() {
      const res = await api.get<any[]>(`CorretorTerceiro/CorretoresTerceirosTipos`);
      setSuperintendentesModalOutros(
        res.data["superintendentes"].map((d: any) => ({
          label: d.tx_apelido,
          value: d.ordem,
          empresaSigavi: d.empresaSigaviId,
          tx_equipe: d.tx_canal,
          id_cargoSigavi: d.cargoSigaviId,
          tx_cargoSigavi: d.tx_canal,
          OpCanal: d.canalId,
          tipo: d.tx_tipo
        })),        
      );          
      setGerentesModalOutros(
        res.data["gerentes"].map((d: any) => ({
          label: d.tx_apelido,
          value: d.ordem,
          empresaSigavi: d.empresaSigaviId,
          tx_equipe: d.tx_canal,
          id_cargoSigavi: d.cargoSigaviId,
          tx_cargoSigavi: d.tx_canal,
          OpCanal: d.canalId,
          tipo: d.tx_tipo
        })),        
      );    
      setCorretoresModalOutros(
        res.data["corretores"].map((d: any) => ({
          label: d.tx_apelido,
          value: d.ordem,
          empresaSigavi: d.empresaSigaviId,
          tx_equipe: d.tx_canal,
          id_cargoSigavi: d.cargoSigaviId,
          tx_cargoSigavi: d.tx_canal,
          OpCanal: d.canalId,
          tipo: d.tx_tipo
        })),        
      );    
    }


    async function loadAllGerentes() {
      const resGerentes = await api.get<any[]>(`Gerente/listAllGerentes`);
      console.log("resGerentes", resGerentes);
      if (user.idCargoSigavi !== 27 && user.idCargoSigavi !== 26){
        setTodosGerentes(
          resGerentes.data.map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
  
        setGerentesFiltro(
          resGerentes.data.map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
  
  
        // Gerente de Vendas
        setGerentesVendas(
          resGerentes.data.filter(item => item.tx_cargoSigavi !== 'Gerente de Parcerias')
            .map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
  
        //Gerente Parcerias
        setGerentesParcerias(
          resGerentes.data.filter(item => item.tx_cargoSigavi === 'Gerente de Parcerias')
            .map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
      }
      else{
        setTodosGerentes(
          resGerentes.data.filter(item => item.tx_equipe === user.apelido)
          .map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
  
        setGerentesFiltro(
          resGerentes.data.filter(item => item.tx_equipe === user.apelido)
          .map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
  
  
        // Gerente de Vendas
        setGerentesVendas(
          resGerentes.data.filter(item => item.tx_cargoSigavi !== 'Gerente de Parcerias' && item.tx_equipe === user.apelido)
            .map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
  
        //Gerente Parcerias
        setGerentesParcerias(
          resGerentes.data.filter(item => item.tx_cargoSigavi === 'Gerente de Parcerias' && item.tx_equipe === user.apelido)
            .map((d: any) => ({
              label: d.tx_apelido.trim(),
              value: d.id_corretorSigavi,
              empresaSigavi: d.tx_empresaSigavi,
              tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
              tx_cargoSigavi: d.tx_cargoSigavi,
              id_cargoSigavi: d.id_cargoSigavi
            })),
        );
  
      }
    }

    async function loadCorretores() {
      const resultado = await api.get(`Visita/CorretoresRegistroVisita`);
      const res = user.idCargoSigavi === 5 ? 
                    resultado.data.filter((m) => (m.tx_equipe ? m.tx_equipe.trim(): m.tx_equipe) === user.apelido.trim()) :
                  user.idCargoSigavi === 26 || user.idCargoSigavi === 27 ?
                    resultado.data.filter((m) => (m.apelidoSuperintendente ? m.apelidoSuperintendente.trim(): m.apelidoSuperintendente) === user.apelido.trim()) :
                    resultado.data;

      setCorretores(
        res.map((d: any) => ({
          label: d.tx_apelido.trim(),
          value: d.id_corretorSigavi,
          empresaSigavi: d.tx_empresaSigavi,
          tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
          tx_cargoSigavi: d.tx_cargoSigavi,
          id_cargoSigavi: d.id_cargoSigavi
        })),
      );
      if (user.idCargoSigavi !== 26){
        setCorretoresFiltro(
          res.map((d: any) => ({
            label: d.tx_apelido.trim(),
            value: d.id_corretorSigavi,
            empresaSigavi: d.tx_empresaSigavi,
            tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
            tx_cargoSigavi: d.tx_cargoSigavi,
            id_cargoSigavi: d.id_cargoSigavi
          })),
        );
      }
    }


    async function loadSuperintendente() {
      const res = await api.get(`Gerente/listSuperintendente`);

      var filtroS = res.data.map((d: any) => ({
        label: d.tx_apelido.trim(),
        value: d.id_corretorSigavi,
        empresaSigavi: d.tx_empresaSigavi,
        tx_equipe: d.tx_equipe ? d.tx_equipe.trim(): d.tx_equipe,
        tx_cargoSigavi: d.tx_cargoSigavi,
        id_cargoSigavi: d.id_cargoSigavi
      }));

      setSuperintendenteModal(filtroS);

      setSuperintendentesFiltro(filtroS);

    }

    async function loadLocaAtendimento() {
      const data = await listaLocal();
      setResultadoLocal(data);
    }

    async function load() {
      console.log("effect");
      try {
        // carregar corretores da api
        setLoaderActive(true);

        loadCorretores();
        loadAllGerentes();
        loadSuperintendente();
        loadLocaAtendimento();
        loadCorretoresTerceiros();
        loadCanais();

        if (user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10){
          const resSup = await api.get(`Empreendimento/listEmpreendimentosNaoVendidosPorSupervisor`,
          {
            params: {
              tx_apelido: user.apelido,
            },
          });
          setEmpreendimentosFiltro(FormatarListaEmpreendimento(resSup.data));  
        }
        else{
          const resEmp = await api.get(`Empreendimento/listEmpreendimentosNaoVendidos`);
          setEmpreendimentosFiltro(FormatarListaEmpreendimento(resEmp.data));  
        }

        setLoaderActive(false);
      } catch (e) {
        console.log(e);
      }
    }
    load();
  }, []);

  function FormatarListaEmpreendimento(resp){
    resp.unshift({
      id: undefined,
      nome: 'Nenhum',
      logradouro: undefined,
    });

    const est = [
      { label: 'Rio de Janeiro', value: 19 },
      { label: 'São Paulo', value: 26 },
      { label: 'Campinas', value: 4793 },
    ];

    const arr = resp.map((c: any) => ({
      value: c.id,
      label: c.nome,
      tipo: c.logradouro,
      cidadeid: c.cidadeId,
      estado: est.find(e => e.value === (c.cidadeId === 4793 ? c.cidadeId : c.estadoId))?.label,
    }));
    const g = groupBy(arr, 'estado');
    const lst:any[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const e in g) {
      if (g[e].length > 0) {
        lst.push({
          label: e,
          options: g[e],
        });
      }
    }
    return lst;
  }

  const onChangeFiltroCorretor = useCallback(
    (e: unknown) => {
      setCorretorFiltroSel(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [corretorFiltroSel],
  );

  // Filtro dos campos de pesquisa.
  // Primeiro é realizado os filtros de regional e Canal nos corretores
  // Segundo é montado a lista de gerentes e superintendentes de acordo com os corretores
  // Terceiro, é aplicado o filtro
  const filterRefresh = (
    regionalSel,
    canalSel,
    superintendenteSel,
    gerenteSel,
  ) => {
    let baseFiltroCorretores = corretores;
    let baseFiltroGerentes = todosGerentes;
    let baseFiltroSuperintendente = superintendenteModal;

    // Regional
    if (regionalSel.value !== '') {
      if (regionalSel.value === 4) {
        setCanalFiltroSel({ label: 'Tegra Lab', value: 4 });
        canalSel = { label: 'Tegra Lab', value: 4 };
      } else if (regionalSel.value !== 4 && canalSel.value !== '') {
        if (canalSel.value === 4) {
          setCanalFiltroSel({ label: 'Todos', value: '' });

          canalSel = { label: 'Todos', value: '' };
        }
      }
      baseFiltroCorretores = corretores.filter(z => z.empresaSigavi === regionalSel.label);
      baseFiltroGerentes = todosGerentes.filter(z => z.empresaSigavi === regionalSel.label);
      baseFiltroSuperintendente = superintendenteModal.filter(z => z.empresaSigavi === regionalSel.label);
    }

    //filtro para o Canal
    if (canalSel.value !== '')
    {
      //Se for Canal cadastrado no Json
      if (canalSel.value >= 5 && canalSel.value != 16){
        baseFiltroSuperintendente = superintendentesModalOutros.filter(e => e.tx_cargoSigavi === canalSel.label);
        baseFiltroGerentes = gerentesModalOutros.filter(e => e.tx_cargoSigavi === canalSel.label);
        baseFiltroCorretores = corretoresModalOutros.filter(e => e.tx_cargoSigavi === canalSel.label);
      }
      else {
        if (canalSel.value === 4) {
          setRegionalFiltroSel({ label: 'Tegra Lab', value: 4 });
          regionalSel = { label: 'Tegra Lab', value: 4 };
          baseFiltroCorretores = corretores;//.filter(z => z.empresaSigavi === regionalSel.label);
          baseFiltroGerentes = todosGerentes;
          baseFiltroSuperintendente = superintendenteModal.filter(z => z.empresaSigavi === regionalSel.label);        
        }
        else if (canalSel.value === 3) // Parcerias
          {
            baseFiltroGerentes = gerentesParcerias;
            baseFiltroSuperintendente = baseFiltroSuperintendente.filter(z => z.tx_cargoSigavi === "Superintendente de Parcerias"
              && (regionalSel.value !== ''?z.empresaSigavi === regionalSel.label:true));  
          }
        else // gerente diferente de Parcerias
          {
            baseFiltroGerentes = gerentesVendas;
            baseFiltroSuperintendente = baseFiltroSuperintendente.filter(z => z.tx_cargoSigavi !== "Superintendente de Parcerias"
            && (regionalSel.value !== ''?z.empresaSigavi === regionalSel.label:true));  
          }
  
        if (regionalSel.value !== ''){
          baseFiltroGerentes = baseFiltroGerentes.filter(z => z.empresaSigavi === regionalSel.label);
        }

        baseFiltroCorretores = baseFiltroCorretores.filter(z =>
          {
            //if (canalSel.value === 0 && z.tx_cargoSigavi === 'Corretor Online'){
            if (canalSel.value === 1  && z.id_cargoSigavi === 22){
              return true;
            } else if (canalSel.value === 16  && z.id_cargoSigavi === 51){
              return true;
            }
            else if (canalSel.value === 2 && z.id_cargoSigavi !== 22 && z.id_cargoSigavi !== 51){
              return true;
            }
            else if (canalSel.value === 3)
            {
              return false;
            }
            else if (canalSel.value === 4 && z.empresaSigavi === 'Tegra Lab')
            {
              return true;
            }
          }
        )
      }
    }

    //Aqui faz o filtro de acordo com o que foi selecionado em Superintendente e Gerente
    if (canalSel.value < 5 || canalSel.value == '' || canalSel.value == 16){
      if (superintendenteSel){
        if (superintendenteSel.value !== ''){
          baseFiltroGerentes = baseFiltroGerentes.filter(ger => ger.tx_equipe === superintendenteSel.label);  

         const thirdArray = baseFiltroCorretores.filter((cor) => {
          return baseFiltroGerentes.some((ger) => {
          return ger.label == cor.tx_equipe;
            });
          });

         baseFiltroCorretores = thirdArray;
        }
      }
      if (gerenteSel){
        if (gerenteSel.value !== '' ){
          baseFiltroCorretores = baseFiltroCorretores.filter(cor => cor.tx_equipe === gerenteSel.label);
        }
      }  
    }


    setSuperintendentesFiltro(baseFiltroSuperintendente);
    setGerentesFiltro(baseFiltroGerentes);
    setCorretoresFiltro(baseFiltroCorretores);

 }

  const onChangeFiltroGerente = useCallback(
    (e) => {
      setCorretoresFiltro([]);
      setCorretorFiltroSel(null);
      setGerenteFiltroSel(e);
      filterRefresh(regionalFiltroSel, canalFiltroSel, superintendenteFiltroSel, e);
    },
    [corretoresFiltro, gerentesFiltro, gerentesFiltro, gerentesVendas, gerentesParcerias],
  );

  const onChangeFiltroSuperintendente = useCallback(
    (e) => {
      console.log('eee',e);
      setSuperintendenteFiltroSel(e);

      setCorretoresFiltro([]);
      setGerenteFiltroSel(null);
      setCorretorFiltroSel(null);

      filterRefresh(regionalFiltroSel, canalFiltroSel, e, null);
    },
    [corretoresFiltro, gerentesFiltro, gerentesFiltro, gerentesVendas, gerentesParcerias],
  );

  const onChangeFiltroRegional = useCallback(
    (e) => {
      console.log("regional", e);
      const searchData = encontrarCorretorFormRef.current?.getData() as any;
      encontrarCorretorFormRef.current?.setData({
        canal:{label: 'Todos', value: ''},
        corretores:{label: 'Todos', value: ''},
        empreendimento:searchData?.empreendimento,
        gerente:{label: 'Todos', value: ''},
        regional:searchData?.regional,
        superintendente:{label: 'Todos', value: ''},
        tipovisita:searchData?.tipovisita,
     })
      setCorretorFiltroSel(null)
      setGerenteFiltroSel(null);
      setSuperintendenteFiltroSel(null);
      setCorretoresFiltro(corretores);
      setGerentesFiltro(todosGerentes);

      setRegionalFiltroSel(e);

      let filtroCanal = canalFiltroSel;
      if (canalFiltroSel.value >= 5){
        filtroCanal = {label: 'Todos', value: ''};
        setCanalFiltroSel(null);
      }
      else if (e.value != 4 && canalFiltroSel.value === 4)
      {

        filtroCanal = {label: 'Todos', value: ''};
        setCanalFiltroSel(filtroCanal);
      }
      filterRefresh(e, filtroCanal, null, null);
    },
    [superintendentesFiltro, canalFiltroSel, regionalFiltroSel, gerentesParcerias, corretoresFiltro,
      corretorFiltroSel, gerenteFiltroSel, superintendenteFiltroSel]
  );

  const onChangeFiltroCanal = useCallback(
    (e) => {
      setCanalFiltroSel(e);
      setCorretorFiltroSel(null)
      setGerenteFiltroSel(null);
      setSuperintendenteFiltroSel(null);

      const searchData = encontrarCorretorFormRef.current?.getData() as any;
      encontrarCorretorFormRef.current?.setData({
        canal:{label: 'Todos', value: ''},
        corretores:{label: 'Todos', value: ''},
        empreendimento:searchData?.empreendimento,
        gerente:{label: 'Todos', value: ''},
        regional:searchData?.regional,
        superintendente:{label: 'Todos', value: ''},
        tipovisita:searchData?.tipovisita,
     })
      setCorretorFiltroSel(null)
      setGerenteFiltroSel(null);
      setSuperintendenteFiltroSel(null);

      filterRefresh(regionalFiltroSel, e, null, null);
    },
    [gerentesFiltro, gerentesVendas, gerentesParcerias, superintendentesFiltro, canalFiltroSel, regionalFiltroSel,
    corretorFiltroSel, gerenteFiltroSel, superintendenteFiltroSel, superintendentesModalOutros, gerentesModalOutros, corretoresModalOutros ]
  );

  const encontrarCorretorFormRef = useRef<FormHandles>(null);

  const getParams = () => {
    if (!encontrarCorretorFormRef.current) {
      return {
        dataInicio: startDate.toDate(),
        dataFim: endDate.toDate(),
        page: currentPage,
      };
    }
    const searchData = encontrarCorretorFormRef.current.getData() as any;
    // eslint-disable-next-line consistent-return
    //console.log('searchData?.canal?.value',searchData?.canal?.value);
    
    var apelidoCorretores = searchData?.canal?.value < 5 || 
                            searchData?.canal?.label === "Todos" || 
                            searchData?.canal?.label === undefined ||
                            searchData?.canal?.label === null;
                            
    return {
      corretor: searchData?.canal?.value >= 5 ? null : searchData?.corretores?.label === "Todos"? null : searchData?.corretores?.value,
      dataInicio: startDate.toDate(),
      dataFim: endDate.toDate(),
      empreendimento: searchData?.empreendimento?.value,
      atendimento: searchData?.atendimento?.value,
      estado: searchData?.estado?.id,
      gerente: 
        user.idCargoSigavi == 5 || user.idCargoSigavi == 14 ? user.id :
          searchData?.canal?.value >= 5 ? null :searchData?.gerente?.label === "Todos"? null : searchData?.gerente?.value,
      tipovisita: searchData?.tipovisita?.label === "Todos"? null :searchData?.tipovisita?.label,
      canal: searchData?.canal?.label === "Todos"? null : searchData?.canal?.label,
      superintendente: 
        user.idCargoSigavi == 27 || user.idCargoSigavi == 26 ? user.id :
          searchData?.canal?.value >= 5 ? null : 
            searchData?.superintendente?.label === "Todos"? null : searchData?.superintendente?.value,
      regional: searchData?.regional?.label === "Todos"? null : searchData?.regional?.value,
      page: currentPage,
      /*
      tx_corretor: searchData?.corretores?.label === "Todos"? null : searchData?.corretores?.label,
      tx_gerente: searchData?.gerente?.label === "Todos"? null : searchData?.gerente?.label,
      tx_superintendente: searchData?.superintendente?.label === "Todos"? null : searchData?.superintendente?.label,
      */
      tx_corretor: apelidoCorretores? null : searchData?.corretores?.label === "Todos"? null : searchData?.corretores?.label,
      tx_gerente: apelidoCorretores? null : searchData?.gerente?.label === "Todos"? null : searchData?.gerente?.label,
      tx_superintendente: apelidoCorretores? null : searchData?.superintendente?.label === "Todos"? null : searchData?.superintendente?.label,
      tx_apelido: user.idCargoSigavi === 8 || user.idCargoSigavi === 18 || user.idCargoSigavi === 10 ? user.apelido : null
    };
  };

  const doSearch = () => {
    setLoaderActive(true);
    const params = getParams();
    api.get(`Visita/VisitaList`, { params }).then((res:any) => {
      const {
        data: { lista, nu_total },
      } = res;
      setList([...(currentPage === 1 ? [] : list), ...lista]);
      setTotalPaginas(nu_total);
      setLoaderActive(false);
    });
  };

  const handleLoadMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const handleSearch = useCallback(() => {
    setCurrentPage(1);
    doSearch();
  }, [startDate, endDate]);

  useEffect(() => {
    doSearch();
  }, [currentPage]);

  const exportList = async (): Promise<void> => {
    setLoaderActive(true);
    const params = getParams();

    const response = await api.get(`Visita/getRelatorio`, {
      params,
      responseType: 'blob',
    });

    const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
      // IE11
      nav.msSaveOrOpenBlob(
        new Blob([response.data]),
        'relatorioVisitas.xlsx',
      );
    } else {
      const urlDown = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDown;
      link.setAttribute('download', 'relatorioVisitas.xlsx');
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(urlDown);
      }, 100);
    }
    setLoaderActive(false);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleExcluir = (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Tem certeza que deseja excluir este registro?')) return;
    setLoaderActive(true);
    api.delete(`Visita/deleteVisita/${id}`).then(() => {
      toast('Visita removida com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'success',
      });
      setCurrentPage(1);
      doSearch();
    })
    .catch((err) => {
      toast("Ops parece que houve algum erro, por favor tente novamente mais tarde.", {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      console.log(err);
    });
  };

  const handleLimpar = useCallback( () => {
    setRegionalFiltroSel({label: 'Todos', value: ''});
    setCanalFiltroSel({label: 'Todos', value: ''});
    setSuperintendenteFiltroSel({label: 'Todos', value: ''});
    setGerenteFiltroSel({label: 'Todos', value: ''});
    setCorretorFiltroSel({label: 'Todos', value: ''});
    setSuperintendentesFiltro(superintendenteModal);
    setGerentesFiltro(todosGerentes);
    if (user.idCargoSigavi === 26){
      setCorretoresFiltro([]);
    }
    else{
      setCorretoresFiltro(corretores);
    }
    
    if (encontrarCorretorFormRef.current) {
      encontrarCorretorFormRef.current.reset();
      setStartDate(moment().startOf('month'));
      setEndDate(moment());
    }
    doSearch();
  },[regionalFiltroSel, canalFiltroSel, superintendenteFiltroSel, gerenteFiltroSel, corretorFiltroSel, superintendentesFiltro, gerentesFiltro, corretoresFiltro]);

  const listaLocal = useCallback(async () => {
    const lstLocal = await api.get(`visita/localAtendimento`);
    const resultadoLocal = lstLocal.data.map((d: any) => ({
      label: d.local,
      value: d.id,
    }));
    return resultadoLocal;
  },[]);

  /*
  useEffect(() => {
    async function fetchData() {
      const data = await listaLocal();
      setResultadoLocal(data);
    }

    fetchData();
  });
*/

  return (
    <Container>
      <TegraOpaqueHero
        title="Registro Visita"
        description="Registre a visita dos corretores nos empreendimentos"
      />
      <Content>
        <SearchContainer>
          <Form
            ref={encontrarCorretorFormRef}
            onSubmit={handleSearch}
            //initialData={searchFormInitialData}
          >
            <GridContainer
              className="grid-container"
              style={{
                padding: 0,
                marginLeft: '0px',
                maxWidth: '100%',
                marginRight: '0px',
              }}
            >
              <Row style={{ marginRight: '0px', marginTop: '10px' }}>
                <Col sm={12} md={6} lg={6} xl={3}>
                    <label className="custom-label">Datas</label>
                    <DateRangePicker
                      isOutsideRange={(day: any) => false}
                      focusedInput={focusedInput}
                      startDate={startDate}
                      startDateId="your_unique_start_date_id"
                      endDate={endDate}
                      minimumNights={0}
                      endDateId="your_unique_end_date_id"
                      onFocusChange={(f: any) => setFocusedInput(f)}
                      onDatesChange={(obj: any) => {
                        setStartDate(obj.startDate);
                        setEndDate(obj.endDate);
                      }}
                    />
                </Col>
              </Row>
              <Row style={{ marginRight: '0px', marginTop: '10px' }}>
                <Col sm={12} md={6} lg={6} xl={3}>
                  <TegraSelect
                    placeholder="Todos"
                    className="empreendimentoRelacionado"
                    label="Empreendimento"
                    noOptionsMessage={() => 'Nenhum selecionado'}
                    name="empreendimento"
                    options={[{label:"Todos",value:""}, ...empreendimentosFiltro]}
                  />
                </Col>
                <Col sm={12} md={6} lg={6} xl={3}>
                      <TegraSelect
                        placeholder="Todos"
                        className="localAtendimento"
                        label="Local de Atendimento"
                        noOptionsMessage={() => 'Nenhum selecionado'}
                        name="atendimento"
                        options={[{label:"Todos",value:""}, ...resultadoLocal]}
                      />
                </Col>
                <Col sm={12} md={6} lg={6} xl={3}>
                  <TegraSelect
                    placeholder="Todos"
                    noOptionsMessage={() => 'Nenhum selecionado'}
                    label="Tipo de Visita"
                    name="tipovisita"
                    options={[{label:"Todos",value:""}, ...dataJson.tipovisita]}
                  />
                </Col>
                { 
                  (
                    user.idCargoSigavi !== 5 &&
                    user.idCargoSigavi !== 14 &&
                    user.idCargoSigavi !== 26 &&
                    user.idCargoSigavi !== 27 
                  ) && 
                  (
                    <Col sm={12} md={6} lg={6} xl={3}>
                      <TegraSelect
                        placeholder="Todos"
                        noOptionsMessage={() => 'Nenhum selecionado'}
                        label="Regional"
                        name="regional"
                        options={[{label:"Todos",value:""}, ...dataJson.regional]}
                        onChange={(e) => onChangeFiltroRegional(e)}
                        value={regionalFiltroSel}
                      />
                    </Col>                
                  )
                }

                {
                  (
                    user.idCargoSigavi !== 14 
                  ) &&
                  <Col sm={12} md={6} lg={6} xl={3}>
                    <TegraSelect
                      placeholder="Todos"
                      noOptionsMessage={() => 'Nenhum selecionado'}
                      label="Canal"
                      name="canal"
                      options= {regionalFiltroSel === null || regionalFiltroSel.value === '' ?
                                    [{label:"Todos",value:""}, ...canalModal] :
                                  regionalFiltroSel.value === 4 ?
                                    canalModal.filter(x=> x.empresaSigavi === 4) :
                                  regionalFiltroSel.value === 3 ?
                                    [{label:"Todos",value:""}, ...canalModal.filter(x=> x.empresaSigavi === 3 || x.empresaSigavi === 0)] :
                                    regionalFiltroSel.value === 1 ?
                                    [{label:"Todos",value:""}, ...canalModal.filter(x=> x.empresaSigavi === 1 || x.empresaSigavi === 0)] :
                                    regionalFiltroSel.value === 2 ?
                                    [{label:"Todos",value:""}, ...canalModal.filter(x=> x.empresaSigavi === 2 || x.empresaSigavi === 0)] :
                                    [{label:"Todos",value:""}, ...canalModal.filter(x=> x.empresaSigavi === 0)]}                               
                      onChange={(e) => onChangeFiltroCanal(e)}
                      value={canalFiltroSel}
                    />
                  </Col>
                }

                {
                  (
                    user.idCargoSigavi !== 27 && 
                    user.idCargoSigavi !== 26 && 
                    user.idCargoSigavi !== 14 && 
                    user.idCargoSigavi !== 5
                  ) && (
                  <Col sm={12} md={6} lg={6} xl={3}>
                    <TegraSelect
                      placeholder="Todos"
                      noOptionsMessage={() => 'Nenhum selecionado'}
                      label="Superintendente/ Diretor"
                      name="superintendente"

                      options={[{label:"Todos",value:""}, ...superintendentesFiltro]}
                      onChange={(e) => onChangeFiltroSuperintendente(e)}
                      value={superintendenteFiltroSel}
                    />
                  </Col>
                )
                }

                {
                  (
                    user.idCargoSigavi !== 5 &&
                    user.idCargoSigavi !== 14  
                  ) && (
                  <Col sm={12} md={6} lg={6} xl={3}>
                  <TegraSelect
                    placeholder="Todos"
                    noOptionsMessage={() => 'Nenhum selecionado'}
                    label="Gerente"
                    name="gerente"
                    options={[{label:"Todos",value:""}, ...gerentesFiltro]}
                    onChange={(e) => onChangeFiltroGerente(e)}
                    value={gerenteFiltroSel}
                  />
                </Col>
                )
                }

                {
                  (
                    user.idCargoSigavi !== 27 &&
                    user.idCargoSigavi !== 14
                  ) && (
                  <Col sm={12} md={6} lg={6} xl={3}>
                    <TegraSelect
                      placeholder="Todos"
                      noOptionsMessage={() => 'Nenhum selecionado'}
                      label="Corretores"
                      name="corretores"
                      options={[{label:"Todos",value:""}, ...corretoresFiltro]}
                      onChange={(e) => onChangeFiltroCorretor(e)}
                      value={corretorFiltroSel}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} xl={12}>
                  <FormActions className="flex-start">
                    <div>
                      <TegraButton isAction onClick={handleLimpar}>
                        LIMPAR
                      </TegraButton>
                      <TegraButton isAction onClick={handleSearch}>
                        BUSCAR
                      </TegraButton>
                      <TegraButton isAction onClick={exportList}>
                        EXPORTAR
                      </TegraButton>
                    </div>
                    {
                      ( user.idCargoSigavi != 5 &&  //Gerente de Venda
                        user.idCargoSigavi != 14 && //Gerente de Parceria
                        user.idCargoSigavi != 26 && //Diretor de Venda
                        user.idCargoSigavi != 27 //Superintendente de Parcerias
                      ) && (
                      <TegraButton isAction onClick={async () =>
                        {
                          setIsInsert(true);
                        }}>
                        ADICIONAR
                      </TegraButton>
                      )
                    }
                  </FormActions>
                </Col>
              </Row>
            </GridContainer>
          </Form>
        </SearchContainer>
        <TegraDivider color="#e6e6e6" axis="x" height={1} />
        <CorretoresContainer>
          <CorretoresDivScroll>
          <CorretoresTable>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Produto</th>
                  <th>Visita</th>
                  <th>Regional</th>
                  <th>Canal</th>
                  <th>Gerente Presente</th>
                  <th>Coordenador Presente</th>
                  <th>Local de Atendimento</th>
                  <th>Corretor</th>
                  <th>Gerente</th>
                  <th>Superintendente/ Diretor</th>
                  <th style={{minWidth:"220px"}}/>
                </tr>
              </thead>
              <tbody>
                {list.map(item => (
                  <tr key={item.id_registroVisita}>
                    <td>
                      <span className="caption">Data</span>
                      {moment(item.dt_data).format('DD/MM/yyyy')}
                    </td>
                    <td>
                      <span className="caption">Produto</span>
                      {item.produto?.nome}
                    </td>
                    <td>
                      <span className="caption">Visita</span>
                      {item.tx_tipoVisita}
                    </td>
                    <td>
                      <span className="caption">Regional</span>
                      {item.regional?.tx_empresaSigavi}
                    </td>
                    <td>
                      <span className="caption">Canal</span>
                      {item.tx_canal}
                    </td>
                    <td>
                      <span className="caption">Gerente Presente</span>
                      {item.isGerentePresente? 'Sim' : 'Não'}
                    </td>
                    <td>
                      <span className="caption">Gerente Presente</span>
                      {item.isCoordenadorPresente? 'Sim' : 'Não'}
                    </td>                    
                    <td>
                      <span className="caption">Local de Atendimento</span>
                      {item.localAtendimento}
                    </td>
                    <td>
                      <span className="caption">Corretor</span>
                      {item.corretor?.tx_apelido}
                    </td>
                    <td>
                      <span className="caption">Gerente</span>
                      {item.gerente?.apelidoGerente
                      }
                    </td>
                    <td>
                      <span className="caption">Superintendente/ Diretor</span>
                      {item.superintendente?.apelidoSuperintendente
                      }
                    </td>
                    <td className='action'>
                      {
                      ((
                       user.idCargoSigavi === 8 || // Coordenador de Produto
                       user.idCargoSigavi === 10 || // Supervisor de Venda
                       user.idCargoSigavi === 18 // Coordenador de Produto 2
                      ) || 
                      (
                        item.id_responsavel === user.id && 
                        (
                          user.idCargoSigavi != 5 &&  //Gerente de Venda
                          user.idCargoSigavi != 14 && //Gerente de Parceria
                          user.idCargoSigavi != 26  //Diretor de Venda
                        )
                      )) && (
                        <div>
                          <TegraButtonExtra
                            onClick={() => {
                              setItemAlterar(item);
                                setIsEdit(true);
                            }}
                            isAction={false}
                            isExtra
                            icon={openIcon}
                          >
                            Editar
                          </TegraButtonExtra>
                          <TegraButtonExtra
                            isAction={false}
                            isExtra
                            onClick={() => handleExcluir(item.id_registroVisita)}
                            icon={openIcon}
                          >
                            Excluir
                          </TegraButtonExtra>
                        </div>
                      )}

                    </td>
                  </tr>
                ))}
              </tbody>
            </CorretoresTable>
          </CorretoresDivScroll>
          {currentPage < totalPaginas && (
            <ListaAction>
              <TegraButton isAction onClick={handleLoadMore}>
                Carregar mais
              </TegraButton>
            </ListaAction>
          )}
        </CorretoresContainer>
        { (isInsert || isEdit) &&
          <FormRegistroVisita
            tipoOperacao={isInsert?1:2}
            empreendimentos={empreendimentosFiltro}
            corretores={corretores}
            gerentesVendas={gerentesVendas}
            gerentesParcerias={gerentesParcerias}
            todosGerentes={todosGerentes}
            superintendentes={superintendenteModal}   
            itemAlterar={itemAlterar}
            superintendentesModalBase={superintendentesModalOutros}
            gerentesModalBase={gerentesModalOutros}
            corretoresModalBase={corretoresModalOutros}
            canalModal={canalModal}
            onChange={() => {setIsInsert(false); setIsEdit(false);handleSearch(); setItemAlterar([]);}}       
          ></FormRegistroVisita>
        }
      </Content>
    </Container>
  );
};

export default RegistroLista;
