import styled, { keyframes } from 'styled-components';
import colors from '../../../../styles/colors';
import NavItem from '../../NavItem';
import externalIcon from '../../../../assets/open-external-icon.svg';

interface NavMenuContainerProps {
  active: boolean;
  closing: boolean;
}

export const NavClose = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const NavCloseIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
`;

const fadeInAnimation = keyframes`
    0% { background: unset; }
    100% { background: ${colors.blackBackdrop} }
`;
const fadeOutAnimation = keyframes`
    0% { background: ${colors.blackBackdrop}; }
    100% { background: unset }
`;

export const NavMenuContainer = styled.div<NavMenuContainerProps>`
  display: ${props => (props.active ? 'flex' : 'none')};
  position: absolute;
  width: 100vw;  
  max-width: 100%;
  top: 0;
  left: 0;
  max-height: 100vh;
  height: 100%;
  justify-content: flex-end;
  transition: all 1s ease-out;
  animation-name: ${props =>
    props.closing ? fadeOutAnimation : fadeInAnimation};
  background: ${colors.blackBackdrop};
  animation-duration: 0.5s;
  @media (min-width: 992px) {
    display: flex;
    position: relative;
    transition: none;
    min-height: unset;
    height: unset;
  }
`;



const slideInAnimation = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
`;

const slideOutAnimation = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(100%); }
`;

interface NavMenuWrapperProps {
  closing: boolean;
}

export const NavMenuWrapper = styled.div<NavMenuWrapperProps>`
  border-top: 10px solid ${colors.primaryColor};
  min-width: 300px;
  background-color: ${colors.greyBackground};
  padding: 1.5rem;
  animation-name: ${props =>
    props.closing ? slideOutAnimation : slideInAnimation};
  animation-duration: 0.5s;
  overflow: auto;
  @media (min-width: 992px) {
    overflow: unset;
    animation: none;
    width: 100%;
    padding: 0 1.5rem;
    border-top: none;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const NavMainItem = styled(NavItem)`
  @media (min-width: 992px) {
    display: ${props => (props.menuItem.featured ? 'none' : 'block')};
    &::after{
      content: '';
      height: 3px;
      width: calc(80% - 1rem);
      margin: 0px calc(10% + 1rem) 0 10%;
      bottom: -24px;
      background-color:  ${props => props.menuItem.active ? colors.inputBorder : 'transparent'};
      position: absolute;
    }
  }  
  @media (max-width: 992px) {
    &.linkExternal {
      position: relative;
      li{
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: flex-end;
      }
    }
  
    &.linkExternal li::after {
      content: '';
      display:block;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(${externalIcon});
    }
  }
`;

export const NavMenuItemContainer = styled.ul`
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
