import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
  position: relative;
  .isLoadingTorre{
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const Content = styled.div`
  margin: 40px auto 0;
  /* padding: 0 33px; */
  position: relative;
  padding: 0 51px;
  max-width: 1280px;

  .divider {
    margin-top: 18px;
  }

  section {
    margin-bottom: 50px;

    .select {
      max-width: 248px;

      @media (max-width: 600px) {
        max-width: 100%;
      }
    }
  }
`;

export const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
`;

export const SectionContent = styled.div`
  margin: 40px 0;
`;

export const Espelho = styled.div`
  display: flex;
  justify-content: flex-start;

  & > div:first-child {
    margin-right: 80px;
    width: 350px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    & > div:first-child {
      margin-right: 0px;
    }

    & > div:last-child {
      margin-top: 80px;
    }
  }
`;

export const Implantacao = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    /* height: 802px; */
    width: 1202px;
    max-width: 100%;
  }
`;

export const FichaTecnica = styled.div`
  ul {
    text-align: left;
    list-style: none;
    li {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1;
      letter-spacing: normal;
      color: #60605d;
      margin: 15px 0;
    }
  }
`;

export const TabelaProduto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
