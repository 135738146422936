import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
} from 'react';

import { Link } from 'react-router-dom';
import { OptionBox, Option, Container } from './styles';

interface IOption {
  name: string;
  label: string;
  link: any;
}

interface TegraOptionsLinksProps extends InputHTMLAttributes<HTMLInputElement> {
  options: IOption[];
  url: string;
  path: string;
  defaultSelected?: string;
}

const TegraOptionsLinks: React.FC<TegraOptionsLinksProps> = ({
  options,
  url,
  defaultSelected = '',
}) => {
  const [selected, setSelected] = useState(defaultSelected);

  const handleSelected = useCallback(link => {
    setSelected(link);
  }, []);

  return (
    <Container>
      <OptionBox>
        {options.map(option => (
          <Link
            onClick={() => handleSelected(option.link)}
            key={option.name}
            to={`${
              url.charAt(url.length - 1) !== '/' ? url : url.slice(0, -1)
            }/${option.link}`}
          >
            <Option selected={selected === option.link}>{option.label}</Option>
          </Link>
        ))}
      </OptionBox>
    </Container>
  );
};

export default TegraOptionsLinks;
