import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;

  &.formContainer {
    background: #e6e6e6;
    padding: 20px 30px;

    button {
      margin-left: auto;
      margin-top: 20px;
    }
  }
`;

export const DadosList = styled.ul`
  padding: 20px 30px;
  padding-top: 0;
`;

export const Subtitle = styled.h2`
  font-weight: bold;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #fff;
`;
export const RowDoc = styled.div`
  border-bottom: 2px solid #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  &:last-of-type {
    border-bottom: 0px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
`;
export const ErrorsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    color: #fff;

    & + p {
      margin-top: 5px;
    }
  }

  p {
    padding: 15px;
    background: #d84128;
  }

  p.warning {
    background: #ebb92d;
  }

  p.success {
    background: #11cc55;
  }
`;

export const BoxTitle = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
`;

export const BoxSubTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
  display: block;

  @media (max-width: 768px) {
    margin-top: 5px;
  }

  &.isAlt {
    opacity: 0;

    @media (min-width: 1288px) {
      display: none;
    }

    @media (max-width: 947px) {
      display: none;
    }
  }
`;

export const DadosItem = styled.li`
  display: flex;
  justify-content: space-between;

  padding-top: 20px;
  padding-bottom: 20px;

  border-bottom: 2px solid #fff;

  &:last-child {
    border-bottom: 2px solid #fff;
  }

  @media (max-width: 600px) {
    padding-bottom: 44px;
  }
`;

export const DadosItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-right: 7%;

  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
    max-width: 100%;
  }

  p {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  strong {
    display: block;
    margin-top: 16px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723;

    @media (max-width: 600px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .fileNameAndView {
    display: flex;

    span {
      display: block;
      align-self: flex-end;

      margin-bottom: 0.2rem;

      cursor: pointer;
      margin-left: 19px;

      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;

      line-height: 1.5;
      letter-spacing: 0.12px;

      text-align: right;
      text-decoration: underline;
      color: #60605d;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      span {
        margin-left: 0px;
        margin-top: 5px;
        align-self: flex-start;
      }
    }
  }
`;

export const DadosItemAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  & > * {
    display: block;
  }

  button {
    background: none;
    border: none;
    text-decoration: underline;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #60605d;
    display: flex;
    align-items: center;
    align-content: center;

    @media (max-width: 600px) {
      bottom: -10px;
      right: 0;
      position: absolute;

      &.isFile {
        bottom: 0.5rem;
      }
    }

    img {
      margin-left: 6px;
    }
  }
`;

export const Content = styled.div`
  margin: 52px auto 0;
  width: 894px;
  max-width: 100%;
`;

export const ProfileBox = styled.div`
  position: relative;
  width: 894px;
  max-width: 100%;
  background-color: #e6e6e6;
  padding-bottom: 40.5px;
`;

export const ProfileTabs = styled.div`
  background-color: #e6e6e6;
  padding: 0px 30px;
  border-bottom: 1px solid #adadab;
  color: #60605d;

  span {
    cursor: pointer;
    display: inline-block;
    padding: 15px;
    transform: translate(0px, 1px);

    &.selected {
      color: #000000;
      border-bottom: 4px solid #ebb92d;
    }
  }
`;

interface ProfileAvatarContainer {
  canUpdate: number;
}
export const ProfileAvatar = styled.label<ProfileAvatarContainer>`
  margin-left: 30px;
  position: relative;
  display: flex;
  cursor: pointer;

  input {
    display: none;
  }

  img {
    border-radius: 50%;
    margin-top: -60px;
    width: 123px;
    height: 123px;
  }

  div.trocarFoto {
    background: #e6e6e6;
    color: #60605d;
    position: absolute;
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    margin-top: -60px;
    width: 123px;
    height: 123px;
  }

  ${props =>
    props.canUpdate &&
    css`
      &:hover {
        div.trocarFoto {
          opacity: 0.5;
        }
      }
    `}
`;

export const ProfileFilesBox = styled.div`
  background-color: #e6e6e6;
  padding: 0 30px;

  li:first-child {
    border: none;
  }
`;
export const MensagemSucesso = styled.div`
  margin-top: 20px;
  background: #1f9e13;
  padding: 15px;
  color: #fff;
  text-align: center;
  margin: 0 30px;
`;
export const MensagemErro = styled.div`
  margin-top: 20px;
  background: #d84128;
  padding: 15px;
  color: #fff;
  text-align: center;
  margin: 0 30px;
`;
export const ProfileBoxActions = styled.div`
  display: flex;
  padding: 40px 30px 0;
  justify-content: center;

  @media (max-width: 600px) {
    button {
      flex: 1;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  & .icon{
    position: absolute;
    font-size: 25px;
    right: 20px;
    bottom: 9px;
  }
`
export const CloseButton = styled.div`
  position: absolute;
  right: 40px;
  top: 35px;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 20px;
  }
`;


export const ImprimirMsg = styled.div`
  h3{
    font-size: 20px;
    margin-bottom: 20px;
  }

  p{
    line-height: 1.8;
    color: #60605d;
  }
`;

export const ListaAction = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 41px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      flex: 1;
      width: 100%;
    }
  }
`;

export const ContainerSuperintendente = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  .selectSuperintendente{
    width: 45%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .selectSuperintendente{
      width: 100%;
    }
  }
`;