/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
  RefObject,
  MouseEvent,
} from 'react';
import { useField, FormHandles } from '@unform/core';
import api, { baseURL } from '../../services/api';
import { toast } from 'react-toastify';

import { Container } from './styles';
import uploadIcon from '../../assets/tegra-upload-icon.svg';
import uploadCancelIcon from '../../assets/tegra-upload-cancel-icon.svg';
import ajaxLoading from '../../assets/ajax-loader-white.gif';

interface Props {
  name: string;
  setErrors?: Function;
  formRef: RefObject<FormHandles>;
  preview: string;
  setPreview: Function;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const TegraFileInputEspacoGerente: React.FC<InputProps> = ({
  name,
  formRef,
  setErrors,
  preview,
  setPreview,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { registerField } = useField(name);
  // const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePreview = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) {
        setPreview('');
        return;
      }

      if (!formRef.current) {
        throw new Error('Sem form');
      }

      setErrors && setErrors([]);
      setLoading(true);
      const multipart = new FormData();
      multipart.append('arquivo', file);
      api
        .post(`Tarefa/upload`, multipart, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then(res => {
          setLoading(false);
          setPreview(res.data);
        })
        .catch(err => {
          setLoading(false);
          if (err.response) {
            const { erro } = err.response.data;
            toast(erro, {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              type: 'error',
            });

          } else {
            toast('Arquivo inválido', {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              type: 'error',
            });
          }
        });
    },
    [formRef, setErrors, setPreview],
  );

  const handleCancel = useCallback(() => {
    if (!inputRef.current) {
      throw new Error('No ref');
    }
    inputRef.current.value = '';
    setPreview('');
  }, [setPreview]);

  useEffect(() => {
    registerField({
      name: 'arquivos',
      ref: inputRef,
      getValue(ref: any) {
        return ref.current.value;
      },
    });
  }, []);

  const download = (e: MouseEvent): void => {
    e.preventDefault();
    api
      .get(`${baseURL}Tarefa/download/${preview}`, {
        responseType: 'blob',
      })
      .then(response => {
        const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
          // IE11
          nav.msSaveOrOpenBlob(new Blob([response.data]), preview);
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', preview);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
          }, 100);
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <Container inputHasValue={!!preview}>
      {preview && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" onClick={download}>
          {preview}
        </a>
      )}
      <div className="upload-btn-wrapper">
        {!preview && !loading && (
          <>
            <button type="button" className="uploadBtn">
              <span>Enviar arquivo</span>
              <img src={uploadIcon} alt="Upload" />
            </button>
          </>
        )}
        {loading && <img src={ajaxLoading} alt="Carregando" />}
        <input type="text" ref={inputRef} value={preview} {...rest} />
        <input type="file" onChange={handlePreview} {...rest} />
      </div>
      {preview && (
        <button type="button" className="cancelBtn" onClick={handleCancel}>
          <img src={uploadCancelIcon} alt="Upload" />
        </button>
      )}
    </Container>
  );
};

export default TegraFileInputEspacoGerente;
