import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { Container, Content } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraBlogOverview from '../../components/TegraBlogOverview';

import placeholderBlogThumbnail from '../../assets/tegra-blog-item-thumbnail.png';
import TegraButton from '../../components/TegraButton';
import TegraBlogDetailsCalendario from '../../components/TegraBlogDetailsCalendario';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';

interface IBlogItem {
  imagem?: any;
  titulo: string;
  subtitulo: string;
  conteudo: string;
  slug: string;
  data: string;
  link: string;
  id: string;
  tipo: number;
  assinadoClara: boolean;
}

interface IDestaque {
  imagem?: any;
  titulo: string;
  categoria: 'Premicações e Metas' | 'Novidades' | 'Calendário de Eventos';
  link: string;
  data: string;
  id: string;
}

const mockDestaques: IDestaque[] = [
];

interface ItemCalendario {
  id_novidade: number;
  id_tipo: number;
  tx_titulo: string;
  tx_subtitulo: string;
  tx_descricao: string;
  tx_chamada: string;
  data_inicio: string;
  nu_estado: number;
  nu_publico: number;
  tx_thumb: number;
  tx_pdf: string;
  tx_pdfnome: string;
  ic_assinadoClara: boolean;
}

type ResponseCalendario = ItemCalendario;

const CalendarioTrabalhoDetalhes: React.FC = () => {
  const [blogItem, setBlogItem] = useState<IBlogItem>({} as IBlogItem);
  const [destaques, setDestaques] = useState<IDestaque[]>([]);

  const { slug } : any = useParams();
  const { setLoaderActive } = useLoader();

  useEffect(() => {
    // chamar api

    // chamar api para pegar itens
    async function loadItem(): Promise<void> {
      setLoaderActive(true);
      const { data } = await api.get<ResponseCalendario>('CalendarioTrabalho/:idall', {
        params: {
          id: slug,
        },
      });

      const item = {
        titulo: data.tx_titulo,
        subtitulo: data.tx_subtitulo,
        id: `${data.id_novidade}`,
        imagem: data.tx_thumb,
        pdf: data.tx_pdf,
        pdfnome: data.tx_pdfnome,
        data: data.data_inicio,
        conteudo: data.tx_descricao,
        link: `${data.id_novidade}`,
        slug,
        tipo: data.id_tipo,
        assinadoClara: data.ic_assinadoClara,
      };
      setBlogItem(item);
      setLoaderActive(false);
    }

    loadItem();

    setDestaques(mockDestaques);
  }, [slug, setLoaderActive]);

  return (
    <Container>
      <TegraOpaqueHero
        title="Calendário de Eventos"
        description="Fique por dentro das datas importantes da Tegra para suas atividades."
      />

      <Content>
        <TegraBlogDetailsCalendario blogItem={blogItem} />
      </Content>
    </Container>
  );
};

export default CalendarioTrabalhoDetalhes;
