import React, { useEffect } from 'react';

import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

const SignInReport: React.FC = () => {
  const { relatorio, regional, token, uf } : any = useParams();
  const { signInAdm } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function doLogin() {
      if (relatorio && regional && token && uf) {
        await signInAdm({
            regional,
            token,
            uf,
        });
      }
      history.push(`/dashboard/relatorio/` + relatorio);
    }
    doLogin();
  }, [history, regional, signInAdm, token]);

  return <div>Loading..</div>;
};

export default SignInReport;
