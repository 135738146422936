import styled from 'styled-components';
import zIndex from '../../../utils/zIndex';

export const Container = styled.div`
  padding-right: 30px;
  padding-bottom: 80px;

  @media (max-width: 1000px) {
    padding-left: 30px;
  }
`;

export const PhotosLinksContainer = styled.div`
  margin-bottom: 80px;
  @media (min-width: 768px) {
    padding: 0 30px;
  }
`;

export const PhotosLinksContainerHeader = styled.div`
  margin: 32px 0 19px;

  h2 {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.36px;
    text-align: left;
    color: #272723;
    margin-bottom: 11px;
  }
`;

export const Title = styled.h2`
  display: none;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #272723;
  padding: 0 36px;
  margin-bottom: 26px;
  margin-top: 36px;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const PhotosLinksContainerContent = styled.div`
  height: 420px;
  overflow-y: auto;
  display: grid;
  margin-top: 20px;

  ::-webkit-scrollbar {
    width: 19px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border: solid 1px #adadab;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 11px;
    height: 40px;
    background-color: #ebb92d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ebb92d;
    opacity: 0.7;
  }
`;

export const PhotosLinksContainerActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 13px;
  }
`;

export const ImageGalleryContainer = styled.div`
  /* height: 801px; */

  .thumb {
    position: relative;
    img {
      height: 70px;
      width: 100%;
    }

    input {
      position: absolute;
      top: 2px;
      right: 2px;
      height: 13px;
      width: 13px;
      border: 1px solid #ccc;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .geekmark {
        background-color: #ebb92d;
      }
    }

    .geekmark {
      position: absolute;
      top: 2px;
      right: 2px;
      height: 13px;
      border: 1px solid #ccc;
      width: 13px;
      background-color: #ffffff;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }

    input:checked ~ .geekmark:after {
      display: block;
    }

    .geekmark:after {
      left: 4px;
      width: 2px;
      height: 6px;
      top: 1px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .image-gallery-slides {
    height: 600px;
  }

  .img-gallery,
  .image-gallery-slide,
  .image-gallery-image,
  .image-gallery-content {
    object-fit: cover;
  }

  .image-gallery-slide::after {
    position: absolute;
    content: '';
    height: 179px;
    @media (max-width: 768px) {
      height: 51px;
    }
    background-image: linear-gradient(
      to bottom,
      rgba(39, 39, 35, 0),
      rgba(39, 39, 35, 0.82) 81%
    );
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .image-gallery-slide {
    border: solid 1px #adadab;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 2000px) {
      height: 100%;
    }
  }

  .image-gallery-image {
    width: 100%;
    width: 100%;
  }

  .image-gallery-thumbnails {
    padding: 0px;
    margin-top: 10px;
  }

  .image-gallery-thumbnail {
    width: 110px;
    height: 78px;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 4px solid #ebb92d;
    margin-bottom: 10px;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 65px;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
  }

  .image-gallery-slide .image-gallery-description {
    right: 0%;
    bottom: 1%;
    background: transparent;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: right;
    color: #ffffff;
    z-index: ${zIndex.produtoPhotoGallery};

    @media (max-width: 768px) {
      left: 0;
      right: inherit;
    }
  }

  @media (max-width: 1200px) {
    .img-gallery,
    .image-gallery-slide,
    .image-gallery-image,
    .image-gallery-content {
      height: inherit;
    }
  }

  @media (max-width: 768px) {
    height: 250px;

    .img-gallery,
    .image-gallery-slide,
    .image-gallery-image,
    .image-gallery-content {
      height: 250px;
    }
  }
`;

export const ImageGalleryButtonLeft = styled.button`
  width: 54.5px;
  height: 54.5px;
  background-color: #e8b829;
  border: none;
  top: 50%;
  position: absolute;
  z-index: ${zIndex.produtoPhotoGallery};
  left: 2%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;

    left: 0;

    img {
      transform: scale(0.5);
    }
  }
`;

export const ImageGalleryButtonRight = styled.button`
  width: 54.5px;
  height: 54.5px;
  background-color: #e8b829;
  border: none;
  right: 2%;
  top: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.produtoPhotoGallery};

  @media (max-width: 1300px) {
    /* left: calc(100% - 70px); */
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    left: calc(100% - 40px);

    img {
      transform: scale(0.6);
    }
  }

  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`;

export const ImageGalleryActionsOver = styled.div`
  display: flex;
  position: absolute;
  bottom: 39px;
  right: 2%;
  z-index: ${zIndex.produtoPhotoGallery};

  @media (max-width: 768px) {
    display: none;
  }

  button {
    height: 40px;
    padding: 10px;
    margin: 10px;
    font-size: 13px;
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: right;
    color: #ffffff;
  }
`;

export const ImageGalleryActions = styled.div`
  padding: 20px 36px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    button {
      flex: 1;
      width: 100%;
      margin-bottom: 80px;
    }
  }
`;
