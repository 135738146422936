import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 0 36px; */
  padding: 0 30px;

  /* border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6; */

  .divider {
    margin-top: 40.5px;
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #272723;
  width: 432px;
  margin-right: 100px;
  max-width: 100%;

  @media (max-width: 1400px){
    margin-right: 0px;
  }
`;

export const ArquivosList = styled.div`
  flex: 1;
  display: grid;
  grid-gap: 61px 100px;
  /* padding: 51px; */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;

  @media (min-width: 1620px) and (max-width: 1920px) {
    grid-gap: 61px 100px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  @media (max-width: 1280px) {
    grid-gap: 61px 10px;
  }

  @media (max-width: 1100px) {
    margin-top: 40px;
  }

  @media (min-width: 768px) {
    grid-gap: 20px 20px;
  }
`;

export const Arquivo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArquivoHeader = styled.div`
  margin-bottom: 10px;
  strong {
    display: block;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  span {
    display: block;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;
  }
`;

export const ArquivoActions = styled.div`
  display: flex;

  button + button {
    margin-left: 7px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .buttonFicha{
    height: 33px;
    padding: 8px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #ebb92d;

    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #ebb92d;
    background-color: transparent;
    color: #ebb92d;

    img {
      margin-left: 8px;
    }
  }

  @media (max-width: 768px) {
    & > div {
      flex: 50%;
    }

    button {
      width: 100%;
    }
  }
`;
