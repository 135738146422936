import React from 'react';
import { Switch, Route as ReactDOMRoute , Redirect, useHistory} from 'react-router-dom';
import Login from '../pages/Login';
import Corretor from '../pages/Corretor';
import CorretorDetalhes from '../pages/CorretorDetalhes';
import Detalhes from '../pages/Detalhes';
// import SignUp from '../pages/SignUp';
import SignUp from '../pages/SignUpRefactor';
import SignInAdm from '../pages/SignInAdm';
import SignInReport from '../pages/SignInReport';
import CadastroSenha from '../pages/CadastroSenha';
import ResetSenha from '../pages/ResetSenha';
import NotFound from '../pages/NotFound';
import ContactFloatCard from '../components/ContactFloatCard';
import Dashboard from '../pages/Dashboard';

import Route from './route';
import ForgetPassword from '../pages/ForgetPassword';
import SignInToken from '../pages/SignInToken';
import { useAuth } from '../hooks/auth';
import CanalParceiros from '../pages/CanalParceiros';

const RedirectToHome: React.FC = () => {
  return <Redirect to="/dashboard" />
}

const RedirectToLogin: React.FC = () => {
  return <Redirect to="/" />
}

const Routes: React.FC = () => {
  const { user } = useAuth();

  return (
    <>
      {!process.env.REACT_APP_PAGINACORRETOR && (
        <>
          <Switch>
            {
              (window.location.host == 'portaldocorretor.tegravendas.com.br' || 
               window.location.host == 'qaportaldocorretor.tegravendas.com.br'||
               window.location.host == 'localhost:3000'  ) &&(
                <Route path="/" exact component={Login} />
              )
            }            
            {
              (window.location.host == 'portaldeparcerias.tegravendas.com.br' || window.location.host == 'qaportaldeparcerias.tegravendas.com.br' ) &&(
                <Route path="/" exact component={CanalParceiros} />               
              )
            }            
            <Route path="/login" exact component={Login} />          
            <Route path="/signup" component={SignUp} />
            <Route path="/parceiros" component={CanalParceiros} />
            <Route
              path="/esqueci-a-senha"
              component={
                (window.location.host != 'portaldocorretor.tegravendas.com.br' && window.location.host != 'qaportaldocorretor.tegravendas.com.br' )
                ? ForgetPassword : RedirectToHome}
            />
            <Route
              path="/login/adm/:uf/:regional/:token"
              component={SignInAdm}
              isExternal
            />
            <Route
              path="/login/relatorio/:uf/:regional/:relatorio/:token"
              component={SignInReport}
              isExternal
            />
            <Route
              path="/login/fromToken/"
              component={SignInToken}
              isExternal
            />
            <Route path="/cadastro/:uf/:apelido" component={SignUp} />
            <Route
              path="/cadastro-senha/:token"
              component={CadastroSenha}
              exact
            />
            <Route
              path="/corretor/:regional/:apelido"
              component={Corretor}
              isExternal
            />
            {user && user.is_senhaResetadaAdm === 1 && user.id_tipoParceiro ? (
              <>
                <ReactDOMRoute component={ResetSenha} />
              </>
            ) : (
              <>
                <Route path="/detalhes/:id" component={Detalhes} isExternal />
                <Route path="/dashboard" component={Dashboard} isPrivate />
              </>
            )}
            <ReactDOMRoute component={NotFound} />
          </Switch>
          <ContactFloatCard />
        </>
      )}
      {process.env.REACT_APP_PAGINACORRETOR && (
        <Switch>
          <Route
            path="/:apelido/:regional/:cidade/:empreendimento"
            exact
            component={CorretorDetalhes}
            isExternal
          />
          <Route
            path="/:apelido/:regional"
            component={Corretor}
            exact
            isExternal
          />
          <ReactDOMRoute
            render={() =>
              (window.location.href = 'https://tegraincorporadora.com.br/')
            }
          />
        </Switch>
      )}
    </>
  );
};

export default Routes;
