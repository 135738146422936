import React from 'react';
import { TegraExternalContainer } from './styles';

const TegraExternal: React.FC = () => {

  return (
    <TegraExternalContainer  src="https://www.tegraincorporadora.com.br/" />
  );
};

export default TegraExternal;
