import { 
    Container,
    BackIcon
} from "./styles";
import React from "react";
import { useHistory } from 'react-router-dom';
import { ICorretor } from "../";
import Fallback from "../../../assets/avatar.svg"

interface ICorretorItem {
    corretorItem: ICorretor;
    tipo: string;
    empreendimento: string;
    materiais: string;
}

const RelatorioGoBack: React.FC<ICorretorItem> = ({corretorItem, tipo, empreendimento, materiais}) => {
    const history = useHistory();
    return (
        <Container>
            { tipo == 'AcessosDetalhes' ?
                <div>
                    <BackIcon onClick={() => history.push('/dashboard/relatorio/acessos')}/> 
                    <div>Materiais acessados por : <img src={corretorItem.foto != null ? corretorItem.foto : Fallback}/> {corretorItem.nome} </div>
                </div>
            : tipo == 'MateriaisDetalhes' ?
                <div>
                    <BackIcon onClick={() => history.push('/dashboard/relatorio/materiais')}/> 
                    <div>Materiais mais acessados do empreendimento {empreendimento}</div>
                </div>
            : 
                <div>
                    <BackIcon onClick={() => history.push('/dashboard/relatorio/materiais')}/> 
                    <div>Downloads do empreendimento {empreendimento} do material {materiais} </div>
                </div>
            }
             
        </Container>
    );
};

export default RelatorioGoBack;