import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { LoadingContainer, LoadingWrapper } from './styles';
import FirstImage from './LoaderImages/FirstImage';
import SecondImage from './LoaderImages/SecondImage';
import ThirdImage from './LoaderImages/ThirdImage';
import colors from '../../styles/colors';

interface ILoadingContext {
  setLoaderActive(loaderActive: boolean): void;
}

const LoadingContext = createContext<ILoadingContext>({} as ILoadingContext);

const LoadingProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState<boolean>(false);
  const [imageChangeInterval, setImageChangeInterval] = useState<number>();
  const [loadingImageIndex, setLoadingImageIndex] = useState<number>(1);

  useEffect(() => {
    if (active) {
      setImageChangeInterval(
        window.setInterval(() => {
          setLoadingImageIndex(oldIndex => (oldIndex + 1) % 3);
        }, 2000),
      );
      disableBodyScroll(document.getElementById('Loader') as HTMLElement);
    } else {
      clearInterval(imageChangeInterval);
      enableBodyScroll(document.getElementById('Loader') as HTMLElement);
    }
  }, [active]);

  const setLoaderActive = useCallback(loaderActive => {
    setActive(loaderActive);
  }, []);

  return (
    <LoadingContext.Provider value={{ setLoaderActive }}>
      <LoadingContainer id="Loader" active={active}>
        <LoadingWrapper>
          {loadingImageIndex === 0 && (
            <>
              <FirstImage color={colors.lightGrey} />{' '}
              <FirstImage color={colors.primaryColor} />
            </>
          )}
          {loadingImageIndex === 1 && (
            <>
              <SecondImage color={colors.lightGrey} />{' '}
              <SecondImage color={colors.primaryColor} />
            </>
          )}
          {loadingImageIndex === 2 && (
            <>
              <ThirdImage color={colors.lightGrey} />{' '}
              <ThirdImage color={colors.primaryColor} />
            </>
          )}
        </LoadingWrapper>
      </LoadingContainer>
      {children}
    </LoadingContext.Provider>
  );
};

function useLoader(): ILoadingContext {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoader must be within an LoadingProvider component');
  }

  return context;
}

export { LoadingProvider, useLoader };
