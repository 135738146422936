import styled from 'styled-components';

export const Icon = styled.img``;

type StyledCheckboxProps = {
  checked?: boolean;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  margin-right: 10px;
  padding: 3.4px 5.6px;
  width: 16px;
  height: 16px;
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: solid 1px #adadab;
  background-color: #ffffff;

  img {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;


export const DivDropDown = styled.div`
  //background: linear-gradient(315deg,#FDBE39 0%,#FDBE39 50%,#E6E5E5 10%,#E6E5E5 100%);
  width: 10px;
  height: 10px;
  position: relative;
  bottom:-10px;
`;

export const ValueWrapper = styled.div`
.dummy-input-wrapper {
  & > :not(input) {
    display: none;
  }
}
`;