import React, { useState, useEffect } from 'react';
import { Container } from './styles';

const Compliance: React.FC = () => {
    const [pageCompliance, SetPageCompliance] = useState("");
    const [pageHeight, SetPageHeight] = useState("2200px");
    const [pageWidth, SetPageWidth] = useState(0);

    //SetPageWidth(window.innerWidth);
    useEffect(() => {
        var ambiente = 3; //produção
        if (window.location.hostname.toLowerCase().indexOf('localhost') === 0){
            ambiente = 1; // localhost            
        }
        else if (window.location.hostname.toLowerCase().indexOf('qa') === 0){
            ambiente = 2; // qa
        }
        if (window.innerWidth != pageWidth){
            handleResize();
        }
        function handleResize() {           
            if (ambiente == 1){
                SetPageCompliance("https://tegraincorporadora.com.br/sobre/compliance");
                //SetPageCompliance("https://sx-tegra-vitrine.azurewebsites.net/sobre/compliance");
            }
            else if (ambiente == 2){
                SetPageCompliance("https://tegraincorporadora.com.br/sobre/compliance");
            }
            else {
                SetPageCompliance("https://tegraincorporadora.com.br/sobre/compliance");
            }            

            if (window.innerWidth >= 1150){
                SetPageHeight("2200px");
            }  
            SetPageWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        //recebe do frame a altura no redimensionamento mobile
        window.addEventListener('message', function(e) {
            // message that was passed from iframe page
            if(e.data.hasOwnProperty('height')) {
                let message = e.data;
                SetPageHeight((message.height + 60) +"px");
            }
        });
    }, [pageWidth, pageCompliance, pageHeight]);

      return (
        <Container>
                <iframe 
                    id='iframe'
                    src={pageCompliance} 
                    width="100%" 
                    height={pageHeight}
                    data-gtm-yt-inspected-7200357_164="true"
                ></iframe>
        </Container>
    );
};
  
  export default Compliance;