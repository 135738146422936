import React from 'react';
import { AuthProvider } from './auth';
import { LoadingProvider } from './loader';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LoadingProvider>{children}</LoadingProvider>    
  </AuthProvider>
);

export default AppProvider;
