import styled, { css } from 'styled-components';

interface ContainerProps {}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  align-content: center;

  label {
    display: flex;
    cursor: pointer;
    flex-wrap: nowrap;
  }

  span,
  input {
    display: block;
    cursor: pointer;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  input {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const Icon = styled.img``;

interface StyledCheckboxProps {
  checked: boolean;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  margin-right: 10px;
  padding: 3.4px 5.6px;
  width: 16px;
  height: 16px;
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: solid 1px #adadab;
  background-color: #ffffff;

  img {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
