import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
`;

export const Content = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: 41px auto;
  padding-left: 36px;
  padding-right: 36px;
`;

export const BlogAction = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 41px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      flex: 1;
      width: 100%;
    }
  }
`;
