import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  RefObject,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { Container, Icon } from './styles';

interface CustomInputProps extends InputProps {
  name: string;
  label: string;
  icon?: any;
  className?: string;
  formRef?: RefObject<FormHandles>;
}

const TegraInputWithMask: React.FC<CustomInputProps> = ({
  name,
  label,
  icon,
  formRef,
  className = '',
  ...rest
}) => {
  const inputRef = useRef<ReactInputMask>(null);

  const handleIconFormSubmit = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.submitForm();
    }
  }, [formRef]);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      // setValue(ref: ReactInputMask, value: string) {
      //   ref.setInputValue(value);
      // },
      // clearValue(ref: ReactInputMask) {
      //   ref.setInputValue('');
      // },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {label && <label>{label}</label>}
      {Icon && <Icon onClick={handleIconFormSubmit} src={icon} />}
      <ReactInputMask
        className={className}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </Container>
  );
};

export default TegraInputWithMask;
