import styled from 'styled-components';
import colors from '../../../styles/colors';

export const RelatorioTableContainer = styled.table`
  border-collapse: collapse;
  width: calc(100% - 7rem);
  margin: 50px 3.5rem;
`;

export const RelatorioTableHeader = styled.th`
  font-size: 13px;
  font-weight: bold;
  color: ${colors.primaryColor};
  line-height: 1;
  text-align: left;
  height: 30px;
  padding: 30px 0;
`;

export const RelatorioTableRow = styled.tr`
  border-bottom: 1px solid ${colors.lightGrey};

  p {
    color: #60605d;
  }

  a {
    cursor: pointer; 
    color: #60605d;
    font-weight: 500;
  }

  .detalhes {
    text-decoration: underline;
  }

  td {
    height: 25px;
    padding: 30px 0;
    max-width: 160px;
    min-width: 100px;
  }
`;