export default {
  primaryColor: '#EBB92D',
  greyBackground: '#60605d',
  darkGreyBackground: '#545451',
  blackBackdrop: 'rgba(0,0,0,0.5)',
  yellowTitle: '#ebb92d',
  inputBorder: '#adadab',
  featuredBlack: '#272723',
  rankingFirst: '#ebb92d',
  rankingSecond: '#adadab',
  rankingThird: '#c18000',
  lightGrey: '#e8e8e8'
};