import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

export const BlogItem = styled.li`
  border: 1px solid #adadab;
  width: calc(100% / 3 - 14px);
  margin: 15px 7px;

  a {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    width: calc(100% / 2 - 14px);
    margin: 15px 7px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 15px 7px;
  }
`;

export const BlogItemContent = styled.div`
  & .thumbnailContainer {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    &:after {
      content: '';
      padding-top: 82.2%;
      display: inline-block;
    }

    &.TitleWithImageContainer::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(180deg, transparent 40%, rgba(0, 0, 0, 0.6));
    }
  }

  & h2 {
    font-weight: 500;
    display: flex;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    position: absolute;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;

    &.TitleWithImage {
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      z-index: 3;
      bottom: 32px;
      -webkit-line-clamp: 2;
    }

    &.SingleTitle {
      color: #60605d;
      padding-top: 40px;
      font-size: 30px;
      justify-content: center;
      -webkit-line-clamp: 4;
    }
  }

  & .SingleTitle-border {
    border: 0px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 80px);
    height: 1px;
    background: #e6e6e6;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const BlogItemThumbnail = styled.img`
  position: absolute;
  width: 200%;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const BlogItemInfo = styled.div`
  padding: 30px 40px;

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    text-align: left;
    color: #60605d;
    max-width: 100%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    min-height: 190px;
  }
`;

export const BlogItemActions = styled.div`
  width: 100px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 40px 40px auto;

  a {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #272723;
    text-decoration: underline;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;
