import React from 'react';
import { ISVGImage } from './FirstImage';

const ThirdImage: React.FC<ISVGImage> = ({ color }) => {
  const styleLine = {
    fill: 'none',
    stroke: color,
    strokeMiterlimit: 10,
    strokeWidth: '4.4473472209804px',
  };
  const stylePolyline = {
    fill: 'none',
    stroke: color,
    strokeMiterlimit: 10,
    strokeWidth: '5.13385404029066px',
  };
  const styleLogoTegra = {
    fill: color,
    stroke: 'none',
    strokeMiterlimit: 0,
    strokeWidth: 0,
  };
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
      <polyline
        points="25.7,132.1 49.3,132.1 49.3,23.4 93.7,23.4 93.7,132.1 114.7,132.1 	"
        style={stylePolyline}
      />
      <g>
        <line x1="57.8" y1="134.4" x2="57.8" y2="41.1" style={styleLine} />
        <line x1="66.7" y1="134.4" x2="66.7" y2="41.1" style={styleLine} />
        <line x1="75.7" y1="134.4" x2="75.7" y2="41.1" style={styleLine} />
        <line x1="84.6" y1="134.4" x2="84.6" y2="41.1" style={styleLine} />
      </g>
      <path
        d="M82.5,34.6l0.9-2.2l0.9,2.2H82.5z M77.2,33.2L77.2,33.2l-1.2,0v-2h1.2c0.5,0,1,0.4,1,1
        C78.2,32.8,77.7,33.2,77.2,33.2 M84.1,29.7h-1.4l-2.9,7.1l-1.3-2.3c0.8-0.4,1.4-1.3,1.4-2.2c0-1.4-1.1-2.5-2.5-2.5h0h-2.9v3.5h-3.2
        v1.5h1.4c-0.4,0.5-1.1,0.9-1.8,0.9c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c0.5,0,0.9,0.1,1.2,0.4l1.2-1.2
        c-0.7-0.5-1.5-0.8-2.4-0.8c-2.1,0-3.9,1.7-3.9,3.9c0,0.8,0.2,1.5,0.6,2.1h-4v-1.3h2.7v-1.6h-2.7v-1.3h3.3v-1.6H54.8v1.6h2.7v5.8
        h1.7v-5.8H62v5.8h4.5l1.3-1.3c0.7,0.9,1.8,1.5,3,1.5c1.6,0,3-1,3.6-2.5v2.3H76v-2.4h0.8l1.4,2.4h3.3l0.4-1H85l0.4,1h1.8L84.1,29.7z
        "
        style={styleLogoTegra}
      />
    </svg>
  );
};

export default ThirdImage;
