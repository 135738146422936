import React, { useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { MenuData } from '../NavMain/NavMenu';
import { UndecoratedAnchor, UndecoratedLink } from '../../UndecoratedLink/styles';
import { NavMenuItem, NavItemContainer } from './styles';
import Dropdown from './Dropdown';

interface NavItemProps extends React.HTMLAttributes<HTMLDivElement> {
  menuItem: MenuData;
}

const NavItem: React.FC<NavItemProps> = ({ menuItem, className }) => {
  const { path } = useRouteMatch();

  return (
    <NavItemContainer className={className}>
      {menuItem.external && (
        <UndecoratedAnchor href={menuItem.link} target="_blank">
          <NavMenuItem>{menuItem.label}</NavMenuItem>
        </UndecoratedAnchor>
      )}
      {!menuItem.external && (!menuItem.children ? (
        <UndecoratedLink
          to={`${menuItem.link}`}
          key={menuItem.link}
        >
          <NavMenuItem>{menuItem.label}</NavMenuItem>
        </UndecoratedLink>
      ) : (
          <Dropdown menuItem={menuItem} key={menuItem.link} />
        ))}
    </NavItemContainer>
  );
};

export default NavItem;
