import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.ul`
  list-style: none;
  margin-top: 100px;

  @media (max-width: 350px)  {
    margin-top: 150px;    
  }
`;

export const BlogItem = styled.li`
  /*padding-bottom: 32px;*/
  padding-bottom: 0px;
  border-bottom: 1px solid #e6e6e6;
  animation: ${fadeIn} 500ms linear forwards;

  &:not(:first-child) {
    /*margin-top: 40px;*/
    margin-top: 25px;
  }

  @media (max-width: 1000px) {
    padding-bottom: 10px;
  }
`;

export const BlogItemContent = styled.div`
  display: flex;

  /*
  @media (max-width: 768px) {
    flex-direction: column;
  }
  */

  a {
    text-decoration: none;
  }
`;

export const BlogItemThumbnail = styled.img`
  width: 100%;
  max-width: 247px;
  max-height: 165px;
  object-fit: cover;
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }

  @media (min-width: 400px) and (max-width: 600px) {
    /* align-self: center; */
  }
`;

export const ContainerEvento = styled.div`
  display: flex;
  //flex-wrap: wrap;

  @media (max-width: 500px)  {
    flex-direction: column;
  }
`;

export const DataEvento = styled.div`
  width: 110px;
  max-height: 125px;
  display: flex;
  flex-direction: column;

  .mesEvento{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 34px;
    p{
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .diaEvento{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 92px;

    p{
      font-size: 56px;
      color: #272723;
      font-weight: 500;
    }
  }

`;

export const BlogItemInfo = styled.div`
  margin-left: 15px;
  flex: 1;

  @media (max-width: 500px)  {
    margin-top: 10px;
    margin-left: 0px;
  }

  time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;
    display: block;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.36px;
    text-align: left;
    color: #272723;
  }
  strong {
    display: block;
    margin-top: 17px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  p {
    margin-top: 17px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
    max-width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 900px)  {
      max-width: 30rem;
    }

    @media (max-width: 700px)  {
      max-width: 18rem;
    }

    @media (max-width: 330px)  {
      max-width: 10rem;
    }

  }
`;

export const BlogItemActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  a {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #272723;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  @media (min-width: 1000px) {
    position: relative;
    top: -31px;
  }
`;
