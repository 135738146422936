/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useCallback, MouseEvent } from 'react';

import {
  Container,
  Title,
  VideosContainer,
  VideoInfo,
  VideoInfoHeader,
  VideoInfoActions,
  VideoPlaceholder,
  VideoThumbnail,
  VideoContainer,
} from './styles';
import TegraDivider from '../../../components/TegraDivider';
import TegraModalCompartilhar from '../../../components/TegraModalCompartilhar';
import TegraVideoViewerModal from '../../../components/TegraVideoViewerModal';
import {
  getTelegramURL,
  getFacebookURL,
  getWhatsappURL,
} from '../../../utils/share';
import { log, ETipoAtividade, ETipoSessao } from '../../../utils/log';
import { useAuth } from '../../../hooks/auth';

interface IVideo {
  id: number;
  name: string;
  link?: string;
  tx_arquivo?: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tx_thumbnail?: any;
}

interface IProdutoVideoGalleryProps {
  videos: IVideo[];
  className?: string;
  empreendimento: string;
  id_empreendimento: number;
}

const youtubeUrl = 'https://www.youtube.com/watch?v=';

const ProdutoVideoGallery: React.FC<IProdutoVideoGalleryProps> = ({
  videos,
  className = '',
  empreendimento,
  id_empreendimento,
}) => {
  const [modalShareOpen, setModalShareOpen] = useState(false);
  const { user } = useAuth();
  const [modalVideoOpen, setModalVideoOpen] = useState(false);
  const [viewingVideo, setViewingVideo] = useState<IVideo>({} as IVideo);

  const handleOpenModal = useCallback((video: IVideo) => {
    setViewingVideo(video);
    setModalShareOpen(true);
  }, []);

  const getYoutubeURL = (url: string | undefined): string => {
    if (!url) return '';
    if (url.indexOf('http') === 0) return url;
    return youtubeUrl + url;
  };

  const handleOpenModalVideo = useCallback((video: IVideo) => {
    setViewingVideo(video);
    setModalVideoOpen(true);
    log(
      empreendimento,
      'Vídeo',
      ETipoAtividade.Acessou,
      ETipoSessao.Video,
      id_empreendimento,
      video.name,
      video.id,
      user,
    );
  }, []);

  const logDownload = useCallback((video: IVideo) => {
    log(
      empreendimento,
      'Vídeo',
      ETipoAtividade.Download,
      ETipoSessao.Video,
      id_empreendimento,
      video.name,
      video.id,
      user,
    );
  }, []);

  const handleCloseModal = useCallback((type?: 'share' | 'video' | string) => {
    switch (type) {
      case 'share':
        setModalShareOpen(false);
        break;
      case 'video':
        setModalVideoOpen(false);
        setViewingVideo({} as IVideo);
        break;
      default:
        setModalShareOpen(false);
        break;
    }
  }, []);

  return (
    <Container className={className}>
      <TegraModalCompartilhar
        handleClose={handleCloseModal}
        isOpen={modalShareOpen}
        urlTelegram={getTelegramURL(getYoutubeURL(viewingVideo.link))}
        urlFacebook={getFacebookURL(getYoutubeURL(viewingVideo.link))}
        urlWhats={getWhatsappURL(getYoutubeURL(viewingVideo.link))}
      />

      <TegraVideoViewerModal
        link={viewingVideo.link}
        title={viewingVideo.name}
        handleClose={handleCloseModal}
        isOpen={modalVideoOpen}
      />

      <Title>Vídeos</Title>

      <VideosContainer>
        {videos.map(video => (
          <VideoContainer key={video.id}>
            <VideoThumbnail>
              {video.tx_thumbnail ? (
                <div
                  style={{ backgroundImage: `url(${video.tx_thumbnail})` }}
                />
              ) : (
                <VideoPlaceholder />
              )}
            </VideoThumbnail>

            <VideoInfo>
              <VideoInfoHeader>
                <strong className="title">{video.name}</strong>
                <span className="description">{video.description}</span>
              </VideoInfoHeader>

              <VideoInfoActions>
                <button
                  type="button"
                  key="visulizar-button"
                  onClick={() => handleOpenModalVideo(video)}
                  className="action"
                >
                  Visualizar
                </button>

                {video.tx_arquivo && (
                  <>
                    <TegraDivider axis="y" height={21} color="#fcb813" />
                    <a
                      href={video.tx_arquivo}
                      onClick={() => logDownload(video)}
                      target="_blank"
                      type="button"
                      key="baixar-button"
                      className="action"
                      download
                    >
                      Baixar
                    </a>
                  </>
                )}

                <TegraDivider axis="y" height={15} color="#fcb813" />

                <button
                  type="button"
                  key="compartilhar-button"
                  className="action"
                  onClick={() => handleOpenModal(video)}
                >
                  Compartilhar
                </button>
              </VideoInfoActions>
            </VideoInfo>
          </VideoContainer>
        ))}
      </VideosContainer>
    </Container>
  );
};

export default ProdutoVideoGallery;
