import React, {
  TextareaHTMLAttributes,
  useEffect,
  useRef,
} from 'react';

import { useField } from '@unform/core';
import { Container } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

const TegraTextArea: React.FC<TextAreaProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <textarea
        ref={inputRef}
        name={fieldName}
        defaultValue={defaultValue}
        {...rest}
      />
    </Container>
  );
};

export default TegraTextArea;
