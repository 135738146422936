import api from '../services/api';
import { User } from '../hooks/auth';

export enum ETipoAtividade {
  Acessou = 0,
  Logou = 1,
  AtualizouDocumentos = 2,
  Visualizou = 3,
  Download = 4,
  Compartilhou = 5,
  AtualizouMinhaPagina = 6,
}

export enum ETipoSessao {
  Undefined = 0,
  Books = 1,//Apresentacoes = 1,
  Atividades = 2,
  CalendarioTrabalho = 3,
  ContatoGerente = 4,
  ContatoTegraVendas = 5,
  ContatoTegraInc = 6,
  DocumentosParceiro = 7,
  EmailMarketingList = 8,
  EmailMarketing = 9,
  ProdutosList = 10,
  Produto = 11,
  EspelhosTabelaList = 12,
  EspelhosTabela = 13,
  FAQ = 14,
  InformacoesImList = 15,
  InformacoesIm = 16,
  IptuCondominioList = 17,
  IptuCondominio = 18,
  LiderancaList = 19,
  Lideranca = 20,
  MemorialDescritivoList = 21,
  MemorialDescritivo = 22,
  MinhaPagina = 23,
  NovidadesList = 24,
  Novidade = 25,
  PremiacaoList = 26,
  Premiacao = 27,
  Ranking = 28,
  RankingEquipe = 29,
  RankingCorretores = 30,
  RankingGerentes = 31,
  RelatorioAcessos = 32,
  RelatorioAtividades = 33,
  SimuleFinanciamento = 34,
  TourVitrual = 35,
  ApresentacaoList = 36,//TreinamentosList = 36,
  Apresentacao = 37,//Treinamento = 37,
  VideosList = 38,
  Video = 39,
  MateriaisGerais = 40,
  TreinamentosList = 41,
  Treinamento = 42,
  RedesSociais = 43,
  PecasAnuncios = 44,
  OutrasImagens = 45,
  MateriaisCorretor = 46,
  Manuais = 47,
  Masterplan = 48,
  TreinamentoProduto = 49,
  CicloNegocio = 50,
  TreinamentoFerramentas = 51,
  TreinamentosDiversos = 52
}

export const getDescricao = (id_tipoAtividade: number): string => {
  switch (id_tipoAtividade) {
    case 0:
      return 'Acessou';
    case 1:
      return 'Logou';
    case 2:
      return 'Atualizou documentos';
    case 3:
      return 'Visualizou';
    case 4:
      return 'Download';
    case 5:
      return 'Compartilhou';
    case 6:
      return 'Atualizou minha página';
    default:
      return '';
  }
};

export const getSessao = (id_tipoSessao: number): string => {
  switch (id_tipoSessao) {
    case 0:
      return 'Undefined';
    case 1:
      return 'Books'; //Apresentações
    case 2:
      return 'Atividades';
    case 3:
      return 'Caldendário de Trabalho';
    case 4:
      return 'Contatos - Gerente';
    case 5:
      return 'Contatos - Tegra Vendas';
    case 6:
      return 'Contatos - Tegra Incorporadora';
    case 7:
      return 'Documentos Parceiro';
    case 8:
      return 'Lista de Email Marketing';
    case 9:
      return 'Email Marketing';
    case 10:
      return 'Lista Produtos';
    case 11:
      return 'Produtos';
    case 12:
      return 'Lista de Espelho e Tabelas';
    case 13:
      return 'Espelho e Tabelas';
    case 14:
      return 'FAQ';
    case 15:
      return 'Lista de Informações de IM';
    case 16:
      return 'Informações de IM';
    case 17:
      return 'Lista de IPTU e Condomínio';
    case 18:
      return 'IPTU e Condomínio';
    case 19:
      return 'Lista de Liderança';
    case 20:
      return 'Liderança';
    case 21:
      return 'Lista de Memorial Descritivo';
    case 22:
      return 'Memorial Descritivo';
    case 23:
      return 'Minha Página';
    case 24:
      return 'Lista de Novidades';
    case 25:
      return 'Novidades';
    case 26:
      return 'Lista de Premiação';
    case 27:
      return 'Premiação';
    case 28:
      return 'Lista de Ranking';
    case 29:
      return 'Ranking de Equipe';
    case 30:
      return 'Ranking de Corretores';
    case 31:
      return 'Ranking de Gerentes';
    case 32:
      return 'Relatório de Acessos';
    case 33:
      return 'Relatório de Atividades';
    case 34:
      return 'Simule Financiamento';
    case 35:
      return 'Tour Vitrual';
    case 36:
      return 'Lista de Apresentações';
    case 37:
      return 'Apresentação';
    case 38:
      return 'Lista de Videos';
    case 39:
      return 'Video';
    case 40:
      return 'Materiais Gerais';
    case 41:
      return 'Lista de Treinamento';
    case 42:
      return 'Treinamento';
    case 43:
      return 'Redes Sociais';
    case 44:
      return 'Peças e Anúncios';
    case 45:
      return 'Outras Imagens';
    case 46:
      return 'Materiais do Corretor';
    case 47:
      return 'Manuais';      
    case 48:
      return 'Masterplan';          
    case 49:
      return 'Treinamento de Produtos';          
    case 50:
      return 'Ciclo do Negócio';          
    case 51:
      return 'Treinamento de Ferramentas';          
    case 52:
      return 'Treinamento Diversos';        
    default:
      return 'Undefined';
  }
};

export const log = async (
  produto: string,
  tipo: string,
  id_tipoAtividade: ETipoAtividade,
  id_tipoSessao: ETipoSessao,
  id_empreendimento?: any,
  arquivo?: string,
  ids?: any,
  user?: User,
): Promise<void> => {
  console.log('ids',ids);
  await api    
    .post('Atividades/create', {
      id_responsavel: user?.id,
      id_tipoAtividade,
      id_empreendimento,
      txt_responsavel: user?.apelido || user?.nome,
      ids: ids == null? [] :ids?.length ? ids.map((i: any) => Number(i)) : [ids],
      tipo,
      id_tipoUsuario: user?.tipo,
      txt_descricao: `${getDescricao(
        id_tipoAtividade,
      )} ${tipo} ${ids != null ? 'do empreendimento': ''} ${produto || ''} ${arquivo || ''}`,
      id_tipoSessao
    })
    .then(res => {})
    .catch(err => {});
};
