import styled from 'styled-components';

interface FloatCardProps {
  translateValue: number;
}

export const FloatCard = styled.div<FloatCardProps>`
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 10%;
  width: 25%;
  min-width: 550px;
  transition: all ease-in 0.2s;
  //max-height: 350px;

  &.fechado1 {
    transform: translate(0px, 130px);
    .FloatCard__header svg {
      transform: rotate(180deg);
    }
  }

  &.fechado2 {
    transform: translate(0px, 260px);
    .FloatCard__header svg {
      transform: rotate(180deg);
    }
  }

  &.fechado {
    transform: translate(0px, ${({ translateValue }) => `${translateValue}px`});
    .FloatCard__header svg {
      transform: rotate(180deg);
    }
  }
  
  & .ContainerCards{
    background: #e6e6e6;
  }
  
  & .FloatCard {
    &__header {
      width: 100%;
      display: flex;
      background: #ebb92d;
      padding: 15px 25px;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;
      color: #272723;
      font-weight: 600;
      font-size: 12px;
      outline: 0;
      border: 0;

      & svg {
        stroke: #766e55;
        transform: rotate(0deg);
        transition: all ease-in 0.2s;
        width: 15px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -6px;
        z-index: -1;
        left: 0;
        width: 100%;
        height: 17px;
        transform: skewY(1.5deg);
        background: #ebb92d;
      }

      & .speech-bubble {
        width: 39px;
        height: 43px;
        padding: 9px;
        left: -17px;
        top: 1px;
        position: absolute;
        background: red;
        border-radius: 4em;

        & img{
          filter: invert(100%) sepia(100%) saturate(14%) hue-rotate(212deg) brightness(104%) contrast(104%);
        }
      }

    }
    &__infos {
      width: 100%;
      background: #e6e6e6;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 25px 25px 35px;
      word-break: break-all;

      &Title{
        margin-bottom: 10px;
        &Text{
          font-weight: 600;
          font-size: 12px;
          user-select: none;
        }
      }

      &Name {
        display: flex;
        flex-direction: row;
        align-items: center;

        &Pic {
          border-radius: 50px;
          width: 70px;
          min-width: 70px;
          height: 70px;
          margin-right: 10px;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: #ccc;
          background-position: center center;
          user-select: none;
        }

        &Text {
          color: #3e3e3b;
          font-size: 12px;
        }
      }
      &Email {
        display: block;
        margin-bottom: 15px;
        align-items: center;

        &Icon {
          margin-right: 10px;
        }

        &Text {
          color: #3e3e3b;
          font-size: 13px;
          width: 100%;
          text-overflow: ellipsis;
          text-decoration: none;
          display: inline-block;
          overflow: hidden;
        }
      }
      &Phone {
        display: flex;
        flex-direction: row;
        align-items: center;

        &Icon {
          margin-right: 10px;
        }

        &Text {
          color: #3e3e3b;
          line-height: 20px;
          text-decoration: none;
          font-size: 13px;
        }
      }
    }
    &__telegram {
      display: flex;
      margin-top: 25px;
      padding-top: 25px;
      border-top: 1px solid #70707026;
      align-items: center;
      &Cta {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        margin-right: 10px;
        &Icon {
          width: 34px;
        }
        &Text {
          font-size: 12px;
          color: #2c2c27;
          margin-left: 15px;
          max-width: 200px;
        }
      }

      &Action {
        border: 1px solid #bdbdbd;
        background: #fff;
        color: #2c2c27;
        width: 100px;
        font-size: 12px;
        text-decoration: none;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    right: 50%;
    min-width: 300px;
    transform: translateX(50%);
    & .FloatCard__header svg {
      display: block;
    }

    .FloatCard__telegramCtaText{
      font-size: 11px;
    }

    &.fechado1 {
      transform: translateX(50%) translateY(158px);
    }

    &.fechado2 {
      transform: translateX(50%) translateY(310px);
    }

 
    &.fechado {
      //transform: translateX(50%) translateY(470px);
      transform: translateX(50%) translateY(${({ translateValue }) => `${translateValue}px`});
    }     
    
  }
`;

export const ModalMackdrop = styled.div`
  display:none;
  position:absolute;
  top:0px;
  left:0px;
  height:100%;
  width:100%;

  @media (max-width: 768px) {
    display:none;
  }  
`;