import styled, { css } from 'styled-components';

export const Container = styled.div`
  .ReactModal__Overlay {
    opacity: 0.9;
    background-color: #272723;
  }

  h1 {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723 !important;
  }
`;

export const ShareButtons = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;

    button {
      justify-content: center;

      svg {
        margin-right: 8px;
      }
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 425px) {
    button {
      width: 100%;
    }
  }
`;

interface ShareButtonProps {
  bgColor: string;
  txtColor?: string;
}

export const ShareButton = styled.button<ShareButtonProps>`
  min-width: 200px;
  height: 49px;
  background-color: transparent;
  border: solid 1px #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 40px;

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.txtColor || '#fff'};

  ${props =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 40px;
  top: 35px;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 20px;
  }
`;
