import styled, { css } from 'styled-components';

interface ContainerProps {
  inputHasValue: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & p {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  strong {
    display: inline-block;
    margin-right: 10px;
    margin-top: 16px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723;

    @media (max-width: 600px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  button.cancelBtn {
    background-color: transparent;
    text-decoration: underline;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    & > img {
      margin-left: 4px;
    }
  }

  button.uploadBtn {
    text-align: center;
    border: solid 1px #fcb813;
    color: #fcb813;
    background-color: #ffffff;
    padding: 10px;
    cursor: pointer;

    width: 170.3px;
    height: 35px;

    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: block;
      margin-right: 10.5px;
    }
  }

  input {
    width: 170.3px;
    height: 35px;

    position: absolute;
    left: calc(100% - 170.3px);
    top: 0;
    opacity: 0;
    cursor: pointer;

    ${props =>
      props.inputHasValue &&
      css`
        width: 0px;
        height: 0px;
      `}
  }

  @media (max-width: 600px) {
    align-items: center;

    .upload-btn-wrapper {
      width: 100%;
      margin-top: 11.5px;
    }

    & > p {
      margin-top: 17px;
      margin-bottom: 10px;
      text-align: left;
      flex: 1;
      margin-right: 0px;
    }

    button.uploadBtn {
      justify-content: center;
      align-items: center;
    }

    button.uploadBtn,
    input {
      width: 100%;
    }

    button.cancelBtn {
      width: 41px;
    }
    
    input {
      top: 11.5px;
      left: 0;
    }
  }
`;
