import { createGlobalStyle } from 'styled-components';
import pageBg from '../assets/tegra-bg.jpg';
import pageBgMobile from '../assets/tegra-bg-mobile.jpg';
import pageBgParceiro from "../assets/canal_parceiros/bg.png";
import iconChecked from '../assets/icon-checked.png';
import fundoExpertegra from '../assets/fundoExpertegra.png';


var PaginaParceiro  = window.location.pathname == "/parceiros" || 
                      (window.location.pathname == "/" && (window.location.host == "portaldeparcerias.tegravendas.com.br" ||
                      window.location.host == "qaportaldeparcerias.tegravendas.com.br"));

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;;
    box-sizing: border-box;
  }

  body {
    background: url(${PaginaParceiro ? pageBgParceiro: pageBg}) no-repeat;
    background-size: cover;
    background-position: 0 -40px;
    //background-size: 2600px 1790px;


    @media (max-width: 600px) {
      background-image: url(${PaginaParceiro? pageBgParceiro:pageBgMobile});

      background-position: -30px 0px;
      background-size: 650px auto;
    }

    background-color: #272723;
    color: #60605d;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }

  #root{
    position: relative;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  p, a {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;

  }

  button {
    cursor: pointer;
  }

  .no-scroll{
    @media(max-width: 992px) {
      position: fixed;
    }
  }
  @media(max-width: 768px) {
    .ReactModal__Content {
      width: 80vw!important;
    }

  }

  .ReactModal__Content {
    h1.video-title {
      /* margin-top: 30px; */
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #272723 !important;
    }
    iframe.video-frame {
      margin-top: 20px;
      border: none;
    }
  }

  .ReactModal__Overlay {
    z-index: 9;
    overflow: scroll;

    #modalAceite, #modalConduta, #modalEtica, #modalLGPD, #modalImagem, #modalResponsabilidade{
      max-width: 1240px;
      max-height: 820px;
      overflow: hidden;
      position: absolute;
      margin: auto !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      border: 1px solid rgb(204, 204, 204);
      background: rgb(255, 255, 255);
      border-radius: 4px;
      padding: 20px;
      outline: none;

      .modal-header {
          border: none;
          padding: 20px 0px;

          p {
              color: #000000;
              font-size: 20px;
              font-weight: 800;
              text-align: left;
              max-width: 100%;
              display: block !important;
          }
      }

      .modal-body {
        padding: 20px 50px;
        line-height: 23px;
        color: #000000;
        border: 1px solid #a7aaaf;
        overflow-y: auto;
        height: 65vh;
        max-height: 630px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        .red{
          color: red;
        }
        .text-right{
          text-align: right;
        }

        .topicos {
            margin-left: 30px;
            color: #000000;
            margin-bottom: 0px;
            font-size: 16px;

            span {
                margin-right: 20px;
                color: #000000;
            }
        }
        .topicos2 {
            margin-left: 30px;
            color: #000000;
            margin-bottom: 0px;
        }
      }

      .modal-footer {
        background-color: #ffffff;
        border: none;
        padding: 20px 0px 0px 20px;
        display: flex;
        justify-content: flex-end;

        .box-checkbox {
          cursor: not-allowed;
          opacity: 0.5;
          margin-top: 4px;

          label, span {
            pointer-events: none;
          }
        }

        a {
          margin-left: 20px;

          &:focus {
              border: none;
          }
        }
        
        input[type=checkbox] {
          width: 0;
          cursor: pointer;
          display: none;
        }

        input[type=checkbox] + label {
          font-family: 'montserratbold';
          position: relative;
          display: table;
          min-height: 20px;
          cursor: pointer;
        }

        input[type=checkbox] + label > span {
          position: relative;
          padding-left: 30px;
          font-size: 14px;
          line-height: 16px;
          display: table-cell;
          vertical-align: middle;
          font-family: 'Montserrat', sans-serif;
        }

        input[type=checkbox] + label > span:before {
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          left: 0;
          content: '';
          border: 1px solid #b1b1af;
          width: 20px;
          height: 20px;
          display: inline-block;
          cursor: pointer;
          background-image: url(${iconChecked});
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 0px 0px;
        }

        input[type=checkbox]:checked + label {
          color: #ebb92d;
        }

        input[type=checkbox]:checked + label > span:before {
          background-position: center center;
          background-size: 14px 11px;
          border: 1px solid #e38536;
          background-color: #ffd766;
        }

        #btn-aceitar{
          height: 30px;
          margin-left: 20px;
          line-height: 30px;
          padding: 0 30px;
          color: white !important;
          font-size: 15px;
          font-weight: 600;
          background-color: #eee;
          display: inline-block;
          border-radius: 0;
          cursor: not-allowed;
        }
      }
      &.modal-enabled #btn-aceitar{
        background-color: #ebb92d;
        cursor: pointer;
      }
      &.modal-enabled .box-checkbox{
        cursor: default;
        opacity: 1;

        label, span {
            pointer-events: all;
        }
      }
    }


    #modalAceite, #modalConduta{
      .modal-footer {
        justify-content: flex-start;
        .box-checkbox {
          width: 100%;
        }
      }
    }

    #modalEtica{
      .modal-footer {
        justify-content: flex-start;
        .box-checkbox {
          width: 100%;
        }
      }
    }

    #modalExpertegra {
      background-image: url(${fundoExpertegra});
      background-size: cover;
      background-repeat: no-repeat;
      width: 70%;
      max-width: 1090px;
      max-height: fit-content;
      position: absolute;
      margin: auto !important;
      top: 5vh !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      padding: 7vh;
      outline: none;

      @media (max-height: 660px) {
        padding: 5vh 5vh 2vh;
      }

      @media (max-width: 500px) {
        padding: 5vh 5vw;
        width: 83vw!important;
        max-height: auto;
      }

      .modal-header {
        .modal-title {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 7vh;

          @media (max-width: 500px) {
            padding-bottom: 5vh;
          }
  
          .decoration {
            width: 1px;
            background: #FFFFFF;
            height: 58px;
            margin: 0px 30px;

            @media (max-width: 500px) {
              margin: 0px 5px;
            }
          }
  
          h2 {
            color: #FFFFFF;
          }
        }

        .btn-close {
          background: #60605D;
          border: 1px solid #FFFFFF;
          font-size: 20px;
          width: 52px;
          height: 52px;
          position: absolute;
          right: -26px;
          top: -26px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;

        }
      }
      

      .modal-body {
        background-color: #FFFFFF;
        color: #707070;
        padding: 50px;
        font-size: 14px;

        @media (max-width: 500px) {
          padding: 10px;
        }

        p {
          color: #707070;
          margin-bottom: 3vh;
          font-size: 16px;
          line-height: 30px;
          text-align: center;

          span {
            color: #FFB20F;
            font-size: 16px;
          }

          strong {
            font-weight: 700;
          }
  
        }

        p:last-child {
          margin: 0;
        }

        .acesso {
          text-align: center;
          font-size: 18px;
        }

        .acessoLiberado {
          text-align: center;
          font-weight: 600;
          font-size: 18px;

          strong {
            font-weight: 800;
          }
        }

        .topicos {
          display: flex;
          margin: 10px 15px;
          padding-bottom: 25px;
          justify-content: center;

          @media (max-width: 1075px) {
            flex-direction: column;
            align-items: center;

            div {
              margin: 20px 0px;
            }
          }

          div {
            display: flex;
            flex-grow: 0.2;
            flex-basis: auto;
            align-items: center;

            img {
              margin-right: 10px;
            }

            p {
              font-size: 18px;
              line-height: 20px;
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        a {
          background-color: #FFB20F;
          color: #707070;
          font-size: 16px;
          text-decoration: none;
          padding: 20px;
          text-align: center;

          span {
            font-weight: 700;
          }
        }

        @media (max-width: 500px) {
          margin-top: 0px;

          a {
            padding: 10px;
          }
        }
      }
    }

    #modalEtica { 
      .modal-body {
        justify-content: center;
      }
      .modal-footer{
        .box-checkbox{
          display: flex;
          justify-content: flex-end;

          label{
            span {
              text-align: justify;
            }
          }
        }
      }

    }

    #modalConduta { 
      .modal-body {
        background-color: #525659;

        img {
          margin: 10px auto;          
          width: 100%;
        }

        .declaracao{
          padding: 20px;
          background-color: #fff;
        }
      }      
    }

    #modalAceite { 
      .modal-body {
        background-color: #525659;

        img {
          margin: 10px auto;          
        }
      }      
      .modal-footer{
        .box-checkbox{
          width: 100%;
          display: flex;
          justify-content: flex-end;

          label{
            span {
              text-align: justify;
            }
          }
        }
      }
    }


    @media (max-height: 650px) {
      #modalAceite, #modalConduta, #modalEtica, #modalLGPD, #modalImagem, #modalResponsabilidade{
        .modal-footer{
          .box-checkbox{ 
            //width: 65%;
          }
        }
      }
    }



    @media (max-width: 800px) {
      #modalAceite, #modalConduta, #modalEtica, #modalLGPD, #modalImagem, #modalResponsabilidade{
        .modal-body {
          padding: 20px 25px;
        }

        .modal-footer{
          .box-checkbox{ 
            width: 100%;
          }
        }
      }

      #modalAceite,  #modalConduta { 
        .modal-body {
          background-color: #525659;
  
          img {
            margin: 10px auto;
            width:  100%;
          }
        }
      } 

      #modalEtica, #modalAceite,  #modalConduta {
        .modal-header{
          padding: 10px 0px;
        }
        .modal-body {
          max-height: 400px;
        } 
        .modal-footer{
          padding: 20px 0px 0px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .box-checkbox{ 
            label{
              width: 100%;
            }
          }
          #btn-aceitar{
            padding: 0px;
            margin-left: 0px;
            display: flex;
            justify-content: center;
            width: 240px;
            margin-top: 15px;
          }

        } 
      }
    }



    #modalEtica { 
      .modal-body {
        background-color: #525659;

        img {
          margin: 10px auto;
          width:  100%;
        }
      }
    }    

    #modalFicha{
      width: 550px;
      height: 500px;
      position: absolute;
      margin: auto !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      border: 1px solid rgb(204, 204, 204);
      background: rgb(255, 255, 255);
      border-radius: 4px;
      padding: 20px;

      .modal-header {
          border: none;
          display: flex;
          justify-content: space-between;

          p {
              color: #ebb92d;
              font-size: 20px;
              font-weight: 800;
              text-align: left;
              max-width: 100%;
              display: block !important;
          }

          h2 {
            color: #ebb92d;
            font-weight: 800;
          }
      }

      .modal-body {
        padding: 20px 0px;
        line-height: 23px;
        color: #000000;
        height: 65vh;
        max-height: 95%;
        overflow: auto;

        p{
          color: #000000;
        }
      }

    }

  }


`;
