import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  RefObject,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import { Container, Icon } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  icon?: any;
  formRef?: RefObject<FormHandles>;
  realTime?: boolean;
}

const TegraInput: React.FC<InputProps> = ({
  name,
  label,
  icon,
  formRef,
  realTime = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputChange = useCallback(async () => {
    if (realTime && formRef && formRef.current) {
      formRef.current.submitForm();
    }
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleIconFormSubmit = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.submitForm();
    }
  }, [formRef]);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container hasError={!!error} isFocused={isFocused} isFilled={isFilled}>
      <label>{label}</label>
      {Icon && <Icon onClick={handleIconFormSubmit} src={icon} />}
      <div {...rest}>
        <input
          name={fieldName}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
      </div>
    </Container>
  );
};

export default TegraInput;
