import React from 'react';
import ProductMaterials from '../..';

const VirtualTour: React.FC = () => {
  return (
    <ProductMaterials
      title="Tour Virtual"
      subtitle=""
      description="Veja os links das visitas virtuais aos decorados de nossos empreendimentos e compartilhe com seus clientes."
      showColumnTitle={false}
      actions={['open', 'share']}
      apiUrl="TourVirtual"
      columns={[{ label: 'Produto', field: 'tx_produto' }]}
      anchorName="Tour"
      onClickItem={() => localStorage.setItem('SCROLL', '#Tour')}
    />
  );
};

export default VirtualTour;
