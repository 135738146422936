import React from 'react';
import { TegraRoundImageContainer } from './styles';

interface TegraRoundImageProps {
    src: string,
    size: string
}

const TegraRoundImage: React.FC<TegraRoundImageProps> = ({ src, size }) => {
    return (
        <TegraRoundImageContainer src={src} size={size}/>
    )
}

export default TegraRoundImage;