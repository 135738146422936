import styled from 'styled-components';
import colors from '../../../styles/colors';
import zIndex from '../../../utils/zIndex';

export const NavMainContainer = styled.div`
  width: 100%;
  border-top: 10px solid ${colors.primaryColor};
  display: flex;
  background-color: ${colors.greyBackground};
  justify-content: center;
  z-index: ${zIndex.navMain};
  @media (min-width: 992px) {
    border-top: none;
    z-index: 3;
  }
`;

export const NavMainWrapper = styled.nav`
  display: flex;
  padding: 1.25rem;
  padding-left: 2.25rem;
  justify-content: space-between;
  width: 100%;
  max-width: calc(1140px + 3.5rem);
  align-items: center;
`;
