import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import TegraButton from '../../../components/TegraButton';
import iconOpen from '../../../assets/tegra-icon-open.svg';
import iconBaixar from '../../../assets/tegra-icon-baixar.svg';
import iconShare from '../../../assets/tegra-icon-share.svg';
import iconMoneysign from '../../../assets/tegra-icon-moneysign.svg';
import api, { baseURL } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { log, ETipoAtividade, ETipoSessao } from '../../../utils/log';
import { ToSlug } from '../../../services/helpers';

interface IMaterialsTableActionProps {
  actionType: 'open' | 'download' | 'share' | 'simulate';
  id: string;
  openShare: () => void;
  anchorName: string;
  link: string;
  download?: string;
  titulo: string;
  produto: string;
  uf: string;
  slug: string;
  cidade: string;
  id_material: string;
}

const MaterialsTableAction: React.FC<IMaterialsTableActionProps> = ({
  actionType,
  id,
  openShare,
  anchorName,
  link,
  download,
  titulo,
  produto,
  uf,
  slug,
  cidade,
  id_material,
}) => {
  const history = useHistory();
  const { user } = useAuth();

/*
  Pela regra já estabelecida aqui, qdo tem empreendimento a seção é do produto
*/

  function tipoSessao(){
    switch (anchorName) {
      case 'MateriaisCorretor':
        return ETipoSessao.MateriaisCorretor;
      case 'Manuais':
        return ETipoSessao.Manuais;
      case 'CicloNegocio':
        return ETipoSessao.CicloNegocio;
      case 'Ferramentas':
        return ETipoSessao.TreinamentoFerramentas;
      case 'TreinamentoDiversos':
        return ETipoSessao.TreinamentosDiversos;     
      default:
        return ETipoSessao.Produto;
    }
  }

  const materialsActionData = {
    open: {
      label: 'VISUALIZAR',
      icon: iconOpen,
      callback: () => {
        log(
          produto,
          titulo,
          ETipoAtividade.Acessou,
          tipoSessao(),
          id,
          link,
          id,
          user,
        );
        switch (anchorName) {
          case 'Videos':
          case 'Tour':
          case 'Imagens':
          case 'Plantas':
            if (link) {
              window.open(`${link}`);
            }
            break;
          case 'Espelhos':
            history.push(`/dashboard/espelhos-e-tabelas/${id}`);
            break;
          default:
            if (link) {
              window.open(`${link}`);
            }
            break;
        }
      },
    },
    download: {
      label: (id == '335' ? 'VISUALIZAR' : 'BAIXAR'),
      icon: (id == '335' ? iconOpen : iconBaixar),
      callback: () => {
        log(
          produto,
          titulo,
          ETipoAtividade.Download,
          tipoSessao(),
          id,
          download || link,
          id_material,
          user,
        );
        if (anchorName === 'Plantas' && id == '335'){
          window.open('https://empreendimentos.tegraincorporadora.com.br/clarisplantas/','_blank');
        }
        else if (anchorName === 'Imagens') {
          const apiURL = `Download/imagens/${id},${'TodasImagens'}`;
          api
            .get(apiURL, { responseType: 'blob' })
            .then(response => {
              const nav = (window.navigator as any);        
              if (nav.msSaveOrOpenBlob) {
                // IE11
                nav.msSaveOrOpenBlob(
                  new Blob([response.data]),
                  `${produto}.zip`,
                );
              } else {
                const url = window.URL.createObjectURL(
                  new Blob([response.data]),
                );
                const lnk = document.createElement('a');
                lnk.href = url;
                lnk.setAttribute('download', `${produto}.zip`);
                document.body.appendChild(lnk);
                lnk.click();
                document.body.removeChild(lnk);
                setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(url);
                }, 100);
              }
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
        } else if (anchorName === 'Plantas') {
          const apiURL = `Download/imagens/${id},${'Plantas'}`;
          api
            .get(apiURL, { responseType: 'blob' })
            .then(response => {
              const nav = (window.navigator as any);        
              if (nav.msSaveOrOpenBlob) {
                // IE11
                nav.msSaveOrOpenBlob(
                  new Blob([response.data]),
                  `${produto}.zip`,
                );
              } else {
                const url = window.URL.createObjectURL(
                  new Blob([response.data]),
                );
                const lnk = document.createElement('a');
                lnk.href = url;
                lnk.setAttribute('download', `${produto}.zip`);
                document.body.appendChild(lnk);
                lnk.click();
                document.body.removeChild(lnk);
                setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(url);
                }, 100);
              }
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
        } else if (anchorName === 'Videos') {
          // window.open(download || link);
          const lnk = document.createElement('a');
          lnk.href = download || link;
          lnk.setAttribute('download', lnk.href);
          lnk.target = '_blank';
          document.body.appendChild(lnk);
          lnk.click();
          document.body.removeChild(lnk);
        } else if (anchorName === 'Materiais Gerais') {
          console.log('anchorName',anchorName);
          const lnk = document.createElement('a');
          lnk.href = download || link;
          lnk.setAttribute('download', lnk.href);
          lnk.target = '_blank';
          document.body.appendChild(lnk);
          lnk.click();
          document.body.removeChild(lnk);          
        } else
          window.open(
            `${baseURL}Download?empreendimento=${encodeURIComponent(
              produto,
            )}&legendas=${encodeURIComponent(titulo)}&link=${decodeURIComponent(
              download || link,
            )}`,
          );
      },
    },
    share: {
      label: 'COMPARTILHAR',
      icon: iconShare,
      callback: () => {
        openShare();
        log(
          produto,
          titulo,
          ETipoAtividade.Compartilhou,
          tipoSessao(),
          id,
          download || link,
          id,
          user,
        );
      },
    },
    simulate: {
      label: 'SIMULAR',
      icon: iconMoneysign,
      callback: () => {
        localStorage.setItem('SCROLL', '#Simule');
        history.push(
          `/dashboard/produtos/${uf.toLowerCase()}/${ToSlug(cidade)}/${slug}`,
        );
      },
    },
  };

  const handleClick = useCallback(() => {
    materialsActionData[actionType].callback();
  }, [actionType, id, download, link, anchorName]);

  if (
    (anchorName === 'Videos' || anchorName === 'MateriaisGerais')  &&
    ((!download && actionType === 'download') ||
      (!link && (actionType === 'open' || actionType === 'share')))
  )
    return <></>;

  return (
    <TegraButton
      isMaterials
      icon={materialsActionData[actionType].icon}
      isAction={false}
      onClick={() => handleClick()}
    >
      {materialsActionData[actionType].label}
    </TegraButton>

  );
};

export default MaterialsTableAction;
