import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Calendar from 'react-calendar';
import { Link, useRouteMatch } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';
import Modal from 'react-modal';
import './Calendar.css';
import {
  Container,
  CustomDots,
  HeroWelcome,
  HeroNewsHeader,
  HeroCalendarContent,
  Hero,
  HeroCalendar,
  HeroContent,
  HeroNews,
  HeroNewsContent,
  HeroNewsNavigation,
  VerMais,
  Content,
  CalendarContainer,
  TitleCalendar,
} from './styles';
import calendarIcon from '../../assets/tegra-icon-calendar.svg';
import OptionsGrid from './OptionsGrid';
import '@brainhubeu/react-carousel/lib/style.css';
import noticiasIcon from '../../assets/tegra-icon-noticias.svg';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import { useAuth } from '../../hooks/auth';
import { getToken, processLogin } from '../../utils/login';
import { getTituloByDomain } from '../../utils/logo';

import pdfAbrainc1 from '../../assets/pdf_abrainc/1.jpg';
import pdfAbrainc2 from '../../assets/pdf_abrainc/2.jpg';
import pdfAbrainc3 from '../../assets/pdf_abrainc/3.jpg';
import pdfAbrainc4 from '../../assets/pdf_abrainc/4.jpg';
import pdfAbrainc5 from '../../assets/pdf_abrainc/5.jpg';
import pdfAbrainc6 from '../../assets/pdf_abrainc/6.jpg';
import pdfAbrainc7 from '../../assets/pdf_abrainc/7.jpg';
import pdfAbrainc8 from '../../assets/pdf_abrainc/8.jpg';
import pdfAbrainc9 from '../../assets/pdf_abrainc/9.jpg';
import pdfAbrainc10 from '../../assets/pdf_abrainc/10.jpg';
import pdfAbrainc11 from '../../assets/pdf_abrainc/11.jpg';
import pdfAbrainc12 from '../../assets/pdf_abrainc/12.jpg';
import pdfAbrainc13 from '../../assets/pdf_abrainc/13.jpg';
import pdfAbrainc14 from '../../assets/pdf_abrainc/14.jpg';
import pdfAbrainc15 from '../../assets/pdf_abrainc/15.jpg';
import pdfAbrainc16 from '../../assets/pdf_abrainc/16.jpg';
import pdfAbrainc17 from '../../assets/pdf_abrainc/17.jpg';
import pdfAbrainc18 from '../../assets/pdf_abrainc/18.jpg';
import pdfAbrainc19 from '../../assets/pdf_abrainc/19.jpg';
import pdfAbrainc20 from '../../assets/pdf_abrainc/20.jpg';

import pdfcodigo1 from '../../assets/pdf_conduta/1.jpg';
import pdfcodigo2 from '../../assets/pdf_conduta/2.jpg';
import pdfcodigo3 from '../../assets/pdf_conduta/3.jpg';
import pdfcodigo4 from '../../assets/pdf_conduta/4.jpg';
import pdfcodigo5 from '../../assets/pdf_conduta/5.jpg';
import pdfcodigo6 from '../../assets/pdf_conduta/6.jpg';
import pdfcodigo7 from '../../assets/pdf_conduta/7.jpg';
import pdfcodigo8 from '../../assets/pdf_conduta/8.jpg';
import pdfcodigo9 from '../../assets/pdf_conduta/9.jpg';
import pdfcodigo10 from '../../assets/pdf_conduta/10.jpg';
import pdfcodigo11 from '../../assets/pdf_conduta/11.jpg';
import pdfcodigo12 from '../../assets/pdf_conduta/12.jpg';
import pdfcodigo13 from '../../assets/pdf_conduta/13.jpg';

import iconCalendar from '../../assets/calendario.svg';
import iconOpiniao from '../../assets/opiniao.svg';
import iconAcesso from '../../assets/acesse.svg';
import iconJogue from '../../assets/jogue.svg';
import logoInovaTegra from '../../assets/logoInovaTegra.png';
import { Console } from 'console';
import { AxiosResponse } from 'axios';
import { ObjectFlags } from 'typescript';
import { getDay, getMonth } from 'date-fns';
import { getWeek } from 'date-fns/esm';
import moment from 'moment';
import CalendarioTrabalho from '../CalendarioTrabalho';

interface CarouselSlide {
  titulo: string;
  subtitulo: string;
  id: number;
  link: string;
  id_tipo: number;
  tipo: 'novidade' | 'calendario' | 'premiacao';
}

interface Calendario {
  titulo: string;
  subtitulo: string;
  id: number;
  link: string;
  id_tipo: number;
  tipo: 'calendario';
}

interface Novidade {
  id_novidade: number;
  id_tipo: number;
  tx_titulo: string;
  tx_subtitulo: string;
}

interface NovidadeResponse {
  items: Novidade[];
}

interface ICalendar {
  id_novidade: number;
  id_tipo: number;
  tx_titulo: string;
  dt_inicio: string;
  dt_fim: string;
}

const Home: React.FC = () => {
  //const [covidchecked, setCovidChecked] = useState(false);
  const [abraincchecked, setAbraincChecked] = useState(false);
  const [eticachecked, setEticaChecked] = useState(false);
  const [condutachecked, setCondutaChecked] = useState(false);
  const [lgpdchecked, setLgpdChecked] = useState(false);
  const [imagemchecked, setImagemChecked] = useState(false);
  const [responsabilidadechecked, setResponsabilidadeChecked] = useState(false);

  const [enabled, setEnabled] = useState(false);
  const [enabledEtica, setEnabledEtica] = useState(false);
  const [enabledConduta, setEnabledConduta] = useState(false);
  const [enabledLgpd, setEnabledLgpd] = useState(false);
  const [enabledImagem, setEnabledImagem] = useState(true);
  const [enabledResponsabilidade, setEnabledResponsabilidade] = useState(true);
  //const [enabledExpertegra, setEnabledExpertegra] = useState(false);

  //const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenAbrainc, setIsOpenAbrainc] = React.useState(false);
  const [modalIsOpenConduta, setIsOpenConduta] = React.useState(false);
  const [modalIsOpenEtica, setIsOpenEtica] = React.useState(false);
  const [modalIsOpenLgpd, setIsOpenLgpd] = React.useState(false);
  const [modalIsOpenImagem, setIsOpenImagem] = React.useState(false);
  //const [modalIsOpenExpertegra, setIsOpenExpertegra] = React.useState(false);
  const [modalIsOpenResponsabilidade, setIsOpenResponsabilidade] = React.useState(false);

  //const [termoMascara, setTermoMascara] = React.useState(false);
  const [termoAbrainc, setTermoAbrainc] = React.useState(false);
  const [termoConduta, setTermoConduta] = React.useState(false);
  const [termoEtica, setTermoEtica] = React.useState(false);
  const [termoLgpd, setTermoLgpd] = React.useState(false);
  const [termoImagem, setTermoImagem] = React.useState(false);
  const [termoResponsabilidade, setTermoResponsabilidade] = React.useState(false);

  const [carouselValue, setCarouselValue] = useState(0);
  const [slides, setSlides] = useState<CarouselSlide[]>([]);
  const [ultimoCalendario, setUltimoCalendario] = useState<Calendario>();

  const { setLoaderActive } = useLoader();

  const { path } = useRouteMatch();
  const { user, authenticatedAzureAD } = useAuth();

  const [dataAtual, setDataAtual] = useState(new Date());
  const [isEventos, setIsEventos] = useState(false);
  const [mesSelecionado, setMesSelecionado] = useState(dataAtual.getMonth());
  const [calendarioGeral, setCalendarioGeral] = useState<ICalendar[]>([]);
  const [posicaoX, setPosicaoX] = useState('0px');
  const [posicaoY, setPosicaoY] = useState('0px');
  const [listaEventos, setListaEventos] = useState<any[]>([]);


  useEffect(() => {
    async function loadNovidades(): Promise<void> {
      try {
        setLoaderActive(true);
        let rsp: AxiosResponse<any>;
        console.log('authenticatedAzureAD',authenticatedAzureAD);
        if (!authenticatedAzureAD) {          
          rsp = await api.post('Login/relogin');
        } else {
          // Chamada para garantir que os dados retornados após o login no AD sejam os mesmos que existem na base
          const token = getToken();
          rsp = await api.post('Login/loginAD');
          rsp.data = { data: rsp.data.data, access_token: token };
        }
        console.log('rsp.data',rsp.data);

        const { access_token: token, data: objUser } = rsp.data;
        processLogin(objUser, token);

        //const modalExpertegra = localStorage.getItem('modalExpertegra');
        //const openModalExpertegra = modalExpertegra && modalExpertegra === 'false'

        /*
        if ( openModalExpertegra && (objUser.idCargoSigavi === 3 || objUser.idCargoSigavi === 22) && objUser.idRegional === 2) {
          setIsOpenExpertegra(true);
        } else 
        */
        if (!objUser.termoAceiteAbrainc && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setIsOpenAbrainc(true);
        } else if (!objUser.termoAceiteConduta  && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1){
          setIsOpenConduta(true);
        } else if (!objUser.termoAceiteEtica && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setIsOpenEtica(true);          
        } else if (!objUser.termoAceiteLgpd && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setIsOpenLgpd(true);          
        } else if (!objUser.termoAceiteImagem && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setIsOpenImagem(true);      
        }else if (!objUser.termoAceiteResponsabilidade && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1 && objUser.idRegional == 1) {
          setIsOpenResponsabilidade(true);
        } 
        
        if (!objUser.termoAceiteResponsabilidade && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1 && objUser.idRegional == 1) {
          setTermoResponsabilidade(true);
        } 

        if (!objUser.termoAceiteAbrainc && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setTermoAbrainc(true);
        }

        if (!objUser.termoAceiteConduta && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setTermoConduta(true);
        }

        if (!objUser.termoAceiteEtica && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setTermoEtica(true);
        }

        if (!objUser.termoAceiteLgpd && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setTermoLgpd(true);
        }

        if (!objUser.termoAceiteImagem && objUser.idCargoSigavi != 0 && objUser.idCargoSigavi != -1) {
          setTermoImagem(true);
        }
        
        const {
          data: { items },
        } = await api.get<NovidadeResponse>('Novidades', {
          params: { pagina: 1, quantidade: 5 },
        });

        const {
          data: { items: lstCalendarios },
        } = await api.get<NovidadeResponse>('CalendarioTrabalho', {
          params: { pagina: 1, quantidade: 1 },
        });

        
        
        const lstCalendariosGeral = await api.get<any[]>(`CalendarioTrabalho/listAllCalendario`);      
        let newDiaCalendar: ICalendar[] = [];
        lstCalendariosGeral.data.map(d =>{
          if (d.dt_inicio < d.dt_fim){
              var inicio = new Date(d.dt_inicio);
              var fim = new Date(d.dt_fim);
              while (inicio <= fim){
                newDiaCalendar.push({
                  id_novidade: d.id_novidade,
                  id_tipo: d.id_tipo,
                  tx_titulo: d.tx_titulo,
                  dt_inicio: moment(inicio).format('YYYY-MM-DD'),
                  dt_fim: moment(fim).format('YYYY-MM-DD'),
                });
                inicio.setDate(inicio.getDate() + 1);                                    
              }                
          }
          else{
            newDiaCalendar.push({
              id_novidade: d.id_novidade,
              id_tipo: d.id_tipo,
              tx_titulo: d.tx_titulo,
              dt_inicio: d.dt_inicio.substring(0,10),
              dt_fim: d.dt_fim.substring(0,10),
            });
          }
        }
        );
        setCalendarioGeral(newDiaCalendar);


        const newSlides = items.map(
          novidade =>
            ({
              titulo: novidade.tx_titulo,
              subtitulo: novidade.tx_subtitulo,
              id: novidade.id_novidade,
              link: `${path}/novidades/${novidade.id_novidade}`,
              id_tipo: novidade.id_tipo,
              tipo: 'novidade',
            } as CarouselSlide),
        );
        setSlides(newSlides);
        if (lstCalendarios && lstCalendarios.length)
          setUltimoCalendario(
            lstCalendarios.map(
              cal =>
                ({
                  titulo: cal.tx_titulo,
                  subtitulo: cal.tx_subtitulo,
                  id: cal.id_novidade,
                  link: `${path}/calendario-de-eventos/${cal.id_novidade}`,
                  id_tipo: cal.id_tipo,
                } as Calendario),
            )[0],
          );
        setLoaderActive(false);
      } catch {
        localStorage.removeItem('@Tegra:user');
        localStorage.removeItem('@Tegra:token');
        sessionStorage.removeItem('@Tegra:user');
        //localStorage.removeItem('modalExpertegra');
        window.location.href = '/';
        console.error('Não foi possível carregar novidades');
      }
    }

    loadNovidades();
  }, [path,user,termoAbrainc, termoConduta, termoEtica, termoImagem, termoLgpd, termoResponsabilidade]);

    const aceitarModalAbrainc = useCallback(async () => {
      if(abraincchecked){
        setLoaderActive(true);
        await api.post('Perfil/termoAceiteAbrainc');
        setIsOpenAbrainc(false);
        if (termoConduta) {
          setIsOpenConduta(true);
        } else if (termoEtica) {
          setIsOpenEtica(true);
        } else if (termoLgpd){
          setIsOpenLgpd(true);
        } else if (termoImagem){
          setIsOpenImagem(true);
        } else if (termoResponsabilidade){
          setIsOpenResponsabilidade(true);
        }

        setLoaderActive(false);
      }
  }, [abraincchecked, termoConduta, termoEtica, termoLgpd, termoImagem, termoResponsabilidade]);

  const aceitarModalConduta = useCallback(async () => {
    if(condutachecked){
      setLoaderActive(true);
      await api.post('Perfil/termoAceiteConduta');
      setIsOpenConduta(false);
      if (termoEtica) {
        setIsOpenEtica(true);
      } else if (termoLgpd){
        setIsOpenLgpd(true);
      } else if (termoImagem){
        setIsOpenImagem(true);
      } else if (termoResponsabilidade){
        setIsOpenResponsabilidade(true);
      }
      setLoaderActive(false);
    }
  }, [condutachecked, termoEtica, termoLgpd, termoImagem, termoResponsabilidade]);

  const aceitarModalEtica = useCallback(async () => {
    if(eticachecked){
      setLoaderActive(true);
      await api.post('Perfil/termoAceiteEtica');
      setIsOpenEtica(false);
      if (termoLgpd){
        setIsOpenLgpd(true);
      } else if (termoImagem){
        setIsOpenImagem(true);
      } else if (termoResponsabilidade){
        setIsOpenResponsabilidade(true);
      }
      setLoaderActive(false);
    }
  }, [eticachecked,termoLgpd, termoImagem, termoResponsabilidade]);

  const aceitarModalLGPD = useCallback(async () => {
    if(lgpdchecked){
      setLoaderActive(true);
      await api.post('Perfil/termoAceiteLGPD');
      setIsOpenLgpd(false);
      if (termoImagem){
        setIsOpenImagem(true);
      } else if (termoResponsabilidade){
        setIsOpenResponsabilidade(true);
      }
      setLoaderActive(false);
    }
  }, [lgpdchecked, termoImagem, termoResponsabilidade]);

  const aceitarModalImagem = useCallback(async () => {
    if(imagemchecked){
      setLoaderActive(true);
      await api.post('Perfil/termoAceiteImagem');
      setIsOpenImagem(false);
      if (termoResponsabilidade){
        setIsOpenResponsabilidade(true);
      }
      setLoaderActive(false);
    }
  }, [imagemchecked, termoResponsabilidade]);


  const aceitarModalResponsabilidade = useCallback(async () => {

    if(responsabilidadechecked){
      setLoaderActive(true);
      await api.post('Perfil/termoAceiteResponsabilidade');
      setIsOpenResponsabilidade(false);
      setLoaderActive(false);
    }
  }, [responsabilidadechecked]);


  const onScroll = useCallback(event => {
    const el = document.getElementById('scroll-body');
    if (el && el.scrollHeight - el.scrollTop - el?.clientHeight < 5) {
      setEnabled(true);
    }
  }, []);

  const onScrollEtica = useCallback(event => {
    const el = document.getElementById('scroll-body');
    if (el && el.scrollHeight - el.scrollTop - el?.clientHeight < 5) {
      setEnabledEtica(true);
    }
  }, []);

  const onScrollConduta = useCallback(event => {
    console.log('conduta');
    const el = document.getElementById('scroll-body');
    if (el && el.scrollHeight - el.scrollTop - el?.clientHeight < 5) {
      setEnabledConduta(true);
    }
  }, []);

  const onScrollLGPD = useCallback(event => {
    const el = document.getElementById('scroll-body');
    if (el && el.scrollHeight - el.scrollTop - el?.clientHeight < 5) {
      setEnabledLgpd(true);
    }
  }, []);

  const onScrollImagem = useCallback(event => {
    const el = document.getElementById('scroll-body');
    if (el && el.scrollHeight - el.scrollTop - el?.clientHeight < 5) {
      setEnabledImagem(true);
    }
  }, []);

  const onScrollResponsabilidade = useCallback(event => {
    const el = document.getElementById('scroll-body');
    if (el && el.scrollHeight - el.scrollTop - el?.clientHeight < 5) {
      setEnabledResponsabilidade(true);
    }
  }, []);

  const onChange = useCallback((value: number) => {
    setCarouselValue(value);
  }, []);

  const confirmExpertegra = useCallback(() => {
    setLoaderActive(true);
    //setIsOpenExpertegra(false)
    if (termoAbrainc) {
      setIsOpenAbrainc(true);
    } else if (termoConduta) {
      setIsOpenConduta(true);
    } else if (termoEtica) {
      setIsOpenEtica(true);
    } else if (termoLgpd){
      setIsOpenLgpd(true);
    } else if (termoImagem){
      setIsOpenImagem(true);
    } else if (termoResponsabilidade){
      setIsOpenResponsabilidade(true);
    }

    setLoaderActive(false);
    //localStorage.setItem('modalExpertegra', 'true')
  }, [termoAbrainc, termoConduta, termoEtica, termoLgpd, termoImagem, termoResponsabilidade]);

  const currentCarouselLink = useMemo<string>(() => {
    if (!slides[carouselValue]) {
      return '/';
    }
    const findSlide = slides[carouselValue];
    return findSlide.link;
  }, [carouselValue, slides]);

  const currentCarouselTitle = useMemo<string>(() => {
    if (!slides[carouselValue]) {
      return '';
    }
    const findSlide = slides[carouselValue];
    switch (findSlide.tipo) {
      case 'novidade':
        return 'Novidades';
        break;
      case 'calendario':
        return 'Calendário de Eventos';
        break;
      case 'premiacao':
        return 'Premiações e metas';
      default:
        return 'Notícias';
        break;
    }

    return findSlide.tipo;
  }, [carouselValue, slides]);

  
  const onChangeCalendario = date =>{
    setDataAtual(date);
  }

  function onMouseOverCalendario(isFuture){
    setIsEventos(isFuture);
  }

  function _onMouseMove(e, lista) { 
    setPosicaoX((e.pageX+20)+'px');
    setPosicaoY((e.pageY-20)+'px');
    setListaEventos(lista);
  }


  const printDay = useCallback((dt) => {
    var dataHoje = new Date();
    var isDataAnterior = moment(dt).format('YYYY-MM-DD') < moment(dataHoje).format('YYYY-MM-DD');
    var isHoje = moment(dataHoje).format('YYYY-MM-DD') === moment(dt).format('YYYY-MM-DD');
    var isFimSemana = dt.getDay() === 0 || dt.getDay() === 6;
    return (
      <div style={{height:'28px', width:'100%', display: 'flex', justifyContent: 'center', marginBottom: '2px'}}>
        <div className={ isHoje ? 'diaHoje' : 
                           isFimSemana ? 'diaFimSemana' : 'diaUtil'} >
          <p className={isFimSemana && !isDataAnterior ? 'diaFimSemanaTexto' : 'diaUtilTexto'} 
             style={{opacity: isDataAnterior? '30%': '100%' }} >
            {dt.getDate()}
          </p> 
        </div>
      </div>); 
  }, [])


  const printEventFuture = useCallback((dt, lista) => {    
    var dataParam =  moment(new Date(dt)).format('YYYY-MM-DD');
    var dataHoje = moment(new Date()).format('YYYY-MM-DD');

    var url ;
    if (lista.length > 1){      
      url = `${path}/calendario-de-eventos/data/${moment(dt).format('DD-MM-YYYY')}`
    }
    else{
      url = `${path}/calendario-de-eventos/${lista[0].id_novidade}`;
    }
    return(
      <a href={url} onMouseOver={() => onMouseOverCalendario(dataParam >= dataHoje)}  onMouseLeave={()=>setIsEventos(false)}
        onMouseMove={(e) => dataParam >= dataHoje ? _onMouseMove(e, lista) : null} style={{textDecoration:'none'}}>
        <div style={{height:'28px', width:'100%', display: 'flex', justifyContent: 'center', marginBottom: '2px'}}>
          <div className= {dataParam >= dataHoje? 'eventoFuturo' :'eventoAnterior'}>
            <p className='eventoAnteriorTexto'>{dt.getDate()}</p> 
          </div>                        
        </div>                                        
      </a>
    )
  }, []);


  return (
    <Container>
      <Modal
        id="modalAceite"
        className={`${enabled ? 'modal-enabled' : ''}`}
        isOpen={modalIsOpenAbrainc}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLabel">
            Guia ABRAINC
          </h2>
        </div>
        <div id="scroll-body" className="modal-body" onScroll={onScroll}>
          <img src={pdfAbrainc1} alt=""/>
          <img src={pdfAbrainc2} alt=""/>
          <img src={pdfAbrainc3} alt=""/>
          <img src={pdfAbrainc4} alt=""/>
          <img src={pdfAbrainc5} alt=""/>
          <img src={pdfAbrainc6} alt=""/>
          <img src={pdfAbrainc7} alt=""/>
          <img src={pdfAbrainc8} alt=""/>
          <img src={pdfAbrainc9} alt=""/>
          <img src={pdfAbrainc10} alt=""/>
          <img src={pdfAbrainc11} alt=""/>
          <img src={pdfAbrainc12} alt=""/>
          <img src={pdfAbrainc13} alt=""/>
          <img src={pdfAbrainc14} alt=""/>
          <img src={pdfAbrainc15} alt=""/>
          <img src={pdfAbrainc16} alt=""/>
          <img src={pdfAbrainc17} alt=""/>
          <img src={pdfAbrainc18} alt=""/>
          <img src={pdfAbrainc19} alt=""/>
          <img src={pdfAbrainc20} alt=""/>
        </div>
        <div className="modal-footer">
          <div className="box-checkbox">
            <input type="checkbox" id="concordo" name="concordo"
                    defaultChecked={abraincchecked}
                    onChange={(e) => setAbraincChecked(e.target.checked)}/>
            <label htmlFor="concordo">
            <span>Declaro ter ciência do teor do material disponibilizado 
              pela Associação Brasileira de Incorporadoras Imobiliárias (Abrainc) denominado 
              "Guia Abrainc de Boas Práticas na Intermediação Imobiliária", fornecido neste 
              ato e também disponibilizado neste Portal através do item - Materiais Gerais {">"} Materiais do Corretor.</span>{' '}
            </label>
          </div>
          <div id="btn-aceitar" onClick={aceitarModalAbrainc}>
            Continuar
          </div>
        </div>
      </Modal> 
      <Modal
        id="modalConduta"
        className={`${enabledConduta ? 'modal-enabled' : ''}`}
        isOpen={modalIsOpenConduta}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLabel">
          CÓDIGO DE CONDUTA PARA CORRETORES ASSOCIADOS
          </h2>
        </div>
        <div id="scroll-body" className="modal-body" onScroll={onScrollConduta}>
          <img src={pdfcodigo1} alt=""/>
          <img src={pdfcodigo2} alt=""/>
          <img src={pdfcodigo3} alt=""/>
          <img src={pdfcodigo4} alt=""/>
          <img src={pdfcodigo5} alt=""/>
          <img src={pdfcodigo6} alt=""/>
          <img src={pdfcodigo7} alt=""/>
          <img src={pdfcodigo8} alt=""/>
          <img src={pdfcodigo9} alt=""/>
          <img src={pdfcodigo10} alt=""/>
          <img src={pdfcodigo11} alt=""/>
          <img src={pdfcodigo12} alt=""/>
          <img src={pdfcodigo13} alt=""/>
          {/*
          <div className='declaracao'> 
            <br/>
            <br/>
            Declaro para os devidos fins que:
            <br/>
            <br/>
            (i) Tenho total conhecimento da existência e do conteúdo do Código de Conduta para corretores associados apresentado neste Ato, e que recebi as orientações para acessa-lo também posteriormente no portal do corretor, bem como realizar o seu download, declaro ainda que li e entendi;
            <br/>
            <br/>
            (ii) Estou ciente de que o Código de Conduta para corretores associados, como um todo, passa a fazer parte dos meus deveres como Parceiro Operacional da Tegra Vendas;
            <br/>
            <br/>
            (iii) Comprometo-me a observar integralmente os termos previstos no Código de Conduta para corretores associados;
            <br/>
            <br/>
            (iv) Tenho total conhecimento de que, a partir desta data, a não observância do Código de Conduta para corretores associados, poderá implicar na caracterização de falta grave, fato que poderá ser passível da aplicação das penalidades cabíveis, graduadas em função da gravidade e da reiteração, a critério da Tegra Vendas.
            <br/>
            <br/>
            <br/>
          </div>
        */}
        </div>

        <div className="modal-footer">
          <div className="box-checkbox">
            <input type="checkbox" id="concordo" name="concordo"
                defaultChecked={condutachecked}
                onChange={(e) => setCondutaChecked(e.target.checked)}/>
            <label htmlFor="concordo">
              <span>
                Declaro que li o Código de Conduta para Corretores Associados e 
                comprometo a observa-lo integralmente nos meus deveres como Parceiro 
                da Tegra Vendas. A não observância a este Código, poderá implicar na 
                caracterização de falta grave, podendo ser passível de rescisão contratual. 
                Este documento está disponibilizado neste Portal através do item - Compliance.
              </span>{' '}
            </label>
          </div>
          <div id="btn-aceitar" onClick={aceitarModalConduta}>
            Continuar
          </div>
        </div>
      </Modal>
      
      <Modal
        id="modalEtica"
        className='modal-enabled' 
        isOpen={modalIsOpenEtica}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLabel">            
            PREVENÇÃO À LAVAGEM DE DINHEIRO E AO FINANCIAMENTO DO TERRORISMO
          </h2>
        </div>
        <div id="scroll-body" className="modal-body" >
          <div className='video'> 
            <iframe
                width="100%"
                height="400px"
                src={`https://www.youtube.com/embed/aoK0CRa_0d4`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
          </div> 
        </div>
        <div className="modal-footer">
          <div className="box-checkbox">
            <input type="checkbox" id="concordo" name="concordo"
                defaultChecked={eticachecked}
                onChange={(e) => setEticaChecked(e.target.checked)}/>
            <label htmlFor="concordo">
              <span>Declaro que vi o vídeo e compreendi sobre os cuidados a Prevenção 
                    à Lavagem de Dinheiro e ao Financiamento do Terrorismo.</span>{' '}
            </label>
          </div>
          <div id="btn-aceitar" onClick={aceitarModalEtica}>
            Continuar
          </div>
        </div>
      </Modal>
      <Modal
        id="modalLGPD"
        className={`${enabledLgpd ? 'modal-enabled' : ''}`}
        isOpen={modalIsOpenLgpd}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLabel">
            Termo de Ciência e Compromisso de Privacidade de Dados
          </h2>
        </div>

        <div id="scroll-body" className="modal-body" onScroll={onScrollLGPD}>
         Eu, <strong>DECLARO</strong> para os devidos fins de direito que:
         <br />
         <br />
         <ul>
            <li>
              Em razão da minha relação de corretor de imóveis parceiro, sem qualquer
              vínculo trabalhista com a Tegra Vendas, terei acesso a diversas informações e dados
              sensíveis, privativos e protegidos pela Lei no. 13.709 (LGPD) de clientes e potenciais
              clientes da Tegra Vendas e empresas por ela atendidas.
              <br />
              <br />
            </li>
            <li>
              Tive acesso, procedi a leitura e Aceito os Termos das diretrizes de privacidade e tratamento
              de Dados Pessoais da Tegra Vendas, cuja versão digital e/ou impressa me foi encaminhada junto
              a este Termo;
              <br />
              <br />
            </li>
            <li>
              Tive acesso e procedi a leitura de material orientativo sobre a LGPD, disponibilizado pela Tegra Vendas;
              <br />
              <br />
            </li>
            <li>
              Tenho ciência e compreendo que, em função das diretrizes da LGPD relativas a tratamento de
              Dados Pessoais, a minha atuação nessa parceria poderá variar entre:
              <br />
              <br />
              <ul className="topicos2">
                <li>
                    <strong>CONTROLADOR</strong> do tratamento de Dados Pessoais de Terceiros, ao utilizar
                    base de clientes própria, onde atuei previamente na coleta, armazenamento e uso desses
                    dados para desempenho de minhas atividades de oferta de produtos; ou
                    <br />
                    <br />
                </li>
                <li>
                    <strong>OPERADOR</strong> do tratamento de Dados Pessoais, quando recebo os dados previamente
                    coletados pela Tegra Vendas ou qualquer outro <strong>CONTROLADOR</strong> para desempenho de
                    minhas atividades;
                    <br />
                    <br />
                </li>
              </ul>
            </li>
            <li>
              Tenho ciência e compreendo que, em função da LGPD, os Titulares de Dados Pessoais (independente se
              oriundos de processos de prospecção, de campanhas de marketing, de cadastros de Clientes, etc.) têm,
              a qualquer tempo, o Direito de, entre outras, revogar o consentimento de uso e/ou solicitar a exclusão
              de seus Dados Pessoais/cadastrais das bases do CONTROLADOR e/ou do <strong>OPERADOR</strong>, incluindo
              mas não se limitando as listas de oferta ativa (telefônico, WhatsApp, etc.), e-mail marketing e/ou
              quaisquer outros acionamentos baseados nesses dados;
              <br />
              <br />
            </li>
            <li>
              Tenho ciência e compreendo que <strong>DEVO</strong> acatar as solicitações dos Titulares de Dados
              Pessoais no exercício de seus Direitos, independente dessas solicitações serem efetuadas diretamente a
              mim ou por intermédio da Tegra Vendas ou qualquer outro <strong>CONTROLADOR</strong>,
              <strong> COMPROMETENDO-ME</strong> a proceder de imediato as ações conforme a natureza das solicitações
              (ex.: cessar acionamentos, excluir dados cadastrais não autorizados, etc.) independentemente da minha
              atuação como <strong>CONTROLADOR</strong> ou <strong>OPERADOR</strong> do tratamento desses Dados Pessoais;
              <br />
              <br />
            </li>
            <li>
              Tenho ciência e compreendo as minhas responsabilidades decorrentes da LGPD, principalmente em relação à
              responsabilização e prestação de contas quanto ao uso não autorizado ou sem justificativa legal de Dados
              Pessoais de Terceiros (mesmo se oriundos de processos de prospecção, de campanhas de marketing, de
              cadastros de Clientes, etc.), independente da minha atuação como <strong>CONTROLADOR</strong> do
              tratamento desses dados pessoais ou da minha atuação como <strong>OPERADOR</strong> do tratamento
              desses dados pessoais;
              <br />
              <br />
            </li>
         </ul>
          <br />
          Glossário de termos utilizados nesse documento:
          <br />
          <br />
          <strong>TITULAR</strong> – Pessoa a quem os dados pessoais referem.
          <br />
          <br />
          <strong>CONTROLADOR</strong> – Quem toma as decisões sobre como os dados são tratados.
          <br />
          <br />
          <strong>OPERADOR</strong> – Quem trata dados em nome do Controlador e sob suas orientações (parceiros, prestadores, etc.).
          <br />
          <br />
          <strong>TRATAMENTO</strong> - Toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
          <br />
          <br />
        </div>
        <div className="modal-footer">
          <div className="box-checkbox">
            <input type="checkbox" id="concordo" name="concordo"
                defaultChecked={lgpdchecked}
                onChange={(e) => setLgpdChecked(e.target.checked)}/>
            <label htmlFor="concordo">
              <span>Li e aceito o termo</span>{' '}
            </label>
          </div>
          <div id="btn-aceitar" onClick={aceitarModalLGPD}>
            Continuar
          </div>
        </div>
      </Modal>
      <Modal
        id="modalImagem"
        className={`${enabledImagem ? 'modal-enabled' : ''}`}
        isOpen={modalIsOpenImagem}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLabel">
            TERMO DE USO DE IMAGEM
          </h2>
        </div>
        <div id="scroll-body" className="modal-body" onScroll={onScrollImagem}>
        AUTORIZO o uso de minha imagem por qualquer meio ou veículo de comunicação ou publicidade (ex: foto ou filme), sem finalidade comercial, para fins de utilização na divulgação das atividades de parceria.
        A presente autorização é concedida à título gratuito, abrangendo o uso da imagem acima mencionada em todo território nacional e no exterior, sem qualquer ônus, limitação de tempo e em todas as suas modalidades em geral.
        Por esta ser a expressão da minha vontade declaro que autorizo o uso acima descrito sem que nada haja a ser reclamado à título de direitos conexos à minha imagem ou a qualquer outro.
        </div>

        <div className="modal-footer">
          <div className="box-checkbox">
            <input type="checkbox" id="concordo" name="concordo"
                defaultChecked={imagemchecked}
                onChange={(e) => setImagemChecked(e.target.checked)}/>
            <label htmlFor="concordo">
              <span>Li e aceito o termo</span>{' '}
            </label>
          </div>
          <div id="btn-aceitar" onClick={aceitarModalImagem}>
            Continuar
          </div>
        </div>
      </Modal>


      <Modal
        id="modalResponsabilidade"
        className={`${enabledResponsabilidade ? 'modal-enabled' : ''}`}
        isOpen={modalIsOpenResponsabilidade}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLabel">
            TERMO DE RESPONSABILIDADE: UTILIZAÇÃO DE LOCKERS
          </h2>
        </div>

        <div id="scroll-body" className="modal-body" onScroll={onScrollResponsabilidade}>
         <ol>
            <li>
              <strong>Critérios para utilização Corretores Parceiros </strong> Tegra Vendas que estejam alocados no escritório administrativo.
              <br />
              <br />
            </li>
            <li>
              <strong>Regras gerais</strong>
              <br />
              É permitida a utilização de apenas 01 armário por pessoa.
              <br />
              A Tegra Vendas não se responsabiliza pelos valores e/ou valores armazenados.
              <br />
              Será fornecida chave com o número correspondente ao armário onde o <strong>Corretor Parceiro</strong> interessado guardará seus pertences, ficando a chave em seu poder;
              O armário é de uso individual, não sendo permitido a troca entre <strong>Corretores</strong>, sem a devida baixa de responsabilidade;
              O Corretor Parceiro será responsável pelos custos integrais de confecção de cópia de chave para os casos de perda ou mau uso;
              <br />
              <br />
              <u><strong>Não será permitido: </strong></u>
              <br />
              Armazenamento de alimentos (sólidos ou líquidos) em embalagens fechadas ou abertas, por questões sanitárias;
              <br />
              Utilização do armário para depositar material volátil, explosivo, de natureza ilícita ou de risco às pessoas;
              <br />
              Colagem de adesivos ou qualquer outra ação que possa marcar ou danificar o armário;
              <br />
              Guarda de objetos que não caibam adequadamente dentro do armário, forçando o seu fechamento;
              <br />
              Área de Facilities acompanhada da equipe de RH, estão autorizados a realizar a abertura do armário em situações necessárias.
              <br />
              Para solicitar sua chave, dirigir-se a recepção.
              <br />
              Ao final da utilização diária o armário deverá ser desocupado e a chave deverá ser devolvida
              <br />
              <br />
              Para solicitar sua chave, dirigir-se a recepção.
              <br />
              <br />
              <strong>Ao final da utilização diária o armário deverá ser desocupado e a chave deverá ser devolvida na recepção.</strong>
            </li>
         </ol>
          <br />
          <br />
        </div>
        <div className="modal-footer">
          <div className="box-checkbox">
            <input type="checkbox" id="concordo" name="concordo"
                defaultChecked={responsabilidadechecked}
                onChange={(e) => setResponsabilidadeChecked(e.target.checked)}/>
            <label htmlFor="concordo">
              <span>Li e aceito o termo</span>{' '}
            </label>
          </div>
          <div id="btn-aceitar" onClick={aceitarModalResponsabilidade}>
            Continuar
          </div>
        </div>
      </Modal>


      {/*
      <Modal
        id="modalExpertegra"
        className={'modal-enabled'}
        isOpen={modalIsOpenExpertegra}
      >
        <div className="modal-header">
          <div className="modal-title" id="exampleModalLabel">
            <img src={logoInovaTegra} alt="Logo Inova Tegra" />
            <div className="decoration"></div>
            <h2>EXPERTEGRA</h2>
          </div>
          <button className="btn-close" onClick={confirmExpertegra}>X</button>
        </div>
        <div id="scroll-body" className="modal-body" onScroll={onScrollImagem}>
          <p>A sua adesão é essencial para o sucesso do projeto <strong>Expertegra</strong>, nossa plataforma<br/>inovadora de jogos didáticos sobre a Tegra e nossos produtos</p>
          <p className="acessoLiberado">O SEU ACESSO ESTÁ <strong>LIBERADO!</strong></p>
          <div className="topicos">
              <div>
                <img src={iconAcesso} alt="" />
                <p><strong>Acesse ao<br/>EXPERTEGRA</strong></p>
              </div>
              <div>
                <img src={iconJogue} alt="" />
                <p><strong>Jogue</strong></p>
              </div>
              <div>
                <img src={iconOpiniao} alt="" />
                <p><strong>Dê a sua<br/>opinião</strong></p>
              </div>
          </div>
          <p className="acesso"><span>Você tem até o dia <strong>25/02</strong> para concluir e garantir o seu certificado.</span></p>
        </div>
        <div className="modal-footer">
          <a href="https://tegra.ludospro.com.br/" target="_blank">
          Ainda não está no EXPERTEGRA?<br/><span>Clique aqui e saiba mais. Só falta você.</span>
          </a>
        </div>
      </Modal>
      */}
      <Hero>
        <HeroContent>
          <HeroNews>
            <HeroWelcome>
              <p>
                Olá, {user.apelido}
                <br />
                Nossas boas-vindas ao {getTituloByDomain()}!
              </p>
            </HeroWelcome>

            {slides.length > 0 && (
              <HeroNewsContent>
                <HeroNewsHeader>
                  <img src={noticiasIcon} alt="Notícias" />
                  <p>{currentCarouselTitle}</p>
                </HeroNewsHeader>
                <Carousel
                  // infinite={slides.length > 1}
                  autoPlay={slides.length > 1 ? 8000 : 0}
                  value={carouselValue}
                  onChange={onChange}
                  slides={slides.map(slide => (
                    <div className="news" key={slide.id}>
                      <h1>{slide.titulo}</h1>
                      <p>{slide.subtitulo}</p>
                    </div>
                  ))}
                />

                <HeroNewsNavigation>
                  <CustomDots
                    value={carouselValue}
                    onChange={onChange}
                    thumbnails={slides.map(slide => (
                      <span key={slide.id} />
                    ))}
                  />
                  <VerMais>
                    <Link to={currentCarouselLink}>Ver mais</Link>
                  </VerMais>
                </HeroNewsNavigation>
              </HeroNewsContent>
            )}
          </HeroNews>
          <CalendarContainer>
 
            <Calendar 
              onChange={onChangeCalendario} 
              calendarType={"US"}              
              formatShortWeekday={(locale,value) => ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'][value.getDay()]}
              value={dataAtual}   
              tileContent={
                ({ activeStartDate, date, view }) => 
                  date.getMonth() !== mesSelecionado? 
                    null 
                  :
                  calendarioGeral.filter((d) => d.dt_inicio ===  moment(date).format('YYYY-MM-DD')).length >= 1 ? 
                    printEventFuture(date, calendarioGeral.filter((d) => d.dt_inicio ===  moment(date).format('YYYY-MM-DD')))
                  : 
                    printDay(date)
              }
              
              onActiveStartDateChange={
                ({ action, activeStartDate, value, view }) => setMesSelecionado(activeStartDate.getMonth())
              }
              formatMonthYear={(locale, date) => moment(date).format('MMMM YYYY').toUpperCase()}
              minDetail={'month'}
              />
              { isEventos && (                
                  <div className='eventosContainer' style={{left:posicaoX, top:posicaoY}}>
                    {listaEventos.map(item => (
                      <>
                        <img src={iconCalendar} alt="Ícone do Calendario" />
                        <p>{item.tx_titulo}</p>
                      </>                                            
                    ))}                    
                  </div>
                )
              }
              <TitleCalendar>
                 <img src={iconCalendar} alt="Calendário" />
                 <p>Calendário de Eventos</p>
              </TitleCalendar>
          </CalendarContainer>

          <HeroCalendar>
            {/* {ultimoCalendario.id && ( */}
            {/*ultimoCalendario && (
              <Link to={ultimoCalendario.link}>
                <HeroCalendarContent>                  
                  <div className="info">
                    <p className="title">Calendário de Trabalho</p>
                    <p className="subtitle">{ultimoCalendario.titulo}</p>
                  </div>
                  <div className="icon">
                    <img src={calendarIcon} alt="Calendário" />
                  </div>
                </HeroCalendarContent>
              </Link>
            )}
            {/* )} */}
          </HeroCalendar>
        </HeroContent>
      </Hero>
      <Content>
        <OptionsGrid />
      </Content>
    </Container>
  );
};

export default Home;
