import styled from "styled-components";

interface TegraRoundImageContainerProps {
    size: string
}

export const TegraRoundImageContainer = styled.img<TegraRoundImageContainerProps>`
    width: ${(props: TegraRoundImageContainerProps) => props.size};
    height: ${(props: TegraRoundImageContainerProps) => props.size};
    border-radius: 50%;
    object-fit: cover;
`;