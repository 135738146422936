import React from 'react';
import {
  ContactsManagementFilterContainer,
  ContactsManagementInputWrapper,
  ContactsManagementInput,
  ContactsManagementFilterTitle,
} from './styles';

interface IContactsManagementFilter {
  setFilter: Function;
}

const ContactsManagementFilter: React.FC<IContactsManagementFilter> = ({
  setFilter,
}) => {
  return (
    <ContactsManagementFilterContainer>
      <ContactsManagementFilterTitle>
        Busque por um corretor ou gerente
      </ContactsManagementFilterTitle>
      <ContactsManagementInputWrapper>
        <ContactsManagementInput onChange={e => setFilter(e.target.value)} />
      </ContactsManagementInputWrapper>
    </ContactsManagementFilterContainer>
  );
};

export default ContactsManagementFilter;
