/* eslint-disable react/no-danger */
import React from 'react';

// import { Hidden, Visible } from 'react-grid-system';
import { Hidden, Visible } from 'react-grid-system';
import {
  Container,
  ProdutosContainer,
  ProdutoDescription,
  ProdutoInfoContainer,
  ProdutoFooterContainer,
  ProdutoLocalidade,
  ProdutoContainer,
  Info,
} from './styles';
import TegraDivider from '../TegraDivider';
import { ToSlug } from '../../services/helpers';
import star from '../../assets/star.png';

export interface IProduto {
  localidade: {
    bairro: string;
    cidade: string;
    uf?: string;
  };
  titulo: string;
  destaque: boolean;
  metragem: string;
  informacoes: string;
  id: number;
  imagem: any;
  preco: number;
  regiao: string;
  estagio_obra: string;
  aerea: number;
  slug?: string;
  isBanner?: boolean;
}

interface IProdutosListProps {
  items: IProduto[];
  goToProduto: Function;
  link?: string;
  apelido?: string;
  materiais?: boolean;
  rodape?:boolean;
}

const TegraProdutosList: React.FC<IProdutosListProps> = ({
  items,
  goToProduto,
  link,
  apelido,
  materiais = true,
  rodape = true,
}) => {
  return (
    <Container>
      <Visible sm={false} md xl xxl lg>
        <ProdutosContainer>
          {items.map(produto => (
            <ProdutoContainer
              key={`produto_container_${produto.id}`}
              onClick={() => {
                goToProduto(
                  `${
                    link ? link + apelido : '/dashboard/produtos'
                  }/${produto.localidade.uf?.toLowerCase()}/${ToSlug(
                    produto.localidade.cidade,
                  )}/${produto.slug}`,
                );
              }}
            >
              {produto.isBanner && (
                <div className="produto-destaque">
                  <img src={star} alt="produto destaque" />
                  <span>Produto destaque</span>
                </div>
              )}
              <img src={produto.imagem} alt={produto.titulo} />
              <ProdutoInfoContainer>
                <ProdutoLocalidade>
                  <h3>{produto.localidade.bairro}</h3>
                  <strong>{produto.localidade.cidade}</strong>
                </ProdutoLocalidade>
                <TegraDivider
                  color="rgba(252,184,19,0.3)"
                  axis="y"
                  height={80}
                />
                <ProdutoDescription>
                  <h2>{produto.titulo}</h2>
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: produto.metragem,
                      }}
                    />
                    . {produto.informacoes}
                  </p>
                </ProdutoDescription>
              </ProdutoInfoContainer>
              {rodape &&
                <ProdutoFooterContainer>
                  {materiais && (
                    <a
                      href={`${
                        link ? link + apelido : '/dashboard/produtos'
                      }/${produto.localidade.uf?.toLowerCase()}/${ToSlug(
                        produto.localidade.cidade,
                      )}/${produto.slug}`}
                    >
                      Materiais gerais
                    </a>
                  )}
                </ProdutoFooterContainer>
              }
            </ProdutoContainer>
          ))}
        </ProdutosContainer>
        {/* </TabPanel>
            <TabPanel>
              <ProdutosContainer />
            </TabPanel>
          </Tabs>
        </TegraTabsContainer> */}
      </Visible>

      <Hidden sm={false} md xl lg xxl>
        <ProdutosContainer>
          {items.map(produto => (
            <ProdutoContainer
              key={`produto_container2_${produto.id}`}
              onClick={() => {
                goToProduto(
                  `${
                    link ? link + apelido : '/dashboard/produtos'
                  }/${produto.localidade.uf?.toLowerCase()}/${ToSlug(
                    produto.localidade.cidade,
                  )}/${produto.slug}`,
                );
              }}
            >
              {produto.isBanner && (
                <div className="produto-destaque">
                  <span>Produto destaque</span>
                </div>
              )}
              <img src={produto.imagem} alt={produto.titulo} />
              <ProdutoInfoContainer>
                <Info>
                  <ProdutoLocalidade>
                    <h3>{produto.localidade.bairro}</h3>
                    <strong>{produto.localidade.cidade}</strong>
                  </ProdutoLocalidade>
                  <TegraDivider
                    color="rgba(252,184,19,0.3)"
                    axis="y"
                    // height={80}
                  />
                  <ProdutoDescription>
                    <h2>{produto.titulo}</h2>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{ __html: produto.metragem }}
                      />
                      . {produto.informacoes}
                    </p>
                  </ProdutoDescription>
                </Info>
                <p className="descriptionBelowTitle">
                  <span
                    dangerouslySetInnerHTML={{ __html: produto.metragem }}
                  />
                  . {produto.informacoes}
                </p>
              </ProdutoInfoContainer>
              {rodape &&
              <ProdutoFooterContainer>
                {materiais && (
                  <a
                    href={`${
                      link ? link + apelido : '/dashboard/produtos'
                    }/${produto.localidade.uf?.toLowerCase()}/${ToSlug(
                      produto.localidade.cidade,
                    )}/${produto.slug}`}
                  >
                    Materiais gerais
                  </a>
                )}
              </ProdutoFooterContainer>
              }
            </ProdutoContainer>
          ))}
        </ProdutosContainer>
      </Hidden>
    </Container>
  );
};

export default TegraProdutosList;
