import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TegraPresentationHeader, {
  TegraUser,
} from '../../components/TegraPresentationHeader';
import TegraFooter from '../../components/TegraFooter';
import TegraExternal from '../../components/TegraExternal';
import TegraContactBar from '../../components/TegraContactBar';
import { TegraDetalhesContainer, SignatureSioux } from './style';
import api from '../../services/api';
import footerLinks from '../../model/footerLinks';
import { useLoader } from '../../hooks/loader';
import { getLogoByTipo, getTituloByTipo } from '../../utils/logo';
import { isLab } from '../../utils/login';

const anoAtual = new Date().getFullYear();
const footerTrademark = '© ' + anoAtual + ' TEGRA Incorporadora. All rights reserved.';

const Detalhes: React.FC = () => {
  const { id, slug, regional, apelido } : any = useParams();

  const { setLoaderActive } = useLoader();

  const [user, setUser] = useState<TegraUser>({
    name: '',
    creci: '',
    email: '',
    phone: '',
    image: '',
    description: '',
    tipo: 0,
    isLab: false,
  });

  useEffect(() => {
    setLoaderActive(true);
    const getProfileData = async () => {
      let response;
      if (regional && apelido)
        response = await api.get(`Perfil/pagina`, {
          params: {
            regional,
            apelido,
          },
        });
      else response = await api.get(`Perfil/byId?id=${id}&tipo=1`);
      const userResponse = response.data;

      setUser({
        name: userResponse.tx_apelido,
        creci: userResponse.tx_creci,
        email: userResponse.tx_email,
        phone: userResponse.tx_telefone,
        image: `https://tegra.sigavi360.com.br/Arquivo/Colaborador/${userResponse.id_tegra}/Avatar.jpg`,
        description: userResponse.tx_saudacao,
        tipo: userResponse.tipo,
        isLab: isLab(userResponse.id_cargoSigavi),
      });

      setLoaderActive(false);
    };
    getProfileData();
  }, []);

  return (
    <TegraDetalhesContainer>
      <TegraContactBar tel={user.phone} mail={user.email} name={user.name}/>
      <TegraPresentationHeader user={user} details />
      <TegraExternal />
      <TegraFooter
        tegraLogo={getLogoByTipo(user.tipo, user.isLab)}
        title=""
        description={getTituloByTipo(user.tipo)}
        links={footerLinks}
        trademark={footerTrademark}
      />
      <SignatureSioux>
          <span>Desenvolvido por <a href="https://sioux.ag" target="_blank" title="Desenvolvido por Sioux digital 1:1">Agência de marketing digital</a></span>
      </SignatureSioux>
    </TegraDetalhesContainer>
  );
};

export default Detalhes;
