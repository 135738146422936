import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
  position: relative;
`;

export const Content = styled.div`
  margin-top: 40px;
  /* padding: 0 33px; */
  position: relative;

  @media (min-width: 600px) {
    form {
      padding-top: 40px;
      background-color: #e6e6e6;
      max-width: 483px;
    }
  }
`;

export const ProductMaterialsTitleWrapper = styled.div`
  h1 {
    @media (max-width: 767px) {
      font-size: 44px;
    }
    @media (max-width: 375px) {
      font-size: 30px;
    }
    padding-right: 33px;
    word-wrap: normal;
  }
`;

export const TabContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 41px auto;
  padding-left: 36px;
  padding-right: 36px;
`;

export const MaterialsContainer = styled.div`
  //padding: 0 2.25rem;
  width: 100%;
  padding-bottom: 2.5rem;
  @media (min-width: 1024px) {
    //padding: 0 1.15rem;
  }
`;

export const MaterialsTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  button {
    @media (max-width: 374px) {
      font-size: 9.8px;
    }
  }
`;

export const MaterialsTableData = styled.td`
  @media (min-width: 992px) {
    margin-right: 1rem;
  }
  padding: .5rem 4px;
  border-bottom: 1px solid rgb(230, 230, 230);
`;

export const MaterialsTableTitle = styled.h3`
  font-size: 15px !important;
  font-weight: bold;
  color: #ebb92d;
  margin-bottom: 10px;
  text-align: left;
`;

interface IMaterialsActionsWrapper {
  length: number;
}

export const MaterialsActionsWrapper = styled.div<IMaterialsActionsWrapper>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  button {
    margin: 4px;
  }
`;
interface IMaterialsTableRow {
  showColumnTitle?: boolean;
}
export const MaterialsTableRow = styled.tr<IMaterialsTableRow>`
  border-bottom: 1px solid #e6e6e6;
  padding: .5rem 0 1.25rem;
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 1.25rem;
  }
  th {
    padding: 0 4px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }
  img {
    max-width: 12vw;
    margin-right: 5px;
  }
`;
export const MaterialsTableLabel = styled.h1`
  font-size: 15px;
  & > a {
    font-size: 15px;
  }
  a.disabled {
    cursor: default;
  }
`;

export const MaterialsLoadMoreContainer = styled.div`
  width: 100%;
  padding: 0 2.25rem;
  display: flex;
  justify-content: center;
  button {
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;
