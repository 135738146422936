import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SubmitHandler, Form, FormHandles } from '@unform/core';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import {
  Container,
  CorretoresContainer,
  Title,
  SearchContainer,
  CorretoresTable,
  ListaAction,
  FormActions,
  ContainerPaginate,
} from './styles';
import TegraSelect from '../../../../components/TegraSelect';
import TegraInput from '../../../../components/TegraInput';

import searchIcon from '../../../../assets/tegra-icon-search.svg';
import openIcon from '../../../../assets/tegra-icon-open.svg';
import TegraDivider from '../../../../components/TegraDivider';
import TegraButtonExtra from '../../../../components/TegraButtonExtra';
import TegraButton from '../../../../components/TegraButton';
import api from '../../../../services/api';
import { useLoader } from '../../../../hooks/loader';
import { useAuth } from '../../../../hooks/auth';
import { Console } from 'console';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from 'moment';
import Select from 'react-select';
import querystring from 'querystring';

const optionsS = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
];

// You can style your pagination component
// thanks to styled-components.
// Use inner class names to style the controls.
const MyPaginate = styled(ReactPaginate).attrs({
  // You can redifine classes here, if you want.
  activeClassName: 'active', // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  width: 400px;

  li a {
    border-radius: 7px;
    padding: 0.1rem 0.5rem;
    border: #EBB92D 1px solid;
    cursor: pointer;
    color: #60605D;
    font-size: 16px;
    caret-color: transparent;
    user-select:none;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #EBB92D;
    border-color: transparent;
    color: #60605D;
    min-width: 32px;
    caret-color: transparent;
    user-select:none;
  }
  li.disabled a {
    color: #60605D;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

interface IEncontrarCorretorFormData {
  tipo_parceiro: {
    value: string;
    label: string;
  };
  status: {
    value: string;
    label: string;
  };
  estado: {
    value: string;
    label: string;
  };
  regional: {
    value: string;
    label: string;
  };
  cidade: {
    value: string;
    label: string;
  }[];
  zona: {
    value: string;
    label: string;
  };
  superintendente: {
    value: string;
    label: string;
  };
  gerente: {
    value: string;
    label: string;
  };
  qtdGerente: number,
  busca: string;
}

const searchFormInitialData = {
  tipo_parceiro: {
    value: undefined,
    label: 'Todos',
  },
  status: {
    value: undefined,
    label: 'Todos',
  },
  estado: {
    value: undefined,
    label: 'Todos',
  },
  regional: {
    value: undefined,
    label: 'Todas',
  },
  cidade: {
    value: undefined,
    label: 'Todos',
  },
  zona: {
    value: undefined,
    label: 'Todos',
  },
  superintendente: {
    value: '',
    label: '',
  },
  gerente: {
    value: '',
    label: '',
  },
  busca: '',
};

export interface ICorretor {
  id_parceiro: string;
  id_tipoCorretor: number;
  id_tipoParceiro: number;
  txt_tipoParceiro: string;
  tx_razaoSocial: string;
  tx_nome: string;
  tx_creci: string;
  tx_email: string;
  superintendente: string;
  tx_gerente: string;
  tx_uf: string;
  tx_regional: string;
  id_situacao: string;
  tx_situacao: string;
  dt_cadastro: string;
}

interface Filter {
  id?: number;
  nome: string;
}

interface Regional extends Filter {
  uf: string;
}

interface Estado extends Filter {
  uf: string;
  regionais: {
    regional: Filter;
  }[];
}

interface Cidade {
  id: number;
  nome: string;
  uf: string;
}

const mockTipoParceiro: Filter[] = [
  {
    id: undefined,
    nome: 'Todos',
  },
  {
    id: -1,
    nome: 'Tegra',
  },
  {
    id: 1,
    nome: 'Imobiliária',
  },
  {
    id: 2,
    nome: 'Autônomo',
  },
];

const optionsStatus = [
  {
    label: 'Todos',
    value: undefined,
  },
  {
    label: 'Cadastro em andamento',
    value: -2,
  },
  {
    label: 'Provisório',
    value: -1,
  },
  {
    label: 'Pendente RH',
    value: 2,
  },
  {
    label: 'Inativo',
    value: 0,
  },
  {
    label: 'Ativo',
    value: 1,
  },
];

const MeusCorretoresLista: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [corretores, setCorretores] = useState<ICorretor[]>([]);

  // Filtros
  const [gerentesFiltro, setGerentesFiltro] = useState<any[]>([]);
  const [tiposParceiro, setTiposParceiro] = useState<Filter[]>([]);
  const [situacoes, setSituacoes] = useState<Filter[]>([]);
  const [estados, setEstados] = useState<Estado[]>([]);
  const [regionais, setRegionais] = useState<Filter[]>([]);
  const [cidades, setCidades] = useState<Cidade[]>([]);
  const [zonas, setZonas] = useState<any[]>([]);
  const [zonasModal, setZonasModal] = useState<any[]>([]);
  const [allRegionais, setAllRegionais] = useState<Regional[]>([]);
  const [totalPaginas, setTotalPaginas] = useState<number>(1);
  const [totalRegistros, setTotalRegistros] = useState<number>(1);
  const [registrosPagina, setRegistrosPagina] = useState<number>(10);
  const [textoRegistros, setTextoRegistro] = useState('');
  const [isSuperintendente, setIsSuperintendente] = useState(false);
  const { setLoaderActive } = useLoader();
  const { user } = useAuth();


  // Router
  const { path, url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    // carregar tipos da api
    setTiposParceiro(mockTipoParceiro);

  }, []);

  useEffect(() => {
    setLoaderActive(true);
    let quantGerentes = 0;
    let estado = {value: 'SP', label:'São Paulo'}

    if (user.idEstado == 19) {
      encontrarCorretorFormRef.current?.setData(
        {
          estado: {
            value: 'RJ',
            label: 'Rio de Janeiro',
          },
        }
      );
      estado = {value: 'RJ', label:'Rio de Janeiro'}
    } else {
      encontrarCorretorFormRef.current?.setData(
        {
          estado: {
            value: 'SP',
            label: 'São Paulo',
          },
        }
      );
    }

    async function loadAllGerentes() {
      const resGerentes = await api.get<any[]>(`Gerente/GetGerentes`);
      quantGerentes = resGerentes.data.length;

      if (resGerentes.data.length > 0)
        setIsSuperintendente(true);

      setGerentesFiltro(
        resGerentes.data.map((d: any) => ({
            label: d.tx_apelido,
            value: d.id_corretorSigavi,
            tx_equipe: d.tx_equipe,
            tx_cargoSigavi: d.tx_cargoSigavi,
          })),
      );
    };

    async function loadAllCorretores() {
      setLoaderActive(true);
      await loadAllGerentes();
      api
        .get(`Gerente/corretores`, {
          params: { nu_pagina: 1, nu_quantidade: registrosPagina, qtdGerente: quantGerentes, tx_uf: estado.value },
        })
        .then(res => {
          const {
            data: { items, paginas, total },
          } = res;
          setCorretores(items);
          setTotalPaginas(paginas);
          setTextoRegistro('1 - ' + registrosPagina + ' de ' + total + ' registros');
          setTotalRegistros(total);
          setLoaderActive(false);
        });
    };

    // carregar regioes e cidades
    onChangeEstado(estado);

    // carregar corretores da api
    loadAllCorretores();
  }, [allRegionais]);

  useEffect(() => {
    // carregar regionais da api
    api.get<Estado[]>(`Estados`).then(estadoRes => {
      const { data: estadoData } = estadoRes;

      api.get<Regional[]>(`Regionais`).then(regionalRes => {
        const { data: regionalData } = regionalRes;
        regionalData.unshift({
          nome: 'Todas',
          uf: '',
          id: undefined,
        });
        estadoData.unshift({
          nome: 'Todos',
          uf: '',
          id: undefined,
          regionais: [],
        });
        setEstados(estadoData);
        setAllRegionais(regionalData);
      });
    });

    let header = [
      { label: '-- Outras Regiões --', tipo: 1,
        options:[{
          value: 1,
          label: 'Grande São Paulo',
          tipo: 1,
        },
        {
          value: 2,
          label: 'Interior',
          tipo: 1,
        }] },
      { label: '-- São Paulo --', tipo: 2,
        options:[{
          value: 1020,
          label: 'Centro',
          tipo: 2,
        },
        {
          value: 1025,
          label: 'Norte',
          tipo: 2,
        },
        {
          value: 1023,
          label: 'Sul',
          tipo: 2,
        },
        {
          value: 1021,
          label: 'Leste',
          tipo: 2,
        },
        {
          value: 1022,
          label: 'Oeste',
          tipo: 2,
        }]
      },
    ];

    setZonasModal(header);
    /*
    api
    .get<Zona[]>(`Cidades/regioes/:id_cidade`, {
      params: { id_cidade: 5242 },
    })
    .then(res => {
      const { data: zonaData } = res;
      setZonasModal(zonaData);
    });
    */
  }, []);

  const onChangeEstado = useCallback(
    ({ value }) => {
      const findEstado = estados.find(estado => estado.uf === value);
      if (findEstado) {
        const findRegionais = allRegionais.filter(
          findRegional => findRegional.uf === findEstado.uf,
        );

        findRegionais.unshift({
          nome: 'Todas',
          uf: '',
          id: undefined,
        });
        setRegionais(findRegionais);
      };
      if (value == 'SP'){
        carregaRegioesSP(0);
      }
      else{
        setZonas([]);
      }

      encontrarCorretorFormRef.current?.setData(
        {
          regional: null,
          cidade: null,
          zona: null
        }
      );
      api
        .get<Cidade[]>('Cidades', {
          params: {
            uf: value,
            regional: 0 },
        })
        .then(res => {
          const { data: cidadeData } = res;
          setCidades(cidadeData);
        });
        //setZonas([]);
    },
    [allRegionais, estados, cidades, zonas],
  );

  const onChangeRegional = useCallback(
    ({ value }) => {
      const estado = value == 2 ? 'RJ' : 'SP';
      if (value == 3){
        setCidades([]);
        setZonas([]);
        encontrarCorretorFormRef.current?.setData(
          {
            cidade: null,
            zona: null
          }
        );
      }
      else
      {
        api
        .get<Cidade[]>('Cidades', {
          params: {
            uf: estado,
            regional: value? value : 0 },
        })
        .then(res => {
          const { data: cidadeData } = res;
          setCidades(cidadeData);
        });
        console.log('estado', estado);
        console.log('value', value);
        if ((value == 1) || (!value && estado == 'SP')){
          carregaRegioesSP(0);
        }
        else{
          setZonas([]);
          encontrarCorretorFormRef.current?.setData(
            {
              zona: null
            }
          );
        }

      }
    },
    [ cidades, zonas],
  );

  //Carrega Regiões SP
  function carregaRegioesSP(cidadeSel){

    const igGrandeSP = [5531, 4749, 4759, 4786, 5761, 5541,
      4830, 4838, 5786, 5753, 4866, 4872, 4873, 4892, 4897, 4939, 4942, 4949, 4968, 4981, 5629, 5501, 5028, 5072,
      5121, 5546, 5507, 5177, 5188, 5205, 5760, 5215, 5508, 5523, 5238, 5242, 5265, 5511, 5313];
    //this.zonas = this.zonasModal.concat(...vetor);

    let vetor = zonasModal;
    if (cidadeSel == 5242){
      vetor = zonasModal.filter(f => (f.tipo == 2));
    }
    else if (igGrandeSP.indexOf(cidadeSel) !== -1){
      vetor = zonasModal.filter(f => (f.tipo == 1)).map((c:any) =>({
        label: c.label,
        tipo: c.tipo,
        options: c.options.filter(o => (o.value == 1))
      }));
    }
    else if (cidadeSel != 0) {
      vetor = zonasModal.filter(f => (f.tipo == 1)).map((c:any) =>({
        label: c.label,
        tipo: c.tipo,
        options: c.options.filter(o => (o.value == 2))
      }));
    }

    setZonas(vetor);
    console.log('Zonas',vetor);
  }

  const onChangeCidade = useCallback(
    ({ value }) => {
      /*
      if (value == 5242){

        api
        .get<Zona[]>(`Cidades/regioes/:id_cidade`, {
          params: { id_cidade: value },
        })
        .then(res => {
          const { data: zonaData } = res;
          console.log('zona',res);
          setZonas(zonaData);
        });
      }
      else{
        setZonas([]);
        encontrarCorretorFormRef.current?.setData(
          {
            zona: null
          }
        );
      }
      */
      encontrarCorretorFormRef.current?.setData(
        {
          zona: null,
        }
      );
      carregaRegioesSP(value);
    },
    [zonas],
  );

  const encontrarCorretorFormRef = useRef<FormHandles>(null);

  /*
  const handleLoadMore = useCallback(() => {
    if (!encontrarCorretorFormRef.current) {
      return;
    }

    const searchData = encontrarCorretorFormRef.current.getData() as IEncontrarCorretorFormData;

    const params = { nu_pagina: currentPage + 1, nu_quantidade: registrosPagina };

    Object.assign(
      params,
      searchData.tipo_parceiro?.value && {
        id_tipoParceiro: searchData.tipo_parceiro?.value,
      },
    );

    Object.assign(
      params,
      {
        id_situacao: searchData.status?.value,
      },
    );

    Object.assign(
      params,
      searchData.estado?.label &&
        searchData.estado?.label !== 'Todos' && {
          tx_uf: searchData.estado?.value,
        },
    );

    Object.assign(
      params,
      searchData.regional?.label &&
        searchData.regional?.label !== 'Todas' && {
          tx_regional: searchData.regional?.label,
        },
    );

    Object.assign(
      params,
      searchData.gerente?.label &&
        searchData.gerente?.label !== 'Todos' && {
           gerente: searchData.gerente?.label,
        },
    );

    Object.assign(
      params,
      {
        qtdGerente: gerentesFiltro.length,
      },
    );

    Object.assign(
      params,
      searchData.busca && {
        busca: searchData.busca,
      },
    );
    setLoaderActive(true);
    api
      .get(`Gerente/corretores`, {
        params,
        //params: { nu_pagina: currentPage + 1, nu_quantidade: 10 },
      })
      .then(res => {
        const {
          data: { items, paginas, total },
        } = res;
        if (items.length > 0) {
          setCorretores([...corretores, ...items]);
          setCurrentPage(currentPage + 1);
          setTotalPaginas(paginas);
          setTotalRegistros(total);
          setLoaderActive(false);
        }
      });
  }, [corretores, currentPage]);
  */

  const handleLoadMorePage = useCallback((page, numReg) => {
    if (!encontrarCorretorFormRef.current) {
      return;
    }

    const searchData = encontrarCorretorFormRef.current.getData() as IEncontrarCorretorFormData;

    const params = { nu_pagina: page + 1, nu_quantidade: numReg };

    Object.assign(
      params,
      searchData.tipo_parceiro?.value && {
        id_tipoParceiro: searchData.tipo_parceiro?.value,
      },
    );

    Object.assign(
      params,
      {
        id_situacao: searchData.status?.value,
      },
    );

    Object.assign(
      params,
      searchData.estado?.label &&
        searchData.estado?.label !== 'Todos' && {
          tx_uf: searchData.estado?.value,
        },
    );

    Object.assign(
      params,
      searchData.regional?.label &&
        searchData.regional?.label !== 'Todas' && {
          tx_regional: searchData.regional?.label,
        },
    );

    Object.assign(
      params,
      searchData.gerente?.label &&
        searchData.gerente?.label !== 'Todos' && {
          gerente: searchData.gerente?.label,
        },
    );

    Object.assign(
      params,
      {
        qtdGerente: gerentesFiltro.length,
      },
    );

    Object.assign(
      params,
      searchData.busca && {
        busca: searchData.busca,
      },
    );
    setLoaderActive(true);
    api
      .get(`Gerente/corretores`, {
        params,
        // params: { nu_pagina: currentPage + 1, nu_quantidade: 10 },
      })
      .then(res => {
        const {
          data: { items, paginas, total },
        } = res;
        if (items.length > 0) {
          setCorretores(items);
          setCurrentPage(page + 1);
          setTotalPaginas(paginas);
          setTotalRegistros(total);
          setRegistrosPagina(numReg);
          setTextoRegistro(page == 0 ? '1 - ' + numReg + ' de ' + total + ' registros' :
            (page + 1) == paginas? (numReg * page + 1)  + ' - ' + total + ' de ' + total + ' registros' :
            (numReg * page + 1)  + ' - ' + ((numReg * page) + numReg) + ' de ' + total + ' registros');
          setLoaderActive(false);
        }
      });
  }, [corretores, currentPage]);

  const handleVisualizar = useCallback(
    (id: string, id_tipoCorretor: number) => {
      history.push(`${path}/${id_tipoCorretor}/${id}`);
    },
    [],
  );
  const handleEditar = useCallback((id: string) => {
    history.push(`/dashboard/corretor/editar/${id}`);
  }, []);

  const handleSearch = useCallback(() => {
    if (!encontrarCorretorFormRef.current) {
      return;
    }

    const searchData = encontrarCorretorFormRef.current.getData() as IEncontrarCorretorFormData;

    const params = { nu_pagina: 1, nu_quantidade: registrosPagina };

    Object.assign(
      params,
      searchData.tipo_parceiro?.value && {
        id_tipoParceiro: searchData.tipo_parceiro?.value,
      },
    );

    Object.assign(
      params,
      {id_situacao: searchData.status?.value,},
    );

    Object.assign(
      params,
      searchData.estado?.label &&
        searchData.estado?.label !== 'Todos' && {
          tx_uf: searchData.estado?.value,
        },
    );

    Object.assign(
      params,
      searchData.regional?.label &&
        searchData.regional?.label !== 'Todas' && {
          tx_regional: searchData.regional?.label,
        },
    );

    // Object.assign(
    //   params,
    //   searchData.cidade?.label &&
    //     searchData.cidade?.label !== 'Todos' && {
    //       tx_uf: searchData.cidade?.value,
    //     },
    // );

    Object.assign(
      params,
      searchData.gerente?.label &&
        searchData.gerente?.label !== 'Todos' && {
           gerente: searchData.gerente?.label,
        },
    );

    Object.assign(
      params,
      {
        qtdGerente: gerentesFiltro.length,
      },
    );

    Object.assign(
      params,
      searchData.busca && {
        busca: searchData.busca,
      },
    );

    // Object.assign(
    //   params,
    //   searchData.cidade && {
    //     cidade: searchData.cidade.map(({value}) => value),
    //   },
    // );

    Object.assign(
      params,
      { zona: searchData.zona?.value },
    );

    console.log('Search Params (Search)', {
      params,
      formData: encontrarCorretorFormRef.current.getData(),
      user: user
    });
    setLoaderActive(true);

    let cidadesString = ''
    if(searchData.cidade) {
      if(searchData.cidade.length == 1){
        cidadesString = `?vetCidades=${searchData.cidade[0].value}`
      } else if (searchData.cidade.length > 1) {
        cidadesString = "?vetCidades="
        cidadesString += searchData.cidade.map((cidade) => cidade.value).join("&vetCidades=");
      }
    }

    api
      .get(`Gerente/corretores${cidadesString}`, {
        params,
        // paramsSerializer: params => {
        //   return querystring.stringify(params);
        // }
      })
      .then(res => {
        const {
          data: { items, paginas, total },
        } = res;
        setCorretores(items);
        setCurrentPage(1);
        setTotalPaginas(paginas);
        setTotalRegistros(total);
        setTextoRegistro('1 - ' + registrosPagina + ' de ' + total + ' registros');
        setLoaderActive(false);
      });
  }, [gerentesFiltro, registrosPagina]);

  const exportCorretores = async (): Promise<void> => {
    setLoaderActive(true);
    try {

      if (!encontrarCorretorFormRef.current) {
        return;
      }
      const searchData = encontrarCorretorFormRef.current.getData() as IEncontrarCorretorFormData;

      const params = { nu_pagina: 1, nu_quantidade: registrosPagina };


      Object.assign(
        params,
        searchData.tipo_parceiro?.value && {
          id_tipoParceiro: searchData.tipo_parceiro?.value,
        },
      );

      Object.assign(
        params,
        {
          id_situacao: searchData.status?.value,
        },
      );

      Object.assign(
        params,
        searchData.estado?.label &&
          searchData.estado?.label !== 'Todos' && {
            tx_uf: searchData.estado?.value,
          },
      );

      Object.assign(
        params,
        searchData.regional?.label &&
          searchData.regional?.label !== 'Todas' && {
            tx_regional: searchData.regional?.label,
          },
      );

      Object.assign(
        params,
        searchData.gerente?.label &&
          searchData.gerente?.label !== 'Todos' && {
             gerente: searchData.gerente?.label,
          },
      );

      Object.assign(
        params,
        {
          qtdGerente: gerentesFiltro.length,
        },
      );

      Object.assign(
        params,
        searchData.busca && {
          busca: searchData.busca,
        },
      );

      // Object.assign(
      //   params,
      //   searchData.cidade && {
      //     cidade: searchData.cidade.map(({value}) => value),
      //   },
      // );

      Object.assign(
        params,
        {zona: searchData.zona?.value,},
      );

      let cidadesStringExport = ''
      if(searchData.cidade) {
        if(searchData.cidade.length == 1){
          cidadesStringExport = `?vetCidades=${searchData.cidade[0].value}`
        } else if (searchData.cidade.length > 1) {
          cidadesStringExport = "?vetCidades="
          cidadesStringExport += searchData.cidade.map((cidade) => cidade.value).join("&vetCidades=");
        }
      }

      console.log('Search Params (Export)', {
        params,
        formData: encontrarCorretorFormRef.current.getData(),
        cidades: searchData.cidade
      });

      const response = await api.get(
        `Gerente/exportarcorretores${cidadesStringExport}`,
        {
          params,
          responseType: 'blob',
        },
      );

      const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
        // IE11
        nav.msSaveOrOpenBlob(
          new Blob([response.data]),
          'MeusCorretores.xlsx',
        );
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MeusCorretores.xlsx');
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
        }, 100);
      }
    } catch (e) {
      console.error('e', e);
      //
    }
    setLoaderActive(false);
  };

  const handleEncontrarCorretorFormSubmit: SubmitHandler<IEncontrarCorretorFormData> = (
    data,
  ): void => {
    handleSearch();
  };

  return (
    <Container>
      <SearchContainer>
        <Form
          ref={encontrarCorretorFormRef}
          onSubmit={handleEncontrarCorretorFormSubmit}
          initialData={searchFormInitialData}
        >
          <Title>Encontre Corretores</Title>
          <GridContainer
            className="grid-container"
            style={{
              padding: 0,
              marginLeft: '0px',
              maxWidth: '100%',
              marginRight: '0px',
            }}
          >
            <Row style={{ marginRight: '0px', marginTop: '10px' }}>
              <Col sm={12} md={6} lg={6} xl={3}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Tipo de corretor"
                  name="tipo_parceiro"
                  options={tiposParceiro.map(tipoParceiro => ({
                    value: tipoParceiro.id,
                    label: tipoParceiro.nome,
                  }))}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Status"
                  name="status"
                  options={optionsStatus}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Estado"
                  name="estado"
                  onChange={onChangeEstado}
                  options={estados.map(estado => ({
                    value: estado.uf,
                    label: estado.nome,
                  }))}
                />
              </Col>

              <Col sm={12} md={6} lg={6} xl={3}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Regional"
                  name="regional"
                  onChange={onChangeRegional}
                  options={regionais.map(regional => ({
                    value: regional.id,
                    label: regional.nome,
                  }))}
                />
              </Col>
            </Row>
            <Row style={{ marginRight: '0px', marginTop: '10px' }}>
              <Col sm={12} md={6} lg={6} xl={isSuperintendente?6:3}>
                <TegraSelect
                  isMulti
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Cidade"
                  name="cidade"
                  // onChange={onChangeCidade}
                  options={cidades.map(cidade => ({
                    value: cidade.id,
                    label: cidade.nome,
                  }))}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={isSuperintendente?3:2}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Região"
                  name="zona"
                  isDisabled={zonas.length == 0}
                  options={zonas}
                />
              </Col>
              {isSuperintendente && (
                <Col sm={12} md={6} lg={6} xl={3}>
                  <TegraSelect
                      placeholder="Todos"
                      noOptionsMessage={() => 'Nenhum selecionado'}
                      label="Gerente"
                      name="gerente"
                      options={[{label:"Todos",value:""},{label:"[Sem Gerente]",value:"null"}, ...gerentesFiltro]}
                    />
                </Col>
              )}
              <Col sm={12} md={6} lg={6} xl={isSuperintendente?6:4}>
                <TegraInput
                  placeholder="Pesquisar"
                  label="Busca por:"
                  name="busca"
                  icon={searchIcon}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={isSuperintendente?6:3}>
                <FormActions>
                  <TegraButton isAction onClick={handleSearch}>
                    BUSCAR
                  </TegraButton>
                  { user.exportarCorretores &&
                    <TegraButton isAction onClick={exportCorretores}>
                    EXPORTAR
                  </TegraButton>
                  }
                </FormActions>
              </Col>
            </Row>
          </GridContainer>
        </Form>
      </SearchContainer>
      <TegraDivider color="#e6e6e6" axis="x" height={1} />
      <CorretoresContainer>
        {/* <Title>Seus corretores</Title> */}
        <CorretoresTable>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Nome / Razão Social</th>
              <th>CRECI</th>
              <th>E-mail</th>
              {/* <th>Superintendente</th>*/}
              <th>Gerente</th>
              <th>UF</th>
              <th>Regional</th>
              <th>Data Cadastro</th>
              <th>Situação</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {corretores.map(corretor => (
              <tr key={corretor.id_parceiro}>
                <td>
                  <span className="caption">Tipo</span>
                  {corretor.txt_tipoParceiro}
                </td>
                <td>
                  <span className="caption">Nome / Razão Social</span>
                  {corretor.tx_nome || corretor.tx_razaoSocial}
                </td>
                <td>
                  <span className="caption">CRECI</span>
                  {corretor.tx_creci}
                </td>
                <td>
                  <span className="caption">E-mail</span>
                  {corretor.tx_email}
                </td>
                {/* <td>
                  <span className="caption">Superintendente</span>
                  {corretor.superintendente}
                </td>*/}
                <td>
                  <span className="caption">Gerente</span>
                  {corretor.tx_gerente}
                </td>
                <td>
                  <span className="caption">UF</span>
                  {corretor.tx_uf}
                </td>
                <td>
                  <span className="caption">Regional</span>
                  {corretor.tx_regional}
                </td>
                <td>
                  <span className="caption">Data Cadastro</span>
                  {moment(new Date(corretor.dt_cadastro)).format(`DD/MM/YYYY`)}
                </td>
                <td>
                  <span className="caption">Situação</span>
                  {corretor.tx_situacao}
                </td>
                <td className="action">
                  <TegraButtonExtra
                    onClick={() =>
                      handleVisualizar(
                        corretor.id_parceiro,
                        corretor.id_tipoCorretor,
                      )}
                    isAction={false}
                    isExtra
                    icon={openIcon}
                  >
                    Visualizar
                  </TegraButtonExtra>
                  { (
                      (corretor.id_tipoCorretor === 0 &&
                      ((corretor.id_tipoParceiro === 1 || corretor.id_tipoParceiro === 2 || corretor.id_tipoParceiro === 21)))
                    )
                    /*((corretor.id_tipoParceiro === 1 && user.idCargoSigavi !== 14) || (corretor.id_tipoParceiro === 2 || corretor.id_tipoParceiro === 21))))*/
                    && (
                    <TegraButtonExtra
                      onClick={() => handleEditar(corretor.id_parceiro)}
                      isAction={false}
                      isExtra
                      icon={openIcon}
                    >
                      Editar
                    </TegraButtonExtra>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </CorretoresTable>
        {/*currentPage < totalPaginas && (
          <ListaAction>
            <TegraButton isAction onClick={handleLoadMore}>
              Carregar mais
            </TegraButton>
          </ListaAction>
        )*/}
      </CorretoresContainer>
      {totalPaginas > 1 && (
        <div>
          <ContainerPaginate>
            <p className='descSelect'>Itens por Página</p>
            <Select
              value={registrosPagina == 10 ? optionsS[0] : registrosPagina == 25 ? optionsS[1] : optionsS[2] }
              options={optionsS}
              className={'selectPage'}
              onChange={ex => ex ? handleLoadMorePage(0,ex.value) : handleLoadMorePage(0,10)}
            />
            <p className='quantRegistro'>{textoRegistros}</p>
          </ContainerPaginate>
          <div style={{display: 'flex', justifyContent: 'center', marginTop:'30px'}}>
          <MyPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              pageCount={totalPaginas}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              forcePage={currentPage-1}
              onClick={(x) => {handleLoadMorePage(x.nextSelectedPage,registrosPagina)}}
            />
        </div>

        </div>
      )}


    </Container>
  );
};

export default MeusCorretoresLista;
