import React, { useState, useEffect, useMemo } from 'react';
import { ReactComponent as PermutaIcon } from '../../../assets/espelhos_tabela/tegra-icon-permuta.svg';

import { Container, StatusColorBox } from './styles';
import TegraScrollBar from '../../../components/TegraScrollBar';
import api from '../../../services/api';

interface StatusType {
  [key: string]: string;
}

const statuses: StatusType = {
  vendida: '#1a6793',
  estoque: '#FFF',
  disponível: '#FFF',
  reservada: '#e07d29',
  'fora de venda': '#adadab',
  'contrato em processo': '#ebb92c',
  'contrato assinado': '#67b9d9',
  permuta: '#000',
  total: '',
};

interface ILegenda {
  nomeLegendaItem: string;
  ordem: number;
  totalQuantidade: number;
  totalPorcentagem: string;
  legendaItemTotais: {
    bloco: string;
    quantidade: number;
    porcentagem: number;
  }[];
  statusApartamento: string;
}

interface TegraEspelhoStatusTabela {
  idEmpreendimento: number;
}

const TegraEspelhoStatusTabela: React.FC<TegraEspelhoStatusTabela> = ({
  idEmpreendimento,
}) => {
  const [legendas, setLegendas] = useState<ILegenda[]>([]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      const response = await api.get<ILegenda[]>(
        `EspelhosTabelas/legenda/${idEmpreendimento}`,
      );
      const legendaTotal = response.data.find(
        legenda => legenda.statusApartamento === 'total',
      );
      var i;
      for(i=0; i < response.data.length; i++){
        response.data[i].legendaItemTotais = response.data[i].legendaItemTotais.filter(item => item.bloco !== 'Total');
      }

      let quantidadeTotal = 0;

      if (legendaTotal) {
        quantidadeTotal = legendaTotal.legendaItemTotais
          .map(item => item.quantidade)
          .reduce((total, amount) => total + amount, 0);
      }
      

      const data = response.data.map(legenda => ({
        ...legenda,
        totalQuantidade: legenda.legendaItemTotais
          .map(item => item.quantidade)
          .reduce((total, amount) => total + amount, 0),
        totalPorcentagem: (
          (legenda.legendaItemTotais
            .map(item => item.quantidade)
            .reduce((total, amount) => total + amount, 0) *
            100) /
          quantidadeTotal
        ).toFixed(2),
      }));      

      setLegendas(data);
    }
    loadData();
  }, [idEmpreendimento, setLegendas]);

  const tableHeaderBlocos = useMemo<string[]>(() => {
    let headers: string[] = [];
    if (legendas[0]) {      
      const { legendaItemTotais } = legendas[0];
      headers = legendaItemTotais.map(item => item.bloco);
    }
    return headers;
  }, [legendas]);

  return (
    <Container>
      <TegraScrollBar>
        <table>
          <thead>
            <tr>
              <td colSpan={1} />
              <td colSpan={2} />
              <th colSpan={2}>Total</th>
              {tableHeaderBlocos.map(item => (
                <th colSpan={2}>{item}</th>
              ))}
            </tr>
            <tr>
              <td colSpan={1} />
              <td colSpan={2} />
              <th>Qtd</th>
              <th>%</th>
              {tableHeaderBlocos.map(() => (
                <>
                  <th>Qtd</th>
                  <th>%</th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {legendas.map(legenda => (
              <tr>
                <StatusColorBox color={statuses[legenda.statusApartamento]}>
                  {legenda.statusApartamento === 'permuta' && <PermutaIcon />}
                </StatusColorBox>

                <th colSpan={2}>{legenda.nomeLegendaItem}</th>
                <td>{legenda.totalQuantidade}</td>
                <td>{legenda.totalPorcentagem}</td>

                {legenda.statusApartamento !== 'total' &&
                  legenda.legendaItemTotais.map(item => (
                    <>
                      <td>{item.quantidade}</td>
                      <td>{item.porcentagem}</td>
                    </>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TegraScrollBar>
    </Container>
  );
};

export default TegraEspelhoStatusTabela;
