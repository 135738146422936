import styled from "styled-components";
import colors from "../../styles/colors";

export const RankingPageContainer = styled.div`
    background-color: white;
`

export const RankingContentWrapper = styled.div`
    padding: 2.25rem;
    padding-top: 5rem;
`

export const RankingActionContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
`;

export const RankingTabsWrapper = styled.div`
    ul{
        display: flex;
    }
`