import styled from 'styled-components';
import { Container as GridContainer } from 'react-grid-system';


export const Wrapper = styled.div`
  div.divider {
    margin: 20.5px 0 28px;
  }

  & > div.divider {
    margin: 23.5px 0 40px;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
  }

  & > p {
    margin-bottom: 17.5px;
  }
`;

export const WizardNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      margin-top: 40px;
    }
    justify-content: ;
    /* justify-content: flex-start; */
  }

  a {
    display: block;
    font-size: 12px;
    text-decoration: none;
  }
`;

export const WizardNavigationGoBack = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  span {
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
  }
`;

export const ErrorsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background: #d84128;
  padding: 15px;

  p {
    color: #fff;

    & + p {
      margin-top: 5px;
    }
  }
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
`;

export const Subtitle = styled.h2`
  height: 24px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
  margin-bottom: 5px;
`;

export const ExtraActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 33px;

  a {
    display: block;
    text-decoration: none;

    &.jaPossuoCadastro {
      text-decoration: underline;
    }
  }

  p,
  a,
  span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
    cursor: pointer;
  }
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #adadab;

  span {
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #adadab;
  }
`;


export const BlogAction = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 41px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      flex: 1;
      width: 100%;
    }
  }
`;