import {
    ContactsSalesFilterContainer, 
    ContactsSalesFilterLabel,
    ContactsSalesFilterSelect,
    ContactsSalesFilterOption,
    ContactsSalesFilterSelectWrapper
} from "./styles";
import React from "react";
import { IContactGroup } from "../TegraContactGroups";

interface IContactsSalesFilterProps {
    contactGroups: IContactGroup[];
    setFilter: Function;
}

const ContactsSalesFilter: React.FC<IContactsSalesFilterProps> = ({ contactGroups, setFilter }) => {

    return (
        <ContactsSalesFilterContainer>
            <ContactsSalesFilterLabel htmlFor="ContactsSalesFilterSelect">Escolha a área em Tegra Vendas</ContactsSalesFilterLabel>
            <ContactsSalesFilterSelectWrapper>
                <ContactsSalesFilterSelect defaultValue="Todas" onChange={(e) => setFilter(e.target.value)} id="ContactsSalesFilterSelect">
                    <ContactsSalesFilterOption value="Todas">Todas</ContactsSalesFilterOption>
                    {contactGroups.map(contactGroupItem => <ContactsSalesFilterOption key={`contact-sales-option-${contactGroupItem.title}`} value={contactGroupItem.title}>
                        {contactGroupItem.title}
                    </ContactsSalesFilterOption>)}
                </ContactsSalesFilterSelect>
            </ContactsSalesFilterSelectWrapper>
        </ContactsSalesFilterContainer>
    );
};


export default ContactsSalesFilter;