import styled from 'styled-components';
import colors from '../../styles/colors';
import searchIcon from '../../assets/tegra-icon-search.svg';

export const RelatorioPageContainer = styled.div`
  background-color: white;
  .text-center {
    margin-top: 16px;
    text-align: center;
    button {
      display: inline-block;
    }
  }
`;

export const RelatorioContentWrapper = styled.div`
  padding: 2.25rem;
`;

export const RelatorioActionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
`;

export const RelatorioTabsWrapper = styled.div`
  ul {
    display: flex;
  }
`;

export const FilterSearchRow = styled.div`
  display: flex;

  input {
    width: 100%;
    max-width: 500px;
    padding: 0.75rem 1rem;
    margin: 20px 0;
    position: relative;
    border: 1px solid #adadab;
    &:after {
      pointer-events: none;
      content: '';
      background-image: url(${searchIcon});
      position: absolute;
      display: block;
      height: 80%;
      width: 40px;
      right: 0;
      background-position: center center;
      background-repeat: no-repeat;
      top: 10%;
    }
  }
`;

export const TableContainer = styled.table`
  margin-top: 24px;
  display: block;

  table {
    text-align: left;
    width: 100%;

    th,
    td {
      padding: 8px;
      border-bottom: 1px solid #ccc;
      border-collapse: collapse;
      white-space: normal;
      overflow-wrap: anywhere;
      min-width: 160px;
    }
  }
`;
export const FilterGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span > label {
    margin: 0 10px;
  }
  & > label {
    display: block;
    width: 100%;
    margin-bottom: 11px;
    margin-top: 15.5px;
  }
  input,
  select {
    padding: 0.75rem 0.5rem;
    margin: 5px 0;
    min-width: 175px;
    border: 1px solid #adadab;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #60605d;
  }

  option {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #60605d;
  }

  div {
    width: 100%;
    &.w-auto {
      width: auto;
    }
  }

  @media (max-width: 1020px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

export const FilterGridItem = styled.div`
  button {
    display: inline-block;
    margin: 0 10px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  label {
    margin: 0 10px;
  }
  .margin {
    margin: 5px;
  }
`;
export const FilterGridInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;

  input {
    height: 40px;
    border: solid 1px #adadab;
    background-color: #ffffff;
  }
  & > label {
    margin-right: 10px;
  }
`;
