export default function getCurrentDate(){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let seconds = newDate.getSeconds();

  function makeTwoDigits (time: number) {
    const timeString = `${time}`;
    if (timeString.length === 2) return time
    return `0${time}`
  }
  
  return `${date}/${month<10?`0${month}`:`${month}`}/${year} ${makeTwoDigits(hours)}:${makeTwoDigits(minutes)}:${makeTwoDigits(seconds)}`
  
}