import React, { useState } from 'react';
import {
  NavMenuContainer,
  NavClose,
  NavMenuWrapper,
  NavMenuItemContainer,
  NavCloseIcon,
  NavMainItem,
} from './styles';
import Dropdown from '../../NavItem/Dropdown';
import ModalRegional from '../../../ModalRegional';
import closeIcon from '../../../../assets/close-icon.svg';
import NavHypnobox from '../../NavHypnobox';
import { useAuth } from '../../../../hooks/auth';

export interface MenuData {
  active: boolean;
  label: string;
  link: string;
  children?: MenuData[];
  featured?: boolean;
  onlyParceiros?: boolean;
  onlyImob?: boolean;
  changeRegional?: boolean;
  external?: boolean;
}

interface NavMenuProps {
  active: boolean;
  menuData: MenuData[];
  closeMenu: Function;
  icon: string;
  name: string;
  isParceiro: boolean;
  isAdmin: boolean;
  isImobiliaria: boolean;
}

const userData: MenuData = {
  active: false,
  label: 'profile',
  link: '#',
  children: [
    {
      active: false,
      label: 'Trocar de regional',
      link: '',
      changeRegional: true,
    },
    {
      active: false,
      label: 'Meus Dados',
      link: '/perfil/meus-dados',
    },
    {
      active: false,
      label: 'Meus Corretores',
      link: '/perfil/meus-corretores',
      onlyImob: true,
    },
    {
      active: false,
      label: 'Meus Documentos',
      link: '/perfil/meus-documentos',
      onlyParceiros: true,
    },
  ],
};

const NavMenu: React.FC<NavMenuProps> = ({
  active,
  menuData,
  closeMenu,
  icon,
  name,
  isParceiro,
  isAdmin,
  isImobiliaria,
}) => {
  const [closing, setClosing] = useState(false);
  const [modalRegional, setModalRegional] = useState(false);
  const { user } = useAuth();

  const fireCloseMenu = (): void => {
    setClosing(true);
    setTimeout(() => {
      closeMenu();
      setClosing(false);
    }, 490);
  };
  if (!isAdmin) {
    userData.children = userData.children?.filter(
      m => !m.changeRegional || isAdmin,
    );
  }
  if (!isParceiro) {
    userData.children = userData.children?.filter(
      m => !m.onlyParceiros || isParceiro,
    );
  }
  if (!isImobiliaria) {
    userData.children = userData.children?.filter(
      m => !m.onlyImob || isImobiliaria,
    );
  }

  return (
    <>
      <NavMenuContainer active={active} closing={closing}>
        <NavMenuWrapper closing={closing}>
          <NavClose onClick={fireCloseMenu}>
            <NavCloseIcon src={closeIcon} />
          </NavClose>
          <Dropdown
            profile
            menuItem={userData}
            icon={icon}
            name={name}
            setModalRegional={setModalRegional}
          />
          <NavMenuItemContainer>
            {menuData
              .filter(m => !m.onlyParceiros || isParceiro)
              .map(menuItem => (
                <NavMainItem
                  key={`menu-item-${menuItem.link}`}
                  menuItem={menuItem}
                  className={menuItem.external ? 'linkExternal' : ''}
                />
              ))}
          </NavMenuItemContainer>
          {user.acessos.hypnobox && <NavHypnobox />}
        </NavMenuWrapper>
      </NavMenuContainer>
      <ModalRegional isOpen={modalRegional} setIsOpen={setModalRegional} />
    </>
  );
};

export default NavMenu;
